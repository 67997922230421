<template>
  <div class="background">
    <div class="ResetPassword">
      <div class="logo">
        <img src="../assets/ksi logo 1.png" />
      </div>
      <div class="header1">
        <h1>Reset Password</h1>
      </div>
      <div class="header2">
        <a>Silahkan masukkan password baru anda</a>
      </div>
      <div class="body">
        <form>
          <b-form-group label="new password">
            <!-- <b-form-input v-model="forgot.password" type="password" placeholder="input your new password" required></b-form-input> -->

            <v-text-field
              :state="checkpassword"
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              outlined
              :type="show3 ? 'text' : 'password'"
              name="input-10-2"
              v-model="forgot.password"
              hint="At least 8 characters"
              value="wqfasds"
              class="input-group--focused"
              @click:append="show3 = !show3"
              placeholder="Enter Password"
              aria-describedby="password-confirm-correct password-confirm-wrong password-confirm-not-right"
              required
            ></v-text-field>
          </b-form-group>
          <b-form-group label="confirm password">
            <!-- <b-form-input v-model="forgot.rePassword"  type="password" placeholder="confirm your password here"  aria-describedby="password-confirm-correct password-confirm-wrong"></b-form-input>
                            <b-form-invalid-feedback id="password-confirm-wrong">
                                        wrong password
                                    </b-form-invalid-feedback> -->
            <v-text-field
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              outlined
              :type="show2 ? 'text' : 'password'"
              name="input-10-2"
              v-model="forgot.rePassword"
              hint="At least 8 characters"
              value="wqfasds"
              class="input-group--focused"
              @click:append="show2 = !show2"
              placeholder="Enter Password"
              aria-describedby="password-confirm-correct password-confirm-wrong password-confirm-not-right"
              required
            ></v-text-field>
          </b-form-group>
          <div class="button">
            <v-btn x-large color="primary" @click="submit" block>Kirim</v-btn>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  export default {
    name: "resetPassword",
    data() {
      return {
        rules: {
          required: (value) => !!value || "Required.",
          min: (value) =>
            (value || "").length >= 8 ||
            "password minimum 8 karekter alphanumeric",
          //  same:(value)=> value !==
        },
        show3: false,
        show2: false,
        forgot: {
          email: "",
          secUserForgetPwdIdAndCode: this.$route.params.token,
          password: "",
          rePassword: "",
        },
      };
    },
    mounted() {
      (this.email = window.localStorage.getItem("email")),
        (this.secUserForgetPwdIdAndCode = this.$route.params.token);
      console.log(this.$route);
      this.emaildata();
    },
    computed: {},
    methods: {
      emaildata() {
        axios
          .get(
            `https://api.klinisia.id/auth/forget-pwd/by-email/sec-user-forget-pwd-id/${this.secUserForgetPwdIdAndCode}`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          )
          .then((response) => {
            this.forgot.email = response.data.payload.email;
            console.log(response);
          });
      },
      submit() {
        axios
          .post(
            " https://api.klinisia.id/auth/forget-pwd/by-email/confirm",
            this.forgot,
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          )
          .then((response) => {
            if (response.data.errors.length > 0) {
              console.log(response);
              console.log(response.data.errors.length);
              alert("check kembali password");
            } else {
              console.log(response);
              this.$router.push("/reset");
            }
          })
          .catch((error) => {
            console.log(error);
          });
        console.log(this.email);

        console.log(window.localStorage.getItem("email"));
        console.log(this.email);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../scss/resetpassword.scss";
</style>
