<template>
  <v-app class="background">
    <v-container>
      <v-card>
        <v-row>
          <v-col>
            <h4 class="ml-3 mt-4">Add new User</h4>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            <v-form>
              <v-row>
                <v-col cols="4" class="ml-3">
                  <p>Nama</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="param.fullName"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="ml-3">
                  <p>Jenis Role</p>
                </v-col>
                <v-col cols="6">
                  <v-select
                    class=""
                    v-model="roleTypeSelect"
                    :items="typeRoles.map((e) => e.userTypeCode)"
                    label="choose role"
                    dense
                    outlined
                    @input="selectingRoleType"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="ml-3">
                  <p>Role</p>
                </v-col>
                <v-col cols="6">
                  <v-select
                    class=""
                    v-model="roleSelect"
                    :items="Roles.map((e) => e.roleName)"
                    label="choose role"
                    dense
                    outlined
                    @input="selectingRole"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="ml-3">
                  <p>email</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="param.email"
                    placeholder="Enter email address"
                    dense
                    required
                    outlined
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="roleTypeSelect !== 'ksi'">
                <v-col cols="4" class="ml-3">
                  <p>Rumah Sakit</p>
                </v-col>
                <v-col cols="6">
                  <v-select
                    class=""
                    v-model="ChooseHospital"
                    :items="Hospitals.map((e) => e.hospitalName)"
                    label="choose Hospital"
                    dense
                    outlined
                    @input="selectingHospital"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="ml-3">
                  <p>Password</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="param.password"
                    :state="checkpassword"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    outlined
                    :type="show3 ? 'text' : 'password'"
                    name="input-10-2"
                    hint="At least 8 characters"
                    value="wqfasds"
                    class="input-group--focused"
                    @click:append="show3 = !show3"
                    placeholder="Enter Password"
                    aria-describedby="password-confirm-correct password-confirm-wrong password-confirm-not-right"
                    required
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="ml-3">
                  <p>Confirm Password</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="param.rePassword"
                    :state="checkpassword"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    outlined
                    :type="show3 ? 'text' : 'password'"
                    name="input-10-2"
                    hint="At least 8 characters"
                    value="wqfasds"
                    class="input-group--focused"
                    @click:append="show3 = !show3"
                    placeholder="Enter Password"
                    aria-describedby="password-confirm-correct password-confirm-wrong password-confirm-not-right"
                    required
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col offset-md="9">
                  <v-btn
                    class="btn-edit"
                    @click="reset"
                    medium
                    outlined
                    color="primary"
                    style="margin-right: 15px"
                    >back</v-btn
                  >
                  <v-btn
                    class="btn-edit"
                    medium
                    @click="submited"
                    color="success"
                    >add</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import adminService from "@/services/adminList_servicce";
export default {
  data: () => ({
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
    Roles: [],
    typeRoles: [],
    roleTypeSelect: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (value) =>
        (value || "").length >= 8 || "password minimum 8 karekter alphanumeric",
      //  same:(value)=> value !==
    },
    show3: false,
    Hospitals: ["Rs sebelah omah"],
    ChooseHospital: "",
    roleSelect: "",
    param: {
      hptHospitalId: "",
      userType: "",
      email: "",
      mobilePhone: "2789951468522",
      randomPassword: false,
      password: "",
      rePassword: "",
      fullName: "",
      gender: "male",
      userRoles: [
        {
          secRoleId: "",
          default: true,
        },
      ],
    },
    // {
    //   "hptHospitalId": "string",
    //   "userType": "admin",
    //   "email": "testing1@gmail.com",
    //   "mobilePhone": "2789951468522",
    //   "randomPassword": true,
    //   "password": "string",
    //   "rePassword": "string",
    //   "fullName": "string",
    //   "gender": "male",
    //   "userRoles": [
    //     {
    //       "secRoleId": "KSI-SA",
    //       "default": true
    //     }
    //   ]
    // }
  }),
  mounted() {
    this.RoleTypeList();
    this.HospitalList();
  },
  methods: {
    reset() {
      this.$router.push("/listControlAdmin");
    },
    submited() {
      if(this.param.userType === 'ksi'){
        if(this.param.fullName === ''){
        this.$toast.open({
          message: "nama harus terisi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      }else if(this.param.userRoles[0].secRoleId === ''){
        this.$toast.open({
          message: "pilih role terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      }
      else if(this.param.email === ''){
        this.$toast.open({
          message: "isi email terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      }     
      else if(this.param.password === ''){
        this.$toast.open({
          message: "isi password terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      }
      else if (this.param.password !== this.param.rePassword) {
        this.$toast.open({
          message: "password tidak sama",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      }
      else if (
        !this.param.email.includes("@") ||
        !this.param.email.includes(".")
      ) {
        this.$toast.error("Format Email Tidak Sesuai", {
          type: "error",
          position: "top-right",
          duration: 4000,
        });
      } else {
        console.log(this.param);
        adminService
          .submitAdmin(this.param)
          .then((res) => {
            console.log(res);
            if (res.data.error !== "") {
              this.$toast.open({
                message: res.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
            } else {
              this.$toast.open({
              message: "Admin Berhasil Ditambahkan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
              this.$router.push("/listControlAdmin");
            }
          })
          .catch((err) => console.log(err));
      }
      }
      else {
        if(this.param.fullName === ''){
        this.$toast.open({
          message: "nama harus terisi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      }else if(this.param.userRoles[0].secRoleId === ''){
        this.$toast.open({
          message: "pilih role terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      }
      else if(this.param.email === ''){
        this.$toast.open({
          message: "isi email terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      }
      else if(this.param.hptHospitalId === ''){
        this.$toast.open({
          message: "pilih rumah sakit terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      }
      else if(this.param.password === ''){
        this.$toast.open({
          message: "isi password terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      }
      else if (this.param.password !== this.param.rePassword) {
        this.$toast.open({
          message: "password tidak sama",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      }
      else if (
        !this.param.email.includes("@") ||
        !this.param.email.includes(".")
      ) {
        this.$toast.error("Format Email Tidak Sesuai", {
          type: "error",
          position: "top-right",
          duration: 4000,
        });
      } else {
        console.log(this.param);
        adminService
          .submitAdmin(this.param)
          .then((res) => {
            console.log(res);
            if (res.data.error !== "") {
              this.$toast.open({
                message: res.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
            } else {
              this.$toast.open({
              message: "Admin Berhasil Ditambahkan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
              this.$router.push("/listControlAdmin");
            }
          })
          .catch((err) => console.log(err));
      }}
    },
    selectingHospital() {
      console.log(this.ChooseHospital);
      var data = this.Hospitals.filter((item) =>
        this.ChooseHospital === item.hospitalName
      );
      console.log(data);
      this.param.hptHospitalId = data[0].hptHospitalId;
      console.log(this.param);
    },
    HospitalList() {
      adminService
        .getHospital()
        .then((res) => {
          console.log(res);
          this.Hospitals = res.data.payload.content;
          // this.Roles = res.data.payload.content
        })
        .catch((err) => console.log(err));
    },
    selectingRole() {
      var data = this.Roles.filter((item) =>
        this.roleSelect === item.roleName
      );
      // console.log(data[0].secRoleId)
      console.log(this.param);
      this.param.userRoles[0].secRoleId = data[0].secRoleId;
      console.log(this.param);
    },
    selectingRoleType() {
      console.log(this.roleTypeSelect);
      this.param.userType = this.roleTypeSelect;
      adminService
        .getRole(this.roleTypeSelect)
        .then((res) => {
          console.log(res);
          this.Roles = res.data.payload.content;
        })
        .catch((err) => console.log(err));
    },
    RoleTypeList() {
      adminService
        .getTypeRole()
        .then((res) => {
          console.log(res);
          this.typeRoles = res.data.payload.content;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style>
.background {
  background-color: #edf4fb;
}
</style>
