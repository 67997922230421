<template>
  <v-app class="background">
    <v-container>
      <v-card class="cardStyle">
        <v-row class="ml-1">
          <v-col md="4" sm="12">
            <h6
              class="mt-4"
              style="font-size:22px font-family:Nunito  font-weight: 600"
            >
              Corporate Member Adaro
            </h6>
          </v-col>
          <v-col md="6" sm="12" class="mr-2">
            <v-text-field
              label="search"
              append-icon="mdi-magnify"
              outlined
              class="mt-3"
              @keyup.enter="onSearch"
              v-model="search"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ml-2">
          <v-col cols="2" class="mr-11">
            <v-btn
              v-if="menuList[0].create === true"
              class="mt-1"
              small
              color="success"
              to="/addCorporateMember"
              dark
            >
              <v-icon dark small> mdi-plus </v-icon> Tambah Member</v-btn
            >
          </v-col>
          <v-col cols="2" class="mb-6">
            <v-select
              hide-details=""
              @change="selectedPage()"
              v-model="pageNumber"
              :items="slot"
              outlined
              dense
              class="text"
            ></v-select>
          </v-col>
          <v-col cols="6" class="mb-6">
            <v-btn
              class="mt-1 ml-15"
              small
              color="primary"
              to="/reportMember"
              dark
            >
              <v-icon class="mr-1" dark small> visibility </v-icon> Lihat Hasil Upload
              Terakhir</v-btn
            >
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="mt-3">
          <v-col>
            <div>
              <v-data-table
                :headers="headers()"
                :items="dataMember"
                hide-default-footer
                :search="search"
                class="elevation-1"
                @change="change"
                dense
              >
                <template v-slot:item.status="{ item }">
                  <td>
                    <v-switch
                      v-model="item.active"
                      dense
                      class="swith"
                      :label="item.status"
                      @change="changeSwitch(item)"
                    >
                    </v-switch>
                  </td>
                </template>
                <template v-slot:item.aksi="{ item }">
                  <td v-if="menuList[0].update === true">
                    <v-btn
                      color="primary"
                      dark
                      x-small
                      class="mr-2"
                      @click="edit(item)"
                    >
                      <v-icon dark x-small class="mr-1">mdi-pencil</v-icon>
                      <p class="mt-4">Ubah</p>
                    </v-btn>
                  </td>
                  <td v-else-if="menuList[0].update === false">
                    <v-btn
                      color="primary"
                      dark
                      x-small
                      class="mr-2"
                      @click="lihat(item)"
                    >
                      <v-icon dark x-small class="mr-1">mdi-pencil</v-icon>
                      <p class="mt-4">Lihat</p>
                    </v-btn>
                  </td>
                  <!-- <td>
                    <v-btn
                      v-if="menuList[0].delete === true"
                      color="error"
                      x-small
                      dark
                      class="mr-2"
                      @click="dels(item)"
                    >
                      <v-icon dark x-small class="mr-1">mdi-delete</v-icon>Hapus
                    </v-btn>
                  </td> -->
                </template>
              </v-data-table>
              <v-row>
                <v-col>
                  <v-pagination
                    circle
                    v-model="page"
                    :length="totalpage.totalPages"
                    :total-visible="7"
                    @input="choosePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  components: {
    // Popup
  },
  data: () => ({
    
    search: "",
    menuList: {},
    slot: [5, 10, 15, 20],
    pageNumber: 10,
    paginate: { name: "", page: 0, size: 10 },
    totalpage: [],
    pageCount: 0,
    page: 1,
    login: {},
    feature: [],
    dataMember: [],
    namadelete: "",
    iddelete: "",
    dhapus: false,
    isactive: [
      {
        status: true,
        label: "Aktif",
      },
      {
        status: false,
        label: "Non Aktif",
      },
    ],
    rules: [(v) => !!v || " is required"],
  }),
  mounted() {
    this.loginData();
    this.getDataMember();
  },
  watch: {},
  computed: {},
  methods: {
    loginData() {
      this.login = JSON.parse(window.localStorage.getItem("loginData"));
      console.log("login list", this.login.features);
      this.menuList = this.login.features.filter(
        (item) => item.featureName === "Corporate Maintenance"
      );
      console.log(this.menuList);
    },
    // onSearch() {
    //   this.paginate.page = 0;
    //   // this.paginate.size = 100000000
    //   this.paginate.name = this.search;
    //   this.getDataMember(this.paginate);
    // },
    // choosePage(page) {
    //   console.log(page);
    //   this.paginate.page = page - 1;
    //   console.log(this.paginate);
    //   this.getDataMember(this.paginate);
    // },
    // selectedPage() {
    //   console.log(this.pageNumber);
    //   this.paginate.size = this.pageNumber;
    //   this.getDataMember(this.paginate);
    // },
    changeSwitch(item) {
      console.log(item);
      if (item.active) {
        item.status = "Aktif";
      } else {
        item.status = "Resign";
      }
    },
    headers() {
      return [
        // {
        //   text: "No",
        //   align: "start",
        //   sortable: true,
        //   value: "index",
        //   class: "primary--text judul",
        // },
        {
          text: "No Karyawan",
          value: "noKaryawan",
          class: "primary--text",
        },
        {
          text: "NIK",
          value: "nik",
          class: "primary--text",
        },
        {
          text: "Nama Karyawan",
          value: "namaKaryawan",
          // value: "icdName",
          class: "primary--text",
        },
        {
          text: "No Telp",
          value: "noHp",
          // value: "icdName",
          class: "primary--text",
        },
        {
          text: "Status",
          value: "status",
          class: "primary--text",
        },
        {
          text: "Action",
          value: "aksi",
          class: "primary--text judul",
        },
      ];
    },

    edit(item) {
      console.log(item);
      this.$router.push("/editCorporateMember");
    },
    lihat(item) {
      console.log(item);
      this.$router.push("/lihatCorporateMember");
    },
    getDataMember() {
      let data = [
        {
          noKaryawan: "0001",
          nik: "3578171876900004",
          namaKaryawan: "Arcyla Odelina",
          noHp: "086412887136",
          active: true,
        },
        {
          noKaryawan: "0002",
          nik: "3578171876900004",
          namaKaryawan: "Tandy Alam",
          noHp: "09876715436",
          active: true,
        },
        {
          noKaryawan: "0003",
          nik: "3578171876900004",
          namaKaryawan: "Tofan",
          noHp: "086412887136",
          active: true,
        },
        {
          noKaryawan: "0004",
          nik: "3578171876900004",
          namaKaryawan: "Arif Ananto",
          noHp: "086412887136",
          active: false,
        },
      ];
      for (let i = 0; i < data.length; i++) {
        this.dataMember.push(data[i]);
      }
      this.dataMember.forEach((e) => {
        if (e.active) {
          e.status = "Aktif";
        } else {
          e.status = "Resign";
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/dashboard.scss";
.titleContent {
  font-weight: 500;
  line-height: 1rem !important;
}
.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
.background {
  background-color: #edf4fb;
}
.cardsearch {
  background-color: #ffffff;
  border-radius: 30px;
  width: 99%;
  margin-left: 2px;
}
.headerComponent {
  margin-left: 250px;
}
.datapasien {
  margin-left: 250px;
  margin-right: 120px;
}
.header {
  margin-left: 250px;
  /* margin-right: 250px; */
}
.poppuphead {
  text-align: center;
}
.swith {
  width: 15px;
}
.v-card__title {
  font-size: 0.9rem !important;
  line-height: 1rem !important;
}
.instructionType {
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.search {
  margin-top: 15px;
  margin-left: 10px;
}
.detail {
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.nama {
  font-family: Nunito;
  font-size: 22px;
  text-align: left;
}
.icd-search {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.icd-text {
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
}
.contentTable {
  background: #e9f5fc;
  border-radius: 15px;
}
.text {
  font-family: Nunito;
  font-size: 11px;
  font-weight: 600;
  height: 2px !important;
  width: 80px !important;
}
</style>