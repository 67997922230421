import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://api.klinisia.id/";

class geo {
  async getCountry() {
    return await axios.get(
      `${API_URL}ksi/master/geography/country/search?page=0&size=100000&sortDirection=ASC&sortField=countryCode`,
      { headers: headers() },
    );
  }
  async getCountryPage(param) {
    return await axios.get(
      `${API_URL}ksi/master/geography/country/search?countryName=${param.name}&page=${param.page}&size=${param.size}&sortDirection=ASC&sortField=countryCode`,
      { headers: headers() },
    );
  }
  async getProvincePage(data) {
    return await axios.get(
      `${API_URL}ksi/master/geography/province/search?geoCountryId=${data.id}&provinceName=${data.name}&page=${data.page}&size=${data.size}&sortDirection=ASC&sortField=provinceCode`,
      { headers: headers() },
    );
  }
  async getProvince(data) {
    return await axios.get(
      `${API_URL}ksi/master/geography/province/search?geoCountryId=${data}&page=0&size=100000&sortDirection=ASC&sortField=provinceCode`,
      { headers: headers() },
    );
  }
  async getCityPage(data) {
    return await axios.get(
      `${API_URL}ksi/master/geography/city/search?geoProvinceId=${data.id}&cityName=${data.name}&page=${data.page}&size=${data.size}&sortDirection=ASC&sortField=cityCode`,
      { headers: headers() },
    );
  }
  async getCity(data) {
    return await axios.get(
      `${API_URL}ksi/master/geography/city/search?geoProvinceId=${data}&page=0&size=100000&sortDirection=ASC&sortField=cityCode`,
      { headers: headers() },
    );
  }
  async getDistrictPage(data) {
    return await axios.get(
      `${API_URL}ksi/master/geography/district/search?geoCityId=${data.id}&districtName=${data.name}&page=${data.page}&size=${data.size}&sortDirection=ASC&sortField=districtCode`,
      { headers: headers() },
    );
  }
  async getDistrict(data) {
    return await axios.get(
      `${API_URL}ksi/master/geography/district/search?geoCityId=${data}&page=0&size=100000&sortDirection=ASC&sortField=districtCode`,
      { headers: headers() },
    );
  }
  async getSubDistrictPage(data) {
    return await axios.get(
      `${API_URL}ksi/master/geography/sub-district/search?geoDistrictId=${data.id}&subDistrictName=${data.name}&page=${data.page}&size=${data.size}&sortDirection=ASC&sortField=subDistrictCode`,
      { headers: headers() },
    );
  }
  async getSubDistrict(data) {
    return await axios.get(
      `${API_URL}ksi/master/geography/sub-district/search?geoDistrictId=${data}&page=0&size=10000&sortDirection=ASC&sortField=subDistrictCode`,
      { headers: headers() },
    );
  }
  async addCountry(params) {
    return await axios.post(`${API_URL}ksi/master/geography/country`, params, {
      headers: headers(),
    });
  }
  async delCountry(data) {
    return await axios.delete(
      `${API_URL}ksi/master/geography/country/${data}`,
      {
        headers: headers(),
      },
    );
  }
  async editCountry(request) {
    return await axios.put(
      `${API_URL}ksi/master/geography/country/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async addProvince(params) {
    return await axios.post(`${API_URL}ksi/master/geography/province`, params, {
      headers: headers(),
    });
  }
  async delProvince(data) {
    return await axios.delete(
      `${API_URL}ksi/master/geography/province/${data}`,
      {
        headers: headers(),
      },
    );
  }
  async editProvince(request) {
    return await axios.put(
      `${API_URL}ksi/master/geography/province/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async addCity(params) {
    return await axios.post(`${API_URL}ksi/master/geography/city`, params, {
      headers: headers(),
    });
  }
  async editCity(request) {
    return await axios.put(
      `${API_URL}ksi/master/geography/city/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async delCity(data) {
    return await axios.delete(`${API_URL}ksi/master/geography/city/${data}`, {
      headers: headers(),
    });
  }
  async addDistrict(params) {
    return await axios.post(`${API_URL}ksi/master/geography/district`, params, {
      headers: headers(),
    });
  }
  async editDistrict(request) {
    return await axios.put(
      `${API_URL}ksi/master/geography/district/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async delDistrict(data) {
    return await axios.delete(
      `${API_URL}ksi/master/geography/district/${data}`,
      {
        headers: headers(),
      },
    );
  }
  async addSubDistrict(params) {
    return await axios.post(
      `${API_URL}ksi/master/geography/sub-district`,
      params,
      {
        headers: headers(),
      },
    );
  }
  async editSubDistrict(request) {
    return await axios.put(
      `${API_URL}ksi/master/geography/sub-district/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async delSubDistrict(data) {
    return await axios.delete(
      `${API_URL}ksi/master/geography/sub-district/${data}`,
      {
        headers: headers(),
      },
    );
  }
  async getSubDistrictByID(request) {
    return await axios.get(
      `${API_URL}ksi/master/geography/sub-district/${request}`,
      { headers: headers() },
    );
  }
  async getProvinceByID(request) {
    return await axios.get(
      `${API_URL}ksi/master/geography/province/${request}`,
      { headers: headers() },
    );
  }
}

export default new geo();
