import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://api.klinisia.id/";

class Schedule {
  async getSPecialization() {
    return await axios.get(`${API_URL}general/doctor/medic-specialization`, {
      headers: headers(),
    });
  }
  async getListDoctor(param) {
    return await axios.get(
      `${API_URL}admin/doctor/list?page=${param.page}&size=${param.size}&docMstMedicSpecializationIds=${param.id}&search=${param.name}&sortDirection=ASC`,
      {
        headers: headers(),
      },
    );
  }
  async getSchdule(request) {
    return await axios.get(
      `${API_URL}admin/doctor/${request.DocId}/schedule?schDate=${request.date}`,
      {
        headers: headers(),
      },
    );
  }
  async postTime(request) {
    return await axios.post(
      `${API_URL}admin/doctor/${request.id}/schedule`,
      request.body,
      {
        headers: headers(),
      },
    );
  }
  // async getListSchdule(request) {
  //   return await axios.get(
  //     `${API_URL}admin/consultation/appointment/list/by-doctor-sch-date?docDoctorHospitalId=${request.id}&schDate=${request.date}`,
  //     { headers: headers() },
  //   );
  // }
  async getListSchdule(request) {
    return await axios.get(
      `${API_URL}admin/appointment/cancellation/list/by-doctor-sch-date?docDoctorHospitalId=${request.id}&schDate=${request.date}`,
      {
        headers: headers(),
      },
    );
  }
  async deleteSchadule(data) {
    return await axios.post(
      `${API_URL}admin/consultation/appointment/cancel`,
      data,
      {
        headers: headers(),
      },
    );
  }
  async cancelAppointment(data) {
    return await axios.post(
      `${API_URL}admin/appointment/cancellation/cancel`,
      data,
      {
        headers: headers(),
      },
    );
  }
  async getListApproval() {
    return await axios.get(
      `${API_URL}admin/appointment/approval-cancellation/list?page=0&size=200&sortDirection=ASC&sortField=StartDate`,
      {
        headers: headers(),
      },
    );
  }
  async postApprove(request) {
    return await axios.post(
      `${API_URL}admin/appointment/approval-cancellation/approve`,
      request,
      {
        headers: headers(),
      },
    );
  }
  async postReject(request) {
    return await axios.post(
      `${API_URL}admin/appointment/approval-cancellation/reject`,
      request,
      {
        headers: headers(),
      },
    );
  }
  // async postTime(request){
  //     return await axios.post(`${API_URL}admin/doctor/${request.id}/schedule`, request.body, { headers: headers() })
  // }
  // async deleteICD(request){
  //     return await axios.delete(`${API_URL}ksi/master/consultation/icd-code/${request}`, { headers: headers() })
  // }
}

export default new Schedule();
