<template>
<div class="background">
    <div class="reset">
        <div class="logo">
            <img src="../assets/Vector.svg"/>
        </div>
        <div class="body">
            <h1>Reset Password Berhasil</h1>
        </div>
        <div class="content">
            <a>Silahkan cek email Anda untuk mendapatkan password baru.</a>
        </div>
        <div class="footer">
            <v-btn x-large color="primary" to="/login" block>Kembali ke Halaman Utama</v-btn>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name:'reset',
    data(){
        return{

        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../scss/Reset.scss";
</style>