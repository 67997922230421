<template>
  <v-app class="background">
    <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row>
          <v-col sm="12" md="4" lg="3" class="search">
            <p
              style="
              color: #2b92e4;
              font-family: Inter;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 34px;
              letter-spacing: 0em;
              text-align: left;
            "
            >
              Konfirmasi Obat
            </p>
          </v-col>
          <v-col sm="12" md="6" lg="6" class="search">
            <v-text-field
              label="search"
              v-model="Search"
              append-icon="mdi-magnify"
              outlined
              @keyup.enter="onSearch"
              rounded
              dense
            ></v-text-field>
            <!-- </v-col> -->
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container>
      <v-row class="ml-1">
        <v-col lg="2" sm="4">
          <p class="text-spesialis">Tampilkan</p>
        </v-col>
        <v-col lg="2" sm="6">
          <v-select
            hide-details
            v-model="pageNumber"
            :items="slot"
            outlined
            @change="selectedPage()"
            dense
            style="background: white"
            item-color="success"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mt-n2">
      <v-data-table
        :page.sync="page"
        :headers="header"
        :items="indexof"
        :server-items-length="pageCount"
        :search="Search"
        hide-default-footer
        class="elevation-1 cardStyle"
      >
        <template v-slot:item.detail="{ item }">
          <v-btn
            v-if="
              item.prescriptionStatus == 'phrConfReq' &&
                menuList[0].update === true
            "
            color="blue"
            outlined
            x-small
            dark
            @click.stop="open(item)"
            >kirim Harga</v-btn
          >
          <p
            v-else-if="
              item.prescriptionStatus == 'phrConfReq' &&
                menuList[0].update === false
            "
          >
            kirim Harga
          </p>
          <template v-else-if="item.phrOrderStatus === 'waitPayment'"
            >Menunggu Pembayaran</template
          >
          <template v-else-if="item.phrOrderStatus === 'canceled'"
            >Pesanan Di Batalkan</template
          >
          <template v-else-if="item.prescriptionStatus === 'phrConfResp'"
            >oldresponse</template
          >
          <v-btn
            x-small
            v-else-if="
              item.phrOrderStatus === 'process' && menuList[0].update === true
            "
            class="success"
            dark
            @click.stop="open2(item)"
            >Pesanan Diproses</v-btn
          >
          <p
            v-else-if="
              item.phrOrderStatus === 'process' && menuList[0].update === false
            "
          >
            Pesanan Diproses
          </p>
          <v-btn
            x-small
            :disabled="!btnDisabled"
            v-else-if="item.phrOrderStatus == 'ready'"
            color="info"
            dark
            @click.stop="finish(item)"
            >obat siap dikirim</v-btn
          >
          <template v-else-if="item.phrOrderStatus == 'finish'"
            >Selesai</template
          >
        </template>
        <template v-slot:item.consultationDate="{ item }">
          <a>{{ item.phrConfirmReqDatetime | orderDate }}</a>
        </template>
        <template v-slot:item.consultationTime="{ item }">
          <a>{{ item.phrConfirmReqDatetime | orderTime }}</a>
        </template>
      </v-data-table>

      <!-- modal -->
      <v-dialog v-model="visible" width="1200" :retain-focus="false">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="10">
                <h1>
                  Order #{{ detailPasien.prescription.prescriptionNumber }}
                </h1>
                <p>
                  {{
                    detailPasien.prescription.phrConfirmReqDatetime | orderDate
                  }}, PK
                  {{
                    detailPasien.prescription.phrConfirmReqDatetime | orderTime
                  }}
                </p>
              </v-col>
              <v-col>
                <v-icon @click="visible = false" class="float-right" large
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-container style="background-color: rgba(235, 239, 242, 0.6)">
              <v-row>
                <v-col>
                  <div class="mb-3">
                    <p class="detail">Detail Pasien</p>
                  </div>
                  <div>
                    <p class="nama">
                      {{ detailPasien.patient.firstName }}
                      {{ detailPasien.patient.middleName }}
                      {{ detailPasien.patient.lastName }}
                    </p>
                    <p>Phone: {{ detailPasien.patient.mobilePhone }}</p>
                  </div>
                </v-col>
                <v-col>
                  <div class="mb-3">
                    <p class="detail">No Rekam Medis</p>
                  </div>
                  <p class="nama">
                    {{ detailPasien.patient.medicalRecordNumber }}
                  </p>
                </v-col>
                <v-col>
                  <div class="mb-3">
                    <p class="detail">Alamat Pengiriman</p>
                  </div>

                  <p class="nama">
                    {{ detailPasien.address.address }} <br />
                    {{ detailPasien.address.districtName }} ,{{
                      detailPasien.address.subDistrictName
                    }}
                    <br />
                    {{ detailPasien.address.provinceName }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="mb-3">
                    <p class="detail">Dokter</p>
                  </div>
                  <p class="nama">Dokter: {{ detailPasien.doctor.fullName }}</p>
                  <v-spacer></v-spacer>
                  <p>SIP: {{ detailPasien.doctor.sipNumber }}</p>
                </v-col>
              </v-row>
            </v-container>
            <div class="text-center my-5">
              <span
                style="
                  font-family: Inter;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 29px;
                  letter-spacing: 0em;
                  text-align: center;
                  color: #2b92e4;
                "
                >Rekomendasi Obat</span
              >
            </div>
            <v-simple-table
              :items-per-page="itemsPerPage"
              :page.sync="page"
              @page-count="pageCount = $event"
            >
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nama Obat</th>
                  <th>Kekuatan</th>
                  <th>Qty</th>
                  <th>Instruksi</th>
                  <th>Harga</th>
                  <th>Satuan</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody v-for="(ngobat, index) in detailobat" :key="index">
                <tr>
                  <td>
                    <p class="mt-3" v-text="ngobat.prescriptionDetailOrder"></p>
                  </td>
                  <td width="250px">
                    <p v-text="ngobat.medicineBrandName" class="mt-3"></p>
                  </td>
                  <td>
                    {{ ngobat.medicineSize }} {{ ngobat.medicineSizeUnitType }}
                  </td>
                  <td>
                    <v-text-field
                      @change="changeTotalMed(ngobat.totalMedicine, index)"
                      v-model="ngobat.totalMedicine"
                      class="mt-3"
                      type="number"
                      dense
                      outlined
                      min="0"
                      :max="ngobat.max"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-row>
                      <v-col cols="auto">
                        <span class="instructionType">{{
                          ngobat.instructionTypes.map((e) => e.label).toString()
                        }}</span>
                      </v-col>
                    </v-row>
                  </td>
                  <td>
                    <v-text-field
                      @change="changeItemPrice(ngobat.itemPrice, index)"
                      v-model="ngobat.itemPrice"
                      min="0"
                      class="mt-3"
                      type="number"
                      dense
                      outlined
                    ></v-text-field>
                  </td>
                  <td>
                    <span>{{ ngobat.medicineType | caps | caps }}</span>
                  </td>
                  <td>
                    <span>{{ ngobat.totalMedicine * ngobat.itemPrice }}</span>
                  </td>
                </tr>
                <tr v-if="nambah.toggle === index">
                  <td colspan="8" class="px-0">
                    <v-card class style="background: #e5fff3">
                      <v-card-title>
                        <v-row>
                          <v-col>
                            <span>Nama Obat</span>
                          </v-col>
                          <v-col>Bentuk</v-col>
                          <v-col>Kekuatan</v-col>
                          <v-col>Cara Pakai</v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <v-autocomplete
                              auto-select-first
                              v-model="namaObat"
                              @change="changedObat()"
                              solo
                              flat
                              :items="dataSearch"
                              item-text="medicineBrandName"
                              item-value="medicineBrandName"
                              return-object
                              :search-input.sync="search"
                              hide-details
                              outlined
                            ></v-autocomplete>
                          </v-col>
                          <v-col>
                            <v-combobox
                              outlined
                              v-model="typeObat"
                              :items="itemsObat"
                              item-value="value"
                              item-text="label"
                              solo
                              flat
                            ></v-combobox>
                          </v-col>
                          <v-col>
                            <v-row>
                              <v-col cols="6" class="py-0">
                                <v-text-field
                                  v-model="powerMg"
                                  min="0"
                                  outlined
                                  style="number"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6" class="py-0">
                                <v-combobox
                                  outlined
                                  v-model="typeUnit"
                                  solo
                                  flat
                                  :items="itemUnit"
                                  item-text="label"
                                  item-value="value"
                                  return-object
                                  hide-details
                                  class="customStyleTF"
                                ></v-combobox>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col>
                            <v-combobox
                              outlined
                              v-model="howToUse"
                              solo
                              flat
                              :items="itemHtu"
                              item-text="label"
                              item-value="value"
                              return-object
                              hide-details
                              class="customStyleTF"
                            ></v-combobox>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="1">
                            <div class="titleContent pb-5">Frekuensi</div>
                            <v-text-field
                              v-model="frekuensiObat"
                              min="0"
                              outlined
                              style="number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <div class="titleContent pb-5">Dosis</div>
                            <v-text-field
                              v-model="dosis"
                              min="0"
                              outlined
                              style="number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <div class="titleContent pb-5">Sigma</div>
                            <v-combobox
                              v-model="typePeriod"
                              :items="itemPeriod"
                              item-value="value"
                              item-text="label"
                              auto-select-first
                              solo
                              flat
                              outlined
                              hide-details
                              class="customStyleTF"
                            ></v-combobox>
                          </v-col>
                          <v-col cols="1">
                            <div class="titleContent pb-5">Total Hari</div>
                            <v-text-field
                              v-model="totalHari"
                              min="0"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <div class="titleContent pb-5">Jumlah</div>
                            <!-- <v-text-field
                              outlined
                              disabled
                              filled
                              :value="sum"
                            ></v-text-field> -->
                            <v-text-field
                              outlined
                              filled
                              v-model="sum"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <div class="titleContent pb-5">Instruksi</div>
                            <v-row>
                              <v-col cols="3">
                                <v-checkbox
                                  v-model="checkBeforeEat.id"
                                  :label="checkBeforeEat.value"
                                  color="info"
                                  hide-details
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="3">
                                <v-checkbox
                                  v-model="checkAfterEat.id"
                                  :label="checkAfterEat.value"
                                  color="info"
                                  hide-details
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="3">
                                <v-checkbox
                                  v-model="checkIfSpent.id"
                                  :label="checkIfSpent.value"
                                  color="info"
                                  hide-details
                                  @change="isChecked(checkIfSpent)"
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="3">
                                <v-checkbox
                                  v-model="checkIfNeeded.id"
                                  :label="checkIfNeeded.value"
                                  color="info"
                                  hide-details
                                  @change="isChecked(checkIfNeeded)"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-alert
                            v-show="showAlert"
                            class="ml-13 mr-13"
                            outlined
                            type="error"
                            text
                            >{{ textAlert }}</v-alert
                          >
                        </v-row>
                        <v-row>
                          <v-col class="text-right">
                            <v-btn
                              color="primary"
                              outlined
                              small
                              @click="cancel(index)"
                              rounded
                              >Batal</v-btn
                            >
                          </v-col>
                          <v-col cols="auto" class="text-right">
                            <v-btn
                              color="blue"
                              rounded
                              small
                              @click="addObat(nambah.id)"
                              >Simpan</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </td>
                </tr>
                <tr v-if="ngobat.substitute" bgcolor="#64B5F6">
                  <td>
                    <p class="mt-3"></p>
                  </td>
                  <td>
                    <p
                      v-text="ngobat.substitute.medicineBrandName"
                      class="mt-3"
                    ></p>
                  </td>
                  <td>
                    {{ ngobat.substitute.medicineSize }}
                    {{ ngobat.substitute.medicineSizeUnitType }}
                  </td>
                  <td>
                    <v-text-field
                      @change="
                        changeItemPrice(ngobat.substitute.totalMedicine, index)
                      "
                      v-model.number="ngobat.substitute.totalMedicine"
                      :value="ngobat.substitute.totalMedicine"
                      class="mt-3"
                      type="number"
                      dense
                      outlined
                      min="0"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-row>
                      <v-col cols="auto">
                        <span class="instructionType">{{
                          ngobat.substitute.instructionTypes
                            .map((e) => e.value)
                            .toString()
                        }}</span>
                      </v-col>
                    </v-row>
                  </td>
                  <td>
                    <v-text-field
                      @change="
                        changeItemPrice(ngobat.substitute.itemPrice, index)
                      "
                      v-model.number="ngobat.substitute.itemPrice"
                      min="0"
                      class="mt-3"
                      type="number"
                      dense
                      outlined
                    ></v-text-field>
                  </td>
                  <td>
                    <span>{{
                      ngobat.substitute.medicineType | caps | caps
                    }}</span>
                  </td>
                  <td>
                    <span>{{
                      ngobat.substitute.totalMedicine *
                        ngobat.substitute.itemPrice
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <div class="text-center">
              <v-card class="mt-10">
                <v-card-title
                  class="justify-center"
                  style="background: #86f2be"
                >
                  <div>
                    <span
                      style="
                        font-family: Nunito;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-align: center;
                      "
                      >Order Summary</span
                    >
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col class="col-md-3 offset-md-3 pt-6">
                      <span>Total Pesanan</span>
                    </v-col>
                    <v-col class="py-6">
                      <!-- <div class="float-left"> -->
                      <span class="float-left" v-text="totalPesanan"></span>
                      <!-- </div> -->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-md-3 offset-md-3">
                      <span>BIaya Pengiriman</span>
                    </v-col>
                    <v-col class="col-md-3 py-0">
                      <v-text-field
                        v-model="shipping"
                        type="number"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-md-3 offset-md-3 py-0 font-weight-bold"
                      >TOTAL</v-col
                    >
                    <v-col class="py-0">
                      <div class="float-left">
                        <p class="font-weight-bold">{{ totalPurchase }}</p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    color="primary"
                    large
                    outlined
                    rounded
                    @click="visible = false"
                    >Batal</v-btn
                  >
                </v-col>
                <v-col cols="auto" class="text-right">
                  <v-btn large color="success" rounded @click="submit()"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="visible2" width="1200" :retain-focus="false">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="10">
                <h1>
                  Order #{{ detailPasien2.prescription.prescriptionNumber }}
                </h1>
                <p>
                  {{
                    detailPasien2.prescription.phrConfirmReqDatetime
                      | orderDate
                  }}, PK
                  {{
                    detailPasien2.prescription.phrConfirmReqDatetime | orderTime
                  }}
                </p>
              </v-col>
              <v-col>
                <v-icon @click="visible2 = false" class="float-right" large
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-container style="background-color: rgba(235, 239, 242, 0.6)">
              <v-row>
                <v-col>
                  <div class="mb-3">
                    <p class="detail">Detail Pasien</p>
                  </div>
                  <div>
                    <p class="nama">
                      {{ detailPasien2.patient.firstName }}
                      {{ detailPasien2.patient.middleName }}
                      {{ detailPasien2.patient.lastName }}
                    </p>
                  </div>
                  <p>Phone: {{ detailPasien2.patient.mobilePhone }}</p>
                </v-col>
                <v-col>
                  <div class="mb-3">
                    <p class="detail">No Rekam Medis</p>
                  </div>
                  <p class="nama">
                    {{ detailPasien2.patient.medicalRecordNumber }}
                  </p>
                </v-col>
                <v-col>
                  <div class="mb-3">
                    <p class="detail">Alamat Pengiriman</p>
                  </div>

                  <p class="nama">
                    {{ detailPasien2.address.address }} <br />
                    {{ detailPasien2.address.districtName }} ,
                    {{ detailPasien2.address.subDistrictName }} <br />
                    {{ detailPasien2.address.cityName }}
                    {{ detailPasien2.address.postalCode }} <br />
                    {{ detailPasien2.address.provinceName }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="mb-3">
                    <p class="detail">Dokter</p>
                  </div>
                  <p class="nama">
                    Dokter: {{ detailPasien2.doctor.fullName }}
                  </p>
                  <v-spacer></v-spacer>
                  <p>SIP: {{ detailPasien2.doctor.sipNumber }}</p>
                </v-col>
              </v-row>
            </v-container>
            <div class="text-center my-5">
              <span
                style="
                  font-family: Inter;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 29px;
                  letter-spacing: 0em;
                  text-align: center;
                  color: #2b92e4;
                "
                >Rekomendasi Obat</span
              >
            </div>
            <v-simple-table
              :items-per-page="itemsPerPage"
              :page.sync="page"
              @page-count="pageCount = $event"
            >
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nama Obat</th>
                  <th>Kekuatan</th>
                  <th>Qty</th>
                  <th>Instruksi</th>
                  <th>Harga</th>
                  <th>Satuan</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ngobat, index) in detailobat" :key="index">
                  <td>
                    <p class="mt-3" v-text="ngobat.prescriptionDetailOrder"></p>
                  </td>
                  <td width="250px">
                    <p v-text="ngobat.medicineBrandName" class="mt-3"></p>
                  </td>
                  <td>
                    {{ ngobat.medicineSize }} {{ ngobat.medicineSizeUnitType }}
                  </td>
                  <td>
                    <v-text-field
                      @change="changeTotalMed(ngobat.maxTotalMedicine, index)"
                      v-model="ngobat.totalMedicine"
                      :value="ngobat.totalMedicine"
                      class="mt-3"
                      type="number"
                      dense
                      outlined
                      min="0"
                      :max="ngobat.totalMedicine"
                      disabled
                    ></v-text-field>
                    <!-- :value="ngobat.totalMedicine" -->
                  </td>
                  <td>
                    <v-row>
                      <v-col cols="auto">
                        <!-- <span
                        v-if="ngobat.instructionTypes[0] == 'beforeEat' "
                        class="instructionType"
                      >Sebelum Makan</span>
                      <span
                        v-if="ngobat.instructionTypes[0] == 'afterEat' "
                        class="instructionType"
                      >Setelah Makan</span> -->
                        <span class="instructionType">{{
                          ngobat.instructionTypes.map((e) => e.label).toString()
                        }}</span>
                        <!-- <span
                        class="instructionType"
                      >{{ngobat.substitute.instructionTypes.map(e=>e.value).toString()}}</span> -->
                      </v-col>
                    </v-row>
                  </td>
                  <td>
                    <v-text-field
                      @change="changeItemPrice(ngobat.itemPrice, index)"
                      v-model="ngobat.itemPrice"
                      min="0"
                      class="mt-3"
                      type="number"
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </td>
                  <td>
                    <span>{{ ngobat.medicineType | caps }}</span>
                  </td>
                  <td>
                    <span>{{ ngobat.totalMedicine * ngobat.itemPrice }}</span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <div class="text-center">
              <v-card class="mt-10">
                <v-card-title
                  class="justify-center"
                  style="background: #86f2be"
                >
                  <div>
                    <span
                      style="
                        font-family: Nunito;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-align: center;
                      "
                      >Order Summary</span
                    >
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col class="col-md-3 offset-md-3 pt-6">
                      <p>Total Pesanan</p>
                    </v-col>
                    <v-col class="py-6">
                      <!-- <div class="float-left"> -->
                      <p class="float-left" v-text="totalPesanan"></p>
                      <!-- </div> -->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-md-3 offset-md-3">
                      <p>BIaya Pengiriman</p>
                    </v-col>
                    <v-col class="col-md-3 py-0">
                      <v-text-field
                        v-model="detailPasien2.shipping.shippingFee"
                        type="number"
                        dense
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-md-3 offset-md-3 py-0 font-weight-bold"
                      >TOTAL</v-col
                    >
                    <v-col class="py-0">
                      <div class="float-left">
                        <p class="font-weight-bold">
                          {{
                            detailPasien2.shipping.shippingFee + totalPesanan
                          }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    color="primary"
                    large
                    outlined
                    rounded
                    @click="visible2 = false"
                    >Batal</v-btn
                  >
                </v-col>
                <v-col cols="auto" class="text-right">
                  <v-btn large color="success" rounded @click="submit()"
                    >Simpan</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>

    <v-pagination
      circle
      v-model="page"
      :length="totalpage.totalPages"
      :total-visible="7"
      @input="choosePage(page)"
    ></v-pagination>
  </v-app>
</template>

<script>
  // import Popup from './popup.vue'
  import axios from "axios";
  import PharmacyServices from "@/services/pharmacy_services";
  import ConsultationServices from "@/services/consultation_services";

  export default {
    components: {
      // Popup
    },
    data: () => ({
      btnDisabled: false,
      menuList: {},
      login: {},
      sum: "",
      paginate: { name: "", page: 0, size: 10 },
      totalpage: [],
      pageCount: 0,
      page: 1,
      checkBeforeEat: { id: false, value: "Sebelum Makan", item: "beforeEat" },
      checkAfterEat: { id: false, value: "Setelah Makan", item: "afterEat" },
      checkIfSpent: { id: false, value: "Dihabiskan", item: "all" },
      checkIfNeeded: { id: false, value: "Jika Perlu", item: "ifNeeded" },
      nambah: { show: false, toggle: "" },
      howToUse: [],
      powerMg: "",
      detailPasien: { doctor: {}, prescription: {}, patient: {}, address: {} },
      detailobat: [],
      searchData: "",
      search: null,
      Search: "",
      dataSearch: [],
      tipeobat: "",
      sigma: {},
      medType: {},
      dialog: {},
      typeObat: "",
      itemsObat: [],
      itemHtu: [],
      itemUnit: [],
      baseItemsObat: "",
      baseItemHtu: "",
      baseItemUnit: "",
      statusSubmit: false,
      baseInstructionType: [],
      usertype: "how-to-use-type",
      sblmMakan: "",
      sdhMakan: "",
      habis: "",
      shipping: 0,
      detailPasien2: {
        doctor: {},
        prescription: {},
        patient: {},
        address: {},
        shipping: {},
      },
      perlu: "",
      kekuatanObat: "",
      pageNumber: 10,
      slot: [10, 25, 50, 75],
      address: {},
      namaObat: "",
      typeUnit: "",
      listKonfirmasi: [],
      detail: [],
      pasien: [
        {
          name: "1",
          NomorPasien: "780891019",
          namaPasien: "Mahmud",
          handphone: "+6910",
          tanggalBeli: "5  Des 2020",
          Jam: "14.00",
          namaDokter: "Dr. Pamela",
          konfirmasi: "konfirmasi",
        },
      ],
      frekuensiObat: "",
      dosis: "",
      totalHari: "",
      typePeriod: "",
      itemPeriod: [],
      instruction: [],
      totalPesanan: 0,
      visible: false,
      visible2: false,
      typeAlert: "",
      textAlert: "",
      showAlert: false,
      totalPurchase: 0,
      header: [
        {
          text: "No",
          align: "start",
          sortable: true,
          value: "index",
          class: "primary--text ",
        },
        {
          text: "Pesanan",
          value: "detail",
          class: "primary--text ",
        },

        {
          text: "No Rekam Medis",
          value: "medicalRecordNumber",
          class: "primary--text ",
        },
        {
          text: "Nama Pasien",
          value: "patientFullName",
          class: "primary--text ",
        },
        {
          text: "No Handphone",
          value: "patientMobilePhone",
          class: "primary--text ",
        },
        {
          text: "Tgl Pembellian",
          value: "consultationDate",
          class: "primary--text ",
        },
        {
          text: "Jam",
          value: "consultationTime",
          class: "primary--text ",
        },
      ],
    }),
    mounted() {
      this.loginData();
      this.getLovs();
      this.getPrescriptions();
      this.detailobat.maxTotalMedicine = this.detailobat.totalMedicine;
      console.log(this.detailobat);
    },
    watch: {
      search(value) {
        if (value != null) {
          if (value.length >= 3) {
            PharmacyServices.getMasterSearchMedice(value).then((response) => {
              this.dataSearch = response.data.payload;
              // console.log(JSON.stringify(this.dataSearch));
            });
          }
        }
      },
      shipping(val) {
        console.log(val);
        if (!val) val = 0;
        this.totalPurchase = this.totalPesanan + parseInt(val);
      },
    },
    computed: {
      nonZero: function() {
        return this.detailobat.filter(function(ngobat) {
          return ngobat.totalMedicine > -10;
        });
      },
      indexof() {
        return this.listKonfirmasi.map((listKonfirmasi, index) => ({
          ...listKonfirmasi,
          index: index + this.totalpage.size * this.totalpage.number + 1,
        }));
      },
    },
    methods: {
      loginData() {
        this.login = JSON.parse(window.localStorage.getItem("loginData"));
        console.log("login list", this.login.features);
        this.menuList = this.login.features.filter(
          (item) => item.featureName === "Konfirmasi Obat",
        );
        console.log(this.menuList);
        if (this.menuList[0].update === true) {
          this.btnDisabled = true;
        }
        console.log(this.btnDisabled);
      },
      choosePage(page) {
        console.log(page);
        this.paginate.page = page - 1;
        console.log(this.paginate);
        this.getPrescriptions(this.paginate);
      },
      isChecked(obj) {
        if (obj.value === "Dihabiskan") {
          this.checkIfNeeded.id = !obj.id;
        }
        if (obj.value === "Jika Perlu") {
          this.checkIfSpent.id = !obj.id;
        }
      },
      changedObat() {
        this.itemsObat = this.baseItemsObat.filter((obj) =>
          this.namaObat.medicineTypes.includes(obj.value),
        );
        this.itemHtu = this.baseItemHtu.filter((obj) =>
          this.namaObat.howToUseTypes.includes(obj.value),
        );
        this.itemUnit = this.baseItemUnit.filter((obj) =>
          this.namaObat.unitTypes.includes(obj.value),
        );
        this.typeObat = this.itemsObat[0];
        this.howToUse = this.itemHtu[0];
        this.typeUnit = this.itemUnit[0];
      },
      cancel(id) {
        this.nambah.toggle = "";
        this.detailobat[id].totalMedicine = this.detailobat[id].max;
      },
      addObat(id) {
        let listCheck = [
          this.checkBeforeEat,
          this.checkAfterEat,
          this.checkIfSpent,
          this.checkIfNeeded,
        ];
        this.instruction.push(...listCheck.filter((obj) => obj.id === true));

        if (this.namaObat.length === 0 || this.instruction.length === 0) {
          this.showAlert = true;
          this.textAlert = "Data Obat dan Instruksi wajib diisi";
          setTimeout(() => {
            this.showAlert = false;
          }, 7000);
        } else {
          let substitute = {
            cntPrescriptionDetailId: this.detailobat[id]
              .cntPrescriptionDetailId,
            cntPrescriptionId: localStorage.getItem("presId"),
            prescriptionTypeCode: "main",
            prescriptionDetailOrder: this.detailobat[id]
              .prescriptionDetailOrder,
            phrMedicineBrandId: this.namaObat.phrMedicineBrandId,
            medicineBrandName: this.namaObat.medicineBrandName,
            medicineType: this.typeObat.value,
            medicineSize: parseInt(this.powerMg),
            medicineSizeUnitType: this.typeUnit.value,
            frequency: parseInt(this.frekuensiObat),
            dosage: parseInt(this.dosis),
            periodType: this.typePeriod.value,
            totalPeriod: parseInt(this.totalHari),
            totalMedicine: this.sum,
            howToUseType: this.howToUse.value,
            instructionTypes: this.instruction,
            itemPrice: 0,
            substituteById: null,
            substituteForId: null,
            reffMedicineBrandId: "",
            totalPrice: 0,
            cekStockStatus: true,
            maxTotalMedicine: this.sum,
          };
          this.detailobat[id].substitute = substitute;

          this.clearData();
          this.nambah.toggle = "";
        }
      },
      clearData() {
        (this.dataSearch = []),
          (this.itemsObat = []),
          (this.itemHtu = []),
          (this.itemUnit = []),
          (this.typeObat = ""),
          (this.howToUse = ""),
          (this.namaObat = []),
          (this.powerMg = ""),
          (this.typeUnit = ""),
          (this.frekuensiObat = ""),
          (this.dosis = ""),
          (this.typePeriod = ""),
          (this.totalHari = ""),
          (this.sum = ""),
          (this.instruction = []),
          (this.checkBeforeEat.id = false),
          (this.checkAfterEat.id = false),
          (this.checkIfSpent.id = false),
          (this.checkIfNeeded.id = false);
      },
      getLovs() {
        PharmacyServices.getMasterLovs().then((response) => {
          this.baseItemsObat = response.data.payload["medicine-type"];
          this.itemPeriod = response.data.payload["period-type"];
          this.baseItemHtu = response.data.payload["how-to-use-type"];
          this.baseItemUnit = response.data.payload["unit-type"];
          this.baseInstructionType = response.data.payload["instruction-type"];
        });
      },
      changeTotalMed(val, index) {
        this.nambah.id = index;
        if (val == 0) {
          this.nambah.toggle = index;
        } else if (val > this.detailobat[index].max) {
          this.detailobat[index].totalMedicine = this.detailobat[index].max;
        } else {
          this.nambah.toggle = "";
        }
        this.totalPesanan = 0;
        this.detailobat.forEach((obj, idx) => {
          this.totalPesanan += obj.itemPrice * obj.totalMedicine;
          if (obj.substitute) {
            this.totalPesanan +=
              obj.substitute.totalMedicine * obj.substitute.itemPrice;
            this.detailobat[idx].substitute.totalPrice =
              this.detailobat[idx].substitute.totalMedicine *
              this.detailobat[idx].substitute.itemPrice;
          }
          this.detailobat[idx].totalPrice =
            this.detailobat[idx].totalMedicine * this.detailobat[idx].itemPrice;
        });
        console.log(this.totalPesanan);
        this.totalPurchase = this.totalPesanan + parseInt(this.shipping);
      },
      changeItemPrice(val, index) {
        console.log(val, index);
        this.totalPesanan = 0;
        this.detailobat.forEach((obj, idx) => {
          this.totalPesanan += obj.totalMedicine * obj.itemPrice;
          if (obj.substitute) {
            this.totalPesanan +=
              obj.substitute.totalMedicine * obj.substitute.itemPrice;
            this.detailobat[idx].substitute.totalPrice =
              this.detailobat[idx].substitute.totalMedicine *
              this.detailobat[idx].substitute.itemPrice;
          }
          this.detailobat[idx].totalPrice =
            this.detailobat[idx].totalMedicine * this.detailobat[idx].itemPrice;
        });

        this.totalPurchase = this.totalPesanan + parseInt(this.shipping || 0);
      },
      open(item) {
        console.log(this.nambah.toggle);
        console.log(item);
        if (item.phrOrderStatus === null) {
          window.localStorage.setItem("status", "pesan");
        } else if (item.phrOrderStatus === "process") {
          window.localStorage.setItem("status", "proses");
        }
        this.visible = true;
        this.totalPesanan = 0;
        window.localStorage.setItem("presId", item.cntPrescriptionId);
        ConsultationServices.getPrescriptionById(item.cntPrescriptionId)
          .then((response) => {
            console.log(response);
            this.detailPasien = response.data.payload;
            this.detailobat = response.data.payload.items;
            this.detailobat.forEach((obj) => {
              this.totalPesanan = obj.itemPrice * obj.totalMedicine;
              obj.instructionTypes = this.baseInstructionType.filter((item) =>
                obj.instructionTypes.includes(item.value),
              );
              obj.max = obj.totalMedicine;
            });
            this.totalPurchase = this.totalPesanan + parseInt(this.shipping);
          })
          .catch((err) => console.log(err));
      },
      open2(item) {
        if (item.phrOrderStatus === null) {
          window.localStorage.setItem("status", "pesan");
        } else if (item.phrOrderStatus === "process") {
          window.localStorage.setItem("status", "proses");
        }
        this.visible2 = true;
        this.totalPesanan = 0;
        window.localStorage.setItem("presId", item.cntPrescriptionId);
        ConsultationServices.getPrescriptionByIdReady(item.cntPrescriptionId)
          .then((response) => {
            this.detailPasien2 = response.data.payload;
            this.detailobat = response.data.payload.items;
            this.shippingReady = response.data.payload;
            console.log(this.shippingReady);
            console.log(this.detailobat.totalMedicine);

            this.detailobat.forEach((obj) => {
              this.totalPesanan += obj.itemPrice * obj.totalMedicine;
              obj.instructionTypes = this.baseInstructionType.filter((item) =>
                obj.instructionTypes.includes(item.value),
              );
            });
            this.totalPurchase = this.totalPesanan + parseInt(this.shipping);
          })
          .catch((err) => console.log(err));
      },
      onSearch() {
        this.paginate.page = 0;
        // this.paginate.size = 100000000
        this.paginate.name = this.Search;
        console.log(this.paginate);
        this.getPrescriptions(this.paginate);
      },

      selectedPage() {
        console.log(this.pageNumber);
        this.paginate.size = this.pageNumber;
        this.getPrescriptions(this.paginate);
      },
      getPrescriptions(value) {
        value = this.paginate;
        ConsultationServices.getPrescriptions(value)
          .then((response) => {
            console.log(response);
            this.pageCount = response.data.payload.totalPages;
            this.totalpage = response.data.payload;
            this.listKonfirmasi = response.data.payload.content;
          })
          .catch((err) => console.log(err));
      },
      ready(item) {
        console.log(item.cntPrescriptionId);
        axios
          .post(
            ` https://api.klinisia.id/admin/consultation/prescription/order-ready/30c2bd51257240d886491f25685c5383`,
            item.cntPrescriptionId,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + window.localStorage.getItem("shortToken"),
              },
            },
          )
          .then((response) => {
            console.log(response);
            // window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      },
      finish(item) {
        let request = {
          id: item.cntPrescriptionId,
        };
        ConsultationServices.postFinish(request).then((response) => {
          console.log(response);
          window.location.reload();
        });
      },
      submit() {
        var stats = window.localStorage.getItem("status");
        console.log("aswal", this.detailobat);
        this.detailobat.forEach((obj) => {
          obj.itemPrice = parseInt(obj.itemPrice);
          obj.maxTotalMedicine = obj.totalMedicine;
          obj.totalPrice = obj.itemPrice * obj.maxTotalMedicine;
          obj.instructionTypes = obj.instructionTypes.map((e) => e.value);
          console.log(obj.substitute);
          if (obj.substitute) {
            obj.substitute.instructionTypes = obj.substitute.instructionTypes.map(
              (e) => e.item,
            );
          }
          delete obj.max;
          console.log("obj.totalMedicine", obj.itemPrice);
          console.log("obj.totalMedicine", obj.totalMedicine);
          if (obj.itemPrice == 0 && obj.totalMedicine != 0) {
            this.statusSubmit = false;
          } else if (obj.itemPrice == 0 && obj.totalMedicine == 0) {
            this.statusSubmit = true;
          } else if (obj.itemPrice != 0 && obj.totalMedicine != 0) {
            this.statusSubmit = true;
          }
        });
        console.log("obat", this.statusSubmit);
        console.log("kk", this.detailobat);
        if (this.statusSubmit !== true) {
          console.log("berhasil");
          this.$toast.error("Kirim Harga Gagal, Harga Obat Tidak Boleh 0", {
            type: "error",
            position: "top-right",
            duration: 5000,
          });
          this.visible = false;
        } else {
          if (stats === "pesan") {
            console.log("dr", this.detailobat);
            this.detailobat.totalMedicine = this.detailobat.maxTotalMedicine;
            let request = {
              id: localStorage.getItem("presId"),
              body: { shippingFee: this.shipping, items: this.detailobat },
            };
            console.log("res", request);
            ConsultationServices.postPrescription(request)
              .then((response) => {
                if (response.data.error !== "") {
                  this.$toast.error(response.data.error, {
                    type: "error",
                    position: "top-right",
                    duration: 5000,
                  });
                } else {
                  console.log("response", response);
                  this.visible = false;
                  this.getPrescriptions();
                }
              })
              .then((err) => console.log(err));
          }
          if (stats === "proses") {
            var id = window.localStorage.getItem("presId");
            axios
              .post(
                ` https://api.klinisia.id/admin/consultation/prescription/order-ready/${id}`,
                id,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer " + window.localStorage.getItem("shortToken"),
                  },
                },
              )
              .then((response) => {
                console.log(response);
                this.visible2 = false;
                this.getPrescriptions();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }

        // for (let i = 0; i < this.detailobat.length; i++) {
        //   console.log(this.detailobat[i].substitute);
        //   if (
        //     this.detailobat[i].itemPrice == 0 &&
        //     this.detailobat[i].totalMedicine != 0
        //   ) {
        //
        //   }else if (this.detailobat[i].substitute.itemPrice == 0 &&
        //     this.detailobat[i].substitute.totalMedicine != 0) {
        //      console.log("berhasil");
        //     this.$toast.error("Harga Obat Tidak Boleh 0", {
        //       type: "error",
        //       position: "top-right",
        //       duration: 5000,
        //     });
        //   } else {
        //     console.log("lolos");
        //     this.detailobat.totalMedicine = this.detailobat.maxTotalMedicine;
        //     if (stats === "pesan") {
        //       console.log("dr", this.detailobat);
        //       this.detailobat.forEach((obj) => {
        //         obj.itemPrice = parseInt(obj.itemPrice);
        //         obj.maxTotalMedicine = obj.totalMedicine;
        //         obj.totalPrice = obj.itemPrice * obj.maxTotalMedicine;
        //         obj.instructionTypes = obj.instructionTypes.map((e) => e.value);
        //         console.log(obj.substitute);
        //         if (obj.substitute) {
        //           obj.substitute.instructionTypes =
        //             obj.substitute.instructionTypes.map((e) => e.item);
        //         }
        //         delete obj.max;
        //       });
        //       console.log("obat", this.detailobat);
        //       let request = {
        //         id: localStorage.getItem("presId"),
        //         body: { shippingFee: this.shipping, items: this.detailobat },
        //       };
        //       console.log("res", request);
        //       ConsultationServices.postPrescription(request)
        //         .then((response) => {
        //           if (response.data.error !== "") {
        //             this.$toast.error(response.data.error, {
        //               type: "error",
        //               position: "top-right",
        //               duration: 5000,
        //             });
        //           } else {
        //             console.log("response", response);
        //             this.visible = false;
        //             this.getPrescriptions();
        //           }
        //         })
        //         .then((err) => console.log(err));
        //     }
        //     if (stats === "proses") {
        //       var id = window.localStorage.getItem("presId");
        //       axios
        //         .post(
        //           ` https://api.klinisia.id/admin/consultation/prescription/order-ready/${id}`,
        //           id,
        //           {
        //             headers: {
        //               "Content-Type": "application/json",
        //               Authorization:
        //                 "Bearer " + window.localStorage.getItem("shortToken"),
        //             },
        //           }
        //         )
        //         .then((response) => {
        //           console.log(response);
        //           this.visible2 = false;
        //           this.getPrescriptions();
        //         })
        //         .catch((error) => {
        //           console.log(error);
        //         });
        //     }
        //   }
        // }
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "../../scss/dashboard.scss";
  .titleContent {
    font-weight: 500;
    line-height: 1rem !important;
  }
  .background {
    background-color: #edf4fb;
  }
  .headerComponent {
    margin-left: 250px;
  }
  .text {
    font-family: Nunito;
    font-size: 11px;
    font-weight: 600;
    height: 2px !important;
    width: 80px !important;
  }
  .text-spesialis {
    font-family: Nunito;
    font-weight: 400;
  }
  .datapasien {
    margin-left: 250px;
    margin-right: 120px;
  }
  .header {
    margin-left: 250px;
    /* margin-right: 250px; */
  }
  .poppuphead {
    text-align: center;
  }
  .v-card__title {
    font-size: 0.9rem !important;
    line-height: 1rem !important;
  }
  .instructionType {
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }
  .search {
    margin-top: 15px;
    margin-left: 10px;
  }
  .detail {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #2b92e4;
  }
  .nama {
    font-family: Nunito;
    font-size: 22px;
    text-align: left;
  }
  .cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
</style>
