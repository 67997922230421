<template>
  <v-app class="background">
    <v-container class="mt-n8">
      <v-card class="containerTop">
        <v-row>
          <v-col lg="2" md="4" sm="12" class="search">
            <p class="text-title">List Dokter</p>
          </v-col>
          <v-col lg="4" md="6" sm="12" class="search">
            <v-text-field
              label="search"
              v-model="Search"
              append-icon="mdi-magnify"
              outlined
              rounded
              dense
              @keyup.enter.native="onSearch"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container>
      <v-row>
        <v-col md="3" sm="6">
          <v-row>
            <v-col md="4" sm="12">
              <p class="text-spesialis">Tampilkan</p>
            </v-col>
            <v-col md="6" sm="12">
              <v-select
                hide-details=""
                @change="selectedPage()"
                v-model="pageNumber"
                :items="slot"
                class="text-spesialis showing"
                outlined
                dense
                style="background: white"
                item-color="success"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col md="3" sm="6">
          <v-row>
            <v-col md="4" sm="12">
              <p class="text-spesialis">Urutkan</p>
            </v-col>
            <v-col md="6" sm="12">
              <v-select
                hide-details=""
                @change="sortBy()"
                v-model="key"
                :items="sortSlot"
                class="text-spesialis showing"
                outlined
                dense
                style="background: white"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6" sm="12" style="margin-top: -23px">
          <v-radio-group dense v-model="selectDokter" @change="pilihJenis" row>
            <v-radio value="All">
              <template v-slot:label>
                <p class="text-radio mt-6">Pilih Semua</p>
              </template></v-radio
            >
            <v-radio value="GENERAL">
              <template v-slot:label>
                <p class="text-radio mt-6">Dokter Umum</p>
              </template></v-radio
            >
            <v-radio value="NOTGENERAL">
              <template v-slot:label>
                <p class="text-radio mt-6">Dokter Spesialis</p>
              </template></v-radio
            >
          </v-radio-group>
        </v-col>

        <!-- <v-col md="6" sm="12">
          <v-spacer>
            <v-row>
              <v-col offset-md="9">
                <v-btn color="white" @click="hidden = !hidden">
                  Filter
                  <img class="btn-filter" src="../../assets/filter.svg" />
                </v-btn>
              </v-col>
            </v-row>
          </v-spacer>
        </v-col> -->
      </v-row>
    </v-container>

    <v-container>
      <v-card v-show="!hidden">
        <v-card-title>
          <p class="text-sort">Filter Data</p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-show="!hidden">
          <v-row>
            <v-col v-for="filter in filter" :key="filter" md="4" sm="6">
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="check.box[filter]"
                    :label="filter"
                    value="filter"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>

    <v-layout>
      <v-container class="mt-n10">
        <v-row v-if="searchForm.length === 0" justify="center">
          <v-col>
            <h5>No Data Available</h5>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col
            lg="3"
            md="4"
            sm="6"
            v-for="(newListDokter, docDoctorHospitalId) in searchForm"
            :key="docDoctorHospitalId"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <v-card class="cardDokter text-xs-center" elevation="4">
              <v-responsive class="text-center">
                <v-row>
                  <v-col offset-md="8" offset-sm="8">
                    <v-menu v-model="newListDokter.index" bottom right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn dark icon v-bind="attrs" v-on="on">
                          <v-icon style="color: #88879c"
                            >mdi-dots-vertical</v-icon
                          >
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="(item, i) in items"
                          :key="i.title"
                          router
                          :to="item.route"
                          @click="option(i, newListDokter)"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.title
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row justify-md="center">
                  <v-col>
                    <v-avatar rounded size="80">
                      <img
                        :src="newListDokter.profilePictureUrl"
                        alt="foto"
                        class="img-card"
                      />
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-responsive>
              <v-card-text>
                <div class="subheading text-center">
                  <p class="text-dokter">
                    {{ newListDokter.fullName }}
                  </p>
                </div>
                <div
                  class="
                    container
                    p-1
                    my-4
                    mt-5
                    border
                    rounded-pill
                    text-center
                  "
                  style="background: #ecf1ff"
                >
                  <span class="text-jenisdokter">
                    {{ newListDokter.specializationName }}
                  </span>
                </div>
                <div class="text-center">
                  {{ newListDokter.sipNumber }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>

    <!-- Popup -->
    <div>
      <div class="popup" v-show="hapus">
        <div class="backgroundPopup">
          <div>
            <div class="body">
              <h1 class="text-title text-center">Hapus Dokter?</h1>
            </div>
            <div class="body">
              <v-avatar class="mt-2" rounded size="100">
                <img
                  :src="
                    'https://api.klinisia.id/storage/file/public/profile-picture/' +
                      newPopUp.secUserId +
                      '/?token=' +
                      hasilToken
                  "
                  alt="foto"
                />
              </v-avatar>
            </div>
            <div class="body">
              <p class="text-dokter text-center">
                {{ newPopUp.fullName }}
              </p>
              <div>
                <p class="text-dokter text-center">
                  Informasi Dokter yang sudah dihapus tidak bisa dikembalikan.
                </p>
              </div>
            </div>
            <div class="content ma-2 pa-4">
              <v-row>
                <v-col>
                  <v-btn
                    color="success"
                    v-show="hapus"
                    @click="hapus = !hapus"
                    block
                    >Batal</v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn
                    color="error"
                    v-show="hapus"
                    @click="del(newPopUp)"
                    block
                    >Hapus</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div class="bg" v-show="hapus" @click="ty = !ty"></div>
    </div>

    <div>
      <div class="popup" v-show="ty">
        <div class="backgroundPopup">
          <div class="body">
            <h1 class="text-title text-center">Reset Password?</h1>
          </div>
          <div class="body">
            <v-avatar class="mt-2" rounded size="100">
              <img
                :src="
                  'https://api.klinisia.id/storage/file/public/profile-picture/' +
                    newPopUp.secUserId +
                    '/?token=' +
                    hasilToken
                "
                alt="foto"
              />
            </v-avatar>
            <div class="body">
              <div>
                <p class="text-dokter text-center">
                  {{ newPopUp.fullName }}
                </p>
              </div>
              <p class="text-dokter text-center">
                Setelah kata sandi dirubah, dokter perlu Login kembali.
              </p>
            </div>
            <div class="content">
              <v-row>
                <v-col class="mx-5">
                  <v-btn
                    x-large
                    color="success"
                    v-show="ty"
                    @click="yes()"
                    block
                    >Reset</v-btn
                  >
                </v-col>
                <v-col class="mx-5">
                  <v-btn
                    x-large
                    color="success"
                    outlined
                    v-show="ty"
                    @click="ty = !ty"
                    block
                    >Batal</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div class="bg" v-show="ty" @click="ty = !ty"></div>
    </div>

    <div>
      <div class="popup" v-show="confirm">
        <div class="backgroundPopup">
          <div class="body">
            <div class="logo">
              <img src="../../assets/Vector.svg" />
            </div>
            <div class="body">
              <h1 class="text-title text-center mb-5">
                Reset Password Berhasil
              </h1>
            </div>
            <div class="content">
              <p class="text-dokter text-center mb-5">
                Silahkan cek email Anda untuk mendapatkan password baru.
              </p>
            </div>
            <div class="footer mt-10">
              <v-row justify="center">
                <v-col cols="8">
                  <v-btn
                    x-large
                    color="primary"
                    v-show="confirm"
                    @click="confirm = !confirm"
                    block
                    >Tutup</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div class="bg" v-show="confirm"></div>
    </div>

    <v-row v-if="searchForm.length > 0" justify="center" class="page">
      <v-btn class="mr-3" small @click="dirPaginate('prev')"
        ><v-icon>mdi-menu-left</v-icon></v-btn
      >
      <v-btn
        v-if="tempListDokter.length === pageNumber"
        class="ml-3"
        small
        @click="dirPaginate('next')"
        ><v-icon>mdi-menu-right</v-icon></v-btn
      >
    </v-row>
  </v-app>
</template>

<script>
  // import axios from "axios";
  import DoctorServices from "@/services/doctor_services";
  import AuthServices from "@/services/auth_services";

  export default {
    components: {},
    data: () => ({
      login: {},
      hasilToken: window.localStorage.getItem("shortToken"),
      reset: {
        userType: "doctor",
        email: "",
      },
      fotoDoc: null,
      foto: "",
      foto2: "",
      key: "",
      ty: false,
      hapus: false,
      confirm: false,
      listdokterShow: false,
      hidden: true,
      Search: "",
      page: 1,
      selectDokter: "",
      pageNumber: 20,
      pageCount: 0,
      itemsPerPage: 10,
      slot: [5, 10, 15, 20],
      sortSlot: ["A-Z", "Z-A"],
      links: [{ text: "Dashboard", route: "/dashboard" }],
      items: [
        { title: "Lihat" },
        // { title: "Edit" },
        { title: "Reset Password" },
        // { title: "Hapus" },
      ],

      sort: ["A-Z", "Z-A"],
      // filter: [
      //   "Dokter umum",
      //   "Akupuntur",
      //   "Alergi & Patologi Klinik",
      //   "Anak",
      //   "Bedah Anak",
      //   "Bedah Pembulu Darah",
      //   "Bedah Plastik",
      //   "Bedah Syaraf",
      //   "Bedah Tulang",
      //   "Bedah Tumor",
      //   "Bedah Umum dan Disgestif",
      //   "Gigi",
      //   "Geriarti",
      //   "Gizi",
      //   "Reumatologi",
      //   "Kebidanan dan Kandungan",
      //   "Endoskopi Saluran Penernaan",
      //   "Klinik Cantik",
      //   "Telinga Hidung dan Tenggorokan",
      //   "Klinik Urologi",
      // ],
      check: {
        box: [],
      },
      label: {
        name: "",
      },
      tempListDokter: [],
      newListDokter: [],
      dataSpesialisasi: [],
      newPopUp: [],
      specializations: [],
      pendataan: [],
      paginate: { page: 0, size: 20, sortBy: "DESC", search: "", filter: "" },
    }),
    computed: {
      searchForm() {
        return this.newListDokter.filter((newListDokter) => {
          return (
            newListDokter.fullName.toLowerCase().match(this.Search) ||
            newListDokter.sipNumber.match(this.Search)
          );
        });
      },
    },
    mounted() {
      // console.log(window.localStorage.getItem("page"));
      this.getData();
      this.selectDokter = "All";
      this.loginData();
      this.doktroeMenu();

      this.getSpesialisasi();

      // if (window.localStorage.getItem("page") === null) {
      //   this.paginate.size = 20;
      // } else {
      //   this.paginate.size = window.localStorage.getItem("page");
      // }
      // if (window.localStorage.getItem("sortBy") === "") {
      //   this.paginate.sortBy = "ASC";
      // } else if (window.localStorage.getItem("sortBy") === "Z-A") {
      //   this.paginate.sortBy = "DESC";
      // } else if (window.localStorage.getItem("sortBy") == "A-Z") {
      //   this.paginate.sortBy = "ASC";
      // }
      this.getData(this.paginate);
      this.newListDokter.docMstMedicSpecializationId = "a";
      this.fotoDoc = this.newListDokter;
    },
    methods: {
      getSpesialisasi() {
        let master = [];
        DoctorServices.getSpesialisasi().then((response) => {
          console.log(response);
          master = response.data.payload.content;
          master.map((e) => {
            this.dataSpesialisasi.push({
              docMstMedicSpecializationId: e.docMstMedicSpecializationId,
              specializationCode: e.specializationCode,
              specializationName: e.specializationName,
            });
          });
        });
      },
      doktroeMenu() {
        var dataDoktor = this.login.features.filter(
          (item) => item.featureName === "Doctor Maintenance",
        );
        console.log(dataDoktor);
        if (dataDoktor[0].update === true) {
          this.items.push({ title: "Edit" });
        }
        if (dataDoktor[0].delete === true) {
          this.items.push({ title: "Hapus" });
        }
      },
      loginData() {
        this.login = JSON.parse(window.localStorage.getItem("loginData"));
        console.log(this.login.features);
        // if(this.login.features.includes('ALL-SEC-USER')){
        //   console.log("true");
        // }else{
        //   console.log("false");
        // }
      },
      pilihJenis() {
        if (this.selectDokter === "All") {
          this.newListDokter = this.pendataan;
          console.log("all");
          console.log(this.searchForm);
          this.paginate.filter = "";
          this.paginate.page = 0;
          this.getData(this.paginate);
          console.log("page", this.paginate);
        } else if (this.selectDokter === "GENERAL") {
          this.newListDokter = this.pendataan;
          console.log("general");
          console.log(this.searchForm);
          this.paginate.filter = "true";
          this.paginate.page = 0;
          console.log("page", this.paginate);
          this.getData(this.paginate);
          // var hasil = []
          // var generl = this.searchForm;
          // console.log(this.newListDokter);
          // this.newListDokter = generl.filter((obj) =>
          //   obj.docMstMedicSpecializationId === "GENERAL"
          // );
          // console.log(this.newListDokter);
          // console.log('hasil',hasil);
        } else if (this.selectDokter === "NOTGENERAL") {
          this.newListDokter = this.pendataan;
          console.log(this.newListDokter);
          console.log("not general");
          this.paginate.filter = "false";
          this.paginate.page = 0;
          this.getData(this.paginate);
          console.log("page", this.paginate);
          // var diff = this.searchForm;
          // this.newListDokter = diff.filter((obj) =>
          //   obj.docMstMedicSpecializationId !== "GENERAL"
          // );
        }
      },
      sortBy() {
        console.log(this.key);
        window.localStorage.setItem("sortBy", this.key);
        if (window.localStorage.getItem("sortBy") === "Z-A") {
          this.paginate.sortBy = "DESC";
          this.getData(this.paginate);
        } else if (window.localStorage.getItem("sortBy") == "A-Z") {
          this.paginate.sortBy = "ASC";
          this.getData(this.paginate);
        }
        // window.location.reload();
        // this.paginate.sortBy = this.key
        // this.getData(this.paginate);
      },
      selectedPage() {
        console.log(this.pageNumber);
        // window.localStorage.setItem("page", this.pageNumber);
        // window.location.reload();
        this.paginate.size = this.pageNumber;
        this.getData(this.paginate);
        console.log(this.getData(this.paginate));
      },
      dirPaginate(val) {
        if (val === "next") {
          console.log(this.tempListDokter);
          this.paginate.page++;
          console.log(this.paginate.page);
          this.getData(this.paginate);
          console.log(this.tempListDokter);
          console.log(this.searchForm.length);
        }
        if (val === "prev") {
          if (this.paginate.page > 0) {
            this.paginate.page--;
            this.getData(this.paginate);
          }
        }
      },
      getData(paginate) {
        DoctorServices.getListDoctor(paginate)
          .then((response) => {
            console.log("data", response);
            this.tempListDokter = response.data.payload.content;
            this.newListDokter = this.tempListDokter;
            this.pendataan = this.newListDokter;
            DoctorServices.getMedSpecialist().then((response) => {
              console.log("spesialis", response);
              this.newListDokter.forEach((obj) => {
                obj.docMstMedicSpecializationId = response.data.payload
                  .filter((med) =>
                    med.docMstMedicSpecializationId ===
                    obj.docMstMedicSpecializationId
                      ? med.specializationName
                      : " ",
                  )
                  .shift().specializationName;
              });
            });
            console.log("list", this.newListDokter);
            //     if (this.selectDokter === "All") {
            //   this.newListDokter = this.pendataan
            //   console.log("all");
            //   console.log(this.searchForm);
            // } else if (this.selectDokter === "GENERAL") {
            //   this.newListDokter = this.pendataan
            //   console.log("general");
            //   console.log(this.searchForm);

            //   var hasil = []
            //   var generl = this.searchForm;
            //   console.log(this.newListDokter);
            //   this.newListDokter = generl.filter((obj) =>
            //     obj.docMstMedicSpecializationId === "GENERAL"
            //   );
            //   console.log(this.newListDokter);
            //   console.log('hasil',hasil);
            // } else if (this.selectDokter === "NOTGENERAL") {
            //   this.newListDokter = this.pendataan
            //   console.log(this.newListDokter);
            //   console.log("not general");
            //   var diff = this.searchForm;
            //   this.newListDokter = diff.filter((obj) =>
            //     obj.docMstMedicSpecializationId !== "GENERAL"
            //   );
            // }
          })
          .catch((err) => {
            console.log("get error");
            console.log(err);
          });
      },
      sorts(prop) {
        this.newListDokter.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
        if (this.key == 1) {
          this.newListDokter.sort((a, b) =>
            a[prop].toLowerCase().trim() < b[prop].toLowerCase().trim()
              ? -1
              : 1,
          );
        } else {
          this.newListDokter.sort((a, b) =>
            a[prop].toLowerCase().trim() < b[prop].toLowerCase().trim()
              ? 1
              : -1,
          );
        }
      },

      findIndex: function(jsonData, findThis) {
        var indexNum = jsonData.findIndex(
          (obj) => obj.docDoctorHospitalId == findThis,
        );
        window.localStorage.setItem("indexGet", indexNum);
      },
      option(i, obj) {
        window.localStorage.setItem(
          "docDoctorHospitalId",
          obj.docDoctorHospitalId,
        );
        this.newPopUp = obj;

        if (this.items[i].title === "Lihat") {
          this.$router.push("/lihat");
        } else if (this.items[i].title === "Edit") {
          this.$router.push("/Edit");
        } else if (this.items[i].title === "Hapus") {
          this.hapus = !this.hapus;
        } else if (this.items[i].title === "Reset Password") {
          this.reset.email = this.newPopUp.email;
          this.ty = true;
        }
      },
      del(obj) {
        DoctorServices.delete(
          window.localStorage.getItem("docDoctorHospitalId"),
        ).then(() => {
          this.hapus = false;
          const index = this.newListDokter.indexOf(obj);
          this.newListDokter.splice(index, 1);
          this.$toast.success("List Dokter Berhasil Di Hapus", {
            type: "success",
            position: "top-right",
            duration: 5000,
          });
        });
      },
      yes() {
        AuthServices.resetPassword(this.reset).then(() => {
          window.localStorage.setItem("email", this.reset.email);
          this.confirm = true;
          this.ty = false;
        });
      },
      onSearch() {
        this.paginate["search"] = this.Search;
        console.log(this.paginate);
        return this.getData(this.paginate);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../scss/dashboard.scss";

  // .background {
  //   background-color: #edf4fb;
  //   overflow: hidden;
  //   overflow-y: hidden; /* Hide vertical scrollbar */
  //   overflow-x: hidden;
  // }
  .btn-filter {
    margin-left: 12px;
  }

  .cardDokter {
    border-radius: 20px;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }

  .popup {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 50%;
    height: 100%;
    padding: 12px;
    /* border: 1px solid red; */
    border-radius: 5px;
    /* background-color: blue; */
    z-index: 10001;
    opacity: 1;
    transition: 0.6s;
  }
  .bg {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(15, 14, 14);
    z-index: 10000;
    opacity: 0.5;
    transition: 0.3s;
  }
  .backgroundPopup {
    background-color: white;
    width: 100%;
    height: 70%;
  }
  .reset {
    position: relative;
    background-color: white;
    margin: auto;
    width: 969px;
    height: 869px;
    top: 97px;
    overflow: hidden;
  }
  .logo img {
    margin-right: auto;
    margin-left: auto;
    margin-top: 0;
    display: block;
  }
  .body {
    color: #2a8bf2;
    text-align: center;
    margin-top: 90px;
  }
  .text-spesialis {
    font-family: Nunito;
    font-size: 14px;
    font-weight: 600;
    color: rgb(102, 102, 102);
  }
  .text-title {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 600;
  }
  .text-radio {
    font-family: Nunito;
    font-size: 14px;
    font-weight: 600;
  }
  .page {
    margin-bottom: 25px;
  }
  .containerTop {
    border-radius: 20px;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
  .showing {
    border: 1px solid rgba(43, 146, 228, 0.5) !important;
  }
</style>
