<template>
  <v-app class="background">
    <v-container class="mt-n8">
      <v-card class="cardStyle">
      <v-row>
        <v-col sm="12" md="4" lg="3" class="search">
          <h1 class="text-title">Rekap Pasien</h1>
        </v-col>
        <v-col sm="12" md="6" lg="6" class="search">
          <v-text-field
            label="search"
            v-model="Search"
            append-icon="mdi-magnify"
            @keyup.enter="onSearch"
            outlined
            rounded
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      </v-card>
    </v-container>

    <b-container>
      <b-row class="ml-1">
        <b-col lg="2" sm="4">
          <p class="text-spesialis">Tampilkan</p>
        </b-col>
        <b-col lg="2" sm="6">
          <v-select
            class="select-box"
            hide-details
            :items="slot"
            v-model="pageNumber"
            @change="selectedPage()"
            outlined
            dense
            style="background: white"
            item-color="success"
          ></v-select>
        </b-col>

        <b-col lg="2" md="4" sm="6">
          <b-form-group>
            <b-form-group>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="paginate.recentDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="itStart"
                    class="datecss"
                    background-color="white"
                    label="Start Date"
                    dense
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    v-model="paginate.recentDate"
                    @change="date(val)"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="paginate.recentDate" no-title>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(paginate.recentDate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </b-form-group>
          </b-form-group>
        </b-col>

        <b-col lg="2" md="4" sm="6">
          <b-form-group>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="paginate.lastDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="itEnds"
                  class="datecss"
                  background-color="white"
                  label="End Date"
                  dense
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  v-model="paginate.lastDate"
                  @change="date(val)"
                ></v-text-field>
              </template>
              <v-date-picker v-model="paginate.lastDate" no-title>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(paginate.lastDate)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <v-container>
      <v-card class="cardtable cardStyle">
        <v-row>
          <v-col cols="12">
            <template>
              <v-data-table
                :page.sync="page"
                :headers="header"
                :items="indexof"
                :search="Search"
                :server-items-length="pageCount"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:item.consultationStartDatetime="{ item }">
                  <a>{{ item.consultationStartDatetime | orderDate }}</a>
                </template>
                <template v-slot:item.consultationStarttime="{ item }">
                  <a>{{ item.consultationStartDatetime | orderTime }}</a>
                </template>
                <template v-slot:item.detail="{ item }">
                  <v-row>
                    <v-col cols="6">
                      <v-btn
                        x-small
                        v-show="detailPasien"
                        @click.stop="open(item)"
                        >detail</v-btn
                      >
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        class="ml-2 mr-2"
                        x-small
                        v-show="detailPasien"
                        @click.stop="Chat(item)"
                        >Chat</v-btn
                      >
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.vid="{ item }">
                  <v-btn x-small v-show="detailPasien" @click.stop="Vid(item)"
                    >Video</v-btn
                  >
                </template>
              </v-data-table>

              <v-dialog
                v-model="popUp"
                width="1200"
                persistent
                :retain-focus="false"
              >
                <v-card>
                  <!-- <v-btn @click="closing2">asd</v-btn> -->
                  <v-col>
                    <!-- <v-icon @click="closing" class="float-right" large>mdi-close</v-icon> -->
                    <v-icon @click="closing2" class="float-right" large
                      >mdi-close</v-icon
                    >
                  </v-col>
                  <v-card-title>
                    <div class="text-center">
                      <p class="text-judul">
                        {{ namaPasien.firstName }} {{ namaPasien.middleName }}
                        {{ namaPasien.lastName }}
                        <br />
                        Nomor Rekam Medis: {{ namaPasien.medicalRecordNumber }}
                        <br />
                        No. Handphone: {{ namaPasien.mobilePhone }}
                        <br />
                        consultation ID: {{ hospitalConsultationNumber }}
                      </p>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-card class="mt-10">
                      <div class="text-center">
                        <p
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                            color: #2b92e4;
                          "
                        >
                          Assessment
                        </p>
                        <!-- <vue-excel-xlsx
                          :data="data"
                          :columns="columns"
                          :filename="'filename'"
                          :sheetname="'sheetname'"
                        >Download</vue-excel-xlsx> -->
                        <v-spacer></v-spacer>
                        <p
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                          "
                        >
                          Diagnosis Utama
                        </p>
                        <v-spacer></v-spacer>
                        <p
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                          "
                        >
                          Catatan: {{ diagnosisDto.diagnosisMainNote }}
                        </p>
                        <v-card class="mb-10">
                          <v-data-table
                            :headers="headerDiagnosisUtama"
                            :items="detailPenyakitUtama"
                            :items-per-page="5"
                            class="elevation-1"
                          ></v-data-table>
                        </v-card>
                        <v-spacer></v-spacer>

                        <p
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                          "
                        >
                          Diagnosis Lainnya
                        </p>
                        <v-spacer></v-spacer>
                        <p
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                          "
                        >
                          Catatan: {{ diagnosisDto.diagnosisOtherNote }}
                        </p>
                        <v-card class="mb-15">
                          <v-data-table
                            :headers="headerDiagnosisLainnya"
                            :items="detailPenyakitLain"
                            :items-per-page="5"
                            class="elevation-1"
                          ></v-data-table>
                        </v-card>
                      </div>
                    </v-card>
                    <v-spacer></v-spacer>
                    <v-card class="mb-15">
                      <div class="text-center">
                        <p
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                            color: #2b92e4;
                          "
                        >
                          Summary
                        </p>
                        <v-spacer></v-spacer>
                        <p
                          style="
                            font-fpmily: Nunito;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 33px;
                            letter-spacing: 0em;
                            text-align: center;
                          "
                        >
                          Keluhan Utama:
                          <span
                            style="
                              font-family: Nunito;
                              font-size: 20px;
                              font-style: normal;
                              font-weight: 600;
                              line-height: 33px;
                              letter-spacing: 0em;
                              text-align: center;
                            "
                            >{{ doctorAllAssessment.mainComplaint }}</span
                          >
                        </p>

                        <p
                          style="
                            font-fpmily: Nunito;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 33px;
                            letter-spacing: 0em;
                            text-align: center;
                          "
                        >
                          Obat yang sedang di konsumsi saat ini:
                          <span
                            style="
                              font-family: Nunito;
                              font-size: 20px;
                              font-style: normal;
                              font-weight: 600;
                              line-height: 33px;
                              letter-spacing: 0em;
                              text-align: center;
                            "
                            >{{ doctorAllAssessment.currentMedicine }}</span
                          >
                        </p>

                        <p
                          style="
                            font-fpmily: Nunito;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 33px;
                            letter-spacing: 0em;
                            text-align: center;
                          "
                        >
                          Catatan :
                          <span
                            style="
                              font-family: Nunito;
                              font-size: 20px;
                              font-style: normal;
                              font-weight: 600;
                              line-height: 33px;
                              letter-spacing: 0em;
                              text-align: center;
                            "
                            >{{ doctorAllAssessment.doctorNote }}</span
                          >
                        </p>

                        <v-data-table
                          :headers="headerSummary"
                          :items="doctorAssessment"
                          :items-per-page="5"
                          class="elevation-1"
                        >
                          <template v-slot:item.optionSelecteds="{ item }">
                            <!-- <tbody v-if="item.optionSelecteds.map(s => s.optionLabel).includes('Lainnya')">
                            {{item.optionNote}}
                          </tbody>
                          <tbody v-else>
                         {{ item.optionSelecteds.map(s => s.optionLabel).join(', ')}}
                            </tbody>-->
                            <!-- <tbody v-if="item.optionSelecteds.map(s => s.optionLabel) == 'Lainnya'">
                            {{item.optionNote}}-->
                            <!-- <v-text-field
                      v-model="pengganti"
                            ></v-text-field>-->
                            <!-- </tbody> -->
                            <!-- <tbody v-else> -->
                            <!-- {{ item.optionSelecteds.map(s => s.optionLabel).join(', ')}} -->
                            {{ item.optionSelecteds.toString() }}
                            <!-- </tbody> -->
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                    <!-- <v-card>
                       <div class="text-center my-5">
                      <span
                        style="font-family: Inter;font-size: 24px;font-style: normal;font-weight: 600;line-height: 29px;letter-spacing: 0em;text-align: center;color:#2B92E4"
                      >subjective</span>

                      <v-spacer></v-spacer>
                      <a
                        style="font-family: Nunito;font-size: 24px;font-style: normal;
                                sfont-weight: 700;line-height: 33px;letter-spacing: 0em;text-align: left;"
                      >Keluhan Utama:</a>

                      <a
                        style="font-family: Nunito;font-size: 24px;font-style: normal;
                                sfont-weight: 700;line-height: 33px;letter-spacing: 0em;text-align: left;"
                        v-text="pasien.mainComplaint"
                      ></a>
                      <v-spacer></v-spacer>

                      <a
                        style="font-family: Nunito;font-size: 24px;font-style: normal;
                                sfont-weight: 700;line-height: 33px;letter-spacing: 0em;text-align: left;"
                      >Obat Sedang Dikonsumsi / Obat Rutin :</a>

                      <a
                        style="font-family: Nunito;font-size: 24px;font-style: normal;
                                sfont-weight: 700;line-height: 33px;letter-spacing: 0em;text-align: left;"
                        v-text="pasien.currentMedicine"
                      ></a>

                      <v-spacer></v-spacer>
                    </div>

                    <v-data-table
                      :headers="headerSubjective"
                      :items="pasienDetailAssessment"
                      :items-per-page="5"
                      class="elevation-1"
                    >
                      <template v-slot:item.optionSelectedIds="{ item }">
                        <a>{{ item.optionSelectedIds.toString()}}</a>
                      </template>
                    </v-data-table>

                  
                    </v-card>-->
                    <v-card v-if="detailObatMain !== null" class="mb-15">
                      <div class="text-center">
                        <span
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                            color: #2b92e4;
                          "
                          >Planning</span
                        >

                        <v-data-table
                          :headers="headerObat"
                          :items="detailObatMain.mainItems"
                          :items-per-page="5"
                          class="elevation-1"
                        >
                          <template v-slot:item.medicineSize="{ item }">
                            <a>
                              {{ item.medicineSize }}
                              {{ item.medicineSizeUnitType }}
                            </a>
                          </template>
                          <template v-slot:item.howToUseType="{ item }">
                            {{
                              item.howToUseType.map((e) => e.label).toString()
                            }}
                            <!-- {{item.howToUseType}} -->
                          </template>
                          <template v-slot:item.periodType="{ item }">
                            {{ item.periodType.map((e) => e.label).toString() }}
                            <!-- {{item.periodType}} -->
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                    <!-- <v-card> -->
                    <v-card v-if="laboratoriumDetail !== null">
                      <div class="text-center">
                        <span
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                            color: #2b92e4;
                          "
                          >Laboratorium</span
                        >
                        <v-spacer></v-spacer>
                        <span
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                            color: #2b92e4;
                          "
                        >
                          Catatan:
                          {{ laboratoriumDetail.laboratoryNote }}
                        </span>
                        <v-data-table
                          :headers="headerLaborat"
                          :items="laboratoriumDetail.groups"
                          :items-per-page="5"
                          class="elevation-1"
                        >
                          <template v-slot:item.items="{ item }">
                            <a>
                              {{
                                item.items
                                  .map((s) => s.laboratoryItemName)
                                  .join(", ")
                              }}
                            </a>
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                    <v-card v-if="lengkap !== null">
                      <!-- <v-card v-show="radiologiCard"> -->
                      <div class="text-center">
                        <span
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                            color: #2b92e4;
                          "
                          >Diagnostik</span
                        >
                        <v-spacer></v-spacer>
                        <span
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                            color: #2b92e4;
                          "
                        >
                          Catatan Diagnostik:
                          {{ radiologi.diagnosticNote }}
                        </span>

                        <v-data-table
                          :headers="headerDiagnostik"
                          :items="radiologi.groups"
                          :items-per-page="5"
                          class="elevation-1"
                        >
                          <template v-slot:item.items="{ item }">
                            <a>
                              {{
                                item.items
                                  .map((s) => s.diagnosticItemName)
                                  .join(", ")
                              }}
                            </a>
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                    <v-card v-if="suggest !== null">
                      <div v-if="suggest !== null" class="text-center">
                        <span
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                            color: #2b92e4;
                          "
                          >Saran Lainnya</span
                        >
                        <v-spacer></v-spacer>
                        <span
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                            color: #2b92e4;
                          "
                          >Diet: {{ suggest.dietNote }}</span
                        >
                        <v-spacer></v-spacer>
                        <span
                          style="
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: center;
                            color: #2b92e4;
                          "
                          >Aktivitas: {{ suggest.activityNote }}</span
                        >
                      </div>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="chatDialog"
                width="1000"
                persistent
                :retain-focus="false"
              >
                <v-card>
                  <v-row>
                    <v-col offset-md="1">
                      <v-btn @click="retry">Retry Chat</v-btn>
                    </v-col>
                    <!-- <v-icon @click="closing" class="float-right" large>mdi-close</v-icon> -->
                    <v-col>
                      <v-icon @click="CloseChat" large>mdi-close</v-icon>
                    </v-col>
                  </v-row>

                  <v-card-text>
                    <div v-for="(item, key) in chatList" :key="key" cols="auto">
                      <v-row
                        v-if="
                          item.chatSenderId.includes(
                            'KlinisiaWebDoctorAppEmail'
                          )
                        "
                      >
                        <v-col>
                          <!-- <v-chip color="primary" class="down1 ma-2" label>{{item.chatMessage}}</v-chip> -->
                          <v-textarea
                            readonly
                            background-color="light-blue"
                            class="ma-0 pa-0 chatingbro"
                            placeholder="Type a message here"
                            hide-details
                            auto-grow
                            rows="1"
                            flat
                            solo
                            v-model="item.chatMessage"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col cols="auto"></v-col>
                        <v-col>
                          <!-- <v-chip
                            color="green"
                            class="down1 ml-12 mb-3"
                            align="right"
                            label
                          >{{item.chatMessage}}</v-chip>-->

                          <v-textarea
                            readonly
                            background-color="green"
                            class="ma-0 pa-0 chatingbro"
                            placeholder="Type a message here"
                            hide-details
                            auto-grow
                            rows="1"
                            flat
                            solo
                            v-model="item.chatMessage"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="videoDialog"
                width="1000"
                persistent
                :retain-focus="false"
              >
                <v-card>
                  <v-data-table
                    :headers="headerVcall"
                    :items="IndexOfVidCall"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template v-slot:item.Download="{ item }">
                      <v-btn x-small @click.stop="openVid(item)">Watch</v-btn>
                    </template>
                  </v-data-table>
                  <v-btn x-small @click.stop="CloseVid">close</v-btn>
                </v-card>
              </v-dialog>
            </template>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-pagination
      circle
      v-model="page"
      :length="totalpage.totalPages"
      :total-visible="7"
      @input="choosePage(page)"
    ></v-pagination>
  </v-app>
</template>

<script>
// import axios from "axios";
import rekapAPI from "@/services/rekapPasien_service";
import PharmacyServices from "@/services/pharmacy_services";
// import moment from "moment";

export default {
  name: "rekapPasien",

  data() {
    return {
      RekapDetailList: {},
      RekapList: {},
      login: {},
      videoDialog: false,
      vcallList: [],
      idchat: "",
      chatList: [],
      chatDialog: false,
      today: "YYYY-MM-DD",
      radiologiCard: true,
      laboratCard: true,
      // date: null,
      enddate: null,
      detailPasien: true,
      popUp: false,
      menu: false,
      menu2: false,
      Search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      slot: [5, 10, 25, 50, 75],
      pageNumber: 10,
      paginate: { recentDate: "", lastDate: "", name: "", page: 0, size: 10 },
      totalpage: [],
      newListDokter: [],
      headerDiagnosisUtama: [
        {
          text: "IcdCode",
          align: "center",
          sortable: false,
          value: "cntMstIcdId",
          class: "primary--text ",
        },
        {
          text: "ICD Name",
          align: "center",
          value: "icdName",
          class: "primary--text ",
        },
      ],
      headerDiagnosisLainnya: [
        {
          text: "IcdCode",
          align: "center",
          sortable: false,
          value: "cntMstIcdId",
          class: "primary--text ",
        },
        {
          text: "ICD Name",
          align: "center",
          value: "icdName",
          class: "primary--text ",
        },
      ],
      headerSummary: [
        {
          text: "Question",
          align: "center",
          sortable: false,
          value: "question",
          class: "primary--text ",
        },
        {
          text: "Answer",
          align: "center",
          value: "optionSelecteds",
          class: "primary--text ",
        },
      ],
      headerSubjective: [
        {
          text: "Id",
          align: "center",
          sortable: false,
          value: "cntMstQuestionId",
          class: "primary--text ",
        },
        {
          text: "Detail",
          align: "center",
          value: "optionSelectedIds",
          class: "primary--text ",
        },
      ],
      header: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text ",
        },
        {
          text: "No Rekam Medis",
          value: "medicalRecordNumber",
          class: "primary--text ",
        },
        {
          text: "Nama Pasien",
          value: "patientFullName",
          class: "primary--text ",
        },
        {
          text: "No Handphone",
          value: "patientMobilePhone",
          class: "primary--text ",
        },
        {
          text: "Tgl Konsultasi",
          value: "consultationStartDatetime",
          class: "primary--text ",
        },
        {
          text: "Waktu Konsultasi",
          value: "consultationStarttime",
          class: "primary--text ",
        },
        {
          text: "Nama Dokter",
          value: "doctorFullName",
          class: "primary--text ",
        },
        {
          text: "",
          value: "detail",
        },
        {
          text: "",
          value: "vid",
        },
      ],
      pasien2: [
        {
          id: "",
        },
      ],
      headerObat: [
        {
          text: "Tipe",
          align: "center",
          sortable: false,
          value: "medicineType",
          class: "primary--text title",
        },
        {
          text: "Nama Obat",
          align: "center",
          value: "medicineBrandName",
          class: "primary--text title",
        },
        {
          text: "Kekuatan",
          align: "center",
          value: "medicineSize",
          class: "primary--text title",
        },
        {
          text: "Sigma",
          align: "center",
          value: "periodType",
          class: "primary--text title",
        },
        {
          text: "Cara Pakai",
          align: "center",
          value: "howToUseType",
          class: "primary--text title",
        },
        //  {
        //   text: "Instruksi",
        //   align: "center",
        //   value: "optionSelectedIds",
        //   class: "primary--text title",
        // },
        {
          text: "Jumlah",
          align: "center",
          value: "totalMedicine",
          class: "primary--text title",
        },
      ],
      headerLaborat: [
        {
          text: "Nama",
          align: "center",
          sortable: false,
          value: "laboratoryGroupName",
          class: "primary--text title",
        },
        // {
        //   text: "Nama",
        //   align: "center",
        //   value: "laboratoryGroupName",
        //   class: "primary--text title",
        // },
        {
          text: "Item",
          align: "center",
          value: "items",
          class: "primary--text title",
        },
      ],
      headerDiagnostik: [
        {
          text: "Nama",
          align: "center",
          sortable: false,
          value: "diagnosticGroupName",
          class: "primary--text title",
        },
        {
          text: "Item",
          align: "center",
          value: "items",
          class: "primary--text title",
        },
      ],
      headerVcall: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text title",
        },
        {
          text: "Daftar Video ",
          value: "Download",
          class: "primary--text title",
        },
      ],
      detailPenyakitUtama: [],
      detailPenyakitLain: [],
      doctor: [],
      doctorAssessment: [],
      doctorAllAssessment: [],
      doctordetail: [],
      pasien: [],
      pasienDetail: [],
      pasienDetailAssessment: [],
      detailObatMain: [],
      laboratorium: [],
      laboratoriumDetail: [],
      radiologiGroup: [],
      radiologi: [],
      lengkap: [],
      diagnosisDto: [],
      suggest: [],
      pengganti: [],
      namaDepan: [],
      namaPasien: [],
      namaBelakang: [],
      hospitalConsultationNumber: [],
      columns: [
        {
          label: "Product",
          field: "product",
        },
        {
          label: "Price",
          field: "price",
          dataFormat: this.priceFormat,
        },
        {
          label: "Quantity",
          field: "quantity",
        },
      ],
      data: [
        {
          product: "Beverage",
          price: 10,
          quantity: 2,
        },
        {
          product: "Snack",
          price: 12,
          quantity: 6,
        },
        {
          product: "Beverage",
          price: 10,
          quantity: 5,
        },
        {
          product: "Snack",
          price: 12,
          quantity: 3,
        },
      ],
    };
  },
  mounted() {
    this.loginData();
    this.getLovs();
    this.getListPasien();
    if (this.RekapDetailList.length === 0) {
      this.detailPasien = false;
    }
    console.log(window.localStorage.getItem("indexGet"));
  },
  methods: {
    loginData() {
      this.login = JSON.parse(window.localStorage.getItem("loginData"));
      console.log("login list", this.login.features);
      this.RekapList = this.login.features.filter(
        (item) => item.featureName === "Rekap Pasien"
      );

      this.RekapDetailList = this.login.features.filter(
        (item) => item.featureName === "Rekap Pasien Detail"
      );
      console.log("rekap pasien list", this.RekapList);
      console.log("rekap pasien list detail", this.RekapDetailList);
    },
    CloseVid() {
      this.vcallList = [];
      this.videoDialog = false;
    },
    choosePage(page) {
      console.log(page);
      this.paginate.page = page - 1;
      console.log(this.paginate);
      this.getListPasien(this.paginate);
    },
    openVid(item) {
      console.log(item);
      window.open(item.streamUrl);
    },
    Vid(item) {
      console.log(item);
      console.log(item.cntConsultationId);
      rekapAPI.vidCall(item.cntConsultationId).then((res) => {
        console.log(res);
        // this.vcallList = res.data.payload;
        let data = res.data.payload;
        for (let i = 0; i < data.length; i++) {
          if (data[i].streamUrl !== null && data[i].streamUrl !== "") {
            this.vcallList.push(data[i]);
          }
        }
        console.log(this.vcallList);
        this.videoDialog = true;
      });
    },
    retry() {
      rekapAPI.retry(this.idchat).then((res) => {
        console.log(res);
        if (res.data.payload === true) {
          window.location.reload();
          //   rekapAPI
          // .getChat(this.idchat)
          // .then((response) => {
          //   console.log(response);
          //   this.chatList = response.data.payload;
          //   console.log(this.chatList);
          // })
        }
      });
    },
    CloseChat() {
      this.chatDialog = false;
    },
    Chat(e) {
      this.chatDialog = true;
      this.idchat = e.cntConsultationId;
      rekapAPI
        .getChat(e.cntConsultationId)
        .then((response) => {
          console.log(response);
          this.chatList = response.data.payload;
          console.log(this.chatList);
        })
        .catch((error) => console.log(error));
    },
    priceFormat(value) {
      return "$ " + value;
    },
    onSearch() {
      this.paginate.page = 0;
      // this.paginate.size = 100000000
      this.paginate.name = this.Search;
      console.log(this.paginate);
      this.getListPasien(this.paginate);
    },

    selectedPage() {
      console.log(this.pageNumber);
      this.paginate.size = this.pageNumber;
      this.getListPasien(this.paginate);
    },

    getListPasien(data) {
      if (this.RekapDetailList.length > 0) {
        console.log("benar");
        data = this.paginate;
        rekapAPI.getAdminHospitalList(data).then((response) => {
          console.log(response);
          console.log(response.data.payload.content);
          this.pageCount = response.data.payload.totalPages;
          this.totalpage = response.data.payload;
          this.pasien2 = response.data.payload.content;
          rekapAPI.getSummary().then((response) => {
            console.log(response);
          });
        });
      } else if (this.RekapDetailList.length === 0) {
        console.log("salah");
        data = this.paginate;
        rekapAPI.getPasienList(data).then((response) => {
          console.log(response.data.payload.content);
          this.pageCount = response.data.payload.totalPages;
          this.totalpage = response.data.payload;
          this.pasien2 = response.data.payload.content;
        });
      }
    },
    getLovs() {
      PharmacyServices.getMasterLovsHospital().then((response) => {
        this.baseItemsObat = response.data.payload["medicine-type"];
        this.itemPeriod = response.data.payload["period-type"];
        this.baseItemHtu = response.data.payload["how-to-use-type"];
        this.baseItemUnit = response.data.payload["unit-type"];
        this.baseInstructionType = response.data.payload["instruction-type"];
      });
    },
    log() {
      console.log("====================================");
      console.log("asdads");
      console.log("====================================");
    },
    // date(){
    //   console.log(this.enddate);
    // },
    closing() {
      window.location.reload();
    },
    closing2() {
      (this.detailPenyakitUtama = []),
        (this.detailPenyakitLain = []),
        (this.doctor = []),
        (this.doctorAssessment = []),
        (this.doctorAllAssessment = []),
        (this.doctordetail = []),
        (this.pasien = []),
        (this.pasienDetail = []),
        (this.pasienDetailAssessment = []),
        (this.detailObatMain = []),
        (this.laboratorium = []),
        (this.laboratoriumDetail = []),
        (this.radiologiGroup = []),
        (this.radiologi = []),
        (this.lengkap = []),
        (this.suggest = []),
        (this.popUp = false);
    },
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday === 0 || weekday === 6;
    },
    open(item) {
      this.popUp = true;
      console.log(item.cntConsultationId);
      rekapAPI
        .getAdminPasienListDetail(item.cntConsultationId)
        .then((response) => {
          this.lengkap = response.data.payload.radiologyForm;
          console.log(response);
          this.hospitalConsultationNumber =
            response.data.payload.consultation.hospitalConsultationNumber;
          this.diagnosisDto = response.data.payload.diagnosisDto;
          this.detailPenyakitUtama =
            response.data.payload.diagnosisDto.detailsMap.main;
          this.detailPenyakitLain =
            response.data.payload.diagnosisDto.detailsMap.other;
          this.doctor =
            response.data.payload.doctorAssessment.assessmentAnswers;
          this.doctordetail =
            response.data.payload.doctorAssessment.assessmentAnswers.optionSelectedIds;
          this.pasien = response.data.payload.patientAssessment;
          this.pasienDetail = response.data.payload.patient;
          this.doctorAssessment =
            response.data.payload.doctorAssessment.answerJson;
          this.namaPasien = response.data.payload.patient;
          console.log(this.namaDepan);
          const index = this.doctorAssessment.findIndex((e) =>
            e.optionSelecteds.map((s) => s.optionLabel).includes("Lainnya")
          );
          // console.log(index);
          // console.log(this.doctorAssessment[index]);
          let data = [];
          for (
            let i = 0;
            i < this.doctorAssessment[index].optionSelecteds.length;
            i++
          ) {
            if (
              this.doctorAssessment[index].optionSelecteds[i].optionLabel ===
              "Lainnya"
            ) {
              data.push(this.doctorAssessment[index].optionNote);
            } else {
              data.push(
                this.doctorAssessment[index].optionSelecteds[i].optionLabel
              );
            }
          }
          // console.log(data);
          this.pengganti = data;

          this.doctorAssessment.forEach((obj) => {
            let data2 = [];
            for (let i = 0; i < obj.optionSelecteds.length; i++) {
              if (obj.optionSelecteds[i].optionLabel === "Lainnya") {
                data2.push(obj.optionNote);
              } else {
                data2.push(obj.optionSelecteds[i].optionLabel);
              }
            }
            // console.log(data2);
            obj.optionSelecteds = data2;
          });
          // console.log(this.doctorAssessment.optionSelecteds)
          console.log(this.doctorAssessment);
          this.doctorAllAssessment = response.data.payload.doctorAssessment;
          // console.log(this.doctorAllAssessment.mainComplaint);

          this.laboratoriumDetail = response.data.payload.laboratoryForm;
          if (this.laboratoriumDetail === null) {
            this.laboratCard = false;
          }
          this.radiologi = response.data.payload.radiologyForm;
          if (this.radiologi === null) {
            this.radiologiCard = false;
          }
          console.log(this.radiologi);
          //  this.detailObatMain=response.data.payload.prescriptionAll.mainItems
          this.detailObatMain = response.data.payload.prescriptionAll;
          this.suggest = response.data.payload.suggestion;
          console.log(this.suggest);
          // this.laboratorium=response.data.payload.laboratoryForm.groups
          // console.log(this.laboratorium);

          // this.radiologiGroup=response.data.payload.radiologyForm.groups
          this.pasienDetailAssessment =
            response.data.payload.patientAssessment.assessmentAnswers;
          if (this.detailObatMain !== null) {
            this.detailObatMain.mainItems.forEach((obj) => {
              obj.howToUseType = this.baseItemHtu.filter((item) =>
                obj.howToUseType.includes(item.value)
              );
              console.log(obj.howToUseType);

              obj.periodType = this.itemPeriod.filter((item) =>
                obj.periodType.includes(item.value)
              );
            });
          }
          //   console.log(this.detailObatMain);
          // console.log(this.detailPenyakitUtama);
        });
    },
  },
  watch: {
    // itEnds(){
    //   return (this.enddate , console.log(this.enddate))
    // },
  },
  computed: {
    IndexOfVidCall() {
      return this.vcallList.map((vcallList, index) => ({
        ...vcallList,
        index: index + 1,
      }));
    },
    itStart() {
      return (
        this.paginate.recentDate,
        console.log(this.paginate.recentDate),
        this.getListPasien(this.paginate)
      );
    },
    itEnds() {
      // this.paging.endDate = this.enddate

      return (
        this.paginate.lastDate,
        console.log(this.paginate.lastDate),
        this.getListPasien(this.paginate)
      );

      // return this.log()
    },
    indexof() {
      return this.pasien2.map((pasien2, index) => ({
        ...pasien2,
        index: index + this.totalpage.size * this.totalpage.number + 1,
      }));
    },
    date(val) {
      return console.log(val);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/dashboard.scss";
.background {
  background-color: #edf4fb;
}
.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
.instructionType {
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.down1 {
  // word-break: break-all;
  font-family: Nunito;
  font-size: 11px;
  margin-top: 0%;
  white-space: pre-line;
  word-wrap: break-word;
  max-width: 500px;
  max-height: 100%;
  height: 500px;
}
.buble {
  background: linear-gradient(90.54deg, #60a9f6 0%, #2a8bf2 100%);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 13px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  margin-top: 1px;
  color: white;
  overflow-y: auto;
  box-shadow: 10px 10px 25px rgba(42, 139, 242, 0.1),
    15px 15px 35px rgba(42, 139, 242, 0.05),
    10px 10px 50px rgba(42, 139, 242, 0.1);
  max-width: 250px;
}
.bubleme {
  background: #ffffff;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 0px;
  margin-top: 1px;
  border: 1px solid rgba(112, 124, 151, 0.25);
  box-sizing: border-box;
  box-shadow: 10px 10px 25px rgba(112, 124, 151, 0.05),
    15px 15px 35px rgba(112, 124, 151, 0.05);
}
.chatingbro {
  overflow-y: auto;
  max-height: 120px;
  font-size: 12px;
}
.theme--light.v-input:not(.v-input--is-disabled) input {
  color: #fff;
}
.cardtable {
  margin-top: -50px;
}
.text-title {
  font-size: 20px !important;
}
</style>