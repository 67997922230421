import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://api.klinisia.id/admin/consultation/";

class Consultation {
  async getPrescriptions(param) {
    return await axios.get(
      `${API_URL}prescription/list?search=${param.name}&page=${param.page}&size=${param.size}&sortDirection=DESC&sortField=phrConfirmReqDatetime`,
      { headers: headers() },
    );
  }
  async getPrescriptionById(id) {
    return await axios.get(`${API_URL}prescription/detail/${id}`, {
      headers: headers(),
    });
  }
  async postPrescription(request) {
    // console.log(JSON.stringify(request));
    return await axios.post(
      `${API_URL}prescription/confirm/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async postReady(request) {
    return await axios.post(
      `${API_URL}prescription/order-ready/${request.id}`,
      request.id,
      { headers: headers() },
    );
  }
  async postFinish(request) {
    return await axios.post(
      `${API_URL}prescription/order-finish/${request.id}`,
      request.id,
      { headers: headers() },
    );
  }
  async getPrescriptionByIdReady(id) {
    return await axios.get(`${API_URL}prescription/detail-ordered/${id}`, {
      headers: headers(),
    });
  }
}

export default new Consultation();
