<template>
  <v-app class="background">
    <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row>
          <v-col sm="12" md="4" lg="3" class="search">
            <span
              style="
              color: #2b92e4;
              font-family: Inter;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 34px;
              letter-spacing: 0em;
              text-align: left;
            "
              >Status Pengiriman</span
            >
          </v-col>
          <v-col sm="12" md="6" lg="6" class="search">
            <v-text-field
              label="search"
              v-model="Search"
              @keyup.enter="onSearch"
              append-icon="mdi-magnify"
              outlined
              rounded
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container>
      <v-row class="ml-1">
        <v-col lg="2" sm="4">
          <p class="text-spesialis">Tampilkan</p>
        </v-col>
        <v-col lg="2" sm="6">
          <v-select
            hide-details
            v-model="pageNumber"
            :items="slot"
            outlined
            @change="selectedPage()"
            dense
            style="background: white"
            item-color="success"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
        :page.sync="page"
        :headers="header"
        :items="indexof"
        :server-items-length="pageCount"
        :search="Search"
        hide-default-footer
        class="elevation-1 cardStyle"
      >
        <template v-slot:item.Resi="{ item }">
          <v-text-field
            class="mt-5"
            v-if="item.shippingStatus == 'start'"
            dense
            v-model="item.deliveryReceiptNumber"
            outlined
          ></v-text-field>
          <div v-if="item.shippingStatus !== 'start'">
            <a>{{ item.deliveryReceiptNumber }}</a>
          </div>
        </template>
        <template v-slot:item.sirsPaymentReceiptNo="{ item }">
          <v-text-field
            class="mt-5"
            v-if="item.shippingStatus == 'start'"
            dense
            v-model="item.sirsPaymentReceiptNo"
            outlined
          ></v-text-field>
          <div v-if="item.shippingStatus !== 'start'">
            <a>{{ item.sirsPaymentReceiptNo }}</a>
          </div>
        </template>
        <template v-slot:item.transaksi="{ item }">
          <a
            style="
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 27px;
              letter-spacing: 0.01em;
              text-align: left;
              color: #2b92e4;
            "
            @click.stop="open2(item)"
            >Lihat Detail</a
          >
        </template>
        <template v-slot:item.phrOrderDatetime="{ item }">
          <a>{{ item.phrOrderDatetime | orderDate }}</a>
        </template>
        <template v-slot:item.shippingStartDate="{ item }">
          <a>{{ item.adminUpdatedOn | orderDate }}</a>
        </template>
        <template v-slot:item.shippingStartTime="{ item }">
          <a>{{ item.adminUpdatedOn | orderTime }}</a>
        </template>

        <template v-slot:item.Aksi="{ item }">
          <v-btn
            v-if="item.shippingStatus == 'start' && menuList[0].update === true"
            color="success"
            small
            @click.stop="confirm(item)"
            :disabled="confirmDisabled"
            >Konfirmasi</v-btn
          >
          <p
            v-else-if="
              item.shippingStatus == 'start' && menuList[0].update === false
            "
          >
            konfirmasi
          </p>

          <v-btn
            v-if="
              item.shippingStatus == 'confirm' && menuList[0].update === true
            "
            @click.stop="send(item)"
            small
            >Konfirmasi</v-btn
          >
          <p
            v-else-if="
              item.shippingStatus == 'confirm' && menuList[0].update === false
            "
          >
            Konfirmasi
          </p>

          <v-btn
            v-if="item.shippingStatus == 'send' && menuList[0].update === true"
            color="#4DD0E1"
            small
            @click.stop="delivered(item)"
          >
            <a style="color: #ffffff">Pesanan Diambil</a>
          </v-btn>
          <p
            v-else-if="
              item.shippingStatus == 'send' && menuList[0].update === false
            "
          >
            Pesanan Diambil
          </p>

          <v-btn
            v-if="
              item.shippingStatus == 'delivered' && menuList[0].update === true
            "
            @click.stop="finishing(item)"
            color="primary"
            small
            >Pesanan Diterima</v-btn
          >
          <p
            v-else-if="
              item.shippingStatus == 'delivered' && menuList[0].update === false
            "
          >
            Pesanan Diterima
          </p>

          <template v-if="item.shippingStatus == 'finish'" color="success"
            >selesai</template
          >
        </template>
      </v-data-table>

      <!-- modal -->
      <v-dialog v-model="visible2" width="1200" :retain-focus="false">
        <v-card>
          <div id="printMe" class="printableArea">
            <v-card-title>
              <v-row>
                <v-col cols="8">
                  <p class="title-order">
                    Transaksi #{{
                      detailPasien2.prescription.prescriptionNumber
                    }}
                  </p>
                  <v-row>
                    <v-col cols="9">
                      <p>
                        {{
                          detailPasien2.prescription.phrConfirmReqDatetime
                            | orderDate
                        }}, PK
                        {{
                          detailPasien2.prescription.phrConfirmReqDatetime
                            | orderTime
                        }}
                      </p>
                    </v-col>
                    <v-col cols="3" align-self="right"> </v-col>
                  </v-row>
                </v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="auto">
                      <img src="../../assets/logo Klinisia.svg" />
                    </v-col>
                    <v-col cols="auto">
                      <img src="../../assets/Klinisia.svg" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-icon @click="visible2 = false" class="float-right" large
                    >mdi-close</v-icon
                  >
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="cardText">
              <v-container style="background-color: rgba(235, 239, 242, 0.6)">
                <v-row>
                  <v-col>
                    <div class="mb-0">
                      <span
                        style="
                          font-family: Nunito;
                          font-size: 24px;
                          font-style: normal;
                          sfont-weight: 700;
                          line-height: 33px;
                          letter-spacing: 0em;
                          text-align: left;
                          color: #2b92e4;
                        "
                        >Detail Pasien</span
                      >
                    </div>
                    <div>
                      <span
                        style="
                          font-family: Nunito;
                          font-size: 24px;
                          font-style: normal;
                          sfont-weight: 700;
                          line-height: 33px;
                          letter-spacing: 0em;
                          text-align: left;
                        "
                      >
                        {{ detailPasien2.patient.firstName }}
                        {{ detailPasien2.patient.middleName }}
                        {{ detailPasien.patient.lastName }}
                      </span>
                    </div>
                    <span>Phone: {{ detailPasien2.patient.mobilePhone }}</span>
                    <v-spacer></v-spacer>
                  </v-col>
                  <v-col>
                    <div class="mb-0">
                      <span
                        style="
                          font-family: Nunito;
                          font-size: 24px;
                          font-style: normal;
                          sfont-weight: 700;
                          line-height: 33px;
                          letter-spacing: 0em;
                          text-align: left;
                          color: #2b92e4;
                        "
                        >No. RM</span
                      >
                    </div>
                    <p
                      class="mt-0"
                      style="
                        font-family: Nunito;
                        font-size: 24px;
                        font-style: normal;
                        sfont-weight: 700;
                        line-height: 33px;
                        letter-spacing: 0em;
                        text-align: left;
                      "
                    >
                      {{ detailPasien2.patient.medicalRecordNumber }}
                    </p>
                  </v-col>
                  <v-col>
                    <div class="mb-0">
                      <span
                        style="
                          font-family: Nunito;
                          font-size: 24px;
                          font-style: normal;
                          sfont-weight: 700;
                          line-height: 33px;
                          letter-spacing: 0em;
                          text-align: left;
                          color: #2b92e4;
                        "
                        >Alamat Pengiriman</span
                      >
                    </div>

                    <a>{{ detailPasien2.address.address }}</a>
                    <v-spacer></v-spacer>
                    <a>
                      {{ detailPasien2.address.districtName }} ,
                      {{ detailPasien2.address.subDistrictName }}
                    </a>
                    <v-spacer></v-spacer>
                    <a>
                      {{ detailPasien2.address.cityName }}
                      {{ detailPasien2.address.postalCode }}
                    </a>
                    <v-spacer></v-spacer>
                    <a>{{ detailPasien2.address.provinceName }}</a>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div>
                      <span
                        style="
                          font-family: Nunito;
                          font-style: normal;
                          sfont-weight: 700;
                          line-height: 33px;
                          letter-spacing: 0em;
                          text-align: left;
                        "
                        >Diterbitkan atas nama:</span
                      >
                    </div>
                    <v-row class="terbit">
                      <v-col>
                        <span
                          style="
                            font-family: Nunito;
                            font-size: 24px;
                            font-style: normal;
                            sfont-weight: 700;
                            line-height: 33px;
                            letter-spacing: 0em;
                            text-align: left;
                          "
                          >{{ namaRs }}</span
                        >
                        <v-spacer></v-spacer>
                        <span>{{ alamat }}</span>
                        <v-spacer></v-spacer>
                        <span>{{ provinceGet }} {{ postalCodeGet }}</span>
                      </v-col>
                      <v-col>
                        <span>Dokter: {{ detailPasien2.doctor.fullName }}</span>
                        <v-spacer></v-spacer>
                        <span>SIP: {{ detailPasien2.doctor.sipNumber }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <div class="text-center my-5">
                <span
                  style="
                    font-family: Inter;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 29px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #2b92e4;
                  "
                  >Belanja Farmasi</span
                >
              </div>
              <v-simple-table
                :items-per-page="itemsPerPage"
                :page.sync="page"
                @page-count="pageCount = $event"
              >
                <thead>
                  <tr>
                    <th class="text-center mr-3">No</th>
                    <th class="text-center mr-3">Nama Obat</th>
                    <th class="text-center mr-3">Kekuatan</th>
                    <th class="text-center mr-3">sigma</th>
                    <th class="text-center mr-3">Qty</th>
                    <th class="text-center mr-3">Instruksi</th>
                    <th class="text-center mr-3">Harga</th>
                    <th class="text-center mr-3">Satuan</th>
                    <th class="text-center mr-3">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ngobat, index) in detailobat" :key="index">
                    <td>
                      <p
                        class="mt-3"
                        v-text="ngobat.prescriptionDetailOrder"
                      ></p>
                    </td>
                    <td width="250px">
                      <p v-text="ngobat.medicineBrandName" class="mt-3"></p>
                    </td>
                    <td>
                      {{ ngobat.medicineSize }}
                      {{ ngobat.medicineSizeUnitType }}
                    </td>
                    <td>
                      {{ ngobat.frequency }} X {{ ngobat.dosage }}
                      {{ ngobat.periodType.map((e) => e.label).toString() }}
                    </td>
                    <td>
                      <p class="mt-3">{{ ngobat.totalMedicine }}</p>
                    </td>
                    <td>
                      <v-row>
                        <v-col cols="auto">
                          <a>
                            {{
                              ngobat.instructionTypes
                                .map((e) => e.label)
                                .toString()
                            }}
                          </a>
                        </v-col>
                      </v-row>
                    </td>
                    <td>
                      <p>
                        Rp.{{
                          new Intl.NumberFormat().format(ngobat.itemPrice)
                        }},-
                      </p>
                    </td>
                    <td>
                      <span>{{ ngobat.medicineType | caps }}</span>
                    </td>
                    <td>
                      <p>
                        Rp.{{
                          new Intl.NumberFormat().format(
                            ngobat.totalMedicine * ngobat.itemPrice,
                          )
                        }},-
                      </p>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-row>
                <v-col cols="4"></v-col>
                <v-col>
                  <div class="text-center">
                    <v-card class="mt-10">
                      <v-card-text>
                        <v-row class="total">
                          <v-col cols="7">
                            <div class="float-left">
                              <p>SUBTOTAL BELANJA FARMASI</p>
                            </div>
                          </v-col>
                          <v-col cols="4">
                            <div class="float-right mr-3">
                              <p class="font-weight-bold">
                                <!-- Rp. {{ totalPesanan }} -->
                                Rp.{{
                                  new Intl.NumberFormat().format(totalPesanan)
                                }},-
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="total">
                          <v-col cols="7">
                            <div class="float-left">
                              <span>SUBTOTAL ONGKOS KIRIM</span>
                            </div>
                          </v-col>
                          <v-col cols="4">
                            <div class="float-right mr-3">
                              <p class="font-weight-bold">
                                Rp.{{
                                  new Intl.NumberFormat().format(
                                    detailPasien2.shipping.shippingFee,
                                  )
                                }},-
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="7">
                            <div class="float-left">SUBTOTAL BIAYA LAINNYA</div>
                          </v-col>

                          <v-col cols="4">
                            <div class="float-right mr-3">
                              <p class="font-weight-bold">Rp. 0</p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-row></v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
          <div>
            <v-btn class="btnPrint" color="primary" @click="printDiv()"
              >Print</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
    </v-container>

    <v-pagination
      circle
      v-model="page"
      :length="totalpage.totalPages"
      :total-visible="7"
      @input="choosePage(page)"
    ></v-pagination>
  </v-app>
</template>

<script>
  import axios from "axios";
  import PharmacyServices from "@/services/pharmacy_services";
  import ConsultationServices from "@/services/consultation_services";
  import ShippingService from "@/services/shipping_service.js";

  export default {
    name: "pengiriman",
    components: {
      // Popup
    },
    data: () => ({
      postalCodeGet: "",
      provinceGet: "",
      namaRs: "",
      alamat: "",
      menuList: {},
      login: {},
      confirmDisabled: false,
      receipt: "",
      printObj: {
        id: "printMe",
        popTitle: "",
        extraCss: "https://www.google.com,https://www.google.com",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
      },
      output: null,
      checkBeforeEat: { id: false, value: "Sebelum Makan", item: "beforeEat" },
      checkAfterEat: { id: false, value: "Setelah Makan", item: "afterEat" },
      checkIfSpent: { id: false, value: "Dihabiskan", item: "all" },
      checkIfNeeded: { id: false, value: "Jika Perlu", item: "ifNeeded" },
      nambah: { show: false, toggle: "" },
      howToUse: [],
      powerMg: "",
      detailPasien: { prescription: {}, patient: {}, address: {} },
      detailobat: [],
      searchData: "",
      search: "",
      Search: "",
      dataSearch: [],
      tipeobat: "",
      sigma: {},
      medType: {},
      dialog: {},
      typeObat: "",
      itemsObat: [],
      itemHtu: [],
      itemUnit: [],
      baseItemsObat: "",
      baseItemHtu: "",
      baseItemUnit: "",
      baseInstructionType: [],
      usertype: "how-to-use-type",
      sblmMakan: "",
      sdhMakan: "",
      habis: "",
      shipping: 0,
      globalOptions: {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: [
          "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
          "https://unpkg.com/kidlat-css/css/kidlat.css",
        ],
      },
      localLandScapeOptions: {
        styles: [
          "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
          "https://unpkg.com/kidlat-css/css/kidlat.css",
          "./portrait.css",
        ],
      },
      detailPasien2: {
        doctor: {},
        prescription: {},
        patient: {},
        address: {},
        shipping: {},
      },
      perlu: "",
      kekuatanObat: "",
      page: 1,
      pageCount: 0,
      address: {},
      namaObat: "",
      typeUnit: "",
      listKonfirmasi: [],
      ShippingList: [],
      detail: [],
      pasien: [
        {
          name: "1",
          NomorPasien: "780891019",
          namaPasien: "Mahmud",
          handphone: "+6910",
          tanggalBeli: "5  Des 2020",
          Jam: "14.00",
          namaDokter: "Dr. Pamela",
          konfirmasi: "konfirmasi",
        },
      ],
      frekuensiObat: "",
      dosis: "",
      totalHari: "",
      typePeriod: "",
      itemPeriod: [],
      instruction: [],
      slot: [5, 10, 15, 20],
      pageNumber: 10,
      paginate: { name: "", page: 0, size: 10 },
      totalpage: [],
      totalPesanan: 0,
      visible: false,
      visible2: false,
      typeAlert: "",
      textAlert: "",
      showAlert: false,
      totalPurchase: 0,
      header: [
        {
          text: "No",
          align: "start",
          sortable: true,
          value: "index",
          class: "primary--text ",
        },
        // {
        //   text: "Pesanan",
        //   value: "detail",
        //   class: "primary--text ",
        // },

        {
          text: "Pembeli",
          value: "patientFullName",
          class: "primary--text ",
        },

        {
          text: "Tgl Pemesanan",
          value: "phrOrderDatetime",
          class: "primary--text ",
        },
        {
          text: "Transaksi",
          value: "transaksi",
          class: "primary--text ",
        },
        {
          text: "No Resi",
          value: "Resi",
          class: "primary--text ",
        },
        {
          text: "Payment Receipt No",
          value: "sirsPaymentReceiptNo",
          class: "primary--text ",
        },
        {
          text: "Aksi",
          value: "Aksi",
          class: "primary--text ",
        },
        {
          text: "Updated by",
          value: "adminUpdatedBy",
          class: "primary--text ",
        },
        {
          text: "Tgl Update",
          value: "shippingStartDate",
          class: "primary--text ",
        },
        {
          text: "Jam",
          value: "shippingStartTime",
          class: "primary--text ",
        },
      ],
    }),
    mounted() {
      this.loginData();
      this.getLovs();
      // this.getPrescriptions();

      this.getShipping();
      this.detailobat.maxTotalMedicine = this.detailobat.totalMedicine;
      this.getAddress();
      console.log(this.detailobat);
    },
    watch: {
      search(query) {
        console.log(query);
        if (!query) {
          this.clearData();
        }
        PharmacyServices.getMasterSearchMedice(query).then((response) => {
          this.dataSearch = response.data.payload;
        });
      },
      shipping(val) {
        console.log(val);
        if (!val) val = 0;
        this.totalPurchase = this.totalPesanan + parseInt(val);
      },
    },
    computed: {
      nonZero: function() {
        return this.detailobat.filter(function(ngobat) {
          return ngobat.totalMedicine > -10;
        });
      },
      indexof() {
        return this.ShippingList.map((ShippingList, index) => ({
          ...ShippingList,
          index: index + this.totalpage.size * this.totalpage.number + 1,
        }));
      },
      sum: {
        get() {
          let hasil = 0;
          if (
            this.frekuensiObat.length == 0 &&
            this.dosis.length == 0 &&
            this.totalHari.length == 0
          ) {
            hasil = 0;
          } else if (
            this.frekuensiObat.length == 0 ||
            this.dosis.length == 0 ||
            this.totalHari.length == 0
          ) {
            hasil = 0;
          } else {
            hasil =
              parseInt(this.frekuensiObat) *
              parseInt(this.dosis) *
              parseInt(this.totalHari);
          }
          return hasil;
        },
        set() {},
      },
    },
    methods: {
      getAddress() {
        ShippingService.getAddress()
          .then((response) => {
            console.log("alamat2s", response);
            this.namaRs = response.data.payload.hospitalName;
            this.alamat = response.data.payload.hospitalAddress;
            this.postalCodeGet = response.data.payload.postalCode;
            this.provinceGet = response.data.payload.provinceName;
          })
          .catch((err) => console.log(err));
      },

      loginData() {
        this.login = JSON.parse(window.localStorage.getItem("loginData"));
        console.log("login list", this.login.features);
        this.menuList = this.login.features.filter(
          (item) => item.featureName === "Status Pengiriman",
        );
        console.log(this.menuList);
        // confirmDisabled
        if (this.menuList[0].update === true) {
          this.confirmDisabled = false;
        }
        console.log(this.confirmDisabled);
      },
      printDiv() {
        //      var divToPrint=document.getElementById('printMe');

        // var newWin=window.open('','Print-Window');

        // newWin.document.open();

        // newWin.document.write('<html><body onload="window.print()">'+divToPrint.innerHTML+'</body></html>');

        // newWin.document.close();

        // setTimeout(function(){newWin.close();},10);
        this.visible2 = false;
        // window.location.reload();
        // return this.visible2 = false;
        setTimeout(() => {
          var headstr = "<html><head><title></title></head><body>";
          var footstr = "</body>";
          var newstr = document.all.item("printMe").innerHTML;
          var oldstr = document.body.innerHTML;
          document.body.innerHTML = headstr + newstr + footstr;
          window.print();
          document.body.innerHTML = oldstr;
          window.location.reload();
        }, 1000);
      },
      //   printDiv(){
      //     this.visible2 = false;
      //     // window.location.reload();

      //     //  var divContents = document.getElementById("printMe").innerHTML;
      //     //       // var a = window.open('', '', 'height=500, width=500');

      //     //       // a.document.write(divContents);
      //     //       // a.document.write('</body></html>');
      //     //       // a.document.close();
      //     //       // a.print();
      //     //        var originalContents = document.body.innerHTML;

      //     //       document.body.innerHTML = divContents;

      //     //        window.print();

      //     //         // window.open()

      //     //         document.body.innerHTML = originalContents;
      //             setTimeout(()=>{
      //                var divContents = document.getElementById("printMe").innerHTML;
      //                var a = window.open('', '', 'height=500, width=500');
      //                 var originalContents = document.body.innerHTML;

      //           a.document.body.innerHTML = divContents;

      //            window.print();
      // a.document.body.innerHTML = originalContents;
      //             },1000)
      //   },
      //  print () {
      //   // Pass the element id here
      //   this.$htmlToPaper('printMe');
      //   // window.print()
      // },
      finishing(item) {
        let request = {
          id: item.cntPrescriptionId,
        };
        ShippingService.finishConfirm(request).then((response) => {
          console.log(response);
          window.location.reload();
          this.getShipping();
        });
      },
      delivered(item) {
        let request = {
          id: item.cntPrescriptionId,
        };
        console.log(item);
        ShippingService.deliveredConfirm(request).then((response) => {
          console.log(response);
          window.location.reload();
          this.getShipping();
        });
      },
      send(item) {
        console.log(item);
        let request = {
          id: item.cntPrescriptionId,
        };
        ShippingService.sendConfirm(request)
          // axios.post(`https://api.klinisia.id/admin/consultation/prescription/delivery/update-send/90619a3f995c44698565319a0febb105`,
          // {
          //    headers: {
          //         "Content-Type": "application/json",
          //         "Authorization": "Bearer eyJhbGciOiJSUzI1NiJ9.eyJyZWZmSWQiOiIgIiwidG9rZW5UeXBlIjoic2hvcnRUb2tlbiIsInVzZXJUeXBlIjoiYWRtaW4iLCJyb2xlIjoiQURNSU4iLCJob3NwaXRhbElkIjoiTUVESVNUUkEiLCJzdWIiOiJVU1ItQURNLTEiLCJpc3MiOiJ0ZWxlbWVkIiwiZXhwIjoxNjE1ODkzMTczLCJpYXQiOjE2MTU4OTEzNzN9.l8W-XyrHWfy5N3ldo8GeqyICLd5V8hp7ARB-ScB674jxa4NkYJBzZNGcK5HAXzSLmug2fsT-jWIZNyZ9JR4ViwxcnztrRJB2Xw58OhqKeMrm_vpcevuxxHI0FIh_uT1Juuo0T7DB5KPoGx8Eo4rkGbpCvwtB9Y5Fx6fzCN5MXsN9akc3cxuUeZ54bEObEgXMCF3El0CPrlimNNl969BNqbyHcuAZonKu8AA0lyRV8GaKV4VdNtNEHCd1W6E0xETh0Pi6vvFOinmdxjUNgBGrn6box_IzLAUqiYSI9a2yeEG__MinKMgSNOXAzpSwvN_3FCezEUa4tfqZWQcTT_sssg"
          //       },
          // })
          .then((response) => {
            console.log(response);
            window.location.reload();
            this.getShipping();
          })
          .catch((error) => console.log(error));
      },
      confirm(item) {
        this.confirmDisabled = true;
        // console.log(item.deliveryReceiptNumber);
        console.log(item);

        // this.getShipping();
        // let data =  {
        //   deliveryReceiptNumber: item.deliveryReceiptNumber
        // }
        let request = {
          id: item.cntPrescriptionId,
          body: {
            deliveryReceiptNumber: item.deliveryReceiptNumber,
            sirsPaymentReceiptNo: item.sirsPaymentReceiptNo,
          },
        };
        if (
          item.sirsPaymentReceiptNo === null ||
          item.sirsPaymentReceiptNo === ""
        ) {
          this.$toast.open({
            message: "isi Payment Receipt Terlebih Dahulu",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
          this.confirmDisabled = false;
        } else if (
          item.deliveryReceiptNumber === null ||
          item.deliveryReceiptNumber === ""
        ) {
          this.$toast.open({
            message: "isi no Resi Terlebih dahulu",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
          this.confirmDisabled = false;
        } else if (
          item.sirsPaymentReceiptNo === null ||
          item.sirsPaymentReceiptNo === ""
        ) {
          this.$toast.open({
            message: "isi Payment Receipt No terlebih dahulu",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
          this.confirmDisabled = false;
        } else {
          ShippingService.updateConfirm(request).then((response) => {
            console.log(response);
            if (
              response.data.errors.some(
                (e) =>
                  e.message === "Quantum status=FAILED (501), remarks=not set",
              )
            ) {
              this.$toast.open({
                message: "Payment Receipt No Tidak Sesuai",
                position: "top-right",
                type: "error",
                duration: 6000,
              });
              this.confirmDisabled = false;
            } else window.location.reload();
          });
        }
      },
      openDetail(item) {
        console.log(item);
      },
      onSearch() {
        this.paginate.page = 0;
        this.paginate.name = this.Search;
        console.log(this.paginate);
        this.getShipping(this.paginate);
      },
      choosePage(page) {
        console.log(page);
        this.paginate.page = page - 1;
        console.log(this.paginate);
        this.getShipping(this.paginate);
      },
      selectedPage() {
        console.log(this.pageNumber);
        this.paginate.size = this.pageNumber;
        this.getShipping(this.paginate);
      },
      getShipping(value) {
        value = this.paginate;
        ShippingService.getShippingList(value)
          .then((response) => {
            console.log(response);
            this.pageCount = response.data.payload.totalPages;
            this.totalpage = response.data.payload;
            this.ShippingList = response.data.payload.content;
          })
          .catch((err) => console.log(err));
      },
      isChecked(obj) {
        if (obj.value === "Dihabiskan") {
          this.checkIfNeeded.id = !obj.id;
        }
        if (obj.value === "Jika Perlu") {
          this.checkIfSpent.id = !obj.id;
        }
      },
      changedObat() {
        this.itemsObat = this.baseItemsObat.filter((obj) =>
          this.namaObat.medicineTypes.includes(obj.value),
        );
        this.itemHtu = this.baseItemHtu.filter((obj) =>
          this.namaObat.howToUseTypes.includes(obj.value),
        );
        this.itemUnit = this.baseItemUnit.filter((obj) =>
          this.namaObat.unitTypes.includes(obj.value),
        );
        this.typeObat = this.itemsObat[0];
        this.howToUse = this.itemHtu[0];
        this.typeUnit = this.itemUnit[0];
      },
      cancel(id) {
        this.nambah.toggle = "";
        this.detailobat[id].totalMedicine = this.detailobat[id].max;
      },
      addObat(id) {
        let listCheck = [
          this.checkBeforeEat,
          this.checkAfterEat,
          this.checkIfSpent,
          this.checkIfNeeded,
        ];
        this.instruction.push(...listCheck.filter((obj) => obj.id === true));

        if (this.namaObat.length === 0 || this.instruction.length === 0) {
          this.showAlert = true;
          this.textAlert = "Data Obat dan Instruksi wajib diisi";
          setTimeout(() => {
            this.showAlert = false;
          }, 7000);
        } else {
          let substitute = {
            cntPrescriptionDetailId: this.detailobat[id]
              .cntPrescriptionDetailId,
            cntPrescriptionId: localStorage.getItem("presId"),
            prescriptionTypeCode: "main",
            prescriptionDetailOrder: this.detailobat[id]
              .prescriptionDetailOrder,
            phrMedicineBrandId: this.namaObat.phrMedicineBrandId,
            medicineBrandName: this.namaObat.medicineBrandName,
            medicineType: this.typeObat.value,
            medicineSize: parseInt(this.powerMg),
            medicineSizeUnitType: this.typeUnit.label,
            frequency: parseInt(this.frekuensiObat),
            dosage: parseInt(this.dosis),
            periodType: this.typePeriod.value,
            totalPeriod: parseInt(this.totalHari),
            totalMedicine: this.sum,
            howToUseType: this.howToUse.value,
            instructionTypes: this.instruction,
            itemPrice: 0,
            substituteById: null,
            substituteForId: null,
            reffMedicineBrandId: "",
            totalPrice: 0,
            cekStockStatus: true,
            maxTotalMedicine: this.sum,
          };
          this.detailobat[id].substitute = substitute;
          this.clearData();
          this.nambah.toggle = "";
        }
      },
      clearData() {
        (this.dataSearch = []),
          (this.itemsObat = []),
          (this.itemHtu = []),
          (this.itemUnit = []),
          (this.typeObat = ""),
          (this.howToUse = ""),
          (this.namaObat = []),
          (this.powerMg = ""),
          (this.typeUnit = ""),
          (this.frekuensiObat = ""),
          (this.dosis = ""),
          (this.typePeriod = ""),
          (this.totalHari = ""),
          (this.sum = ""),
          (this.instruction = []),
          (this.checkBeforeEat.id = false),
          (this.checkAfterEat.id = false),
          (this.checkIfSpent.id = false),
          (this.checkIfNeeded.id = false);
      },
      getLovs() {
        PharmacyServices.getMasterLovs().then((response) => {
          this.baseItemsObat = response.data.payload["medicine-type"];
          this.itemPeriod = response.data.payload["period-type"];
          this.baseItemHtu = response.data.payload["how-to-use-type"];
          this.baseItemUnit = response.data.payload["unit-type"];
          this.baseInstructionType = response.data.payload["instruction-type"];
        });
      },
      changeTotalMed(val, index) {
        this.nambah.id = index;
        if (val == 0) {
          this.nambah.toggle = index;
        } else if (val > this.detailobat[index].max) {
          this.detailobat[index].totalMedicine = this.detailobat[index].max;
        } else {
          this.nambah.toggle = "";
        }
        this.totalPesanan = 0;
        this.detailobat.forEach((obj, idx) => {
          this.totalPesanan += obj.itemPrice * obj.totalMedicine;
          if (obj.substitute) {
            this.totalPesanan +=
              obj.substitute.totalMedicine * obj.substitute.itemPrice;
            this.detailobat[idx].substitute.totalPrice =
              this.detailobat[idx].substitute.totalMedicine *
              this.detailobat[idx].substitute.itemPrice;
          }
          this.detailobat[idx].totalPrice =
            this.detailobat[idx].totalMedicine * this.detailobat[idx].itemPrice;
        });
        console.log(this.totalPesanan);
        this.totalPurchase = this.totalPesanan + parseInt(this.shipping);
      },
      changeItemPrice(val, index) {
        console.log(val, index);
        this.totalPesanan = 0;
        this.detailobat.forEach((obj, idx) => {
          this.totalPesanan += obj.totalMedicine * obj.itemPrice;
          if (obj.substitute) {
            this.totalPesanan +=
              obj.substitute.totalMedicine * obj.substitute.itemPrice;
            this.detailobat[idx].substitute.totalPrice =
              this.detailobat[idx].substitute.totalMedicine *
              this.detailobat[idx].substitute.itemPrice;
          }
          this.detailobat[idx].totalPrice =
            this.detailobat[idx].totalMedicine * this.detailobat[idx].itemPrice;
        });

        this.totalPurchase = this.totalPesanan + parseInt(this.shipping || 0);
      },
      open(item) {
        console.log(this.nambah.toggle);
        if (item.phrOrderStatus === null) {
          window.localStorage.setItem("status", "pesan");
        } else if (item.phrOrderStatus === "process") {
          window.localStorage.setItem("status", "proses");
        }
        this.visible = true;
        this.totalPesanan = 0;
        window.localStorage.setItem("presId", item.cntPrescriptionId);
        ConsultationServices.getPrescriptionById(item.cntPrescriptionId)
          .then((response) => {
            this.detailPasien = response.data.payload;
            this.detailobat = response.data.payload.items;

            this.detailobat.forEach((obj) => {
              this.totalPesanan = obj.itemPrice * obj.totalMedicine;
              obj.instructionTypes = this.baseInstructionType.filter((item) =>
                obj.instructionTypes.includes(item.value),
              );
              obj.max = obj.totalMedicine;
            });
            this.totalPurchase = this.totalPesanan + parseInt(this.shipping);
          })
          .catch((err) => console.log(err));
      },
      open2(item) {
        if (item.phrOrderStatus === null) {
          window.localStorage.setItem("status", "pesan");
        } else if (item.phrOrderStatus === "process") {
          window.localStorage.setItem("status", "proses");
        }
        this.visible2 = true;
        this.totalPesanan = 0;
        window.localStorage.setItem("presId", item.cntPrescriptionId);
        ConsultationServices.getPrescriptionByIdReady(item.cntPrescriptionId)
          .then((response) => {
            this.detailPasien2 = response.data.payload;
            this.detailobat = response.data.payload.items;
            this.shippingReady = response.data.payload;
            console.log(this.shippingReady);
            console.log(this.detailobat.totalMedicine);

            this.detailobat.forEach((obj) => {
              this.totalPesanan += obj.itemPrice * obj.totalMedicine;
              obj.instructionTypes = this.baseInstructionType.filter((item) =>
                obj.instructionTypes.includes(item.value),
              );
              obj.periodType = this.itemPeriod.filter((item) =>
                obj.periodType.includes(item.value),
              );
            });
            this.totalPurchase = this.totalPesanan + parseInt(this.shipping);
          })
          .catch((err) => console.log(err));
      },
      getPrescriptions() {
        ConsultationServices.getPrescriptions()
          .then((response) => {
            console.log(response);
            this.listKonfirmasi = response.data.payload.content;
          })
          .catch((err) => console.log(err));
      },
      ready(item) {
        console.log(item.cntPrescriptionId);
        axios
          .post(
            `https://api.klinisia.id/admin/consultation/prescription/order-ready/30c2bd51257240d886491f25685c5383`,
            item.cntPrescriptionId,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + window.localStorage.getItem("shortToken"),
              },
            },
          )
          .then((response) => {
            console.log(response);
            // window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      },
      finish(item) {
        let request = {
          id: item.cntPrescriptionId,
        };
        ConsultationServices.postFinish(request).then((response) => {
          console.log(response);
          window.location.reload();
        });
      },
      submit() {
        var stats = window.localStorage.getItem("status");
        this.detailobat.totalMedicine = this.detailobat.maxTotalMedicine;
        // if (stats==='pesan'){
        // console.log('nyoba pesan');
        // }else if(stats==='proses'){
        //     console.log('siap bayar');
        // }

        if (stats === "pesan") {
          this.detailobat.forEach((obj) => {
            obj.itemPrice = parseInt(obj.itemPrice);
            obj.maxTotalMedicine = obj.totalMedicine;
            obj.totalPrice = obj.itemPrice * obj.maxTotalMedicine;
            obj.instructionTypes = obj.instructionTypes.map((e) => e.value);
            if (obj.substitute) {
              obj.substitute.instructionTypes = obj.substitute.instructionTypes.map(
                (e) => e.item,
              );
            }
            delete obj.max;
          });
          let request = {
            id: localStorage.getItem("presId"),
            body: { shippingFee: this.shipping, items: this.detailobat },
          };
          ConsultationServices.postPrescription(request)
            .then((response) => {
              console.log(JSON.stringify(response));
              this.visible = false;
              this.getPrescriptions();
            })
            .then((err) => console.log(err));
        }
        if (stats === "proses") {
          var id = window.localStorage.getItem("presId");
          axios
            .post(
              `https://api.klinisia.id/admin/consultation/prescription/order-ready/${id}`,
              id,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "Bearer " + window.localStorage.getItem("shortToken"),
                },
              },
            )
            .then((response) => {
              console.log(response);
              this.visible2 = false;
              this.getPrescriptions();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "../../scss/dashboard.scss";
  @media print {
    @page {
      size: portrait;
    }
    //  body {
    //    transform: scale(10);
    //    }
    //   table {page-break-inside: avoid;}
  }
  .titleContent {
    font-weight: 500;
    line-height: 1rem !important;
  }
  .background {
    background-color: #edf4fb;
  }
  .headerComponent {
    margin-left: 250px;
  }
  .datapasien {
    margin-left: 250px;
    margin-right: 120px;
  }
  .header {
    margin-left: 250px;
    /* margin-right: 250px; */
  }
  .poppuphead {
    text-align: center;
  }
  .v-card__title {
    font-size: 0.9rem !important;
    line-height: 1rem !important;
  }
  .instructionType {
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }
  .cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
  .search {
    margin-top: 15px;
    margin-left: 10px;
  }
  .title-order {
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }
  .text {
    font-family: Nunito;
    font-size: 11px;
    font-weight: 600;
    height: 2px !important;
    width: 80px !important;
  }
  .KSI {
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;

    line-height: 16px;
    letter-spacing: 0px;
    text-align: right;
  }
  .total {
    border-bottom: 1px solid rgb(198, 196, 196);
  }
  .terbit {
    margin-top: -15px;
  }
  .btnPrint {
    margin-top: -120px;
    margin-left: 80px;
  }
  .cardText {
    margin-top: -50px;
  }
</style>
