import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://api.klinisia.id/";

class Obat {
  async getnameObat() {
    return await axios.get(
      `${API_URL}admin/master/pharmacy/medicine/search?page=0&size=1000000&sortDirection=ASC&sortField=medicineCode`,
      {
        headers: headers(),
      },
    );
  }
  async addObat(request) {
    return await axios.post(
      `${API_URL}admin/master/pharmacy/medicine`,
      request,
      {
        headers: headers(),
      },
    );
  }
  async deleteobat(data) {
    return await axios.delete(
      `${API_URL}admin/master/pharmacy/medicine/${data}`,
      {
        headers: headers(),
      },
    );
  }
  async getBrand(data) {
    return await axios.get(
      `${API_URL}admin/master/pharmacy/medicine-brand/search?phrMedicineId=${data}&page=0&size=1000&sortDirection=ASC&sortField=medicineBrandCode`,
      { headers: headers() },
    );
  }
  async addNewBrand(data) {
    return await axios.post(
      `${API_URL}admin/master/pharmacy/medicine-brand`,
      data,
      {
        headers: headers(),
      },
    );
  }
  async openBrandDetail(data) {
    return await axios.get(
      `${API_URL}admin/master/pharmacy/medicine-brand/${data}`,
      {
        headers: headers(),
      },
    );
  }
  async editBrandDetail(data) {
    return await axios.put(
      `${API_URL}admin/master/pharmacy/medicine-brand/${data.id}`,
      data.item,
      { headers: headers() },
    );
  }
  async deleteBrand(data) {
    return await axios.delete(
      `${API_URL}admin/master/pharmacy/medicine-brand/${data}`,
      {
        headers: headers(),
      },
    );
  }
  async searchBrand(data) {
    return await axios.get(
      `${API_URL}admin/master/pharmacy/medicine-brand/search?medicineBrandName=${data}&page=0&size=1000&sortDirection=ASC&sortField=medicineBrandCode`,
      { headers: headers() },
    );
  }
}

export default new Obat();
