<template>
  <v-app class="background">
    <v-container>
      <v-card>
        <v-card-title>
          <p class="">Tambah Spesialisasi</p>
        </v-card-title>
        <v-divider class="devider"></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="4"> Kode Spesialisasi </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="params.specializationCode"
                  :rules="nameRules"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> Nama Spesialisasi </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="params.specializationName"
                  :rules="nameRules"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> Deskripsi Spesialisasi </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="params.specializationDescription"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="devider"></v-divider>
        <v-card-actions>
          <v-row justify="end" class="pb-5 mr-5">
            <v-btn
              dark
              color="error"
              bottom
              small
              rounded
              class="mr-2"
              @click="back"
            >
              Kembali
            </v-btn>
            <v-btn
              dark
              class="mr-15"
              small
              color="success"
              bottom
              rounded
              @click="simpan"
            >
              Simpan
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import doctorServices from "@/services/doctor_services";

export default {
  components: {
    // Popup
  },
  data: () => ({
    nameRules: [(v) => !!v || "Wajib Di Isi"],
    params: {
      docMstMedicSpecializationId: "",
      specializationCode: "",
      specializationName: "",
      specializationDescription: "",
    },
  }),
  methods: {
    back() {
      this.$router.push("/spesialisasiDoctor");
    },
    simpan() {
      console.log(this.params);
      if (this.params.specializationCode === "") {
        this.$refs.form.validate();
        this.$toast.open({
          message: "Kode Spesialisasi Harus Di isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params.specializationName === "") {
        this.$refs.form.validate();
        this.$toast.open({
          message: "Nama pesialisasi Harus Di isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
    } else {
        doctorServices.postSpesialisasi(this.params).then((res) => {
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Spesialisasi Baru Berhasil Disimpan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.$router.push("/spesialisasiDoctor");
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/dashboard.scss";
.titleContent {
  font-weight: 500;
  line-height: 1rem !important;
}

.background {
  background-color: #edf4fb;
}
.devider {
  margin-top: -10px;
}
</style>