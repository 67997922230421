<template>
  <v-app class="background">
  <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row class="ml-2 mr-2">
          <v-col cols="4">
            <p dark class="mt-5 ml-2 icd-search" rounded small width="170">
              Cari {{ label }}
            </p>
          </v-col>
          <v-col sm="12" md="6" lg="6" class="search">
            <v-text-field
              label="search"
              append-icon="mdi-magnify"
              outlined
              class="mt-3"
              @keyup.enter="onSearch"
              v-model="search"
              rounded
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container>
      <!-- Chip -->
      <v-card class="cardStyle">
        <v-row>
          <v-col>
            <div>
              <v-row class="ml-2 mb-4">
                <v-col cols="2" class="mr-3">
                  <v-btn
                    v-if="menuList[0].create === true"
                    color="success"
                    class="mt-2 pa-3"
                    x-small
                    dense
                    @click="tambah"
                    ><v-icon dark x-small> mdi-plus </v-icon>Tambah
                    {{ label }}</v-btn
                  >
                </v-col>
                <v-col cols="2" class="mb-6">
                  <v-select
                    hide-details=""
                    @change="selectedPage()"
                    v-model="pageNumber"
                    :items="slot"
                    outlined
                    dense
                    class="text ml-15 mt-1"
                  ></v-select>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers()"
                :items="dataInstitusi"
                hide-default-footer
                :server-items-length="pageCount"
                :search="search"
                class="elevation-1"
                dense
              >
                <template v-slot:[`item.aksi`]="{ item }">
                  <td>
                    <v-btn
                      color="primary"
                      dark
                      x-small
                      class="mr-2"
                      @click="edit(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-pencil </v-icon>
                      <p v-if="menuList[0].update === true" class="mt-4">
                        Ubah
                      </p>
                      <p v-if="menuList[0].update === false" class="mt-4">
                        lihat
                      </p>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      v-if="menuList[0].delete === true"
                      color="error"
                      x-small
                      dark
                      class="mr-2"
                      @click="delt(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-delete </v-icon
                      >Hapus</v-btn
                    >
                  </td>
                  <td>
                    <v-btn
                      color=""
                      x-small
                      v-show="vbtn"
                      dark
                      @click="pindah(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-eye </v-icon>Lihat
                      {{ subLabel }}</v-btn
                    >
                  </td>
                </template>
              </v-data-table>
              <v-btn
                color="orange"
                v-show="vbtn2"
                class="ml-3 mt-6"
                x-small
                dark
                @click="back()"
              >
                <v-icon dark x-small class="mr-1"> mdi-arrow-left </v-icon>
                Kembali
              </v-btn>
              <v-pagination
                circle
                v-model="page"
                :length="totalpage.totalPages"
                :total-visible="7"
                @input="choosePage(page)"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <!-- Tambah Financial  -->
      <v-dialog v-model="tambahDialog" persistent max-width="830">
        <v-card>
          <v-card-title class="icd-search">
            <p>Tambah {{ label }}</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-select
                    v-model="params.institutionType"
                    :items="typeInstitution"
                    label="Institution Type"
                    dense
                    item-text="text"
                    item-value="value"
                    outlined
                  ></v-select
                ></v-col>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params.institutionCode"
                    label="Institution Code"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params.institutionName"
                    label="Institution Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params.institutionDescription"
                    label="Institution Description"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="success" dark @click="tambahInstitusi"
              >Tambah {{ label }}</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cencel"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Edit Financial -->
      <v-dialog v-model="dialogEdit" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>Edit {{ label }}</p></v-card-title
          >
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-select
                    v-model="params.institutionType"
                    :items="typeInstitution"
                    label="Institution Type"
                    dense
                    item-text="text"
                    item-value="value"
                    outlined
                  ></v-select
                ></v-col>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params.institutionCode"
                    label="Institution Code"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params.institutionName"
                    label="Institution Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params.institutionDescription"
                    label="Institution Description"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="menuList[0].update === true"
              medium
              outlined
              color="primary"
              dark
              @click="editbro"
              >Edit</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Hapus Financial -->
      <v-dialog v-model="dels" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>
              Yakin Hapus {{ label }} {{ params.institutionName }}
            </p></v-card-title
          >
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="hapusfinancial"
              >Hapus</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelDelete"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Tambah Channel  -->
      <v-dialog v-model="tambahChannel" persistent max-width="830">
        <v-card>
          <v-card-title class="icd-search">
            <p>Tambah {{ label }}</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-select
                    v-model="params2.channelType"
                    :items="typeChannel"
                    label="Channel Type"
                    dense
                    item-text="text"
                    item-value="value"
                    outlined
                  ></v-select
                ></v-col>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params2.channelCode"
                    label="Channel Code"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params2.channelName"
                    label="Channel Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params2.channelDescription"
                    label="Channel Description"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="success" dark @click="tambahchannel"
              >Tambah {{ label }}</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cencel"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Edit Channel -->
      <v-dialog v-model="dialogEditChannel" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>Edit {{ label }} {{ params2.channelName }}</p></v-card-title
          >
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-select
                    v-model="params2.channelType"
                    :items="typeChannel"
                    label="Channel Type"
                    dense
                    item-text="text"
                    item-value="value"
                    outlined
                  ></v-select
                ></v-col>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params2.channelCode"
                    label="Channel Code"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params2.channelName"
                    label="Channel Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params2.channelDescription"
                    label="Channel Description"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="menuList[0].update === true"
              medium
              outlined
              color="primary"
              dark
              @click="editbroChannel"
              >Edit</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Hapus Channel -->
      <v-dialog v-model="delsChannel" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>
              Yakin Hapus {{ label }} {{ params2.channelName }}
            </p></v-card-title
          >
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="hapusChannel"
              >Hapus</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelDelete"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="tambahGatewayChannel" persistent max-width="830">
        <v-card>
          <v-card-title class="icd-search">
            <p>Tambah {{ label }}</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params3.payGatewayId"
                    label="Payment Gateway ID"
                    dense
                    disabled
                    outlined
                  ></v-text-field
                ></v-col>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params3.gatewayChannelCode"
                    label="Gateway Channel Code"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params3.gatewayChannelName"
                    label="Gateway Channel Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params3.gatewayChannelOrder"
                    label="Gateway Channel Order"
                    dense
                    type="number"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params3.gatewayChannelDescription"
                    label="Gateway Channel Description"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              medium
              outlined
              color="success"
              dark
              @click="tambahgatewaychannel"
              >Tambah {{ label }}</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cencel"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Edit Gateway Channel -->
      <v-dialog v-model="editGatewayChannel" persistent max-width="830">
        <v-card>
          <v-card-title class="icd-search">
            <p>Edit {{ label }} {{ params3.gatewayChannelName }}</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params3.payGatewayId"
                    label="Payment Gateway ID"
                    dense
                    disabled
                    outlined
                  ></v-text-field
                ></v-col>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params3.gatewayChannelCode"
                    label="Gateway Channel Code"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params3.gatewayChannelName"
                    label="Gateway Channel Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params3.gatewayChannelOrder"
                    label="Gateway Channel Order"
                    dense
                    type="number"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="params3.gatewayChannelDescription"
                    label="Gateway Channel Description"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="menuList[0].update === true"
              medium
              outlined
              color="success"
              dark
              @click="editgatewaychannel"
              >Edit {{ label }}</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Hapus Channel -->
      <v-dialog v-model="delsGatewayChannel" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>
              Yakin Hapus {{ label }} {{ params3.gatewayChannelName }}
            </p></v-card-title
          >
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              medium
              outlined
              color="primary"
              dark
              @click="hapusGatewayChannel"
              >Hapus</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelDelete"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import servicePayment from "@/services/payment";
export default {
  data: () => ({
    menuList: {},
    login: {},
    itemgroup: [],
    search: "",
    vbtn: true,
    vbtn2: false,
    label: "Financial-Institution",
    subLabel: "Channel",
    dialogEdit: false,
    dialogEditChannel: false,
    editGatewayChannel: false,
    slot: [5, 10, 15, 20],
    pageNumber: 10,
    paginate: {name: "", page: 0, size: 10 },
    totalpage: [],
    pageCount: 0,
    page: 1,
    dataInstitusi: [],
    listdetail: "",
    editItemDIalog: false,
    dels: false,
    delsChannel: false,
    delsGatewayChannel: false,
    tool: false,
    diagnostikitem: [],
    tambahDialog: false,
    tambahChannel: false,
    tambahGatewayChannel: false,
    nameRules: [(v) => !!v || "Name is required"],
    params: {
      payFinancialInstitutionId: "",
      institutionType: "",
      institutionCode: "",
      institutionName: "",
      institutionDescription: "",
    },
    params2: {
      payChannelId: "",
      payFinancialInstitutionId: "",
      payCreditCardNetworkId: null,
      channelType: "",
      channelCode: "",
      channelName: "",
      channelDescription: "",
    },
    params3: {
      payGatewayChannelId: "",
      payGatewayId: "FASPAY",
      payChannelId: "",
      gatewayChannelOrder: "",
      gatewayChannelCode: "",
      gatewayChannelName: "",
      gatewayChannelDescription: "",
    },
    typeInstitution: [
      {
        value: "bank",
        text: "Bank",
      },
      {
        value: "retail",
        text: "Retail",
      },
      {
        value: "fintech",
        text: "Fintech",
      },
      {
        value: "other",
        text: "Other",
      },
    ],
    typeChannel: [
      {
        value: "creditCard",
        text: "creditCard",
      },
      {
        value: "virtualAccount",
        text: "virtualAccount",
      },
      {
        value: "internetBanking",
        text: "internetBanking",
      },
      {
        value: "bankTranfer",
        text: "bankTranfer",
      },
      {
        value: "eMoney",
        text: "eMoney",
      },
      {
        value: "fintech",
        text: "fintech",
      },
      {
        value: "retail",
        text: "retail",
      },
      {
        value: "other",
        text: "other",
      },
    ],
  }),
  mounted() {
    this.loginData();
    this.getPayment();
  },
  methods: {
    loginData() {
      this.login = JSON.parse(window.localStorage.getItem("loginData"));
      console.log("login list", this.login.features);
      this.menuList = this.login.features.filter(
        (item) => item.featureName === "Payment Master"
      );
      console.log(this.menuList);
    },

    onSearch() {
      if (this.label === "Financial-Institution") {
        this.paginate.page = 0;
        // this.paginate.size = 100000000
        this.paginate.name = this.search;
        console.log(this.paginate);
        this.getPayment(this.paginate);
      } else if (this.label === "Channel") {
        this.paginate.name = this.search;
        console.log(this.paginate);
        this.getPaymentChanel(this.paginate);
      }
      else if (this.label === "Gateway-Channel") {
        this.paginate.name = this.search;
      console.log(this.paginate);
      this.getPaymentChanelGetway(this.paginate);
      }
    },
    choosePage(page) {
      if (this.label === "Financial-Institution") {
        console.log(page);
        this.paginate.page = page - 1;
         this.paginate.name = this.search;
        console.log(this.paginate);
        this.getPayment(this.paginate);
      } else if (this.label === "Channel") {
        console.log(page);
        this.paginate.page = page - 1;
        console.log(this.paginate);
         this.paginate.name = this.search;
        this.getPaymentChanel(this.paginate);
      }
      else if (this.label === "Gateway-Channel") {
        this.paginate.page = page - 1;
      console.log(this.paginate);
       this.paginate.name = this.search;
      this.getPaymentChanelGetway(this.paginate);
      }
    },
    selectedPage() {
      if (this.label === "Financial-Institution") {
        console.log(this.pageNumber);
        this.paginate.size = this.pageNumber;
         this.paginate.name = this.search;
        this.getPayment(this.paginate);
      } else if (this.label === "Channel") {
        this.paginate.size = this.pageNumber;
        this.paginate.name = this.search;
        console.log(this.paginate);
        this.getPaymentChanel(this.paginate);
      }
      else if (this.label === "Gateway-Channel") {
         this.paginate.size = this.pageNumber;
        this.paginate.name = this.search;
      console.log(this.paginate);
      this.getPaymentChanelGetway(this.paginate);
      }
    },
    delt(item) {
      console.log("hahaha", item);
      if (this.label === "Financial-Institution") {
        this.params.payFinancialInstitutionId = item.payFinancialInstitutionId;
        this.params.institutionName = item.institutionName;
        this.dels = true;
      } else if (this.label === "Channel") {
        console.log(item);
        this.params2.payChannelId = item.payChannelId;
        this.params2.channelName = item.channelName;
        this.delsChannel = true;
      } else if (this.label === "Gateway-Channel") {
        console.log(item);
        this.params3.payChannelId = item.payChannelId;
        this.params3.payGatewayChannelId = item.payGatewayChannelId;
        this.params3.gatewayChannelName = item.gatewayChannelName;
        this.delsGatewayChannel = true;
      }
    },
    edit(item) {
      if (this.label === "Financial-Institution") {
        console.log(item);
        this.dialogEdit = true;
        this.params.payFinancialInstitutionId = item.payFinancialInstitutionId;
        this.params.institutionType = item.institutionTypeCode;
        this.params.institutionCode = item.institutionCode;
        this.params.institutionName = item.institutionName;
        this.params.institutionDescription = item.institutionDescription;
      } else if (this.label === "Channel") {
        console.log(item);
        this.params2.payChannelId = item.payChannelId;
        this.params2.payFinancialInstitutionId = item.payFinancialInstitutionId;
        this.params2.payCreditCardNetworkId = item.payCreditCardNetworkId;
        this.params2.channelType = item.channelTypeCode;
        this.params2.channelCode = item.channelCode;
        this.params2.channelName = item.channelName;
        this.params2.channelDescription = item.channelDescription;
        this.dialogEditChannel = true;
      } else if (this.label === "Gateway-Channel") {
        console.log(item);
        this.params3.payChannelId = item.payChannelId;
        this.params3.payGatewayId = item.payGatewayId;
        this.params3.payGatewayChannelId = item.payGatewayChannelId;
        this.params3.gatewayChannelOrder = item.gatewayChannelOrder;
        this.params3.gatewayChannelCode = item.gatewayChannelCode;
        this.params3.gatewayChannelName = item.gatewayChannelName;
        this.params3.gatewayChannelDescription = item.gatewayChannelDescription;
        this.editGatewayChannel = true;
      }
    },
    cencel() {
      this.params.payFinancialInstitutionId = "";
      this.params.institutionType = "";
      this.params.institutionCode = "";
      this.params.institutionName = "";
      this.params.institutionDescription = "";
      this.params2.channelType = "";
      this.params2.channelCode = "";
      this.params2.channelName = "";
      this.params2.channelDescription = "";
      this.params3.gatewayChannelOrder = "";
      this.params3.gatewayChannelCode = "";
      this.params3.gatewayChannelName = "";
      this.params3.gatewayChannelDescription = "";
      this.tambahDialog = false;
      this.tambahChannel = false;
      this.tambahGatewayChannel = false;
    },
    tambahInstitusi() {
      console.log("haha");
      if (this.params.institutionType === "") {
        this.$toast.open({
          message: "Institusi Type Harus Di Isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params.institutionCode === "") {
        this.$toast.open({
          message: "Institusi Code Harus Di Isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params.institutionName === "") {
        this.$toast.open({
          message: "Institusi Name Harus Di Isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        console.log(this.params);
        servicePayment.addFinence(this.params).then((res) => {
          console.log(res);
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Financial Institution Baru Berhasil Di tambahkan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            window.location.reload();
          }
        });
      }
    },
    editbro() {
      console.log(this.params);
      var data = {
        id: this.params.payFinancialInstitutionId,
        body: this.params,
      };
      servicePayment.editFinence(data).then((res) => {
        console.log(res);
        if (res.data.error !== "") {
          this.$toast.open({
            message: res.data.error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          this.$toast.open({
            message:
              "Financial Instruction " +
              this.params.institutionName +
              " berhasil di edit",
            position: "top-right",
            type: "success",
            duration: 6000,
          });
          
          window.location.reload();
        }
      });
    },
    hapusfinancial() {
      console.log("hapus");
      servicePayment
        .delFinence(this.params.payFinancialInstitutionId)
        .then((res) => {
          console.log(res);
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message:
                this.label +
                " " +
                this.params.institutionName +
                " Berhasil Dihapus",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.getPayment();
            this.dels = false;
          }
        });
    },
    tambahchannel() {
      if (this.params2.channelType === "") {
        this.$toast.open({
          message: "Channel Type Harus Di Isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params2.channelCode === "") {
        this.$toast.open({
          message: "Channel Code Harus Di Isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params2.channelName === "") {
        this.$toast.open({
          message: "Channel Name Harus Di Isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        console.log(this.params2);
        servicePayment.addChannel(this.params2).then((res) => {
          console.log(res);
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Channel Baru Berhasil Di tambahkan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });

            this.getPaymentChanel(this.paginate);
            this.tambahChannel = false;
          }
        });
      }
    },
    hapusChannel() {
      console.log(this.params2.payChannelId);
      servicePayment.delChannel(this.params2.payChannelId).then((res) => {
        if (res.data.error !== "") {
          this.$toast.open({
            message: res.data.error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          this.$toast.open({
            message: "Channel" + this.params2.channelName + " Berhasil Dihapus",
            position: "top-right",
            type: "success",
            duration: 6000,
          });
          this.getPaymentChanel(this.paginate);
          this.delsChannel = false;
        }
      });
    },
    editbroChannel() {
      let data = {
        id: this.params2.payChannelId,
        body: this.params2,
      };
      console.log(data);
      servicePayment.editChannel(data).then((res) => {
        console.log(res);
        if (res.data.error !== "") {
          this.$toast.open({
            message: res.data.error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          this.$toast.open({
            message: "Channel" + this.params2.channelName + " Berhasil Dirubah",
            position: "top-right",
            type: "success",
            duration: 6000,
          });
          this.getPaymentChanel(this.paginate);
          this.dialogEditChannel = false;
        }
      });
    },
    tambahgatewaychannel() {
      if (this.params3.gatewayChannelOrder === "") {
        this.$toast.open({
          message: "Gateway Channel Order Harus Di Isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params3.gatewayChannelCode === "") {
        this.$toast.open({
          message: "Gateway Channel Code Harus Di Isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params3.gatewayChannelName === "") {
        this.$toast.open({
          message: "Gateway Channel Name Harus Di Isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        servicePayment.addGatewayChannel(this.params3).then((res) => {
          console.log(res);
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Gateway Channel Baru BerHasil Ditambah",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.getPaymentChanelGetway(this.paginate);
            this.tambahGatewayChannel = false;
          }
        });
      }
    },
    editgatewaychannel() {
      let data = {
        id: this.params3.payGatewayChannelId,
        body: this.params3,
      };
      console.log(data);
      servicePayment.editGatewayChannel(data).then((res) => {
        console.log(res);
        if (res.data.error !== "") {
          this.$toast.open({
            message: res.data.error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          this.$toast.open({
            message:
              "Gateway-Channel" +
              this.params3.gatewayChannelName +
              " Berhasil Diubah",
            position: "top-right",
            type: "success",
            duration: 6000,
          });
          this.getPaymentChanelGetway(this.paginate);
          this.editGatewayChannel = false;
        }
      });
    },
    hapusGatewayChannel() {
      console.log(this.params3.payGatewayChannelId);
      servicePayment
        .delGatewayChannel(this.params3.payGatewayChannelId)
        .then((res) => {
          console.log(res);
          this.$toast.open({
            message:
              "Gateway-Channel" +
              this.params3.gatewayChannelName +
              " Berhasil Dihapus",
            position: "top-right",
            type: "success",
            duration: 6000,
          });
          this.getPaymentChanelGetway(this.paginate);
          this.delsGatewayChannel = false;
        });
    },
    pindah(item) {
      if (this.subLabel === "Channel") {
        console.log(item);
        this.label = "Channel";
        this.subLabel = "Gateway-Channel";
        this.paginate.page = 0
        this.paginate["id"] = item.payFinancialInstitutionId;
        this.params2.payFinancialInstitutionId = item.payFinancialInstitutionId;
        this.getPaymentChanel(this.paginate);
        // this.vbtn = false;
        this.vbtn2 = true;
      } else if (this.subLabel === "Gateway-Channel") {
        console.log(item);
        this.label = "Gateway-Channel";
        this.subLabel = "";
        this.paginate["id"] = item.payChannelId;
        this.params3.payChannelId = item.payChannelId;
        this.getPaymentChanelGetway(this.paginate);
        this.vbtn = false;
        this.vbtn2 = true;
      }
    },
    back() {
      if (this.label === "Channel") {
        this.label = "Financial-Institution";
        this.subLabel = "Channel";
        this.vbtn = true;
        this.paginate.page = 0
     
        this.getPayment(this.paginate);
        this.vbtn2 = false;
      } else if (this.label === "Gateway-Channel") {
        this.label = "Channel";
        this.subLabel = "Gateway-Channel";
        this.vbtn = true;
        this.paginate.page = 0
       
        this.paginate.id = this.params2.payFinancialInstitutionId;
        this.getPaymentChanel(this.paginate);
        this.vbtn2 = true;
      }
    },
    headers() {
      if (this.label === "Financial-Institution") {
        return [
          // {
          //   text: "No",
          //   align: "start",
          //   sortable: true,
          //   value: "index",
          //   class: "primary--text judul",
          // },
          {
            text: "Type ",
            value: "institutionTypeCode",
            class: "primary--text judul",
          },
          {
            text: "Nama Institusi",
            // value: "icdDescription",
            value: "institutionName",
            class: "primary--text judul",
          },
          {
            text: "Aksi",
            value: "aksi",
            class: "primary--text judul",
          },
        ];
      } else if (this.label === "Channel") {
        return [
          // {
          //   text: "No",
          //   align: "start",
          //   sortable: true,
          //   value: "index",
          //   class: "primary--text judul",
          // },
          {
            text: "Type Channel",
            value: "channelTypeCode",
            class: "primary--text judul",
          },
          {
            text: "Nama Channel",
            // value: "icdDescription",
            value: "channelName",
            class: "primary--text judul",
          },
          {
            text: "Aksi",
            value: "aksi",
            class: "primary--text judul",
          },
        ];
      } else if (this.label === "Gateway-Channel") {
        return [
          // {
          //   text: "No",
          //   align: "start",
          //   sortable: true,
          //   value: "index",
          //   class: "primary--text judul",
          // },
          {
            text: "Code Gateway-Channel",
            value: "gatewayChannelCode",
            class: "primary--text judul",
          },
          {
            text: "Nama Gateway-Channel",
            // value: "icdDescription",
            value: "gatewayChannelName",
            class: "primary--text judul",
          },
          {
            text: "Aksi",
            value: "aksi",
            class: "primary--text judul",
          },
        ];
      }
    },

    getPayment(value) {
      value = this.paginate;
      servicePayment.getFinence(value).then((response) => {
        console.log(response);

        this.pageCount = response.data.payload.totalPages;
        this.totalpage = response.data.payload;
        this.dataInstitusi = response.data.payload.content;
      });
    },
    getPaymentChanel(value) {
      servicePayment.getChannel(value).then((response) => {
        console.log(response);
        this.pageCount = response.data.payload.totalPages;
        this.totalpage = response.data.payload;
        this.dataInstitusi = response.data.payload.content;
      });
    },
    getPaymentChanelGetway(value) {
      servicePayment.getGatewayChannel(value).then((response) => {
        console.log(response);
        this.pageCount = response.data.payload.totalPages;
        this.totalpage = response.data.payload;
        this.dataInstitusi = response.data.payload.content;
      });
    },
    tambah() {
      if (this.label === "Financial-Institution") {
        this.tambahDialog = true;
      } else if (this.label === "Channel") {
        this.tambahChannel = true;
      } else if (this.label === "Gateway-Channel") {
        this.tambahGatewayChannel = true;
      }
    },
    closeDialog() {
      // this.params.payFinancialInstitutionId = "";
      // this.params.institutionType = "";
      // this.params.institutionCode = "";
      // this.params.institutionName = "";
      // this.params.institutionDescription = "";
      // this.params2.payFinancialInstitutionId = "";
      // this.params2.payCreditCardNetworkId = "";
      // this.params2.channelType = "";
      // this.params2.channelCode = "";
      // this.params2.channelName = "";
      // this.params2.channelDescription = "";
      this.dialogEdit = false;
      this.dialogEditChannel = false;
      this.editGatewayChannel = false;
    },
    cancelDelete() {
      this.params.institutionName = "";
      this.params.payFinancialInstitutionId = "";
      this.dels = false;
      this.params2.payChannelId = "";
      this.params2.channelName = "";
      this.delsChannel = false;
    },
    cancelEdit() {
      this.editItemDIalog = false;
    },
  },
};
</script>

<style>
.background {
  background-color: #edf4fb;
}
.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
.unstyled,
.v-input
  > .v-input-control
  > .v-input-slot
  > .v-text-field__slot
  > input
  > ::-webkit-inner-spin-button,
::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.inputPrice input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
