<template>
  <v-app class="background">
   <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row class="ml-1">
          <v-col>
            <h4 class="mt-4">ROLE MAINTENANCE</h4>
          </v-col>
        </v-row>
        <v-row class="ml-1">
          <v-col>
           
              <h6 style="font-size:14px font-family:Nunito  font-weight: 600;">
                Data Search
              </h6>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p style="font-size:12px font-family:Nunito">Search</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="search"
                    append-icon="mdi-magnify"
                    outlined
                    v-model="search"
                    @keyup.enter="onSearch"
                    rounded
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
           
          </v-col>
        </v-row>
        <v-row class="ml-2">
          <v-col cols="2" class="mr-1">
            <v-btn
              v-if="menuList[0].create === true"
              class="mt-1"
              small
              color="success"
              to="/AddRoleControl"
              dark
              ><v-icon dark small> mdi-plus </v-icon>Tambah Role</v-btn
            >
          </v-col>

          <v-col cols="2" class="mb-6">
            <v-select
              hide-details=""
              @change="selectedPage()"
              v-model="pageNumber"
              :items="slot"
              outlined
              dense
              class="text"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-3">
          <v-col>
            <div>
              <v-data-table
                :headers="headers()"
                :items="dataRole"
                hide-default-footer
                :server-items-length="pageCount"
                :search="search"
                class="elevation-1"
                dense
              >
                <template v-slot:item.aksi="{ item }">
                  <td>
                    <v-btn
                      color="primary"
                      dark
                      x-small
                      class="mr-2"
                      @click="edit(item)"
                    >
                      <v-icon dark x-small class="mr-1">mdi-pencil</v-icon>
                      <p v-if="menuList[0].update === true" class="mt-4">
                        Ubah
                      </p>
                      <p v-else-if="menuList[0].update === false" class="mt-4">
                        Lihat
                      </p>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      v-if="menuList[0].delete === true"
                      color="error"
                      x-small
                      dark
                      class="mr-2"
                      @click="dels(item)"
                    >
                      <v-icon dark x-small class="mr-1">mdi-delete</v-icon>Hapus
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
              <v-pagination
                circle
                v-model="page"
                :length="totalpage.totalPages"
                :total-visible="7"
                @input="choosePage(page)"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>

        <v-dialog v-model="dialogEdit" persistent max-width="1500">
          <v-card>
            <v-form>
              <v-row class="ml-2 mt-4">
                <v-col>
                  <h5 class="mt-3">Edit Role</h5>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="ml-2 mt-4">
                <v-col>
                  <v-form>
                    <v-row>
                      <v-col cols="6">
                        <p>Jenis Role</p>
                        <v-select
                          class
                          v-model="params.userType"
                          :items="Roles"
                          item-value="value"
                          item-text="text"
                          label="choose role"
                          dense
                          outlined
                          @input="selectingRoleType"
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <p>Kode Role</p>
                        <v-text-field
                          v-model="params.roleCode"
                          :rules="rules"
                          label
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <p>Nama Role</p>
                        <v-text-field
                          v-model="params.roleName"
                          :rules="rules"
                          label
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <p>Deskripsi</p>
                        <v-text-field
                          v-model="params.roleDescription"
                          label
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list>
                          <v-col
                            v-for="(obj, key) in chooseFeature"
                            :key="key"
                            class="mb-5 px-5"
                          >
                            <v-row>
                              <v-container
                                fluid
                                fill-height
                                class="contentTable"
                              >
                                <v-checkbox
                                  class="mt-2 mx-4 pt-0"
                                  v-model="obj.active"
                                  @change="isnotChaked(obj)"
                                >
                                  <template v-slot:label>
                                    <span class="checkboxLabel">
                                      {{ obj.featureName }}
                                    </span>
                                  </template>
                                </v-checkbox>

                                <v-row
                                  align="center"
                                  justify="center"
                                  class="mt-3"
                                >
                                  <v-col>
                                    <v-list class="scrollableList">
                                      <v-row no-gutters>
                                        <v-col cols="3">
                                          <v-checkbox
                                            class="mt-2 mx-4 pt-0"
                                            v-model="obj.create"
                                            :disabled="obj.isCreate"
                                            @change="isChecked(obj)"
                                          >
                                            <template v-slot:label>
                                              <span class="checkboxLabel"
                                                >Tambah</span
                                              >
                                            </template>
                                          </v-checkbox>
                                        </v-col>
                                        <v-col cols="3">
                                          <v-checkbox
                                            class="mt-2 mx-4 pt-0"
                                            v-model="obj.read"
                                            :disabled="obj.isRead"
                                            @change="isChecked(obj)"
                                          >
                                            <template v-slot:label>
                                              <span class="checkboxLabel"
                                                >Lihat</span
                                              >
                                            </template>
                                          </v-checkbox>
                                        </v-col>
                                        <v-col cols="3">
                                          <v-checkbox
                                            class="mt-2 mx-4 pt-0"
                                            v-model="obj.update"
                                            :disabled="obj.isUpdate"
                                            @change="isChecked(obj)"
                                          >
                                            <template v-slot:label>
                                              <span class="checkboxLabel"
                                                >Ubah</span
                                              >
                                            </template>
                                          </v-checkbox>
                                        </v-col>
                                        <v-col cols="3">
                                          <v-checkbox
                                            class="mt-2 mx-4 pt-0"
                                            v-model="obj.delete"
                                            :disabled="obj.isDelete"
                                            @change="isChecked(obj)"
                                          >
                                            <template v-slot:label>
                                              <span class="checkboxLabel"
                                                >Hapus</span
                                              >
                                            </template>
                                          </v-checkbox>
                                        </v-col>
                                      </v-row>
                                    </v-list>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-row>
                          </v-col>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-form>
            <v-card-actions class="pb-4 mb-3">
              <v-spacer></v-spacer>
              <v-btn
                v-if="menuList[0].update === true"
                medium
                outlined
                color="success"
                dark
                @click="saveEdit"
                >Simpan Edit</v-btn
              >
              <v-btn medium outlined color="error" dark @click="closeDialog"
                >Batal</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dhapus" persistent max-width="500">
          <v-card>
            <v-card-title>Yakin Hapus Role {{ namadelete }} ?</v-card-title>
            <v-card-actions class="pb-4 mb-3">
              <v-spacer></v-spacer>
              <v-btn
                v-if="menuList[0].delete === true"
                medium
                outlined
                color="success"
                dark
                @click="hapus"
                >Hapus</v-btn
              >
              <v-btn medium outlined color="error" dark @click="closeDialog"
                >Batal</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import serviceRole from "@/services/auth_services";
// import servicegeo from "@/services/geo_services";
import adminService from "@/services/adminList_servicce";
export default {
  components: {
    // Popup
  },
  data: () => ({
    menuList: {},
    login: {},
    feature: [],
    role: ["Admin", "Ksi"],
    dialogEdit: false,
    dataRole: [],
    namadelete: "",
    iddelete: "",
    slot: [5, 10, 15, 20],
    pageNumber: 10,
    paginate: { name: "", page: 0, size: 10 },
    totalpage: [],
    pageCount: 0,
    page: 1,
    dhapus: false,
    params: {
      secRoleId: "",
      roleCode: "",
      userType: "",
      roleName: "",
      roleDescription: "",
      roleFeatures: [],
    },
    isactive: [
      {
        status: true,
        label: "Aktif",
      },
      {
        status: false,
        label: "Non Aktif",
      },
    ],
    staturs: [],
    chooseFeature: [],
    dataFeature: [],
    Roles: [],
    typeRoles: [],
    typeuser: "",
    rules: [(v) => !!v || "Name is required"],
    search: "",
  }),
  mounted() {
    this.loginData();
    this.getfeature();
    this.getrole();
    this.RoleTypeList();
  },
  watch: {},
  computed: {},
  methods: {
    loginData() {
      this.login = JSON.parse(window.localStorage.getItem("loginData"));
      console.log("login list", this.login.features);
      this.menuList = this.login.features.filter(
        (item) => item.featureName === "Master Role"
      );
      console.log(this.menuList);
    },
    headers() {
      return [
        // {
        //   text: "No",
        //   align: "start",
        //   sortable: true,
        //   value: "index",
        //   class: "primary--text judul",
        // },
        {
          text: "Role",
          value: "name",
          class: "primary--text",
        },
        {
          text: "Description",
          value: "desc",
          // value: "icdName",
          class: "primary--text",
        },
        // {
        //   text: "Status",
        //   value: "status",
        //   // value: "icdName",
        //   class: "primary--text",
        // },
        {
          text: "Aksi",
          value: "aksi",
          class: "primary--text judul",
        },
        // {
        //   text: "",
        //   value: "aksi2",
        //   class: "primary--text judul",
        // },
      ];
    },
    isnotChaked(obj) {
      console.log(obj);
      if (obj.active === false) {
        (obj.read = false),
          (obj.create = false),
          (obj.update = false),
          (obj.delete = false),
          (obj.isCreate = true),
          (obj.isRead = true),
          (obj.isUpdate = true),
          (obj.isDelete = true);
      } else if (obj.active === true) {
        (obj.read = true),
          (obj.create = true),
          (obj.update = true),
          (obj.delete = true),
          (obj.isCreate = false),
          (obj.isRead = false),
          (obj.isUpdate = false),
          (obj.isDelete = false);
      }
    },
    edit(item) {
      console.log(item);
      (this.chooseFeature = []),
        (this.params.secRoleId = item.id),
        (this.params.userType = item.usercode),
        (this.params.roleName = item.name),
        (this.params.roleCode = item.code),
        (this.params.roleDescription = item.desc),
        // this.selectingRoleType(this.params.userType),
        serviceRole.getSecRole(item.id).then((response) => {
          console.log(response);
          this.feature = response.data.payload.roleFeatures;
          console.log(this.feature);
          this.selectingRoleType(this.params.userType),
            console.log("ini feature", this.feature);
        });
      this.dialogEdit = true;
    },
    dels(item) {
      console.log(item);
      (this.iddelete = item.id), (this.namadelete = item.nama);
      console.log(this.iddelete);
      this.dhapus = true;
    },
    closeDialog() {
      this.dialogEdit = false;
      this.dhapus = false;
      //  window.location.reload();
      this.params = {
        secRoleId: "",
        roleCode: "",
        userType: "",
        roleName: "",
        roleDescription: "",
        roleFeatures: [],
      };
    },
    RoleTypeList() {
      adminService
        .getTypeRole()
        .then((res) => {
          console.log(res);
          this.typeRoles = res.data.payload.content;
          this.typeRoles.map((e) => {
            this.Roles.push({
              value: e.userTypeCode,
              text: e.userTypeCode,
            });
          });
        })
        .catch((err) => console.log(err));
    },
    hapus() {
      serviceRole.deleteRole(this.iddelete).then((response) => {
        console.log(response);
        if (response.data.error != "") {
          this.$toast.open({
            message: response.data.error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          this.$toast.open({
            message: "Role Berhasil DiHapus",
            position: "top-right",
            type: "success",
            duration: 6000,
          });
          window.location.reload();
        }
      });
    },
    selectingRoleType(value) {
      console.log("421", this.feature);
      console.log(this.dataFeature);
      console.log(value);
      // console.log(value)
      // console.log(this.params.userType);
      var trial = [];
      var notNeeded = [];
      var data = [];

      for (let i = 0; i < this.dataFeature.length; i++) {
        let spliting = this.dataFeature[i].userTypeCodes.split("|");
        console.log(spliting.length);
        // for (let j = 0; j < spliting.length; j++) {
        //   if (value === spliting[j]) {
        //     data.push(this.dataFeature[i]);
        //   }
        // }
        if (value === this.dataFeature[i].userTypeCodes) {
          data.push(this.dataFeature[i]);
        }
      }
      console.log(data);
      // this.chooseFeature = data
      // console.log(this.feature[0]);
      // console.log(
      //   data.filter((item) =>
      //     this.feature[0].featureCode.includes(item.featureCode)
      //   )
      // );
      for (let i = 0; i < this.feature.length; i++) {
        // console.log("asda");
        // console.log(data.filter((item) =>
        //     this.feature[i].featureCode.includes(item.featureCode)
        //   )[0]);
        if (
          data.filter((item) =>
            this.feature[i].featureCode.includes(item.featureCode)
          )[0] !== undefined
        ) {
          trial.push(
            data.filter(
              (item) =>
                // this.feature[i].featureCode.includes(item.featureCode)
                this.feature[i].featureCode === item.featureCode
            )[0]
          );
        }
      }
      console.log(trial);
      for (let i = 0; i < this.feature.length; i++) {
        for (let j = 0; j < trial.length; j++) {
          if (trial[j].featureCode === this.feature[i].featureCode) {
            trial[j]["active"] = true;
            trial[j]["read"] = this.feature[i].read;
            trial[j]["create"] = this.feature[i].create;
            trial[j]["update"] = this.feature[i].update;
            trial[j]["delete"] = this.feature[i].delete;
          }
        }
      }
      console.log("asda");
      for (let i = 0; i < data.length; i++) {
        console.log("data for push", data[i]);
        var diff = trial.filter(
          (item) => data[i].featureName === item.featureName
        );
        console.log("ini diff", diff);
        if (diff.length === 0) {
          notNeeded.push(data[i]);
        }
      }
      for (let i = 0; i < notNeeded.length; i++) {
        notNeeded[i]["active"] = false;
        notNeeded[i]["read"] = false;
        notNeeded[i]["create"] = false;
        notNeeded[i]["update"] = false;
        notNeeded[i]["delete"] = false;
      }
      console.log("hasil mapNotNeeded", notNeeded);
      var using = [];
      for (let i = 0; i < trial.length; i++) {
        console.log("trial by index", trial[i]);
        using.push(trial[i]);
      }
      for (let i = 0; i < notNeeded.length; i++) {
        using.push(notNeeded[i]);
      }
      console.log("this is trial", trial);
      console.log("this is not need", notNeeded);
      console.log("ngising", using);
      this.chooseFeature = using;
      this.chooseFeature.forEach((e) => {
        if (e.active === false) {
          (e.isCreate = true),
            (e.isRead = true),
            (e.isUpdate = true),
            (e.isDelete = true);
        } else {
          (e.isCreate = false),
            (e.isRead = false),
            (e.isUpdate = false),
            (e.isDelete = false);
        }
      });
      console.log(this.chooseFeature);
    },
    getfeature() {
      serviceRole.getFeature().then((response) => {
        console.log("response feature", response);
        this.dataFeature = response.data.payload.content;
        // this.chooseFeature = this.dataFeature
      });
    },
    onSearch() {
      this.paginate.page = 0;
      // this.paginate.size = 100000000
      this.paginate.name = this.search;
      console.log(this.paginate);
      this.getrole(this.paginate);
    },
    choosePage(page) {
      console.log(page);
      this.paginate.page = page - 1;
      console.log(this.paginate);
      this.getrole(this.paginate);
    },

    selectedPage() {
      console.log(this.pageNumber);
      this.paginate.size = this.pageNumber;
      this.getrole(this.paginate);
    },
    getrole(value) {
      value = this.paginate;
      serviceRole.getRolepage(value).then((response) => {
        console.log(response);
        this.pageCount = response.data.payload.totalPages;
        this.totalpage = response.data.payload;
        var master = response.data.payload.content;
        this.dataRole = [];
        console.log(master);
        master.map((e) => {
          this.dataRole.push({
            id: e.secRoleId,
            name: e.roleName,
            code: e.roleCode,
            desc: e.roleDescription,
            status: e.active,
            usercode: e.userTypeCode,
          });
        });
      });
    },
    saveEdit() {
      var data = [];

      data = this.chooseFeature;
      console.log(data);
      let trial = [];
      for (let i = 0; i < data.length; i++) {
        console.log(data[i].active);
        if (data[i].active === true) {
          console.log(data[i]);
          trial.push(data[i]);
        }
      }
      console.log("trial", trial.length);
      if (trial.length === 0) {
        this.$toast.error("isi Role terlebih dahulu", {
          type: "error",
          position: "top-right",
          duration: 4000,
        });
      } else {
        trial.map((e) => {
          this.params.roleFeatures.push({
            secFeatureId: e.secFeatureId,
            featureName: e.featureName,
            featureCode: e.featureCode,
            read: e.read,
            create: e.create,
            update: e.update,
            delete: e.delete,
          });
        });
        console.log(this.params);
        console.log(this.params.secRoleId);
        let request = {
          id: this.params.secRoleId,
          body: this.params,
        };
        console.log(request);
        serviceRole.editRole(request).then((response) => {
          console.log(response);
          if (response.data.error !== "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            (this.dataRole = []), this.getrole();
            this.$toast.open({
              message: "Role Berhasil Diubah",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.closeDialog();
           //  window.location.reload();
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/dashboard.scss";
.titleContent {
  font-weight: 500;
  line-height: 1rem !important;
}.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
.background {
  background-color: #edf4fb;
}
.cardsearch {
  background-color: #ffffff;
  border-radius: 30px;
  width: 99%;
  margin-left: 2px;
}
.headerComponent {
  margin-left: 250px;
}
.datapasien {
  margin-left: 250px;
  margin-right: 120px;
}
.header {
  margin-left: 250px;
  /* margin-right: 250px; */
}
.poppuphead {
  text-align: center;
}
.v-card__title {
  font-size: 0.9rem !important;
  line-height: 1rem !important;
}
.instructionType {
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.search {
  margin-top: 15px;
  margin-left: 10px;
}
.detail {
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.nama {
  font-family: Nunito;
  font-size: 22px;
  text-align: left;
}
.icd-search {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.icd-text {
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
}
.contentTable {
  background: #e9f5fc;
  border-radius: 15px;
}
</style>
