import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://api.klinisia.id/";

class laboratory {
  async getLaboratory(param) {
    return await axios.get(
      `${API_URL}ksi/master/laboratory/group/search?laboratoryGroupName=${param.name}&page=${param.page}&size=${param.size}&sortDirection=ASC&sortField=laboratoryGroupOrder`,
      { headers: headers() },
    );
  }
  async getLaboratoryitem(data) {
    return await axios.get(
      `${API_URL}ksi/master/laboratory/item/search?labLaboratoryGroupId=${data.id}&laboratoryItemName=${data.name}&page=${data.page}&size=${data.size}&sortDirection=ASC&sortField=laboratoryItemOrder`,
      { headers: headers() },
    );
  }
  async addNewGroup(data) {
    return await axios.post(`${API_URL}ksi/master/laboratory/group`, data, {
      headers: headers(),
    });
  }
  async deleteGroup(data) {
    return await axios.delete(`${API_URL}ksi/master/laboratory/group/${data}`, {
      headers: headers(),
    });
  }
  async addNewItem(data) {
    return await axios.post(`${API_URL}ksi/master/laboratory/item`, data, {
      headers: headers(),
    });
  }
  async openItemDetail(data) {
    return await axios.get(`${API_URL}ksi/master/laboratory/item/${data}`, {
      headers: headers(),
    });
  }
  async editItemDetail(data) {
    return await axios.put(
      `${API_URL}ksi/master/laboratory/item/${data.id}`,
      data.item,
      { headers: headers() },
    );
  }
  async deleteItem(data) {
    return await axios.delete(`${API_URL}ksi/master/laboratory/item/${data}`, {
      headers: headers(),
    });
  }
  async editGroup(request) {
    return await axios.put(
      `${API_URL}ksi/master/laboratory/group/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
}

export default new laboratory();
