<template>
  <v-app class="background">
    <v-container>
      <v-card>
        <v-card-title>
          <p class="">Tambah Sub Spesialisasi</p>
        </v-card-title>
        <v-divider class="devider"></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="4">Spesialisasi </v-col>
              <v-col cols="4">
                <v-select
                  dense
                  outlined
                  :items="spesialisasiData"
                  v-model="params.docMstMedicSpecializationId"
                  item-text="name"
                  item-value="value"
                  label="Pilih Spesialisasi"
                  required
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> Kode Sub Spesialisasi </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="params.subSpcCode"
                  :rules="nameRules"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> Nama Sub Spesialisasi </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="params.subSpcName"
                  :rules="nameRules"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> Deskripsi Sub Spesialisasi </v-col>
              <v-col cols="6">
                <v-text-field dense outlined v-model="params.subSpcDescription">
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="devider"></v-divider>
        <v-card-actions>
          <v-row justify="end" class="pb-5 mr-5">
            <v-btn
              dark
              color="error"
              bottom
              small
              rounded
              class="mr-2"
              @click="back"
            >
              Kembali
            </v-btn>
            <v-btn
              dark
              class="mr-15"
              small
              color="success"
              bottom
              rounded
              @click="simpan"
            >
              Simpan
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import doctorServices from "@/services/doctor_services";

export default {
  components: {
    // Popup
  },
  data: () => ({
    nameRules: [(v) => !!v || "Wajib Di Isi"],
    spesialisasiData: [],
    params: {
      docMstMedicSubSpcId: "",
      docMstMedicSpecializationId: "",
      subSpcCode: "",
      subSpcName: "",
      subSpcDescription: "",
    },
  }),
  mounted(){
      this.getSpesialisasi()
  },
  methods: {
    back() {
      this.$router.push("/subSpesialisasi");
    },
    getSpesialisasi() {
      let master = [];
      doctorServices.getMedSpecialist().then((response) => {
        console.log(response);
        master = response.data.payload;
        master.map((e) => {
          this.spesialisasiData.push({
            value: e.docMstMedicSpecializationId,
            name: e.specializationName,
          });
        });
      });
    },
    simpan() {
      console.log(this.params);
      if (this.params.docMstMedicSpecializationId === "") {
        this.$refs.form.validate();
        this.$toast.open({
          message: "Spesialisasi Harus Di isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params.subSpcCode === "") {
        this.$refs.form.validate();
        this.$toast.open({
          message: "Kode Sub Spesialisasi Harus Di isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params.subSpcName === "") {
        this.$refs.form.validate();
        this.$toast.open({
          message: "Nama Sub Spesialisasi Harus Di isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        doctorServices.postSubSpesialisasi(this.params).then((res) => {
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Sub Spesialisasi Baru Berhasil Disimpan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.$router.push("/subSpesialisasi");
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/dashboard.scss";
.titleContent {
  font-weight: 500;
  line-height: 1rem !important;
}

.background {
  background-color: #edf4fb;
}
.devider {
  margin-top: -10px;
}
</style>