/* eslint-disable no-unused-vars */ /* eslint-disable no-unused-vars */

<template>
  <div class="login">
    <div class="row">
      <div class="leftside col-md-6" sm="12">
        <div class="sliders">
          <b-carousel
            class="carousel"
            id="carausel-1"
            v-model="slide"
            :interval="4000"
            :controls="true"
            :indicators="true"
            background="transparent"
            img-width="1024"
            img-height="380"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide
              caption=""
              :img-src="require('../assets/Frame 1.png')"
            >
              <p>
                Klinisia provide end-to-end solution for medical providers to
                conduct telemedicine
              </p>
            </b-carousel-slide>
            <!-- <b-carousel-slide v-for="item in items" :key="item.id" :img-src="item.gambar"></b-carousel-slide> -->
          </b-carousel>
        </div>
      </div>

      <div class="col-md-6">
        <div class="loginform">
          <div class="header">
            <v-row>
              <v-col cols="auto">
                <img src="../assets/logo Klinisia.svg" />
              </v-col>
              <v-col cols="auto">
                <img src="../assets/Klinisia.svg" />
              </v-col>
            </v-row>
          </div>
          <div class="header2">
            <h1>Selamat Datang di Klinisia</h1>
            <h5>Silahkan cek email untuk password anda</h5>
          </div>
          <div class="body">
            <form @submit.prevent="handleSubmit">
              <v-alert :value="failed" type="error">
                <p>password required</p>
              </v-alert>
              <v-alert :value="length" type="error">
                <p>password minimum 8 karekter alphanumeric</p>
              </v-alert>
              <b-form-group id="emailGroup" label-for="email">
                <div align="left">
                  <b-row>
                    <b-col sm="1">
                      <font-awesome-icon :icon="['far', 'envelope']" />
                    </b-col>
                    <b-col sm="8">
                      <span
                        style="
                          font-family: Inter;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 17px;
                          letter-spacing: 0em;
                          text-align: left;
                        "
                        >Email adress *</span
                      >
                    </b-col>
                  </b-row>
                </div>
                <b-form-input
                  v-model="param.email"
                  :state="checkemail"
                  type="email"
                  size="lg"
                  placeholder="Enter email address"
                  aria-describedby="email-correct email-incorrect"
                  required
                ></b-form-input>
                <b-form-invalid-feedback id="email-incorrect">
                  email required and must be have true email format.
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="passwordGroup" label-for="Password">
                <div align="left">
                  <b-row>
                    <b-col sm="1">
                      <font-awesome-icon icon="lock" />
                    </b-col>
                    <b-col sm="4">
                      <span
                        style="
                          font-family: Inter;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 17px;
                          letter-spacing: 0em;
                          text-align: left;
                        "
                        >Password *</span
                      >
                    </b-col>
                  </b-row>
                </div>
                <v-text-field
                  :state="checkpassword"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  outlined
                  :type="show3 ? 'text' : 'password'"
                  name="input-10-2"
                  v-model="param.password"
                  hint="At least 8 characters"
                  value="wqfasds"
                  class="input-group--focused"
                  @click:append="show3 = !show3"
                  placeholder="Enter Password"
                  v-on:keypress.enter="login"
                  aria-describedby="password-confirm-correct password-confirm-wrong password-confirm-not-right"
                  required
                ></v-text-field>
                <b-form-invalid-feedback id="password-confirm-wrong">
                  password required
                </b-form-invalid-feedback>
              </b-form-group>
              <div align="right">
                <a
                  style="
                    font-family: Inter;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #2a8bf2;
                  "
                  href="/LupaPassword"
                  >Lupa Password?</a
                >
              </div>
              <div class="bodyBottom">
                <div></div>
                <b-form-group>
                  <b-row class="justify-content-md-center">
                    <!-- <b-button type="submit" variant="primary" text-variant="black" href="/landingPage">Masuk</b-button> -->
                    <div class="button1">
                      <v-btn x-large color="primary" @click="login" block
                        >Masuk</v-btn
                      >
                      <!-- to="/listDokter" -->
                    </div>
                  </b-row>
                </b-form-group>
                <div class="text-center mt-10">
                  <a>V2.17.0.20221129</a>
                </div>
              </div>
              <!-- <b-form-group>
                            <b-row class="justify-content-md-center">    
                                <v-btn x-large color="primary" to="/registration">Lupa Password</v-btn>
                            </b-row>
                        </b-form-group> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  // import Vue from 'vue'

  // import BootstrapVue from
  // import {BCard} from 'bootstrap-vue'
  // import {BForm} from 'bootstrap-vue'
  // import {BCol} from 'bootstrap-vue'
  // import {BFormGroup} from 'bootstrap-vue'
  // import {BFormInput} from 'bootstrap-vue'
  // import {BButton} from 'bootstrap-vue'
  // import Registration from './components/registration'
  // Vue.component('b-form', BForm)
  // import { FormPlugin } from 'bootstrap-vue'
  // Vue.use(FormPlugin)
  // import BootstrapVue  from 'bootstrap-vue'

  export default {
    name: "Login",
    components: {
      // axios
      // BootstrapVue,
      // BCard,
      // BCol,
      // BForm,
      // BFormGroup,
      // BFormInput,
      // BButton,
      // Registration
    },
    data() {
      return {
        rules: {
          required: (value) => !!value || "Required.",
          min: (value) =>
            (value || "").length >= 8 ||
            "password minimum 8 karekter alphanumeric",
        },
        show3: false,
        failed: false,
        length: false,
        param: {
          deviceType: "browser",
          deviceCode: "123",
          userType: "admin",
          email: "",
          mobilePhone: "",
          password: "",

          // "deviceType": "browser",
          // "deviceCode": "123",
          // "userType": "admin",
          // "email": "",
          // "mobilePhone": "",
          // "password": ""
        },
        status: "not_accepted",
        items: [
          {
            gambar: "../assets/logo.png",
          },

          {
            gambar: "../assets/exam3.jpg",
          },
        ],
        form: {
          email: "",
          password: "",
        },
        slide: 0,
        sliding: null,
      };
    },
    mounted() {
      if (this.param.password !== "" || this.param.password !== "") {
        this.failed = false;
      }
    },
    methods: {
      // onClick() {
      //     this.login({email:this.email, password: this.password})
      // },
      login() {
        // if(this.param.email.length > 8){

        //      this.length=true
        // }

        if (
          this.param.email === "" ||
          this.param.password === "" ||
          this.param.email.length < 8
        ) {
          this.failed = true;
        } else if (this.param.password.length < 8) {
          // alert("password minimum 8 karekter alphanumeric")
          this.length = true;
        } else if (
          this.param.email.length > 8 &&
          this.param.password !== "" &&
          this.param.password.length >= 8
        ) {
          this.failed = false;
          axios
            .post(" https://api.klinisia.id/auth/login", this.param, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (this.param.email.length < 8) {
                // alert("password minimum 8 karekter alphanumeric")
                this.length = true;
              }
              window.localStorage.setItem(
                "loginData",
                JSON.stringify(response.data.payload),
              );
              window.localStorage.setItem(
                "shortToken",
                response.data.payload.jwtToken.shortToken.token,
              );
              window.localStorage.setItem(
                "longToken",
                response.data.payload.jwtToken.longToken.token,
              );
              console.log(response);
              console.log(response.data.payload.deviceCode);
              console.log(response.data.payload.deviceType);
              console.log(response.data.payload.secUserId);
              window.localStorage.setItem(
                "deviceCode",
                response.data.payload.deviceCode,
              );
              window.localStorage.setItem(
                "deviceType",
                response.data.payload.deviceType,
              );
              window.localStorage.setItem("role", response.data.payload.role);
              window.localStorage.setItem(
                "secUserId",
                response.data.payload.secUserId,
              );
              // console.log( response.data.payload.jwtToken);
              window.localStorage.setItem("authenticated", true);
              if (
                JSON.parse(window.localStorage.getItem("loginData"))
                  .userType === "ksi"
              ) {
                this.$router.push("/inputIcd");
                // window.location.reload()
              } else if (
                JSON.parse(window.localStorage.getItem("loginData"))
                  .userType === "admin"
              ) {
                this.$router.push("/listDokter");
                // window.location.reload()
              } else {
                this.$router.push("/listDokter");
                // window.location.reload()
              }
            })

            .catch((error) => {
              if (this.param.email.length < 8) {
                // alert("password minimum 8 karekter alphanumeric")
                this.length = true;
              } else {
                alert("email dan password salah");
                // this.length = true;
                console.log("login error");
                console.log("login error", error);
              }
            });
        }
      },
      onSubmit(event) {
        event.preventDefault();
        alert(JSON.stringify(this.form));
      },
      onSlideStart() {
        this.sliding = true;
      },
      onSlideEnd() {
        this.sliding = false;
      },
    },
    computed: {
      checkemail() {
        return (
          this.param.email.includes("@") &&
          this.param.email.includes(".") &&
          this.param.email !== ""
        );
        // return this.param.email !== "";
      },
      checkpassword() {
        return this.param.password !== "";
      },
    },
    created() {
      if (window.localStorage.getItem("authenticated") === "true") {
        this.$router.push("/listDokter");
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "../scss/login.scss";
</style>
