<template>
  <v-app class="background">
    <v-container>
      <v-card>
        <v-row class="ml-1">
          <v-col>
            <h6
              class="mt-4"
              style="font-size:22px font-family:Nunito  font-weight: 600"
            >
              Data Hasil Upload Terakhir
            </h6>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ml-2">
          <v-col class="instructionType">
            <p>Upload Berhasil</p>
          </v-col>
        </v-row>
        <v-container>
          <v-row class="" style="margin-top: -30px">
            <v-col>
              <div>
                <v-data-table
                  :headers="headers()"
                  :items="dataMember"
                  hide-default-footer
                  :search="search"
                  class="elevation-1"
                  @change="change"
                  dense
                >
                </v-data-table>
                <!-- <v-row>
                <v-col>
                  <v-pagination
                    circle
                    v-model="page"
                    :length="totalpage.totalPages"
                    :total-visible="7"
                    @input="choosePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row> -->
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>
        <v-row class="ml-2">
          <v-col class="instructionType">
            <p>Upload Gagal</p>
          </v-col>
        </v-row>
        <v-container>
          <v-row class="" style="margin-top: -30px">
            <v-col>
              <div>
                <v-data-table
                  :headers="headersGagal()"
                  :items="dataGagal"
                  hide-default-footer
                  :search="search"
                  class="elevation-1"
                  @change="change"
                  dense
                >
                </v-data-table>
                <!-- <v-row>
                <v-col>
                  <v-pagination
                    circle
                    v-model="page"
                    :length="totalpage.totalPages"
                    :total-visible="7"
                    @input="choosePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row> -->
              </div>
            </v-col>
          </v-row>
        </v-container>
         <v-row>
          <v-col align="right">
            <v-btn
              class="mr-5"
              x-small
              color="error"
              dark
              to="/setCorporateMember"
              >Kembali</v-btn
            >
           
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  components: {
    // Popup
  },
  data: () => ({
    search: "",
    menuList: {},
    slot: [5, 10, 15, 20],
    pageNumber: 10,
    paginate: { name: "", page: 0, size: 10 },
    totalpage: [],
    pageCount: 0,
    page: 1,
    login: {},
    feature: [],
    dataMember: [],
    dataGagal:[],
    rules: [(v) => !!v || " is required"],
  }),
  mounted() {
    this.loginData();
    this.getDataMember();
    this.getDataGagal()
  },
  watch: {},
  computed: {},
  methods: {
    loginData() {
      this.login = JSON.parse(window.localStorage.getItem("loginData"));
      console.log("login list", this.login.features);
      this.menuList = this.login.features.filter(
        (item) => item.featureName === "Corporate Maintenance"
      );
      console.log(this.menuList);
    },
    // onSearch() {
    //   this.paginate.page = 0;
    //   // this.paginate.size = 100000000
    //   this.paginate.name = this.search;
    //   this.getDataMember(this.paginate);
    // },
    // choosePage(page) {
    //   console.log(page);
    //   this.paginate.page = page - 1;
    //   console.log(this.paginate);
    //   this.getDataMember(this.paginate);
    // },
    // selectedPage() {
    //   console.log(this.pageNumber);
    //   this.paginate.size = this.pageNumber;
    //   this.getDataMember(this.paginate);
    // },
    headers() {
      return [
        // {
        //   text: "No",
        //   align: "start",
        //   sortable: true,
        //   value: "index",
        //   class: "primary--text judul",
        // },
        {
          text: "Nama Corporate",
          value: "corporateName",
          class: "primary--text",
        },
        {
          text: "Alamat",
          value: "alamat",
          class: "primary--text",
        },
        {
          text: "Hodding",
          value: "hodding",
          // value: "icdName",
          class: "primary--text",
        },
        {
          text: "No Telp",
          value: "noHp",
          // value: "icdName",
          class: "primary--text",
        },
        {
          text: "Tanggal",
          value: "tanggal",
          class: "primary--text",
        },
      ];
    },
    headersGagal() {
      return [
        // {
        //   text: "No",
        //   align: "start",
        //   sortable: true,
        //   value: "index",
        //   class: "primary--text judul",
        // },
        {
          text: "Nama Corporate",
          value: "corporateName",
          class: "primary--text",
        },
        {
          text: "Alamat",
          value: "alamat",
          class: "primary--text",
        },
        {
          text: "Hodding",
          value: "hodding",
          // value: "icdName",
          class: "primary--text",
        },
        {
          text: "No Telp",
          value: "noHp",
          // value: "icdName",
          class: "primary--text",
        },
        {
          text: "Tanggal",
          value: "tanggal",
          class: "primary--text",
        },
        {
          text: "Keterangan",
          value: "keterangan",
          class: "primary--text",
        },
      ];
    },
    setMember(item) {
      console.log(item);
      this.$router.push("/setCorporateMember");
    },
    lihat(item) {
      console.log(item);
      this.$router.push("/lihatCorporateMaintenance");
    },
    getDataMember() {
      let data = [
        {
          corporateName: "Adaro",
          alamat: "Jl Perjuangan No 12 Jakarta",
          hodding: "PT Adaro Group",
          noHp: "086412887136",
          tanggal: "23 Agustus 2021",
        },
        {
          corporateName: "DSI",
          alamat: "Jl Alam Sutera No 12 Banten",
          hodding: "PT DSI",
          noHp: "09876715436",
          tanggal: "23 Agustus 2021",
        },
        {
          corporateName: "Anter aja",
          alamat: "Jl Persatuan No 12 Jakarta",
          hodding: "PT Anter aja Group",
          noHp: "086412887136",
          tanggal: "23 Agustus 2021",
        },
      ];
      for (let i = 0; i < data.length; i++) {
        this.dataMember.push(data[i]);
      }
    },
     getDataGagal() {
      let data = [
        {
          corporateName: "Adaro",
          alamat: "Jl Perjuangan No 12 Jakarta",
          hodding: "PT Adaro Group",
          noHp: "086412887136",
          tanggal: "23 Agustus 2021",
          keterangan: "Nik Sama",
        },    
      ];
      for (let i = 0; i < data.length; i++) {
        this.dataGagal.push(data[i]);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/dashboard.scss";
.titleContent {
  font-weight: 500;
  line-height: 1rem !important;
}

.background {
  background-color: #edf4fb;
}
.cardsearch {
  background-color: #ffffff;
  border-radius: 30px;
  width: 99%;
  margin-left: 2px;
}
.headerComponent {
  margin-left: 250px;
}
.datapasien {
  margin-left: 250px;
  margin-right: 120px;
}
.header {
  margin-left: 250px;
  /* margin-right: 250px; */
}
.poppuphead {
  text-align: center;
}
.v-card__title {
  font-size: 0.9rem !important;
  line-height: 1rem !important;
}
.instructionType {
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}
.detail {
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.nama {
  font-family: Nunito;
  font-size: 22px;
  text-align: left;
}
.icd-search {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.icd-text {
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
}
.contentTable {
  background: #e9f5fc;
  border-radius: 15px;
}
.text {
  font-family: Nunito;
  font-size: 11px;
  font-weight: 600;
  height: 2px !important;
  width: 80px !important;
}
</style>
