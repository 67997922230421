<template>
  <v-app class="background">
    <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row class="ml-1">
          <v-col>
            <h4 class="mt-4">Pembatalan Konsultasi</h4>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ml-1">
          <v-col>
            <v-form>
              <h5>Data Search</h5>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Search</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="search"
                    append-icon="mdi-magnify"
                    outlined
                    v-model="search"
                    @keyup.enter="onSearch"
                    rounded
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Kategori</p>
                </v-col>
                <v-col cols="6">
                  <v-select
                    class
                    v-model="category"
                    :items="kategori"
                    item-value="docMstMedicSpecializationId"
                    item-text="specializationName"
                    label="Pilih Kategory"
                    dense
                    outlined
                    @input="selectingCategory"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row class="ml-1">
          <v-col cols="1" class="mt-1">
            <v-btn small @click="Reset" color="error">Reset</v-btn>
          </v-col>
          <v-col cols="2" class="mb-6">
            <v-select
              hide-details=""
              @change="selectedPage()"
              v-model="pageNumber"
              :items="slot"
              outlined
              dense
              class="text"
            ></v-select>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-data-table
          :headers="headers()"
          :items="doctorList"
          hide-default-footer
          :server-items-length="pageCount"
          :search="search"
          class="elevation-1"
          dense
        >
          <template v-slot:item.action="{ item }"
            >
            <td>
              <v-btn x-small @click="detail(item)" color="primary"
                >Lihat Schedule</v-btn
              >
            </td>
          </template>
        </v-data-table>
        <v-row>
          <v-col>
            <v-pagination
              circle
              v-model="page"
              :length="totalpage.totalPages"
              :total-visible="7"
              @input="choosePage(page)"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card>

      <v-dialog v-model="openDialog" persistent max-width="1500">
        <v-card>
          <v-card-title>
            <p class="judul mt-1">Kalendar Konsultasi Admin</p>
          </v-card-title>
          <v-divider class="o"></v-divider>
          <v-row justify="center" class>
            <!-- <v-date-picker
              v-model="date"
              @click:date="pickDate"
              :min="new Date().toISOString().substr(0, 10)"
              width="450"
            ></v-date-picker>-->
            <v-date-picker
              v-model="date"
              @click:date="pickDate"
              width="450"
            ></v-date-picker>
          </v-row>
          <v-row class="ml-2" v-for="cancel in cancelList" :key="cancel">
            <v-col cols="10">
              <v-card>
                <v-row>
                  <v-col cols="3" class="ml-3 text-card">
                    <!-- <p>Rabu, 16 Juni 2021</p> -->
                    <p>{{ cancel.patientFullName }}</p>
                    <p>{{ cancel.patientMobilePhone }}</p>
                    <p>
                      Status :
                      <!-- <span>{{cancel.appointmentStatus}}</span> -->
                      <v-spacer></v-spacer>
                      <span v-if="cancel.appointmentStatus === 'Queued'"
                        >Aktif</span
                      >
                      <span
                        v-else-if="
                          cancel.appointmentStatus === 'PaymentProgress'
                        "
                        >Menunggu Pembayaran</span
                      ><span
                        v-else-if="
                          cancel.appointmentStatus === 'PaymentSuccess'
                        "
                        >Pengkajian Awal</span
                      >
                      <span
                        v-else-if="cancel.appointmentStatus === 'PtnCancelReq'"
                        >Menunggu Otorisasi</span
                      >
                      <span
                        v-else-if="cancel.appointmentStatus === 'PtnCancelAppr'"
                        >Pembatalan Disetujui</span
                      >
                      <span
                        v-else-if="cancel.appointmentStatus === 'PtnCancelRjct'"
                        >Pembatalan Ditolak</span
                      >
                      <span v-else-if="cancel.appointmentStatus === 'DocCancel'"
                        >Pembatalan Oleh Dokter</span
                      >
                      <span v-else-if="cancel.appointmentStatus === 'Finish'"
                        >Selesai</span
                      >
                      <!-- <span v-else-if="cancel.appointmentStatus === 'DocCancel'">Dibatalkan Dokter</span> -->
                    </p>
                  </v-col>
                  <v-divider vertical inset></v-divider>
                  <v-col cols="8">
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="cancel.startTime"
                          class="text-card"
                          label="Jam Mulai"
                          dense
                          readonly
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="cancel.endTime"
                          class="text-card"
                          readonly
                          label="Jam Selesai"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row
                      justify="center"
                      align="center"
                      v-show="cancel.batal"
                    >
                      <v-col cols="10">
                        <v-text-field
                          class="btn-card"
                          v-model="cancel.alasan"
                          label="Alasan Pembatalan"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="center" class="ma-0 pa-0">
                      <div
                        v-if="
                          cancel.appointmentStatus !== 'PtnCancelReq' &&
                          cancel.appointmentStatus !== 'PtnCancelAppr' &&
                          cancel.appointmentStatus !== 'Finish' &&
                          cancel.appointmentStatus !== 'PtnCancelRjct' &&
                          cancel.appointmentStatus !== 'DocCancel'
                        "
                      >
                        <v-btn
                          v-show="showBtn"
                          color="error"
                          @click="actbtnbatalkan(cancel)"
                          class="btn-card mr-1"
                          x-small
                          v-if="!cancel.batal"
                          >Batalkan</v-btn
                        >
                      </div>
                      <v-btn
                        color="error"
                        @click="back(cancel)"
                        class="btn-card mr-1"
                        x-small
                        v-if="cancel.batal"
                        >Back</v-btn
                      >

                      <v-btn
                        color="success"
                        @click="submit(cancel)"
                        class="btn-card"
                        x-small
                        v-if="cancel.batal"
                        >Submit</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-1 ml-5 pb-3">
            <v-btn small color="primary" @click="closeDialog">Close</v-btn>
          </v-row>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>
<script>
import scheduleService from "@/services/DoctorSchedule_service";
// import DoctorServices from "@/services/doctor_services";
// const DEFAULT_TIME = "00:00:00";
export default {
  //  components: {
  //     VuetifyTimeSelect
  //   },
  data: () => ({
    showBtn: true,
    menuList: {},
    login: {},
    picked: {},
    datePick: "",
    cancelList: [],
    doctorList: [],
    category: null,
    kategori: [],
    search: "",
    openDialog: false,
    
    date: new Date().toISOString().substr(0, 10),
    // batal: false,
    // btnbatal: true,
    alasan: "",
    id: "",
    timeSelect: [],
    dateSchedule: [],
    dateSelect: "",
    newData: "",
    New: false,
    slot: [5, 10, 15, 20],
    pageNumber: 10,
    paginate: { page: 0, size: 10, id:"", name: "" },
    totalpage: [],
    pageCount: 0,
    page: 1,
  }),
  mounted() {
    this.loginData();
    this.getSpecialization();
    this.getDoctorList();
  },
  methods: {
    Reset(){
       this.paginate.id = ""
      this.paginate.name =""
      this.getDoctorList(this.paginate)
    },
    loginData() {
      this.login = JSON.parse(window.localStorage.getItem("loginData"));
      console.log("login list", this.login.features);
      this.menuList = this.login.features.filter(
        (item) => item.featureName === "Pembatalan Janji Konsultasi"
      );
      if (this.menuList[0].create === false) {
        this.showBtn = false;
      }
      console.log(this.menuList);
    },
    selectingCategory(){
       console.log(this.category);
       this.paginate.id = this.category
       this.getDoctorList(this.paginate)
      //  console.log(this.doctorList);
      //  for (let i = 0; i < this.doctorList.length; i++) {
      //    if (this.doctorList[i].docMstMedicSpecializationId === this.category) {
      //      console.log(this.doctorList[i]);
      //    }    
      //  }
    },
    choosePage(page) {
      console.log(page);
      this.paginate.page = page - 1;
      console.log(this.paginate);
      this.getDoctorList(this.paginate);
    },
    onSearch() {
      this.paginate.page = 0;
      // this.paginate.size = 100000000
      this.paginate.name = this.search;
      console.log(this.paginate);
      this.getDoctorList(this.paginate);
    },

    selectedPage() {
      console.log(this.pageNumber);
      this.paginate.size = this.pageNumber;
      this.getDoctorList(this.paginate);
    },

    getSpecialization() {
      scheduleService
        .getSPecialization()
        .then((res) => {
          console.log(res);
          this.kategori = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openEditor(cancel) {
      cancel.batal = true;
    },
    closeEditor(cancel) {
      cancel.batal = false;
    },
    detail(items) {
      console.log(items);
      this.id = items.docDoctorHospitalId;
      this.openDialog = true;
    },
    closeDialog() {
      // this.openDialog = false;
      window.location.reload();
    },
    pickDate(date) {
      console.log(date);
      this.datePick = date;
      let getSch = {
        id: this.id,
        date: date,
      };
      this.picked = getSch;
      console.log(getSch);
      console.log(this.picked);
      scheduleService
        .getListSchdule(getSch)
        .then((res) => {
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            console.log(res);
            var data = res.data.payload;
            this.cancelList = res.data.payload;
            for (let i = 0; i < data.length; i++) {
              this.cancelList[i]["batal"] = false;
              this.cancelList[i]["alasan"] = "";
            }
            console.log(this.cancelList);
          }
        })
        .catch((e) => {
          this.$toast.open({
            message: e.data.error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        });
    },
    gettrial() {
      console.log("trial");
    },
    getDoctorList(value) {
      console.log("data");
      value = this.paginate;
      scheduleService
        .getListDoctor(value)
        .then((res) => {
          console.log(res);
          this.pageCount = res.data.payload.totalPages;
          this.totalpage = res.data.payload;
          this.doctorList = res.data.payload.content;
          console.log(this.doctorList);
        })
        .catch((err) => console.log(err));
    },
    headers() {
      return [
        {
          text: "Nama Dokter",
          value: "fullName",
          class: "primary--text",
        },
        {
          text: "No SIP",
          value: "sipNumber",
          class: "primary--text",
        },
        // {
        //   text: "Hospital Emoloyee Number",
        //   value: "hospitalEmployeeNumber",
        //   class: "primary--text",
        // },
        {
          text: "Email",
          value: "email",
          class: "primary--text",
        },
        {
          text: "No Telp",
          value: "mobilePhone",
          class: "primary--text",
        },
        {
          text: "Kategori",
          value: "specializationName",
          class: "primary--text",
        },
        {
          text: "action",
          value: "action",
          class: "primary--text",
        },
      ];
    },
    actbtnbatalkan(cancel) {
      // console.log(i);
      cancel.batal = true;
      cancel.alasan = "";
      var data = this.cancelList;
      this.cancelList = [];
      this.cancelList = data;
      // this.cancelList[i].btnbatal = !this.cancelList[i].btnbatal
      // console.log(cancel);
      // this.batal[i] = !this.batal[i]
      console.log(this.cancelList);
      // this.batal = true;
      // this.btnbatal = false;
    },
    back(cancel) {
      this.batal = false;
      this.btnbatal = true;
      this.alasan = "";
      cancel.batal = false;
      var data = this.cancelList;
      this.cancelList = [];
      this.cancelList = data;
    },
    submit(cancel) {
      console.log(cancel);
      let data = {
        aptAppointmentId: cancel.aptAppointmentId,
        note: cancel.alasan,
      };
      scheduleService
        .cancelAppointment(data)
        .then((resp) => {
          console.log(resp);
          this.cancelList = [];
          this.alasan = "";
          this.batal = false;
          this.btnbatal = true;
          scheduleService
            .getListSchdule(this.picked)
            .then((res) => {
              if (res.data.error !== "") {
                this.$toast.open({
                  message: res.data.error,
                  position: "top-right",
                  type: "error",
                  duration: 6000,
                });
              } else {
                console.log(res);
                var data = res.data.payload;
                this.cancelList = res.data.payload;
                for (let i = 0; i < data.length; i++) {
                  this.cancelList[i]["batal"] = false;
                  this.cancelList[i]["alasan"] = "";
                }
                console.log(this.cancelList);
              }
            })
            .catch((e) => {
              this.$toast.open({
                message: e.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
            });
          //    cancel.batal = false
          // var data = this.cancelList
          // this.cancelList = []
          // this.cancelList = data
        })
        .catch((error) => {
          console.log(error);
          cancel.batal = false;
          var data = this.cancelList;
          this.cancelList = [];
          this.cancelList = data;
        });
    },
  },
  computed: {
    filterDoctor() {
      return this.doctorList.filter((i) => {
        return (
          !this.category || i.docMstMedicSpecializationId === this.category
        );
      });
    },
  },
};
</script>


<style scoped>
.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
.i {
  margin-top: -40px;
}
.o {
  margin-top: -20px;
}
.background {
  background-color: #edf4fb;
}
.judul {
  font-family: Nunito;
  font-weight: bold;
  font-size: 18px;
  color: #313131;
}
.text-card {
  font-family: Nunito;
  font-weight: 600;
  font-size: 12px;
  color: #313131;
}
.btn-card {
  font-family: Nunito;
  font-weight: 600;
  font-size: 12px;
  margin-top: -25px;
}
</style>