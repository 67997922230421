<template>
  <div class="background">
    <div class="LupaPassword">
      <div class="logo">
        <img src="../assets/ksi logo 1.png" />
      </div>
      <div class="header1">
        <h1>Lupa Password?</h1>
      </div>
      <div class="header2">
        <a>Jangan khawatir. Reset password Anda dengan mudah,</a>
        <p>masukkan email yang terdaftar.</p>
      </div>
      <div class="body">
        <form>
          <b-form-group label="Email address*">
            <b-form-input
              v-model="forgot.email"
              type="email"
              placeholder="Enter email address"
            ></b-form-input>
          </b-form-group>
          <div class="bodyBottom">
            <v-btn x-large color="primary" @click="submit" block>Kirim</v-btn>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  export default {
    name: "LupaPassword",
    components: {},
    data() {
      return {
        forgot: {
          userType: "admin",
          email: "",
        },
      };
    },
    mounted() {
      axios
        .post(
          " https://api.klinisia.id/auth/forget-pwd/by-email",
          this.forgot,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        )
        .then((response) => {
          console.log(this.$route);
          this.email = this.$route;
          console.log("ini email");
          console.log(this.email);
          console.log(response);
        });
    },
    methods: {
      submit() {
        if (this.forgot.email === "") {
          this.$toast.error("Email tidak boleh kosong", {
            type: "error",
            position: "top-right",
            duration: 4000,
          });
        } else {
          axios
            .post(
              " https://api.klinisia.id/auth/forget-pwd/by-email",
              this.forgot,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              },
            )
            .then((response) => {
              console.log(response);
              window.localStorage.setItem("email", response.data.payload.email);
              this.$router.push("/login");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../scss/LupaPassword.scss";
</style>
