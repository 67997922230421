<template>
  <v-app class="background">
    <v-container>
      <v-card>
        <v-row class="ml-1">
          <v-col>
            <v-form>
              <h4 class="mt-3">Role MAINTENANCE</h4>
              <v-divider></v-divider>
              <h5>Add Role</h5>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Jenis Role</p>
                </v-col>
                <v-col cols="6">
                  <v-select
                    class=""
                    v-model="params.userType"
                    :items="Roles"
                    item-value="value"
                    item-text="text"
                    label="choose role"
                    dense
                    outlined
                    @change="selectingRoleType"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Kode Role</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="params.roleCode"
                    :rules="rules"
                    label=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Nama Role</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="params.roleName"
                    :rules="rules"
                    label=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Deskripsi</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="params.roleDescription"
                    label=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-list>
                    <v-col
                      v-for="(obj, key) in chooseFeature"
                      :key="key"
                      class="mb-5 px-5"
                    >
                      <v-row>
                        <v-container fluid fill-height class="contentTable">
                          <v-checkbox
                            class="mt-2 mx-4 pt-0"
                            v-model="obj.active"
                            @change="isnotChaked(obj)"
                          >
                            <template v-slot:label>
                              <span class="checkboxLabel">{{
                                obj.featureName
                              }}</span>
                            </template></v-checkbox
                          >

                          <v-row align="center" justify="center" class="mt-3">
                            <v-col>
                              <v-list class="scrollableList">
                                <v-row no-gutters>
                                  <v-col cols="3">
                                    <v-checkbox
                                      class="mt-2 mx-4 pt-0"
                                      v-model="obj.create"
                                      :disabled="obj.disCreate"
                                      @change="isChecked(obj)"
                                    >
                                      <template v-slot:label>
                                        <span class="checkboxLabel"
                                          >Tambah</span
                                        >
                                      </template></v-checkbox
                                    >
                                  </v-col>
                                  <v-col cols="3">
                                    <v-checkbox
                                      class="mt-2 mx-4 pt-0"
                                      v-model="obj.read"
                                      :disabled="obj.disRead"
                                      @change="isChecked(obj)"
                                    >
                                      <template v-slot:label>
                                        <span class="checkboxLabel">Lihat</span>
                                      </template></v-checkbox
                                    >
                                  </v-col>
                                  <v-col cols="3">
                                    <v-checkbox
                                      class="mt-2 mx-4 pt-0"
                                      v-model="obj.update"
                                      :disabled="obj.disUpdate"
                                      @change="isChecked(obj)"
                                    >
                                      <template v-slot:label>
                                        <span class="checkboxLabel">Ubah</span>
                                      </template></v-checkbox
                                    >
                                  </v-col>
                                  <v-col cols="3">
                                    <v-checkbox
                                      class="mt-2 mx-4 pt-0"
                                      v-model="obj.delete"
                                      :disabled="obj.disDelete"
                                      @change="isChecked(obj)"
                                    >
                                      <template v-slot:label>
                                        <span class="checkboxLabel">Hapus</span>
                                      </template></v-checkbox
                                    >
                                  </v-col>
                                </v-row>
                              </v-list>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-row>
                    </v-col>
                  </v-list>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row>
          <v-col align="right">
            <v-btn class="mr-2" small color="error" dark to="/RoleControlList"
              >Kembali</v-btn
            >
            <v-btn class="mr-10" small color="primary" @click="simpan" dark
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import adminService from "@/services/adminList_servicce";
import serviceRole from "@/services/auth_services";
export default {
  components: {
    // Popup
  },
  data: () => ({
    featurefilter: [],
    chooseFeature: [],
    forsir: "",
    Roles: [],
    typeRoles: [],
    isNotChacked: true,
    roleTypeSelect: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (value) =>
        (value || "").length >= 8 || "password minimum 8 karekter alphanumeric",
      //  same:(value)=> value !==
    },
    dataFeature: [],
    params: {
      secRoleId: "",
      roleCode: "",
      userType: "",
      roleName: "",
      roleDescription: "",
      roleFeatures: [],
    },
  }),
  mounted() {
    this.RoleTypeList();
    this.getfeature();
  },
  watch: {},
  computed: {},
  methods: {
    RoleTypeList() {
      adminService
        .getTypeRole()
        .then((res) => {
          console.log(res);
          this.typeRoles = res.data.payload.content;
          this.typeRoles.map((e) => {
            this.Roles.push({
              value: e.userTypeCode,
              text: e.userTypeCode,
            });
          });
        })
        .catch((err) => console.log(err));
    },
    isnotChaked(obj){
      console.log(obj);
      if (obj.active === false) {
        obj.read = false,
        obj.create = false,
        obj.update = false,
        obj.delete = false,
        obj.disCreate = true,
        obj.disRead = true,
        obj.disUpdate = true,
        obj.disDelete = true
      }
      if (obj.active === true) {
        obj.read = true,
        obj.create = true,
        obj.update = true,
        obj.delete = true,
        obj.disCreate = false,
        obj.disRead = false,
        obj.disUpdate = false,
        obj.disDelete = false
      }
    },
    selectingRoleType() {
      var data = []
      console.log(this.params.userType);
      // this.chooseFeature = this.dataFeature.filter((item) =>
      //   this.params.userType.includes(item.userTypeCodes)
      // );
      for(let i = 0; i < this.dataFeature.length ;  i++){
      let spliting = this.dataFeature[i].userTypeCodes.split("|")
      for(let j = 0 ; j < spliting.length; j++){
          if(this.params.userType === spliting[j])
          {data.push(this.dataFeature[i])}
        }
      }
      console.log(data);
      this.chooseFeature = data
      console.log(this.chooseFeature);
    },
    getfeature() {
      serviceRole.getFeature().then((response) => {
        console.log("response feature", response);
        this.dataFeature = response.data.payload.content;
        // this.chooseFeature = this.dataFeature
      });
    },
    simpan() {
      var data = this.chooseFeature;
      console.log(data.length);
      let trial = [];
      for (let i = 0; i < data.length; i++) {
        // trial.push(i)
        console.log(data[i].active);
        if (data[i].active === true) {
          console.log(data[i]);
          trial.push(data[i]);
        }
      }
      console.log(trial);
      trial.map((e) => {
        this.params.roleFeatures.push({
          secFeatureId: e.secFeatureId,
          featureName: e.featureName,
          featureCode: e.featureCode,
          read: e.read,
          create: e.create,
          update: e.update,
          delete: e.delete,
        });
      });
      console.log(this.params.roleFeatures);
      console.log(this.params);
      if (
        this.params.roleCode === "" &&
        this.params.roleName === "" &&
        this.params.userType === ""
      ) {
        this.$toast.open({
          message: "Harap Isi Semua Field",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params.userType === "") {
        this.$toast.open({
          message: "Harap Pilih Jenis Role",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params.roleName === "") {
        this.$toast.open({
          message: "Harap Isi Nama Role",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params.roleCode === "") {
        this.$toast.open({
          message: "Harap Isi Code Role",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        serviceRole.addRole(this.params).then((response) => {
          console.log(response);
          if (response.data.error != "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Role Baru Berhasil Disimpan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.$router.push("/RoleControlList");
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/dashboard.scss";
.titleContent {
  font-weight: 500;
  line-height: 1rem !important;
}

.background {
  background-color: #edf4fb;
}
.cardsearch {
  background-color: #ffffff;
  border-radius: 30px;
  width: 99%;
  margin-left: 2px;
}
.headerComponent {
  margin-left: 250px;
}
.datapasien {
  margin-left: 250px;
  margin-right: 120px;
}
.header {
  margin-left: 250px;
  /* margin-right: 250px; */
}
.poppuphead {
  text-align: center;
}
.v-card__title {
  font-size: 0.9rem !important;
  line-height: 1rem !important;
}
.instructionType {
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.search {
  margin-top: 15px;
  margin-left: 10px;
}
.detail {
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.nama {
  font-family: Nunito;
  font-size: 22px;
  text-align: left;
}
.icd-search {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.icd-text {
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
}
.contentTable {
  background: #e9f5fc;
  border-radius: 15px;
}
</style>