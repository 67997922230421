<template>
  <v-app class="background">
  <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row class="ml-1">
          <v-col>
            <h6
              class="mt-4"
              style="font-size:18px font-family:Nunito  font-weight: 600"
            >
              Master Spesialisasi
            </h6>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="ml-1">
          <v-col>
           
              <h6 style="font-size:14px font-family:Nunito  font-weight: 600;">
                Data Search
              </h6>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p style="font-size:12px font-family:Nunito">Search</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="search"
                    append-icon="mdi-magnify"
                    outlined
                    class="mt-3"
                    @keyup.enter="onSearch"
                    v-model="search"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
           
          </v-col>
        </v-row>
        <v-row class="ml-2">
          <v-col cols="2" class="mr-11">
            <v-btn
              v-if="menuList[0].create === true"
              class="mt-1"
              small
              color="success"
              to="/addspesialisasiDoctor"
              dark
            >
              <v-icon dark small> mdi-plus </v-icon> Tambah Spesialisasi</v-btn
            >
          </v-col>
          <v-col cols="2" class="mb-6">
            <v-select
              hide-details=""
              @change="selectedPage()"
              v-model="pageNumber"
              :items="slot"
              outlined
              dense
              class="text"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="mt-3">
          <v-col>
            <div>
              <v-data-table
                :headers="headers()"
                :items="dataSpesialisasi"
                :server-items-length="pageCount"
                hide-default-footer
                :search="search"
                class="elevation-1"
                @change="change"
                dense
              >
                <template v-slot:item.aksi="{ item }">
                  <td>
                    <v-btn
                      color="primary"
                      dark
                      x-small
                      class="mr-2"
                      @click="edit(item)"
                    >
                      <v-icon dark x-small class="mr-1">mdi-pencil</v-icon>
                      <p v-if="menuList[0].update === true" class="mt-4">
                        Ubah
                      </p>
                      <p v-else-if="menuList[0].update === false" class="mt-4">
                        Lihat
                      </p>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      v-if="menuList[0].delete === true"
                      color="error"
                      x-small
                      dark
                      class="mr-2"
                      @click="dels(item)"
                    >
                      <v-icon dark x-small class="mr-1">mdi-delete</v-icon>Hapus
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
              <v-row>
                <v-col>
                  <v-pagination
                    circle
                    v-model="page"
                    :length="totalpage.totalPages"
                    :total-visible="7"
                    @input="choosePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-dialog v-model="dialogEdit" persistent max-width="1500">
          <v-card>
            <v-form>
              <v-row class="ml-2 mt-4">
                <v-col>
                  <h5 class="mt-3">
                    Edit {{ params.specializationName }}
                  </h5>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="ml-2 mt-4">
                <v-col>
                  <v-form>
                    <v-row>
                      <v-col cols="6">
                        <p>Kode Spesialisasi</p>
                        <v-text-field
                          dense
                          outlined
                          v-model="params.specializationCode"
                          :rules="nameRules"
                          required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <p>Nama Spesialisasi</p>
                        <v-text-field
                          dense
                          outlined
                          v-model="params.specializationName"
                          :rules="nameRules"
                          required
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <p>Deskripsi Spesialisasi</p>
                        <v-text-field
                          dense
                          outlined
                          v-model="params.specializationDescription"
                          :rules="nameRules"
                          required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6"> </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-form>
            <v-card-actions class="pb-4 mb-3">
              <v-spacer></v-spacer>
              <v-btn
                v-if="menuList[0].update === true"
                medium
                outlined
                color="success"
                dark
                @click="saveEdit"
                >Simpan Edit</v-btn
              >
              <v-btn medium outlined color="error" dark @click="closeDialog"
                >Batal</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dhapus" persistent max-width="500">
          <v-card>
            <v-card-title
              >Yakin Hapus Spesialisasi {{ namadelete }} ?</v-card-title
            >
            <v-card-actions class="pb-4 mb-3">
              <v-spacer></v-spacer>
              <v-btn
                v-if="menuList[0].delete === true"
                medium
                outlined
                color="success"
                dark
                @click="hapus"
                >Hapus</v-btn
              >
              <v-btn medium outlined color="error" dark @click="closeDialog"
                >Batal</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import doctorServices from "@/services/doctor_services";
// import servicegeo from "@/services/geo_services";
// import adminService from "@/services/adminList_servicce";
export default {
  components: {
    // Popup
  },
  data: () => ({
    search: "",
    menuList: {},
    slot: [5, 10, 15, 20],
    pageNumber: 10,
    paginate: { name: "", page: 0, size: 10 },
    totalpage: [],
    pageCount: 0,
    page: 1,
    login: {},
    feature: [],
    dialogEdit: false,
    dataSpesialisasi: [],
    namadelete: "",
    iddelete: "",
    dhapus: false,
    params: {
      docMstMedicSpecializationId: "",
      specializationCode: "",
      specializationName: "",
      specializationDescription: "",
    },
    isactive: [
      {
        status: true,
        label: "Aktif",
      },
      {
        status: false,
        label: "Non Aktif",
      },
    ],
    rules: [(v) => !!v || " is required"],
  }),
  mounted() {
    this.loginData();
    this.getSpesialisasi();
  },
  watch: {},
  computed: {},
  methods: {
    loginData() {
      this.login = JSON.parse(window.localStorage.getItem("loginData"));
      console.log("login list", this.login.features);
      this.menuList = this.login.features.filter(
        (item) => item.featureName === "Spesialisasi Dokter"
      );
      console.log(this.menuList);
    },
    onSearch() {
      this.paginate.page = 0;
      // this.paginate.size = 100000000
      this.paginate.name = this.search;
      this.getSpesialisasi(this.paginate);
    },
    choosePage(page) {
      console.log(page);
      this.paginate.page = page - 1;
      console.log(this.paginate);
      this.getSpesialisasi(this.paginate);
    },
    selectedPage() {
      console.log(this.pageNumber);
      this.paginate.size = this.pageNumber;
      this.getSpesialisasi(this.paginate);
    },
    headers() {
      return [
        // {
        //   text: "No",
        //   align: "start",
        //   sortable: true,
        //   value: "index",
        //   class: "primary--text judul",
        // },
        {
          text: "Kode",
          value: "specializationCode",
          class: "primary--text",
        },
        {
          text: "Spesialisasi",
          value: "specializationName",
          class: "primary--text",
        },
        {
          text: "Deskripsi",
          value: "specializationDescription",
          // value: "icdName",
          class: "primary--text",
        },
        {
          text: "Status",
          value: "status",
          class: "primary--text",
        },
        {
          text: "Aksi",
          value: "aksi",
          class: "primary--text judul",
        },
      ];
    },
    isnotChaked(obj) {
      console.log(obj);
      if (obj.active === false) {
        (obj.read = false),
          (obj.create = false),
          (obj.update = false),
          (obj.delete = false),
          (obj.isCreate = true),
          (obj.isRead = true),
          (obj.isUpdate = true),
          (obj.isDelete = true);
      } else if (obj.active === true) {
        (obj.read = true),
          (obj.create = true),
          (obj.update = true),
          (obj.delete = true),
          (obj.isCreate = false),
          (obj.isRead = false),
          (obj.isUpdate = false),
          (obj.isDelete = false);
      }
    },
    edit(item) {
      console.log(item);
      //   this.iddelete = item.hptHospitalTypeId;
      this.params.docMstMedicSpecializationId =
        item.docMstMedicSpecializationId;
      this.params.specializationCode = item.specializationCode;
      this.params.specializationName = item.specializationName;
      this.params.specializationDescription = item.specializationDescription;
      this.dialogEdit = true;
    },
    dels(item) {
      console.log(item);
      this.iddelete = item.docMstMedicSpecializationId;
      this.namadelete = item.specializationName;
      this.dhapus = true;
    },
    closeDialog() {
      this.params.docMstMedicSpecializationId = "";
      this.params.specializationCode = "";
      this.params.specializationName = "";
      this.params.specializationDescription = "";
      this.iddelete = "";
      this.namadelete = "";
      this.dialogEdit = false;
      this.dhapus = false;
      //  window.location.reload();
    },

    hapus() {
      doctorServices.deleteSpesialisasi(this.iddelete).then((res) => {
        if (res.data.error !== "") {
          this.$toast.open({
            message: res.data.error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          this.$toast.open({
            message: `Spesialisasi ${this.namadelete} Berhasil Dihapus`,
            position: "top-right",
            type: "success",
            duration: 6000,
          });
          this.dataSpesialisasi = [];
          this.getSpesialisasi();
          this.dhapus = false;
        }
      });
    },
    getSpesialisasi(value) {
      let master = [];
      this.dataSpesialisasi = [];
      value = this.paginate;
      doctorServices.getSpesialisasi(value).then((response) => {
        console.log(response);
        this.pageCount = response.data.payload.totalPages;
        this.totalpage = response.data.payload;
        master = response.data.payload.content;
        master.map((e) => {
          this.dataSpesialisasi.push({
            docMstMedicSpecializationId: e.docMstMedicSpecializationId,
            specializationCode: e.specializationCode,
            specializationName: e.specializationName,
            specializationDescription: e.specializationDescription,
            active: e.active,
          });
          this.dataSpesialisasi.forEach((e) => {
            if (e.active === true) {
              e.status = "Aktif";
            } else {
              e.status = "Non Aktif";
            }
          });
        });
      });
    },
    saveEdit() {
      let data = {
        id: this.params.docMstMedicSpecializationId,
        body: this.params,
      };
      doctorServices.editSpesialisasi(data).then((res) => {
        if (res.data.error !== "") {
          this.$toast.open({
            message: res.data.error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          this.$toast.open({
            message: `Spesialisasi ${this.params.specializationName} Berhasil Diubah`,
            position: "top-right",
            type: "success",
            duration: 6000,
          });
        }
        (this.dataSpesialisasi = []), this.getSpesialisasi();
        this.dialogEdit = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/dashboard.scss";
.titleContent {
  font-weight: 500;
  line-height: 1rem !important;
}
.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
.background {
  background-color: #edf4fb;
}
.cardsearch {
  background-color: #ffffff;
  border-radius: 30px;
  width: 99%;
  margin-left: 2px;
}
.headerComponent {
  margin-left: 250px;
}
.datapasien {
  margin-left: 250px;
  margin-right: 120px;
}
.header {
  margin-left: 250px;
  /* margin-right: 250px; */
}
.poppuphead {
  text-align: center;
}
.v-card__title {
  font-size: 0.9rem !important;
  line-height: 1rem !important;
}
.instructionType {
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.search {
  margin-top: 15px;
  margin-left: 10px;
}
.detail {
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.nama {
  font-family: Nunito;
  font-size: 22px;
  text-align: left;
}
.icd-search {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.icd-text {
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
}
.contentTable {
  background: #e9f5fc;
  border-radius: 15px;
}
.text {
  font-family: Nunito;
  font-size: 11px;
  font-weight: 600;
  height: 2px !important;
  width: 80px !important;
}
</style>