<template>
  <v-app class="background">
    <v-container>
      <!-- <v-icon>home</v-icon> -->
      <v-toolbar extended color="#EDF4FB" flat>
        <v-app-bar-nav-icon
          class="colornav"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <div class="mx-3">
          <v-badge :content="messages" :value="messages" overlap>
            <font-awesome-icon icon="bell" size="2x" color="#2B92E4" />
          </v-badge>
        </div>

        <div class="mx-6 mt-1 mr-10">
          <v-avatar color="white" size="60">
            <img src="../../assets/image 2.svg" />
          </v-avatar>
        </div>
      </v-toolbar>
    </v-container>

    <!-- expand-on-hover elevation="5" -->
    <v-navigation-drawer
      class="menu"
      v-model="drawer"
      app
      :width="300"
      elevation="5"
    >
      <div class="logo">
        <!-- <img src="../../assets/ksi logo 1.png" />
          <div class="font">
            <p>Klinisia</p>
        </div>-->
        <v-row>
          <v-col cols="auto">
            <img src="../../assets/logo Klinisia.svg" />
          </v-col>
          <v-col cols="auto">
            <img src="../../assets/Klinisia.svg" />
          </v-col>
        </v-row>
      </div>
      <div
        v-if="
          login.features.some((log) => log.featureName === 'Doctor Maintenance')
        "
        class="dokter"
      >
        <b-row>
          <b-col sm="1">
            <img src="../../assets/logo dokter.svg" />
          </b-col>
          <b-col>
            <p>Dokter</p>
          </b-col>
        </b-row>
      </div>
      <div class="dokterMenu mt-n5">
        <v-list
          v-if="
            login.features.some(
              (log) => log.featureName === 'Doctor Maintenance',
            )
          "
          flat
        >
          <v-list-item
            v-for="i in menu"
            :key="i.text"
            router
            :to="i.route"
            color="#109CF1"
            class="mt-n3"
          >
            <v-list-item-content>
              <v-list-item-title class="down">{{ i.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-show="dokter" class="mt-n5" flat>
          <v-list-item
            v-for="i in schedule"
            :key="i.text"
            router
            :to="i.route"
            color="#109CF1"
            class="mt-n3"
          >
            <v-list-item-content>
              <v-list-item-title class="down">{{ i.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <!-- <div v-if="login.features.includes('ADM-CNT-QUEUE')" class="dokter"> -->
      <div
        v-if="
          login.features.some((log) => log.featureName === 'Antrian Pasien')
        "
        class="dokter mt-n1"
      >
        <b-row class="">
          <b-col sm="1">
            <img src="../../assets/ant-design_field-time-outlined2.svg" />
          </b-col>
          <b-col>
            <a class="down" href="/antrian">Antrian Pasien</a>
          </b-col>
        </b-row>
      </div>
      <!-- <div v-if="ksi === false"> -->
      <div
        v-if="
          login.features.some((log) => log.featureName === 'Rekap Pasien') ||
            login.features.some(
              (log) => log.featureName === 'Rekap Pasien Detail',
            )
        "
        class="dokter mt-n1"
      >
        <b-row class="mt-2">
          <b-col sm="1">
            <img src="../../assets/rekap.png" />
          </b-col>
          <b-col>
            <a class="down" href="/rekapPasien">Rekap Pasien</a>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="
          login.features.some((log) => log.featureName === 'Konfirmasi Obat')
        "
        class=" dokter mt-n1"
      >
        <b-row class="mt-2">
          <b-col sm="1">
            <img src="../../assets/konfir.svg" />
          </b-col>
          <b-col>
            <a class="down" href="/konfirmasiobat">Konfirmasi Obat</a>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="
          login.features.some((log) => log.featureName === 'Status Pengiriman')
        "
        class="dokter mt-n1"
      >
        <b-row class="mt-2">
          <b-col sm="1">
            <img src="../../assets/carbon_delivery.svg" />
          </b-col>
          <b-col>
            <a class="down" href="/Pengiriman">Status Pengiriman</a>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="login.features.some((log) => log.featureName === 'Master Obat')"
        class="dokter mt-n1"
      >
        <b-row class="mt-2">
          <b-col sm="1">
            <!-- <img src="../../assets/logo dokter.svg" /> -->
            <v-icon class="mt-n1" color="#000000">{{
              icons.mdiDatabaseCheck
            }}</v-icon>
          </b-col>
          <b-col>
            <p class="down">Master Data</p>
          </b-col>
        </b-row>
      </div>
      <div class="dokterMenu">
        <v-list
          v-if="login.features.some((log) => log.featureName === 'Master Obat')"
          flat
          class="mt-n5"
        >
          <v-list-item
            v-for="i in menuMasterDataAdmin"
            :key="i.text"
            router
            :to="i.route"
            color="#109CF1"
            class="mt-n3"
          >
            <v-list-item-content>
              <v-list-item-title class="down">{{ i.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

      <div v-show="ksi" class="dokter mt-10">
        <b-row class="">
          <b-col sm="1">
            <!-- <img src="../../assets/logo dokter.svg" /> -->
            <v-icon class="mt-n1">{{ icons.mdiDatabaseCheck }}</v-icon>
          </b-col>
          <b-col class="">
            <p>Master Data</p>
          </b-col>
        </b-row>
      </div>
      <div class="dokterMenu">
        <v-list v-show="ksi" flat class="mt-n5">
          <v-list-item
            v-for="i in menuMasterDataKsi"
            :key="i.text"
            router
            :to="i.route"
            color="#109CF1"
            class="mt-n3"
          >
            <v-list-item-content>
              <v-list-item-title class="down">{{ i.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

      <div v-show="ksi" class="dokter mt-n1">
        <b-row>
          <b-col sm="1">
            <!-- <img src="../../assets/logo dokter.svg" /> -->
            <v-icon class="mt-n1">{{ icons.mdiAccountBox }}</v-icon>
          </b-col>
          <b-col>
            <p>User Administration</p>
          </b-col>
        </b-row>
      </div>

      <div class="dokterMenu">
        <v-list v-show="ksi" flat class="mt-n6">
          <v-list-item
            v-for="i in menuControlAdmmin"
            :key="i.text"
            router
            :to="i.route"
            color="#109CF1"
            class="mt-n3"
          >
            <v-list-item-content>
              <v-list-item-title class="down">{{ i.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

      <div v-show="ksi" class="dokter mt-n1">
        <b-row>
          <b-col sm="1">
            <!-- <img src="../../assets/logo dokter.svg" /> -->
            <v-icon class="mt-n1">{{ icons.mdiHospitalBuilding }}</v-icon>
          </b-col>
          <b-col>
            <p>Hospital Administration</p>
          </b-col>
        </b-row>
      </div>
      <div class="dokterMenu mt-n6">
        <v-list v-show="ksi" flat>
          <v-list-item
            v-for="i in menuControlHospital"
            :key="i.text"
            router
            :to="i.route"
            color="#109CF1"
            class="mt-n3"
          >
            <v-list-item-content>
              <v-list-item-title class="down">{{ i.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

      <!-- <div v-show="ksi" class="dokter mt-n1">
        <b-row>
          <b-col sm="1">
             <v-icon class="mt-n1">{{ icons.mdiBank }}</v-icon>
          </b-col>
          <b-col>
            <p>Corporate Maintenance</p>
          </b-col>
        </b-row>
      </div>
      <div class="dokterMenu mt-n6">
        <v-list v-show="ksi" flat>
          <v-list-item
            v-for="i in menuCorporate"
            :key="i.text"
            router
            :to="i.route"
            color="#109CF1"
            class="mt-n3"
          >
            <v-list-item-content>
              <v-list-item-title class="down">{{ i.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div> -->

      <div v-show="ksi" class="dokter mt-n1">
        <b-row>
          <b-col sm="1">
            <!-- <img src="../../assets/logo dokter.svg" /> -->
            <v-icon class="mt-n1">{{ icons.mdiCreditCardPlus }}</v-icon>
          </b-col>
          <b-col>
            <p>Payment</p>
          </b-col>
        </b-row>
      </div>
      <div class="dokterMenu mt-n6">
        <v-list v-show="ksi" flat>
          <v-list-item
            v-for="i in menuPayment"
            :key="i.text"
            router
            :to="i.route"
            color="#109CF1"
            class="mt-n3"
          >
            <v-list-item-content>
              <v-list-item-title class="down">{{ i.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div class="">
        <b-row class="keluar">
          <b-col sm="1">
            <img src="../../assets/keluar.svg" />
          </b-col>
          <b-col>
            <a class="down" @click="logout()">Keluar</a>
          </b-col>
        </b-row>
      </div>
    </v-navigation-drawer>

    <router-view />
  </v-app>
</template>

<script>
  import axios from "axios";
  import rekapAPI from "@/services/rekapPasien_service";
  import notif from "@/assets/pristine-609.mp3";
  import {
    mdiBank,
    mdiDatabaseCheck,
    mdiAccountBox,
    mdiHospitalBuilding,
    mdiCreditCardPlus,
  } from "@mdi/js";
  export default {
    name: "sidebar",
    data: () => ({
      login: {},
      icons: {
        mdiBank,
        mdiDatabaseCheck,
        mdiAccountBox,
        mdiHospitalBuilding,
        mdiCreditCardPlus,
      },
      file1: notif,
      antri: 0,
      dokter: false,
      ksi: false,
      drawer: true,
      param: {
        secUserId: window.localStorage.getItem("secUserId"),
        deviceType: window.localStorage.getItem("deviceType"),
        deviceCode: window.localStorage.getItem("deviceCode"),
        longToken: window.localStorage.getItem("longToken"),
      },
      menu: [
        // { text: "Tambahkan Dokter", route: "/dashboard" },
        // { text: "List Dokter", route: "/listDokter" },
      ],
      menuMasterDataAdmin: [{ text: "Master Data Obat", route: "/inputObat" }],
      menuMasterDataKsi: [
        // { text: "Master ICD Code", route: "/inputIcd" },
        // { text: "Master Labroratorium", route: "/inputLab" },
        // { text: "Master Geolocation", route: "/inputGeo" },
        // { text: "Master Diagnostik", route: "/inputDiagnostik" },
      ],
      menuControlAdmmin: [
        // { text: "User Maintenance", route: "/listControlAdmin" },
        // { text: "Role Maintenance", route: "/RoleControlList" },
      ],
      menuPayment: [
        // { text: "Payment Control", route: "/paymentchannel" }
      ],
      menuControlHospital: [
        // { text: "Hospital Maintenance", route: "/hospitalList" },
      ],
      menuCorporate: [
        // { text: "Payment Control", route: "/paymentchannel" }
      ],
      menu2: [{ text: "Konfirmasi Obat", route: "/konfirmasiobat" }],
      schedule: [
        // {text: "Doctor Schedule", route: "/doctorSchedule" },
        // {text: "Doctor Oppointment", route: "/DoctorAppointment" }
      ],
      messages: 0,
      show: false,
    }),
    mounted() {
      this.loginData();
      this.getSumary();
      this.getListPasien();
    },
    methods: {
      getSumary() {
        rekapAPI.getSummary();
      },
      loginData() {
        this.login = JSON.parse(window.localStorage.getItem("loginData"));
        // console.log("login list", this.login.features);
        if (this.login.features.includes("ALL-SEC-USER")) {
          console.log("true");
        } else {
          console.log("false");
        }
        if (
          JSON.parse(window.localStorage.getItem("loginData")).userType ===
          "admin"
        ) {
          this.dokter = true;
        } else if (
          JSON.parse(window.localStorage.getItem("loginData")).userType ===
          "ksi"
        ) {
          this.ksi = true;
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "User Maintenance",
          )
        ) {
          this.menuControlAdmmin.push({
            text: "User Maintenance",
            route: "/listControlAdmin",
          });
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Master ICD Code",
          )
        ) {
          this.menuMasterDataKsi.push({
            text: "Master ICD Code",
            route: "/inputIcd",
          });
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Master Diagnostic",
          )
        ) {
          this.menuMasterDataKsi.push({
            text: "Master Diagnostik",
            route: "/inputDiagnostik",
          });
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Master Laboratory",
          )
        ) {
          this.menuMasterDataKsi.push({
            text: "Master Labroratorium",
            route: "/inputLab",
          });
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Spesialisasi Dokter",
          )
        ) {
          this.menuMasterDataKsi.push({
            text: "Master Spesialisasi",
            route: "/spesialisasiDoctor",
          });
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Spesialisasi Dokter",
          )
        ) {
          this.menuMasterDataKsi.push({
            text: "Master Sub Spesialisasi",
            route: "/subSpesialisasi",
          });
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Master Geografi",
          )
        ) {
          this.menuMasterDataKsi.push({
            text: "Master Geolocation",
            route: "/inputGeo",
          });
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Payment Master",
          )
        ) {
          this.menuPayment.push({
            text: "Payment Control",
            route: "/paymentchannel",
          });
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Corporate Maintenance",
          )
        ) {
          this.menuCorporate.push({
            text: "Corporate Maintenance",
            route: "/corporateMaintenance",
          });
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Corporate Member",
          )
        ) {
          this.menuCorporate.push({
            text: "Corporate Member",
            route: "/corporateMember",
          });
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Hospital Maintenance",
          )
        ) {
          this.menuControlHospital.push({
            text: "Hospital Maintenance",
            route: "/hospitalList",
          });
        }
        if (
          this.login.features.some((log) => log.featureName === "Hospital Type")
        ) {
          this.menuControlHospital.push({
            text: "Hospital Type",
            route: "/hospitalType",
          });
        }
        if (
          this.login.features.some((log) => log.featureName === "Master Role")
        ) {
          this.menuControlAdmmin.push({
            text: "Role Maintenance",
            route: "/RoleControlList",
          });
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Doctor Maintenance",
          )
        ) {
          var data = this.login.features.filter(
            (item) => item.featureName === "Doctor Maintenance",
          );
          console.log("doctor", data);
          this.schedule.push({
            text: "Jadwal Dokter",
            route: "/doctorSchedule",
          });
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Pembatalan Janji Konsultasi",
          )
        ) {
          console.log("true");
          this.schedule.push({
            text: "Pembatalan Konsultasi",
            route: "/DoctorAppointment",
          });
        } else {
          console.log("false");
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Approval Pembatalan Janji Konsultasi",
          )
        ) {
          this.schedule.push({
            text: "Otorisasi Pembatalan Konsultasi",
            route: "/doctorApproval",
          });
        }
        if (
          this.login.features.some(
            (log) => log.featureName === "Doctor Maintenance",
          )
        ) {
          var dataDoktor = this.login.features.filter(
            (item) => item.featureName === "Doctor Maintenance",
          );
          // console.log(dataDoktor);
          if (dataDoktor[0].read === true) {
            console.log("benar");
            this.menu.push({ text: "List Dokter", route: "/listDokter" });
          }
          if (dataDoktor[0].create === true) {
            this.menu.push({ text: "Tambahkan Dokter", route: "/dashboard" });
          }
          console.log(this.menu);
        }
      },
      getListPasien() {
        rekapAPI
          .getQueueList()
          .then((response) => {
            // console.log(response);
            // console.log(response.data.payload);

            this.pasien2 = response.data.payload;
            // console.log( this.pasien2.length);
            var data = this.pasien2.length;
            // console.log(data +1);
            // console.log("295", this.antri);
            if (this.antri > 0) {
              if (data + 1 > this.antri) {
                this.antri = this.pasien2.length + 1;
                // this.statusPlay = true;
                var audio = new Audio(this.file1);
                audio.play();
                // console.log("nyala");
              } else if (data + 1 < this.antri || data + 1 == this.antri) {
                //    var  sound = new Audio(this.file1)
                // sound.play()
                this.antri = this.pasien2.length + 1;
                // console.log("diam");
              }
            }
            if (this.antri == 0) {
              this.antri = this.pasien2.length + 1;
              // console.log(this.antri);
              // console.log("diam");
            }

            // .then((response) => {
            //   console.log(response);
            // });
            setTimeout(this.getListPasien, 5000);
          })
          .catch((error) => {
            console.log(error);
            clearTimeout(this.getListPasien);
          });
      },
      logout() {
        axios
          .post("https://api.klinisia.id/auth/login/logout", this.param, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("ini logout");
            window.localStorage.removeItem("shortToken");
            window.localStorage.removeItem("longToken");
            window.localStorage.removeItem("authenticated");
            console.log(response);
            this.$router.push("/login");
            window.location.reload();
          })

          .catch((error) => {
            console.log("refresh error");
            console.log(error);
          });
        window.localStorage.removeItem("shortToken");
        window.localStorage.removeItem("authenticated");
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "../../scss/option.scss";
  .colornav {
    color: #2b92e4 !important;
  }
</style>
