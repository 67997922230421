<template>
  <v-app class="background">
   <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row class="ml-2 mr-2">
          <v-col cols="4">
            <p dark class="mt-5 ml-2 icd-search" rounded small width="170">
              Cari {{ label }}
            </p>
          </v-col>
          <v-col sm="12" md="6" lg="6" class="search">
            <v-text-field
              label="search"
              append-icon="mdi-magnify"
              outlined
              v-model="search"
               @keyup.enter="onSearch"
              rounded
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container>
      <v-card class="cardStyle">
        <v-row>
          <v-col>
            <div>
              <v-row class="ml-2 mb-4">
                <v-col cols="2" class="mr-1">
                  <v-btn
                    v-if="menuList[0].create === true"
                    color="success"
                    small
                    class="mt-1"
                    dense
                    @click="isTambah"
                    ><v-icon dark x-small> mdi-plus </v-icon>Tambah
                    {{ label }}</v-btn
                  >
                </v-col>
                <v-col cols="2" class="mb-6 ml-5">
                  <v-select
                    hide-details=""
                    @change="selectedPage()"
                    v-model="pageNumber"
                    :items="slot"
                    outlined
                    dense
                    class="text"
                  ></v-select>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers()"
                :items="geoitem"
                hide-default-footer
                :server-items-length="pageCount"
                :search="search"
                class="elevation-1"
                dense
              >
                <template v-slot:item.aksi="{ item }">
                  <td>
                    <v-btn
                      color="primary"
                      dark
                      x-small
                      class="mr-2"
                      @click="edit(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-pencil </v-icon>
                      <p v-if="menuList[0].update === true" class="mt-4">
                        Ubah
                      </p>
                      <p v-if="menuList[0].update === false" class="mt-4">
                        lihat
                      </p>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      v-if="menuList[0].delete === true"
                      color="error"
                      x-small
                      dark
                      class="mr-2"
                      @click="dels(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-delete </v-icon
                      >Hapus</v-btn
                    >
                  </td>
                  <td>
                    <v-btn
                      color=""
                      v-show="vbtn"
                      x-small
                      dark
                      @click="pindah(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-eye </v-icon>Lihat
                      {{ subLabel }}</v-btn
                    >
                  </td>
                </template>
              </v-data-table>
              <v-btn
                v-if="subLabel !== 'Provinsi'"
                color="orange"
                v-show="vbtn"
                class="ml-3 mt-2"
                x-small
                dark
                @click="back()"
              >
                <v-icon dark x-small class="mr-1"> mdi-arrow-left </v-icon>
                Kembali Ke {{ backLabel }}</v-btn
              >
              <v-btn
                color="orange"
                v-show="LastBtn"
                class="ml-3 mt-2"
                x-small
                dark
                @click="LastBack()"
              >
                <v-icon dark x-small class="mr-1">mdi-arrow-left </v-icon
                >Kembali ke {{ backLabel }}</v-btn
              >
              <v-pagination
                circle
                v-model="page"
                :length="totalpage.totalPages"
                :total-visible="7"
                @input="choosePage(page)"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <!-- Dialog Tambah Country -->
      <v-dialog v-model="isTambahnegara" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>Tambah {{ label }}</p></v-card-title
          >
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Kode Negara</p>
                  <v-text-field
                    v-model="params.countryCode"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Nama Negara</p>
                  <v-text-field
                    v-model="params.countryName"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col lg="12" md="12" sm="12">
                  <p class="icd-text">Deskripsi Negara</p>
                  <v-text-field
                    v-model="params.countryDescription"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="addCountry"
              >Tambah</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog Edit Country -->
      <v-dialog v-model="iseditnegara" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>
              Edit {{ label }} {{ this.paramsEdit.countryName }}
            </p></v-card-title
          >
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Kode Negara</p>
                  <v-text-field
                    v-model="paramsEdit.countryCode"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Nama Negara</p>
                  <v-text-field
                    v-model="paramsEdit.countryName"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col lg="12" md="12" sm="12">
                  <p class="icd-text">Deskripsi Negara</p>
                  <v-text-field
                    v-model="paramsEdit.countryDescription"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="menuList[0].update === true"
              medium
              outlined
              color="primary"
              dark
              @click="editCountry"
              >Edit</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog Delete Country -->
      <v-dialog v-model="ishapusnegara" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>
              Yakin Hapus {{ label }} {{ this.paramsEdit.countryName }} ?
            </p></v-card-title
          >
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="deleteCountry"
              >Hapus</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Tambah Province -->
      <v-dialog v-model="isTambahprovinsi" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>Tambah {{ label }}</p></v-card-title
          >
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <p class="icd-text">ID Negara</p>
                  <v-text-field
                    v-model="params2.geoCountryId"
                    disabled
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Kode Provinsi</p>
                  <v-text-field
                    v-model="params2.provinceCode"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Nama Provinsi</p>
                  <v-text-field
                    v-model="params2.provinceName"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col lg="12" md="12" sm="12">
                  <p class="icd-text">Deskripsi Provinsi</p>
                  <v-text-field
                    v-model="params2.provinceDescription"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="addprovince"
              >Tambah</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog Delete Province -->
      <v-dialog v-model="ishapusprovinsi" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>
              Yakin Hapus {{ label }} {{ this.paramsEdit2.provinceName }} ?
            </p></v-card-title
          >
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="deleteProvince"
              >Hapus</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog Edit Province -->
      <v-dialog v-model="iseditprovinsi" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>
              Edit {{ label }} {{ this.paramsEdit2.provinceName }}
            </p></v-card-title
          >
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <p class="icd-text">Kode Negara</p>
                  <v-text-field
                    v-model="paramsEdit2.geoCountryId"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Kode Provinsi</p>
                  <v-text-field
                    v-model="paramsEdit2.provinceCode"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Nama Provinsi</p>
                  <v-text-field
                    v-model="paramsEdit2.provinceName"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col lg="12" md="12" sm="12">
                  <p class="icd-text">Deskripsi Provinsi</p>
                  <v-text-field
                    v-model="paramsEdit2.provinceDescription"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="menuList[0].update === true"
              medium
              outlined
              color="primary"
              dark
              @click="editProvince"
              >Edit</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Tambah City -->
      <v-dialog v-model="isTambahkota" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>Tambah {{ label }}</p></v-card-title
          >
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <p class="icd-text">ID Provinsi</p>
                  <v-text-field
                    v-model="params3.geoProvinceId"
                    disabled
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Kode Kota</p>
                  <v-text-field
                    v-model="params3.cityCode"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Nama Kota</p>
                  <v-text-field
                    v-model="params3.cityName"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col lg="12" md="12" sm="12">
                  <p class="icd-text">Deskripsi Kota</p>
                  <v-text-field
                    v-model="params3.cityDescription"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="addcity"
              >Tambah</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog Delete City -->
      <v-dialog v-model="ishapuskota" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>
              Yakin Hapus {{ label }} {{ paramsEdit3.cityName }} ?
            </p></v-card-title
          >
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="deletecity"
              >Hapus</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog Edit kota -->
      <v-dialog v-model="iseditkota" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>Edit {{ label }} {{ paramsEdit3.cityName }}</p></v-card-title
          >
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <p class="icd-text">ID Provinsi</p>
                  <v-text-field
                    v-model="paramsEdit3.geoProvinceId"
                    :rules="nameRules"
                    placeholder=""
                    required
                    disabled
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Kode Kota</p>
                  <v-text-field
                    v-model="paramsEdit3.cityCode"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Nama Kota</p>
                  <v-text-field
                    v-model="paramsEdit3.cityName"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col lg="12" md="12" sm="12">
                  <p class="icd-text">Deskripsi Kota</p>
                  <v-text-field
                    v-model="paramsEdit3.cityDescription"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="menuList[0].update === true"
              medium
              outlined
              color="primary"
              dark
              @click="editcity"
              >Edit</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Tambah District -->
      <v-dialog v-model="isTambahkecamatan" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>Tambah {{ label }}</p></v-card-title
          >
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <p class="icd-text">ID Kota</p>
                  <v-text-field
                    v-model="params4.geoCityId"
                    disabled
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Kode Kecamatan</p>
                  <v-text-field
                    v-model="params4.districtCode"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Nama Kecamatan</p>
                  <v-text-field
                    v-model="params4.districtName"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col lg="12" md="12" sm="12">
                  <p class="icd-text">Deskripsi Kecamatan</p>
                  <v-text-field
                    v-model="params4.districtDescription"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="adddistrict"
              >Tambah</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog Edit District -->
      <v-dialog v-model="iseditkecamatan" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>Edit {{ label }} {{ params4.districtName }}</p></v-card-title
          >
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <p class="icd-text">ID Kota</p>
                  <v-text-field
                    v-model="paramsEdit4.geoCityId"
                    disabled
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Kode Kecamatan</p>
                  <v-text-field
                    v-model="paramsEdit4.districtCode"
                    :rules="nameRules"
                    placeholder=""
                    required
                    disabled
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Nama Kecamatan</p>
                  <v-text-field
                    v-model="paramsEdit4.districtName"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col lg="12" md="12" sm="12">
                  <p class="icd-text">Deskripsi Kecamatan</p>
                  <v-text-field
                    v-model="paramsEdit4.districtDescription"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="menuList[0].update === true"
              medium
              outlined
              color="primary"
              dark
              @click="editdistrict"
              >Edit</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog Delete District -->
      <v-dialog v-model="ishapuskecamatan" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>
              Yakin Hapus {{ label }} {{ paramsEdit4.districtName }} ?
            </p></v-card-title
          >
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="deletedistrict"
              >Hapus</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Tambah SubDistrict -->
      <v-dialog v-model="isTambahkelurahan" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>Tambah {{ label }}</p></v-card-title
          >
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <p class="icd-text">ID Kecamatan</p>
                  <v-text-field
                    v-model="params5.geoDistrictId"
                    disabled
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Kode Kelurahan</p>
                  <v-text-field
                    v-model="params5.subDistrictCode"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Nama Kelurahan</p>
                  <v-text-field
                    v-model="params5.subDistrictName"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Kode Pos</p>
                  <v-text-field
                    v-model="params5.postalCode"
                    :rules="nameRules"
                    placeholder=""
                    type="number"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Deskripsi Kelurahan</p>
                  <v-text-field
                    v-model="params5.subDistrictDescription"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="addsubdistrict"
              >Tambah</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog Edit SubDistrict -->
      <v-dialog v-model="iseditkelurahan" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>
              Edit {{ label }} {{ paramsEdit5.subDistrictName }}
            </p></v-card-title
          >
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <p class="icd-text">ID Kecamatan</p>
                  <v-text-field
                    v-model="paramsEdit5.geoDistrictId"
                    disabled
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Kode Kelurahan</p>
                  <v-text-field
                    v-model="paramsEdit5.subDistrictCode"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Nama Kelurahan</p>
                  <v-text-field
                    v-model="paramsEdit5.subDistrictName"
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Kode Pos</p>
                  <v-text-field
                    v-model="paramsEdit5.postalCode"
                    :rules="nameRules"
                    placeholder=""
                    disabled
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Deskripsi Kelurahan</p>
                  <v-text-field
                    v-model="paramsEdit5.subDistrictDescription"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="menuList[0].update === true"
              medium
              outlined
              color="primary"
              dark
              @click="editsubdistrict"
              >Edit</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog Delete SubDistrict -->
      <v-dialog v-model="ishapuskelurahan" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search"
            ><p>
              Yakin Hapus {{ label }} {{ paramsEdit5.subDistrictName }} ?
            </p></v-card-title
          >
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              medium
              outlined
              color="primary"
              dark
              @click="deletesubdistrict"
              >Hapus</v-btn
            >
            <v-btn medium outlined color="error" dark @click="closeDialog"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import serviceGeo from "@/services/geo_services";
export default {
  components: {
    // Popup
  },
  data: () => ({
    login: {},
    menuList: {},
    LastBtn: false,
    backLabel: "",
    CityId: "",
    provinceId: "",
    countryId: "",
    itemgeo: "",
    search: "",
    masterGeo: [
      { nama: "Negara", id: "negara" },
      { nama: "Provinsi", id: "provinsi" },
      { nama: "Kota", id: "kota" },
      { nama: "Kecamatan", id: "kecamatan" },
      { nama: "Kelurahan", id: "kelurahan" },
    ],
    label: "Negara",
    subLabel: "Provinsi",
    idHapus: "",
    idEdit: "",
    geoitem: [],
    master: [],
    slot: [5, 10, 15, 25],
    pageNumber: 10,
    paginate: { name: "", page: 0, size: 10 },
    totalpage: [],
    pageCount: 0,
    page: 1,
    isTambahnegara: false,
    iseditnegara: false,
    ishapusnegara: false,
    isTambahprovinsi: false,
    ishapusprovinsi: false,
    iseditprovinsi: false,
    isTambahkota: false,
    ishapuskota: false,
    iseditkota: false,
    isTambahkecamatan: false,
    ishapuskecamatan: false,
    iseditkecamatan: false,
    isTambahkelurahan: false,
    ishapuskelurahan: false,
    iseditkelurahan: false,
    toEdit: false,
    vbtn: true,
    params: {
      geoCountryId: "string",
      countryCode: "",
      countryName: "",
      countryDescription: "",
    },
    paramsEdit: {
      geoCountryId: "string",
      countryCode: "",
      countryName: "",
      countryDescription: "",
    },
    params2: {
      geoProvinceId: "",
      geoCountryId: "",
      provinceCode: "",
      provinceName: "",
      provinceDescription: "",
    },
    paramsEdit2: {
      geoProvinceId: "",
      geoCountryId: "",
      provinceCode: "",
      provinceName: "",
      provinceDescription: "",
    },
    params3: {
      geoCityId: "",
      geoProvinceId: "",
      cityCode: "",
      cityName: "",
      cityDescription: "",
    },
    paramsEdit3: {
      geoCityId: "",
      geoProvinceId: "",
      cityCode: "",
      cityName: "",
      cityDescription: "",
    },
    params4: {
      geoDistrictId: "",
      geoCityId: "",
      districtCode: "",
      districtName: "",
      districtDescription: "",
    },
    paramsEdit4: {
      geoDistrictId: "",
      geoCityId: "",
      districtCode: "",
      districtName: "",
      districtDescription: "",
    },
    params5: {
      geoSubDistrictId: "",
      geoDistrictId: "",
      subDistrictCode: "",
      postalCode: "",
      subDistrictName: "",
      subDistrictDescription: "",
    },
    paramsEdit5: {
      geoSubDistrictId: "",
      geoDistrictId: "",
      subDistrictCode: "",
      postalCode: "",
      subDistrictName: "",
      subDistrictDescription: "",
    },
    nameRules: [(v) => !!v || "Name is required"],
  }),
  mounted() {
    this.getCountry();
    this.loginData();
    this.selecting();
  },
  watch: {},
  computed: {},
  methods: {
    loginData() {
      this.login = JSON.parse(window.localStorage.getItem("loginData"));
      console.log("login list", this.login.features);
      this.menuList = this.login.features.filter(
        (item) => item.featureName === "Master Geografi"
      );
      console.log(this.menuList);
    },
    headers() {
      return [
        // {
        //   text: "No",
        //   align: "start",
        //   sortable: true,
        //   value: "index",
        //   class: "primary--text judul",
        // },
        {
          text: "Code " + this.label,
          value: "code",
          class: "primary--text judul",
        },
        {
          text: "Nama " + this.label,
          // value: "icdDescription",
          value: "name",
          class: "primary--text judul",
        },
        {
          text: "Desksripsi " + this.label,
          value: "desc",
          // value: "icdName",
          class: "primary--text judul",
        },
        {
          text: "Aksi",
          value: "aksi",
          class: "primary--text judul",
        },
        // {
        //   text: "",
        //   value: "aksi2",
        //   class: "primary--text judul",
        // },
      ];
    },
    isTambah() {
      if (this.label === "Negara") {
        this.isTambahnegara = true;
      } else if (this.label === "Provinsi") {
        this.isTambahprovinsi = true;
      } else if (this.label === "Kota") {
        this.isTambahkota = true;
      } else if (this.label === "Kecamatan") {
        this.isTambahkecamatan = true;
      } else if (this.label === "Kelurahan") {
        this.isTambahkelurahan = true;
      }
    },
    selectedPage() {
      if (this.label === "Negara") {
        console.log(this.pageNumber);
        this.paginate.size = this.pageNumber;
        this.getCountry(this.paginate);
      } else if (this.label === "Provinsi") {
        console.log(this.pageNumber);
        this.paginate.size = this.pageNumber;
        this.getProvince(this.paginate);
      } else if (this.label === "Kota") {
        console.log(this.pageNumber);
        this.paginate.size = this.pageNumber;
        this.gettheCity(this.paginate);
      } else if (this.label === "Kecamatan") {
        console.log(this.pageNumber);
        this.paginate.size = this.pageNumber;
        this.getdistrict(this.paginate);
      } else if (this.label === "Kelurahan") {
        console.log(this.pageNumber);
        this.paginate.size = this.pageNumber;
        this.getSubdistrict(this.paginate);
      }
    },
    choosePage(page) {
      if (this.label === "Negara") {
        console.log(page);
        this.paginate.page = page - 1;
        this.paginate.name = this.search;
        console.log(this.paginate);
        this.getCountry(this.paginate);
      } else if (this.label === "Provinsi") {
         console.log(page);
        this.paginate.page = page - 1;
        this.paginate.name = this.search;
        console.log(this.paginate);
        this.getProvince(this.paginate);
      } else if (this.label === "Kota") {
         console.log(page);
        this.paginate.page = page - 1;
        this.paginate.name = this.search;
        console.log(this.paginate);
        this.gettheCity(this.paginate);
      } else if (this.label === "Kecamatan") {
         console.log(page);
        this.paginate.page = page - 1;
        this.paginate.name = this.search;
        console.log(this.paginate);
        this.getdistrict(this.paginate);
      } else if (this.label === "Kelurahan") {
         console.log(page);
        this.paginate.page = page - 1;
        this.paginate.name = this.search;
        console.log(this.paginate);
        this.getSubdistrict(this.paginate);
      }
    },
    onSearch() {
      if (this.label === "Negara") {
        this.paginate.page = 0;
        this.paginate.name = this.search;
        this.getCountry(this.paginate);
      } else if (this.label === "Provinsi") {
        this.paginate.page = 0;
        this.paginate.name = this.search;
        this.getProvince(this.paginate);
      } else if (this.label === "Kota") {
        this.paginate.page = 0;
        this.paginate.name = this.search;
        this.gettheCity(this.paginate);
      } else if (this.label === "Kecamatan") {
        this.paginate.page = 0;
        this.paginate.name = this.search;
        this.getdistrict(this.paginate);
      } else if (this.label === "Kelurahan") {
        this.paginate.page = 0;
        this.paginate.name = this.search;
        this.getSubdistrict(this.paginate);
      }
    },
    closeDialog() {
      if (this.label === "Negara") {
        this.isTambahnegara = false;
        this.ishapusnegara = false;
        this.iseditnegara = false;
        this.params.countryCode = "";
        this.params.countryName = "";
        this.params.countryDescription = "";
      } else if (this.label === "Provinsi") {
        this.isTambahprovinsi = false;
        this.ishapusprovinsi = false;
        this.iseditprovinsi = false;
        this.params2.provinceCode = "";
        this.params2.provinceName = "";
        this.params2.provinceDescription = "";
      } else if (this.label === "Kota") {
        this.isTambahkota = false;
        this.ishapuskota = false;
        this.iseditkota = false;
        this.params3.cityCode = "";
        this.params3.cityName = "";
        this.params3.cityDescription = "";
      } else if (this.label === "Kecamatan") {
        this.isTambahkecamatan = false;
        this.ishapuskecamatan = false;
        this.iseditkecamatan = false;
        this.params4.districtCode = "";
        this.params4.districtName = "";
        this.params4.districtDescription = "";
      } else if (this.label === "Kelurahan") {
        this.isTambahkelurahan = false;
        this.iseditkelurahan = false;
        this.ishapuskelurahan = false;
        this.params5.subDistrictCode = "";
        this.params5.subDistrictName = "";
        this.params5.postalCode = "";
        this.params5.subDistrictDescription = "";
      }
    },
    edit(item) {
      if (this.label === "Negara") {
        window.localStorage.setItem("id", item.id);
        this.idEdit = item.id;
        this.paramsEdit.countryCode = item.code;
        this.paramsEdit.countryName = item.name;
        this.paramsEdit.countryDescription = item.desc;
        this.iseditnegara = true;
      } else if (this.label === "Provinsi") {
        console.log(item);
        window.localStorage.setItem("id", item.id);
        this.idEdit = item.id;
        this.paramsEdit2.geoProvinceId = item.id;
        this.paramsEdit2.provinceCode = item.code;
        this.paramsEdit2.provinceName = item.name;
        this.paramsEdit2.provinceDescription = item.desc;
        this.iseditprovinsi = true;
      } else if (this.label === "Kota") {
        window.localStorage.setItem("id", item.id);
        this.idEdit = item.id;
        this.paramsEdit3.cityCode = item.code;
        this.paramsEdit3.cityName = item.name;
        this.paramsEdit3.cityDescription = item.desc;
        this.iseditkota = true;
      } else if (this.label === "Kecamatan") {
        window.localStorage.setItem("id", item.id);
        this.idEdit = item.id;
        this.paramsEdit4.districtCode = item.code;
        this.paramsEdit4.districtName = item.name;
        this.paramsEdit4.districtDescription = item.desc;
        this.iseditkecamatan = true;
      } else if (this.label === "Kelurahan") {
        window.localStorage.setItem("id", item.id);
        this.idEdit = item.id;
        this.paramsEdit5.subDistrictCode = item.code;
        this.paramsEdit5.subDistrictName = item.name;
        this.paramsEdit5.subDistrictDescription = item.desc;
        this.paramsEdit5.postalCode = item.postcode;
        this.iseditkelurahan = true;
      }
    },
    dels(item) {
      console.log("hahaha", item);
      if (this.label === "Negara") {
        window.localStorage.setItem("id", item.id);
        this.idHapus = item.id;
        this.paramsEdit.countryName = item.name;
        this.ishapusnegara = true;
      } else if (this.label === "Provinsi") {
        window.localStorage.setItem("id", item.id);
        this.idHapus = item.id;
        this.paramsEdit2.provinceName = item.name;
        this.ishapusprovinsi = true;
      } else if (this.label === "Kota") {
        window.localStorage.setItem("id", item.id);
        this.idHapus = item.id;
        this.paramsEdit3.cityName = item.name;
        this.ishapuskota = true;
      } else if (this.label === "Kecamatan") {
        window.localStorage.setItem("id", item.id);
        this.idHapus = item.id;
        this.paramsEdit4.districtName = item.name;
        this.ishapuskecamatan = true;
      } else if (this.label === "Kelurahan") {
        window.localStorage.setItem("id", item.id);
        this.idHapus = item.id;
        this.paramsEdit5.subDistrictName = item.name;
        this.ishapuskelurahan = true;
      }
    },
    back() {
      if (this.subLabel === "Kota") {
        this.getCountry();
        this.label = "Negara";
        this.subLabel = "Provinsi";
        this.search = "";
      } else if (this.subLabel === "Kecamatan") {
        this.backLabel = "Negara";
        this.label = "Provinsi";
        this.subLabel = "Kota";
        this.paginate.id = this.countryId
        this.getProvince(this.paginate);
        this.search = "";
      } else if (this.subLabel === "Kelurahan") {
        this.backLabel = "Provinsi";
        this.label = "Kota";
        this.subLabel = "Kecamatan";
        this.paginate.id = this.provinceId
         this.gettheCity(this.paginate);
        this.search = "";
      }
    },
    LastBack() {
      this.backLabel = "Kota";
      console.log(this.cityId);
      this.label = "Kecamatan";
      this.subLabel = "Kelurahan";
       this.paginate.id = this.cityId
      this.getdistrict(this.paginate);
      this.search = "";
      this.vbtn = true;
      this.LastBtn = false;
    },
    pindah(item) {
      if (this.subLabel === "Provinsi") {
        this.backLabel = "Negara";
        this.countryId = item.id;
        this.paginate["id"] = item.id
        this.paginate.page = 0
        this.getProvince(this.paginate);
        this.label = "Provinsi";
        this.subLabel = "Kota";
        this.search = "";
      } else if (this.subLabel === "Kota") {
        this.backLabel = "Provinsi";
        this.provinceId = item.id;
        console.log(this.provinceId);
        this.paginate["id"]= item.id
        this.paginate.page = 0
        this.gettheCity(this.paginate);
        this.label = "Kota";
        this.subLabel = "Kecamatan";
        this.search = "";
      } else if (this.subLabel === "Kecamatan") {
        this.backLabel = "Kota";
        this.cityId = item.id;
        console.log(this.cityId);
        this.paginate["id"]= item.id
        this.paginate.page = 0
        this.getdistrict(this.paginate);
        this.label = "Kecamatan";
        this.subLabel = "Kelurahan";
        this.search = "";
      } else if (this.subLabel === "Kelurahan") {
        this.backLabel = "Kecamatan";
        this.paginate["id"]= item.id
        this.paginate.page = 0
        this.getSubdistrict(this.paginate);
        this.label = "Kelurahan";
        this.subLabel = null;
        this.vbtn = false;
        this.LastBtn = true;
        this.search = "";
      }
    },
    getSubdistrict(value) {
      this.geoitem = [];
      serviceGeo.getSubDistrictPage(value).then((response) => {
        console.log("ini Kelurahan", response);
        this.params5.geoDistrictId = value.id;
        this.paramsEdit5.geoDistrictId = value.id;
        this.pageCount = response.data.payload.totalPages;
       this.totalpage = response.data.payload;
        this.master = response.data.payload.content;
        this.master.map((e) => {
          this.geoitem.push({
            id: e.geoSubDistrictId,
            code: e.subDistrictCode,
            name: e.subDistrictName,
            desc: e.subDistrictDescription,
            postcode: e.postalCode,
          });
        });
      });
    },
    getdistrict(value) {
      this.geoitem = [];
      serviceGeo.getDistrictPage(value).then((response) => {
        console.log("ini city", response);
        this.params4.geoCityId = value.id;
        this.paramsEdit4.geoCityId = value.id;
    this.pageCount = response.data.payload.totalPages;
    this.totalpage = response.data.payload;
        this.master = response.data.payload.content;
        console.log(this.master);
        this.master.map((e) => {
          this.geoitem.push({
            id: e.geoDistrictId,
            code: e.districtCode,
            name: e.districtName,
            desc: e.districtDescription,
          });
        });
      });
    },
    gettheCity(value) {
      this.geoitem = [];
      serviceGeo.getCityPage(value).then((response) => {
        console.log("ini city", response);
        this.params3.geoProvinceId = value.id;
        this.paramsEdit3.geoProvinceId = value.id;
       this.pageCount = response.data.payload.totalPages;
       this.totalpage = response.data.payload;
        this.master = response.data.payload.content;
        this.master.map((e) => {
          this.geoitem.push({
            id: e.geoCityId,
            code: e.cityCode,
            name: e.cityName,
            desc: e.cityDescription,
          });
        });
      });
    },
    getProvince(value) {
      this.geoitem = [];
      serviceGeo.getProvincePage(value).then((response) => {
        console.log("ini privince", response);
        this.params2.geoCountryId = value.id;
        this.paramsEdit2.geoCountryId = value.id;
    this.pageCount = response.data.payload.totalPages;
    this.totalpage = response.data.payload;
        this.master = response.data.payload.content;
        this.master.map((e) => {
          this.geoitem.push({
            id: e.geoProvinceId,
            code: e.provinceCode,
            name: e.provinceName,
            desc: e.provinceDescription,
          });
        });
      });
    },
    getCountry(value) {
      value = this.paginate;
      serviceGeo.getCountryPage(value).then((response) => {
        this.pageCount = response.data.payload.totalPages;
        this.totalpage = response.data.payload;
        console.log("ini country", response);
        this.geoitem = [];
        this.master = response.data.payload.content;
        this.master.map((e) => {
          this.geoitem.push({
            id: e.geoCountryId,
            code: e.countryCode,
            name: e.countryName,
            desc: e.countryDescription,
          });
        });
      });
    },
    addCountry() {
      if (this.params.countryCode == "" && this.params.countryName == "") {
        this.$toast.open({
          message: "Country Code dan Country Name Harus DI isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (
        this.params.countryCode == "" ||
        this.params.countryName == ""
      ) {
        this.$toast.open({
          message: "Country Code dan Country Name Harus DI isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        serviceGeo.addCountry(this.params).then((response) => {
          console.log(response);
          if (response.data.error === "Data duplikat") {
            this.$toast.open({
              message:
                "Nama Negara " + this.params.countryName + " Sudah Di Gunakan",
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else if (response.data.error !== "") {
            console.log(response.data.error);
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Negara Berhasil Ditambahkan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.params.countryCode = "";
            this.params.countryName = "";
            this.params.countryDescription = "";
            this.getCountry();
            this.isTambahnegara = false;
          }
        });
      }
    },
    deleteCountry() {
      serviceGeo
        .delCountry(this.idHapus)
        .then((response) => {
          console.log(response);
          if (response.data.errors.length > 0) {
            this.$toast.error(
              "hapus semua Provinsi, Kota, Kecamatan, Kelurahan terlebih dahulu",
              {
                type: "error",
                position: "top-right",
                duration: 4000,
              }
            );
            this.ishapusnegara = false;
          } else {
            this.$toast.open({
              message: "Negara Berhasil Di Hapus ",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            console.log(response);
            this.getCountry();
            this.ishapusnegara = false;
            // window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        });
    },
    editCountry() {
      if (this.paramsEdit.countryCode === "") {
        this.$toast.open({
          message: "isi code negara terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.paramsEdit.countryName === "") {
        this.$toast.open({
          message: "isi nama negara terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        let request = {
          id: this.idEdit,
          body: this.paramsEdit,
        };
        serviceGeo
          .editCountry(request)
          .then((response) => {
            console.log(response);
            if (response.data.error !== "") {
              this.$toast.open({
                message: response.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
            } else {
              this.$toast.open({
                message: "Edit Negara Berhasil Di Simpan ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              console.log(response);
              this.getCountry();
              this.iseditnegara = false;
              // window.location.reload();
            }
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
            console.log(error);
          });
      }
    },

    addprovince() {
      console.log("hahah");
      if (
        this.params2.provinceCode === "" &&
        this.params2.provinceName === ""
      ) {
        this.$toast.open({
          message: "Province Code dan Province Name Harus DI isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (
        this.params2.provinceCode === "" ||
        this.params2.provinceName === ""
      ) {
        this.$toast.open({
          message: "Province Code dan Province Name Harus DI isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        serviceGeo.addProvince(this.params2).then((response) => {
          console.log(response);
          if (response.data.error === "Data duplikat") {
            this.$toast.open({
              message:
                "Nama Province " +
                this.params2.provinceName +
                " Sudah Di Gunakan",
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else if (response.data.error !== "") {
            console.log(response.data.error);
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Provinsi Berhasil Ditambahkan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.params2.provinceCode = "";
            this.params2.provinceName = "";
            this.params2.provinceDescription = "";
            this.paginate.id = this.params2.geoCountryId
            this.getProvince(this.paginate);
            this.isTambahprovinsi = false;
          }
        });
      }
    },
    deleteProvince() {
      serviceGeo
        .delProvince(this.idHapus)
        .then((response) => {
          console.log(response);
          if (response.data.errors.length > 0) {
            this.$toast.error(
              "Hapus Semua Kota, Kecamatan, Kabupaten Terlebih Dahulu",
              {
                type: "error",
                position: "top-right",
                duration: 4000,
              }
            );
            this.ishapusprovinsi = false;
          } else if (response.data.error !== "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Negara Berhasil Di Hapus ",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            console.log(response);
            this.paginate.id = this.params2.geoCountryId
            this.getProvince(this.paginate);
            this.ishapusprovinsi = false;
            // window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        });
    },
    editProvince() {
      if (this.paramsEdit2.provinceCode === "") {
        this.$toast.open({
          message: "isi code provinsi terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.paramsEdit2.provinceName === "") {
        this.$toast.open({
          message: "isi nama provinsi terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        let request = {
          id: this.idEdit,
          body: this.paramsEdit2,
        };
        serviceGeo
          .editProvince(request)
          .then((response) => {
            console.log(response);
            if (response.data.error !== "") {
              this.$toast.open({
                message:
                  "Nama Provinsi " +
                  this.paramsEdit2.provinceName +
                  " Sudah Ada",
                position: "top-right",
                type: "error",
                duration: 6000,
              });
            } else {
              this.$toast.open({
                message: "Edit Provinsi Berhasil Di Simpan ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              console.log(response);
              this.paginate.id =this.params2.geoCountryId
              this.getProvince(this.paginate);
              this.iseditprovinsi = false;
              // window.location.reload();
            }
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
            console.log(error);
          });
      }
    },
    addcity() {
      console.log("hahah");
      if (this.params3.cityCode === "" && this.params3.cityName === "") {
        this.$toast.open({
          message: "City Code dan City Name Harus DI isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params3.cityCode === "" || this.params3.cityName === "") {
        this.$toast.open({
          message: "City Code dan City Name Harus DI isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        serviceGeo.addCity(this.params3).then((response) => {
          console.log(response);
          if (response.data.error === "Data duplikat") {
            this.$toast.open({
              message:
                "Nama Kota " + this.params3.cityName + " Sudah Di Gunakan",
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else if (response.data.error !== "") {
            console.log(response.data.error);
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Kota Berhasil Ditambahkan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.params3.cityCode = "";
            this.params3.cityName = "";
            this.params3.cityDescription = "";
            this.paginate.id = this.params3.geoProvinceId
            this.gettheCity(this.paginate);
            this.isTambahkota = false;
          }
        });
      }
    },
    editcity() {
      if (this.paramsEdit3.cityCode === "") {
        this.$toast.open({
          message: "isi code kota terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.paramsEdit3.cityName === "") {
        this.$toast.open({
          message: "isi nama kota terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        let request = {
          id: this.idEdit,
          body: this.paramsEdit3,
        };
        serviceGeo
          .editCity(request)
          .then((response) => {
            console.log(response);
            if (response.data.error !== "") {
              this.$toast.open({
                message:
                  "Nama Kota " + this.paramsEdit3.cityName + " Sudah Ada",
                position: "top-right",
                type: "error",
                duration: 6000,
              });
            } else {
              this.$toast.open({
                message: "Edit Kota Berhasil Di Simpan ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              console.log(response);
               this.paginate.id = this.params3.geoProvinceId
            this.gettheCity(this.paginate);
              this.iseditkota = false;
              // window.location.reload();
            }
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
            console.log(error);
          });
      }
    },
    deletecity() {
      serviceGeo
        .delCity(this.idHapus)
        .then((response) => {
          console.log(response);
          if (response.data.errors.length > 0) {
            this.$toast.error(
              "Hapus Semua Kecamatan,Kabupaten Terlebih Dahulu",
              {
                type: "error",
                position: "top-right",
                duration: 4000,
              }
            );
            this.ishapuskota = false;
          } else {
            this.$toast.open({
              message: "Kota Berhasil Di Hapus ",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            console.log(response);
            this.paginate.id = this.params3.geoProvinceId
            this.gettheCity(this.paginate);
            this.ishapuskota = false;
            // window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        });
    },

    adddistrict() {
      console.log("hahah");
      if (
        this.params4.districtCode === "" &&
        this.params4.districtName === ""
      ) {
        this.$toast.open({
          message: "City Code dan City Name Harus DI isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (
        this.params4.districtCode === "" ||
        this.params4.districtName === ""
      ) {
        this.$toast.open({
          message: "District Code dan District Name Harus DI isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        serviceGeo.addDistrict(this.params4).then((response) => {
          console.log(response);
          if (response.data.error === "Data duplikat") {
            this.$toast.open({
              message:
                "Nama Kecamatan " +
                this.params4.districtName +
                " Sudah Di Gunakan",
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else if (response.data.error !== "") {
            console.log(response.data.error);
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Kecamatan Berhasil Ditambahkan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.params4.districtCode = "";
            this.params4.districtName = "";
            this.params4.districtDescription = "";
            this.paginate.id = this.params4.geoCityId
            this.getdistrict(this.paginate);
            this.isTambahkecamatan = false;
          }
        });
      }
    },
    editdistrict() {
      if (this.paramsEdit4.districtCode === "") {
        this.$toast.open({
          message: "isi code kecamatan terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.paramsEdit4.districtName === "") {
        this.$toast.open({
          message: "isi nama kecamatan terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        let request = {
          id: this.idEdit,
          body: this.paramsEdit4,
        };
        serviceGeo
          .editDistrict(request)
          .then((response) => {
            console.log(response);
            if (response.data.error !== "") {
              this.$toast.open({
                message:
                  "Nama Kecamatan " +
                  this.paramsEdit4.districtName +
                  " Sudah Ada",
                position: "top-right",
                type: "error",
                duration: 6000,
              });
            } else {
              this.$toast.open({
                message: "Edit Kecamatan Berhasil Di Simpan ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              console.log(response);
              this.paginate.id = this.params4.geoCityId
              this.getdistrict(this.paginate);
              this.iseditkecamatan = false;
              // window.location.reload();
            }
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
            console.log(error);
          });
      }
    },
    deletedistrict() {
      serviceGeo
        .delDistrict(this.idHapus)
        .then((response) => {
          console.log(response);
          if (response.data.errors.length > 0) {
            this.$toast.error("Hapus Semua Kabupaten Terlebih Dahulu", {
              type: "error",
              position: "top-right",
              duration: 4000,
            });
            this.ishapuskecamatan = false;
          } else if (response.data.error !== "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Kecamatan Berhasil Di Hapus ",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            console.log(response);
            this.paginate.id = this.params4.geoCityId
            this.getdistrict(this.paginate);
            this.ishapuskecamatan = false;
            // window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        });
    },

    addsubdistrict() {
      console.log("hahah");
      if (
        this.params5.subDistrictName === "" &&
        this.params5.subDistrictCode === "" &&
        this.params5.postalCode === ""
      ) {
        this.$toast.open({
          message: "City Code dan City Name Harus DI isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (
        this.params5.subDistrictName === "" ||
        this.params5.subDistrictCode === ""
      ) {
        this.$toast.open({
          message: "District Code dan District Name Harus DI isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params5.postalCode === "") {
        this.$toast.open({
          message: "Kode Post harus di isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        serviceGeo.addSubDistrict(this.params5).then((response) => {
          console.log(response.data.error);
          if (response.data.error === "Data duplikat") {
            this.$toast.open({
              message:
                "Nama Kelurahan " +
                this.params5.subDistrictName +
                " Sudah Di Gunakan",
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else if (response.data.error !== "") {
            console.log(response.data.error);
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            console.log(response);
            this.$toast.open({
              message: "Kelurahan Berhasil Ditambahkan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            console.log(response.data.error != "");
            this.params5.subDistrictCode = "";
            this.params5.subDistrictName = "";
            this.params5.postalCode = "";
            this.params5.subDistrictDescription = "";
            this.paginate.id = this.params5.geoDistrictId
            this.getSubdistrict(this.paginate);
            this.isTambahkelurahan = false;
          }
        });
      }
    },
    editsubdistrict() {
      if (this.paramsEdit5.subDistrictCode === "") {
        this.$toast.open({
          message: "isi code kelurahan terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.paramsEdit5.subDistrictName === "") {
        this.$toast.open({
          message: "isi nama kelurahan terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        let request = {
          id: this.idEdit,
          body: this.paramsEdit5,
        };
        serviceGeo
          .editSubDistrict(request)
          .then((response) => {
            console.log(response);
            if (response.data.error !== "") {
              this.$toast.open({
                message:
                  "Nama Kelurahan " +
                  this.paramsEdit5.subDistrictName +
                  " Sudah Ada",
                position: "top-right",
                type: "error",
                duration: 6000,
              });
            } else {
              this.$toast.open({
                message: "Edit Kelurahan Berhasil Di Simpan ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              console.log(response);
               this.paginate.id = this.params5.geoDistrictId
            this.getSubdistrict(this.paginate);
              this.iseditkelurahan = false;
              // window.location.reload();
            }
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
            console.log(error);
          });
      }
    },
    deletesubdistrict() {
      serviceGeo
        .delSubDistrict(this.idHapus)
        .then((response) => {
          console.log(response);
          if (response.data.error !== "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Kecamatan Berhasil Di Hapus ",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            console.log(response);
            this.paginate.id = this.params5.geoDistrictId
            this.getSubdistrict(this.paginate);
            this.ishapuskelurahan = false;
            // window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/dashboard.scss";
.titleContent {
  font-weight: 500;
  line-height: 1rem !important;
}
.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
.background {
  background-color: #edf4fb;
}
.cardsearch {
  background-color: #ffffff;
  border-radius: 30px;
  width: 99%;
  margin-left: 2px;
}
.headerComponent {
  margin-left: 250px;
}
.datapasien {
  margin-left: 250px;
  margin-right: 120px;
}
.header {
  margin-left: 250px;
  /* margin-right: 250px; */
}
.poppuphead {
  text-align: center;
}
.v-card__title {
  font-size: 0.9rem !important;
  line-height: 1rem !important;
}
.instructionType {
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.search {
  margin-top: 15px;
  margin-left: 10px;
}
.detail {
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.nama {
  font-family: Nunito;
  font-size: 22px;
  text-align: left;
}
.icd-search {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.icd-text {
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
}
</style>
