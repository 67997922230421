<template>
  <v-app class="background">
    <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row class="ml-1">
          <v-col>
            <h5 class="mt-4">Otorisasi Pembatalan Konsultasi</h5>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers()"
          :items="listApproval"
          :items-per-page="5"
          :search="search"
          class="elevation-1"
          dense
        >
          <template v-slot:item.action="{ item }">
            <v-btn @click="detail(item)" x-small color="primary"
              >Lihat Detail</v-btn
            >
          </template>
           <template v-slot:item.DateTime="{ item }">
           <p class="my-3">{{item.schDate}}/{{item.startTime}}</p>
          </template>
        </v-data-table>
      </v-card>

      <v-dialog v-model="openDialog" persistent max-width="1000">
        <v-card>
          <v-card-text>
            <v-row>
              <h6 class="mt-3 ml-3">Setujui/Tolak</h6>
            </v-row>
            <v-divider></v-divider>
            <v-form>
              <v-card>
                <v-row class="ml-2">
                  <v-col cols="3">
                    <p>Nama Dokter</p>
                  </v-col>
                  <v-col cols="6">
                    <p>: {{cancelDetail.doctorFullName}}</p>
                  </v-col>
                </v-row>
                <v-row class="ml-2">
                  <v-col cols="3">
                    <p>Nama Pasien</p>
                  </v-col>
                  <v-col cols="6">
                    <p>: {{cancelDetail.patientFullName}}</p>
                  </v-col>
                </v-row>
                <v-row class="ml-2">
                  <v-col cols="3">
                    <p>No Tlp Pasien</p>
                  </v-col>
                  <v-col cols="6">
                    <p>: {{cancelDetail.patientMobilePhone}}</p>
                  </v-col>
                </v-row>
                <v-row class="ml-2">
                  <v-col cols="3">
                    <p>Jadwal Konsultasi</p>
                  </v-col>
                  <v-col cols="6">
                    <p>
                      : {{cancelDetail.schDate}} - Jam Mulai : {{cancelDetail.startTime}}, Jam Selesai
                      {{cancelDetail.endTime}}
                    </p>
                  </v-col>
                </v-row>
                <v-row class="ml-2">
                  <v-col cols="3">
                    <p>Alasan Pembatalan Konsultasi</p>
                  </v-col>
                  <v-col cols="6">
                    <p>:  {{cancelDetail.note}}</p>
                  </v-col>
                </v-row>
                <v-row class="ml-2">
                  <v-col cols="" align-self="">
                    <v-btn rounded small @click="closeDialog" color="error"
                      >Close</v-btn
                    >
                  </v-col>
                  <v-col cols="" class="margin">
                    <v-btn
                    v-if='menuList[0].create === true'
                      rounded
                      small
                      class="mr-2"
                      color="primary"
                      @click="reject"
                      >Tolak</v-btn
                    >
                    <v-btn v-if='menuList[0].create === true' rounded small color="success" @click="approve"
                      >Setujui</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>
<script>
import scheduleService from "@/services/DoctorSchedule_service";
// import DoctorServices from "@/services/doctor_services";
// const DEFAULT_TIME = "00:00:00";
export default {
  //  components: {
  //     VuetifyTimeSelect
  //   },
  data: () => ({
     menuList:{},
    login:{},
    cancelDetail:{},
    listApproval:[],
    kategori: [],
    openDialog: false,
    listJadwal: [
      {
        doctorFullName: "Dokter A",
        patientFullName: "Patient A",
        docMstMedicSubSpcId: "1234567890",
        date: "23 Juni 2021 / 12:30:01",
      },
      {
        doctorFullName: "Dokter B",
        patientFullName: "Patient B",
        docMstMedicSubSpcId: "1234567890",
        date: "23 Juni 2021 / 12:30:01",
      },
      {
        doctorFullName: "Dokter C",
        patientFullName: "Patient C",
        docMstMedicSubSpcId: "123456789483021930",
        date: "23 Juni 2021 / 12:30:01",
      },

      {
        doctorFullName: "Dokter D",
        patientFullName: "Patient D",
        docMstMedicSubSpcId: "1234567890243524358",
        date: "23 Juni 2021 / 12:30:01",
      },
    ],
  }),
  mounted() {
     this.loginData();
    this.listJadwal;
    this.getApprovalList();
  },
  methods: {
    loginData(){
      this.login = JSON.parse(window.localStorage.getItem("loginData"))
      console.log('login list',this.login.features);
        this.menuList = this.login.features.filter((item) =>
       item.featureName === 'Approval Pembatalan Janji Konsultasi'
      );
      console.log('pembatalan approval',this.menuList);
    },
    detail(items) {
      console.log(items);
      this.cancelDetail = items
      console.log(this.cancelDetail);
      this.id = items.docDoctorHospitalId;
      this.openDialog = true;
    },
    closeDialog() {
      this.openDialog = false;
    },
    approve() {
      let data = {
        aptAppointmentId: this.cancelDetail.aptAppointmentId,
        note: this.cancelDetail.note,
      };
      console.log(data);
      scheduleService.postApprove(data).then((res) => {
        console.log(res);
        if (res.data.error !== "") {
          this.$toast.open({
            message: res.data.error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          this.$toast.open({
            message: "Pembatalan berhasil disetujui",
            position: "top-right",
            type: "success",
            duration: 6000,
          });
          this.openDialog = false;
          this.listApproval = []
          this.getApprovalList();
        }
      });
    },
    reject() {
      let data = {
        aptAppointmentId: this.cancelDetail.aptAppointmentId,
        note: this.cancelDetail.note,
      };
      console.log(data);
      scheduleService.postReject(data).then((res) => {
        if (res.data.error !== "") {
          this.$toast.open({
            message: res.data.error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          this.$toast.open({
            message: "Pembatalan berhasil ditolak",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
          this.openDialog = false;
          this.listApproval = []
          this.getApprovalList();
        }
      });
    },
    getApprovalList() {
      scheduleService.getListApproval().then((res) => {
        if (res.data.error !== "") {
          this.$toast.open({
            message: res.data.error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          console.log("ress approval", res);
          // var data = res.data.payload;
          this.listApproval = res.data.payload.content
          console.log(this.listApproval);
        }
      });
    },
    headers() {
      return [
        {
          text: "Nama Dokter",
          value: "doctorFullName",
          class: "primary--text",
        },
        // {
        //   text: "No SIP",
        //   value: "docMstMedicSubSpcId",
        //   class: "primary--text",
        // },
        {
          text: "Nama Pasien",
          value: "patientFullName",
          class: "primary--text",
        },
        {
          text: "Date/Time",
          value: "DateTime",
          class: "primary--text",
        },
         {
          text: "Note",
          value: "note",
          class: "primary--text",
        },
        {
          text: "Action",
          value: "action",
          class: "primary--text",
        },
      ];
    },
  },
  computed: {},
};
</script>


<style scoped>
.i {
  margin-top: -40px;
}
.o {
  margin-top: -20px;
}
.margin {
  margin-left: 450px;
}
.background {
  background-color: #edf4fb;
}
.judul {
  font-family: Nunito;
  font-weight: bold;
  font-size: 18px;
  color: #313131;
}
.text-card {
  font-family: Nunito;
  font-weight: 600;
  font-size: 12px;
  color: #313131;
}
.btn-card {
  font-family: Nunito;
  font-weight: 600;
  font-size: 12px;
  margin-top: -25px;
}
.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
</style>