import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://api.klinisia.id/";
// const API_URL2 = "https://api.klinisia.id/";

class paymentServices {
  async getFinence(param) {
    return await axios.get(
      `${API_URL}ksi/master/payment/financial-institution/search?institutionName=${param.name}&page=${param.page}&size=${param.size}&sortDirection=ASC&sortField=institutionCode`,
      { headers: headers() },
    );
  }
  async editFinence(request) {
    return await axios.put(
      `${API_URL}ksi/master/payment/financial-institution/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async addFinence(params) {
    return await axios.post(
      `${API_URL}ksi/master/payment/financial-institution`,
      params,
      {
        headers: headers(),
      },
    );
  }
  async delFinence(data) {
    return await axios.delete(
      `${API_URL}ksi/master/payment/financial-institution/${data}`,
      {
        headers: headers(),
      },
    );
  }
  async getChannel(data) {
    return await axios.get(
      `${API_URL}ksi/master/payment/channel/search?payFinancialInstitutionId=${data.id}&channelName=${data.name}&page=${data.page}&size=${data.size}&sortDirection=ASC&sortField=channelCode`,
      { headers: headers() },
    );
  }
  async addChannel(params) {
    return await axios.post(`${API_URL}ksi/master/payment/channel`, params, {
      headers: headers(),
    });
  }
  async editChannel(request) {
    return await axios.put(
      `${API_URL}ksi/master/payment/channel/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async delChannel(data) {
    return await axios.delete(`${API_URL}ksi/master/payment/channel/${data}`, {
      headers: headers(),
    });
  }
  async getGatewayChannel(data) {
    return await axios.get(
      `${API_URL}ksi/master/payment/gateway-channel/search?payChannelId=${data.id}&gatewayChannelName=${data.name}&page=${data.page}&size=${data.size}&sortDirection=ASC&sortField=gatewayChannelCode`,
      { headers: headers() },
    );
  }
  async addGatewayChannel(params) {
    return await axios.post(
      `${API_URL}ksi/master/payment/gateway-channel`,
      params,
      {
        headers: headers(),
      },
    );
  }
  async editGatewayChannel(request) {
    return await axios.put(
      `${API_URL}ksi/master/payment/gateway-channel/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async delGatewayChannel(data) {
    return await axios.delete(
      `${API_URL}ksi/master/payment/gateway-channel/${data}`,
      {
        headers: headers(),
      },
    );
  }
}
export default new paymentServices();
