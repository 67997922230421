<template>
  <v-app class="background">
   <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row class="ml-2 mr-2">
          <v-col cols="4">
            <p dark class="mt-5 ml-2 icd-search" rounded small width="170">
              Cari {{ label }}
            </p>
          </v-col>
          <v-col sm="12" md="6" lg="6" class="search">
            <v-text-field
              label="search"
              append-icon="mdi-magnify"
              outlined
              class="mt-3"
              v-model="search"
              @keyup.enter="onSearch"
              rounded
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container>
      <!-- Chip -->
      <v-card class="cardStyle">
        <v-row>
          <v-col>
            <div>
              <v-row>
                <v-col cols="2">
                  <v-btn
                    v-if="menuList[0].create === true"
                    color="success"
                    class="ma-3 pa-3"
                    x-small
                    dense
                    @click="oenNewGroup"
                    ><v-icon dark x-small> mdi-plus </v-icon>Tambah
                    {{ label }}</v-btn
                  >
                </v-col>
                <v-col cols="2" class="mb-6">
                  <v-select
                    hide-details=""
                    @change="selectedPage()"
                    v-model="pageNumber"
                    :items="slot"
                    outlined
                    dense
                    class="text"
                  ></v-select>
                </v-col>
              </v-row>

              <v-data-table
                :headers="headers()"
                :items="itemgroup"
                :search="search"
                :server-items-length="pageCount"
                hide-default-footer
                class="elevation-1"
                dense
              >
                <template v-slot:item.aksi="{ item }">
                  <td>
                    <v-btn
                      color="primary"
                      dark
                      x-small
                      class="mr-2"
                      @click="edit(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-pencil </v-icon>
                      <p v-if="menuList[0].update === true" class="mt-4">
                        Ubah
                      </p>
                      <p v-if="menuList[0].update === false" class="mt-4">
                        Lihat
                      </p>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      v-if="menuList[0].delete === true"
                      color="error"
                      x-small
                      dark
                      class="mr-2"
                      @click="delt(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-delete </v-icon
                      >Hapus</v-btn
                    >
                  </td>
                  <td>
                    <v-btn
                      color=""
                      x-small
                      v-show="vbtn"
                      dark
                      @click="pindah(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-eye </v-icon>Lihat
                      {{ subLabel }}</v-btn
                    >
                  </td>
                </template>
              </v-data-table>
              <v-row>
                <v-col cols="2">
                  <v-btn
                    v-if="label == 'Items'"
                    color="orange"
                    v-show="vbtn2"
                    class="ml-3 mt-3"
                    x-small
                    dark
                    @click="back()"
                  >
                    <v-icon dark x-small class="mr-1"> mdi-arrow-left </v-icon>
                    Kembali Ke Group</v-btn
                  ></v-col
                >
                <v-col cols="6">
                  <v-pagination
                    circle
                    v-model="page"
                    :length="totalpage.totalPages"
                    :total-visible="7"
                    @input="choosePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <!-- Dialog newGroup -->
      <v-dialog v-model="newGroupDIalog" persistent max-width="830">
        <v-card>
          <v-card-title class="icd-search">
            <p>Add New Group</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Group Code</p>
                  <v-text-field
                    v-model="addGroup.diagnosticGroupCode"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Group Name</p>
                  <v-text-field
                    v-model="addGroup.diagnosticGroupName"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="icd-text">Group Description</p>
                  <v-text-field
                    v-model="addGroup.diagnosticGroupDescription"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="success" dark @click="newGroup"
              >Tambah</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelNewGroup"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog Edit newGroup -->
      <v-dialog v-model="dialogEdit" persistent max-width="830">
        <v-card>
          <v-card-title class="icd-search">
            <p>Edit Group {{ paramsEditGroup.diagnosticGroupName }}</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Group Code</p>
                  <v-text-field
                    v-model="paramsEditGroup.diagnosticGroupCode"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Group Name</p>
                  <v-text-field
                    v-model="paramsEditGroup.diagnosticGroupName"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="icd-text">Group Description</p>
                  <v-text-field
                    v-model="paramsEditGroup.diagnosticGroupDescription"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="menuList[0].update === true"
              medium
              outlined
              color="success"
              dark
              @click="editGroup"
              >Simpan Edit</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelNewGroup"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog Hapus -->
      <v-dialog v-model="dels" persistent max-width="830">
        <v-card>
          <v-row class="ma-4 pa-2">
            <v-col>
              <p class="icd-text">
                Yakin Hapus Group
                {{ this.paramsEditGroup.diagnosticGroupName }} ?
              </p>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="deleteConfirm"
              >Hapus</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelDelete"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog new Item -->
      <v-dialog v-model="newItemDIalog" persistent max-width="830">
        <v-card>
          <v-card-title class="icd-search">
            <p>Add New Item</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col>
                  <p class="icd-text">Group ID</p>
                  <v-text-field
                    v-model="addNewItemonGroup.radDiagnosticGroupId"
                    dense
                    disabled
                    outlined
                    @input="selectingData"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Item Code</p>
                  <v-text-field
                    v-model="addNewItemonGroup.diagnosticItemCode"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Item Name</p>
                  <v-text-field
                    v-model="addNewItemonGroup.diagnosticItemName"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="12" md="12" sm="12">
                  <p class="icd-text">Item Description</p>
                  <v-text-field
                    v-model="addNewItemonGroup.diagnosticItemDescription"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="success" dark @click="newItem"
              >Tambah</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelNewItem"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog edit Item -->
      <v-dialog v-model="editItemDIalog" persistent max-width="830">
        <v-card>
          <v-card-title class="icd-search">
            <p>Edit Item</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Item Code</p>
                  <v-text-field
                    v-model="editItem.diagnosticItemCode"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Item Name</p>
                  <v-text-field
                    v-model="editItem.diagnosticItemName"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="12" md="12" sm="12">
                  <p class="icd-text">Item Description</p>
                  <v-text-field
                    v-model="editItem.diagnosticItemDescription"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="menuList[0].update === true"
              medium
              outlined
              color="success"
              dark
              @click="editConfirm"
              >Edit</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelEdit"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Hapus -->
      <v-dialog v-model="delsItem" persistent max-width="830">
        <v-card>
          <v-row class="ma-4 pa-2">
            <v-col>
              <p class="icd-text">
                Yakin Hapus Item Name
                {{ this.paramsEditGroup.diagnosticItemName }} ?
              </p>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="remove"
              >Hapus</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelDelete"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import serviceDiagnostik from "@/services/diagnostik_service";
// import IcdService from "@/services/ICD_service";
export default {
  data: () => ({
    menuList: {},
    login: {},
    itemgroup: [],
    search: "",
    // page: 1,
    vbtn: true,
    vbtn2: false,
    slot: [5, 10, 15, 20],
    totalpage: [],
    pageNumber: 10,
    page: 1,
    paginate: { name: "", page: 0, size: 10 },
    label: "Group",
    subLabel: "Items",
    delsItem: false,
    dialogEdit: false,
    diagnostikheader: [],
    listdetail: "",
    pageCount: 0,
    editItemDIalog: false,
    dels: false,
    diagnostikSelect: null,
    diagnostikItemSelect: null,
    tool: false,
    diagnostikId: "",
    itemdelete: "",
    diagnostikitem: [],
    newGroupDIalog: false,
    newItemDIalog: false,
    addNewDiagnostikItemId: "",
    nameRules: [(v) => !!v || "Name is required"],
    addGroup: {
      radDiagnosticGroupId: "string",
      diagnosticGroupOrder: 0,
      diagnosticGroupCode: "",
      diagnosticGroupName: "",
      diagnosticGroupDescription: "",
      createdBy: "string",
      createdOn: Date,
      updatedBy: "string",
      updatedOn: Date,
      active: true,
      deleted: true,
    },
    addNewItemonGroup: {
      radDiagnosticItemId: "",
      radDiagnosticGroupId: "",
      diagnosticItemOrder: 0,
      diagnosticItemCode: "",
      diagnosticItemName: "",
      diagnosticItemDescription: "",
      createdBy: "string",
      createdOn: Date,
      updatedBy: "string",
      updatedOn: Date,
      active: true,
      deleted: true,
    },
    paramsEditGroup: {
      radDiagnosticGroupId: "",
      diagnosticGroupOrder: 0,
      diagnosticGroupCode: "",
      diagnosticGroupName: "",
      diagnosticGroupDescription: "",
      createdBy: "",
      createdOn: Date,
      updatedBy: "",
      updatedOn: Date,
      active: true,
      deleted: true,
    },
    editItem: {
      radDiagnosticItemId: "",
      radDiagnosticGroupId: "",
      diagnosticItemOrder: 0,
      diagnosticItemCode: "",
      diagnosticItemName: "",
      diagnosticItemDescription: "",
      createdBy: "",
      createdOn: Date,
      updatedBy: "",
      updatedOn: Date,
      active: true,
      deleted: true,
    },
  }),
  mounted() {
    this.getdiagnostik();
    this.loginData();
  },
  methods: {
    onSearch() {
      if (this.label === "Group") {
        this.paginate.page = 0;
        // this.paginate.size = 100000000
        this.paginate.name = this.search;
        console.log(this.paginate);
        this.getdiagnostik(this.paginate);
      } else if (this.label === "Items") {
        this.paginate.page = 0;
        // this.paginate.size = 100000000
        this.paginate.name = this.search;
        console.log(this.paginate);
        this.getItems(this.paginate);
      }
    },

    selectedPage() {
      if (this.label === "Group") {
        console.log(this.pageNumber);
        this.paginate.size = this.pageNumber;
        this.getdiagnostik(this.paginate);
      } else if (this.label === "Items") {
        console.log(this.pageNumber);
        this.paginate.size = this.pageNumber;
        this.getItems(this.paginate);
      }
    },
    loginData() {
      this.login = JSON.parse(window.localStorage.getItem("loginData"));
      console.log("login list", this.login.features);
      this.menuList = this.login.features.filter(
        (item) => item.featureName === "Master Diagnostic"
      );
      console.log(this.menuList);
    },
    delt(item) {
      console.log("hahaha", item);
      if (this.label === "Group") {
        window.localStorage.setItem("id", item.radDiagnosticGroupId);
        this.groupID = item.radDiagnosticGroupId;
        this.paramsEditGroup.diagnosticGroupCode = item.diagnosticGroupCode;
        this.paramsEditGroup.diagnosticGroupName = item.diagnosticGroupName;
        this.dels = true;
      } else if (this.label === "Items") {
        window.localStorage.setItem("id", item.radDiagnosticItemId);
        this.groupID = item.radDiagnosticItemId;
        this.editItem.radDiagnosticGroupId = item.radDiagnosticGroupId;
        this.paramsEditGroup.diagnosticItemCode = item.diagnosticItemCode;
        this.paramsEditGroup.diagnosticItemName = item.diagnosticItemName;
        this.delsItem = true;
      }
    },
    edit(item) {
      if (this.label === "Group") {
        console.log(item);
        this.groupID = item.radDiagnosticGroupId;
        this.dialogEdit = true;
        this.paramsEditGroup.radDiagnosticGroupId = item.radDiagnosticGroupId;
        this.paramsEditGroup.diagnosticGroupCode = item.diagnosticGroupCode;
        this.paramsEditGroup.diagnosticGroupName = item.diagnosticGroupName;
        this.paramsEditGroup.diagnosticGroupDescription =
          item.diagnosticGroupDescription;
      } else if (this.label === "Items") {
        console.log(item);
        this.groupID = item.radDiagnosticItemId;
        this.editItem.radDiagnosticGroupId = item.radDiagnosticGroupId;
        this.editItem.radDiagnosticItemId = item.radDiagnosticItemId;
        this.editItem.diagnosticItemCode = item.diagnosticItemCode;
        this.editItem.diagnosticItemName = item.diagnosticItemName;
        this.editItem.diagnosticItemDescription =
          item.diagnosticItemDescription;
        this.editItemDIalog = true;
      }
    },
    pindah(item) {
      if (this.subLabel === "Items") {
        this.label = "Items";
        this.paginate["id"] = item.radDiagnosticGroupId;
        console.log(this.paginate);
        this.getItems(this.paginate);
        this.addNewItemonGroup.radDiagnosticGroupId = item.radDiagnosticGroupId;
        this.vbtn = false;
        this.vbtn2 = true;
      }
    },
    back() {
      this.label = "Group";
      this.vbtn = true;
      this.itemgroup = [];
      this.getdiagnostik(this.paginate);
      this.vbtn2 = false;
    },
    headers() {
      if (this.label === "Group") {
        return [
          // {
          //   text: "No",
          //   align: "start",
          //   sortable: true,
          //   value: "index",
          //   class: "primary--text judul",
          // },
          {
            text: "Code Group ",
            value: "diagnosticGroupCode",
            class: "primary--text judul",
          },
          {
            text: "Nama Group",
            // value: "icdDescription",
            value: "diagnosticGroupName",
            class: "primary--text judul",
          },
          {
            text: "Desksripsi Group",
            value: "diagnosticGroupDescription",
            // value: "icdName",
            class: "primary--text judul",
          },
          {
            text: "Aksi",
            value: "aksi",
            class: "primary--text judul",
          },
        ];
      } else if (this.label === "Items") {
        return [
          // {
          //   text: "No",
          //   align: "start",
          //   sortable: true,
          //   value: "index",
          //   class: "primary--text judul",
          // },
          {
            text: "Code Item ",
            value: "diagnosticItemCode",
            class: "primary--text judul",
          },
          {
            text: "Nama Item",
            // value: "icdDescription",
            value: "diagnosticItemName",
            class: "primary--text judul",
          },
          {
            text: "Desksripsi Item",
            value: "diagnosticItemDescription",
            // value: "icdName",
            class: "primary--text judul",
          },
          {
            text: "Aksi",
            value: "aksi",
            class: "primary--text judul",
          },
        ];
      }
    },
    choosePage(page) {
      if (this.label === "Group") {
        console.log(page);
        this.paginate.page = page - 1;
        console.log(this.paginate);
        this.getdiagnostik(this.paginate);
      } else if (this.label === "Items") {
        console.log(page);
        this.paginate.page = page - 1;
        console.log(this.paginate);
        this.getItems(this.paginate);
      }
    },
    getItems(value) {
      serviceDiagnostik.getdiagnosticitem(value).then((response) => {
        console.log(response);
        this.itemgroup = [];
        this.itemgroup = response.data.payload.content;
        this.pageCount = response.data.payload.totalPages;
        this.totalpage = response.data.payload;
      });
    },
    getdiagnostik(value) {
      value = this.paginate;
      serviceDiagnostik.getdiagnostic(value).then((response) => {
        console.log(response);
        this.itemgroup = [];
        this.itemgroup = response.data.payload.content;
        this.pageCount = response.data.payload.totalPages;
        this.totalpage = response.data.payload;
      });
    },
    deleteConfirm() {
      console.log(this.groupID);
      serviceDiagnostik
        .deleteGroup(this.groupID)
        .then((response) => {
          console.log(response);
          if (response.data.errors.length > 0) {
            this.$toast.error(
              // response.data.errors[0].field +
              //   " " +
              //   response.data.errors[0].message,

              "Hapus Semua Item Terlebih Dahulu",
              {
                type: "error",
                position: "top-right",
                duration: 4000,
              }
            );
            this.dels = false;
          } else {
            this.$toast.open({
              message: "Group Radiologi Berhasil Di Hapus ",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            console.log(response);
            this.dels = false;
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        });
    },

    cancelDelete() {
      this.dels = false;
    },
    deleteGroup() {
      this.dels = true;
    },
    remove() {
      console.log(this.groupID);
      serviceDiagnostik
        .deleteItem(this.groupID)
        .then((response) => {
          console.log(response);
          if (response.data.errors.length > 0) {
            this.$toast.error(
              // response.data.errors[0].field +
              //   " " +
              //   response.data.errors[0].message,

              "Hapus Semua Item Terlebih Dahulu",
              {
                type: "error",
                position: "top-right",
                duration: 4000,
              }
            );
            this.dels = false;
          } else if (response.data.error !== "") {
            this.$toast.error(response.data.error, {
              type: "error",
              position: "top-right",
              duration: 4000,
            });
          } else {
            this.$toast.open({
              message: "Item Radiologi Berhasil Di Hapus ",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.getItems(this.paginate);
            this.delsItem = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editConfirm() {
      if (this.editItem.diagnosticItemCode === "") {
        this.$toast.open({
          message: "isi item code terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.editItem.diagnosticItemName === "") {
        this.$toast.open({
          message: "isi item name terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        console.log(this.editItem);
        let data = {
          id: this.editItem.radDiagnosticItemId,
          item: this.editItem,
        };
        serviceDiagnostik
          .editItemDetail(data)
          .then((response) => {
            console.log(response);
            if (response.data.error !== "") {
              this.$toast.open({
                message: response.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
            } else {
              this.$toast.open({
                message: "Item Radiologi Berhasil Di Edit ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              this.getItems(this.paginate);
              this.editItemDIalog = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    cancelEdit() {
      this.editItemDIalog = false;
    },

    newItem() {
      console.log(this.addNewItemonGroup);
      if (
        this.addNewItemonGroup.diagnosticItemCode === "" ||
        this.addNewItemonGroup.diagnosticItemName == ""
      ) {
        this.$toast.open({
          message: "radiologiItemCode dan radiologiItemName Harus Di Isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        serviceDiagnostik
          .addNewItem(this.addNewItemonGroup)
          .then((response) => {
            console.log(response);
            if (response.data.error != "") {
              this.$toast.open({
                message: response.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
              this.newItemDIalog = false;
            } else {
              this.$toast.open({
                message: "Item Baru Berhasil Di Tambahkan ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              this.getItems(this.paginate);
              this.newItemDIalog = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    cancelNewItem() {
      this.addNewItemonGroup.diagnosticItemCode = "";
      this.addNewItemonGroup.diagnosticItemName = "";
      this.addNewItemonGroup.diagnosticItemDescription = "";
      this.newItemDIalog = false;
    },
    openNewitem() {
      this.newItemDIalog = true;
    },
    cancelNewGroup() {
      //  this.paramsEditGroup.radDiagnosticGroupId = "";
      this.addGroup.diagnosticGroupCode = "";
      this.addGroup.diagnosticGroupName = "";
      this.addGroup.diagnosticGroupDescription = "";
      this.newGroupDIalog = false;
      this.dialogEdit = false;
    },
    oenNewGroup() {
      if (this.label === "Group") {
        this.newGroupDIalog = true;
      } else if (this.label === "Items") {
        this.newItemDIalog = true;
      }
    },
    newGroup() {
      if (
        this.addGroup.diagnosticGroupCode === "" ||
        this.addGroup.diagnosticGroupName === ""
      ) {
        this.$toast.open({
          message: "Radiologi GroupCode dan Radiologi GroupCode Harus Di Isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        serviceDiagnostik
          .addNewGroup(this.addGroup)
          .then((response) => {
            if (response.data.error != "") {
              this.$toast.open({
                message: response.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
              this.newGroupDIalog = false;
            } else {
              this.$toast.open({
                message: "Group Radiologi Baru Berhasil Di Tambahkan ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              this.getdiagnostik();
              console.log(response);
              this.newGroupDIalog = false;
              window.location.reload();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    editGroup() {
      if (this.paramsEditGroup.diagnosticGroupCode === "") {
        this.$toast.open({
          message: "isi group code terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.paramsEditGroup.diagnosticGroupName === "") {
        this.$toast.open({
          message: "isi group name terlebih dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        let request = {
          id: this.groupID,
          body: this.paramsEditGroup,
        };
        serviceDiagnostik
          .editGroup(request)
          .then((response) => {
            console.log(response);
            if (response.data.error != "") {
              this.$toast.open({
                message: response.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
              this.dialogEdit = false;
            } else {
              this.$toast.open({
                message: "Edit Group Radiology Berhasil Di Simpan ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              // this.getdiagnostik();
              console.log(response);
              this.dialogEdit = false;
              window.location.reload();
            }
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
            console.log(error);
          });
      }
    },

    // selectingData() {
    //   console.log("Value: ", this.diagnostikItemSelect);
    //   this.addNewDiagnostikItemId = this.diagnostikheader.filter((item) =>
    //     this.diagnostikItemSelect.includes(item.diagnosticGroupName)
    //   );
    //   console.log(this.addNewDiagnostikItemId);
    //   this.addNewItemonGroup.radDiagnosticGroupId = this.addNewDiagnostikItemId[0].radDiagnosticGroupId;
    //   console.log(this.addNewItemonGroup);
    // },
    // selectingHapus() {
    //   console.log("Value: ", this.diagnostikItemSelect);
    //   this.addNewDiagnostikItemId = this.diagnostikheader.filter((item) =>
    //     this.diagnostikItemSelect.includes(item.diagnosticGroupName)
    //   );
    //   console.log(this.addNewDiagnostikItemId[0].radDiagnosticGroupId);
    //   this.itemdelete = this.addNewDiagnostikItemId[0].radDiagnosticGroupId;
    // },

    // selecting() {
    //   console.log("Value: ", this.diagnostikSelect);
    //   this.diagnostikId = this.diagnostikheader.filter((item) =>
    //     this.diagnostikSelect.includes(item.diagnosticGroupName)
    //   );
    //   console.log(this.diagnostikId);
    //   this.listdetail = this.diagnostikId[0].radDiagnosticGroupId;
    //   console.log(this.listdetail);
    //   serviceDiagnostik.getdiagnosticitem(this.listdetail).then((response) => {
    //     console.log(response);
    //     this.diagnostikitem = response.data.payload.content;
    //   });
    // },
    // directToBank() {
    //   console.log("Value: ", this.diagnostikSelect);
    // },
  },
};
</script>

<style>
.background {
  background-color: #edf4fb;
}
.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
.text {
  font-family: Nunito;
  font-size: 11px;
  font-weight: 600;
  height: 2px !important;
  width: 80px !important;
}
</style>