<template>
  <v-app class="background">
    <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row class="ml-1">
          <v-col>
            <h4 class="mt-4">Jadwal Dokter</h4>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ml-1">
          <v-col>
            <v-form>
              <h5>Data Search</h5>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Search</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="search"
                    append-icon="mdi-magnify"
                    outlined
                    v-model="search"
                    @keyup.enter="onSearch"
                    rounded
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Kategori</p>
                </v-col>
                <v-col cols="6">
                  <v-select
                    class
                    v-model="category"
                    :items="kategori"
                    item-value="docMstMedicSpecializationId"
                    item-text="specializationName"
                    dense
                    outlined
                    placeholder="Pilih Kategori"
                    @input="selectingCategory"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row class="ml-1">
          <v-col cols="1" class="mt-1">
            <v-btn small @click="Reset" color="error">Reset</v-btn>
          </v-col>
          <v-col cols="2" class="mb-6">
            <v-select
              hide-details=""
              @change="selectedPage()"
              v-model="pageNumber"
              :items="slot"
              outlined
              dense
              class="text"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers()"
          :items="filterDoctor"
          hide-default-footer
          :server-items-length="pageCount"
          :search="search"
          class="elevation-1"
          dense
        >
          <template v-slot:item.action="{ item }">
            <td>
              <v-btn x-small color="primary" @click="detail(item)"
                >Set Schedule</v-btn
              >
            </td>
          </template>
        </v-data-table>

        <v-row>
          <v-col>
            <v-pagination
              circle
              v-model="page"
              :length="totalpage.totalPages"
              :total-visible="7"
              @input="choosePage(page)"
            ></v-pagination>
          </v-col>
        </v-row>

        <v-dialog v-model="openDialog" persistent max-width="1500">
          <v-card>
            <v-card-title>
              <p>SET SCHEDULE DOKTER</p>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="ml-1">
                <v-col cols="4" class="mr-2">
                  <v-row>
                    <p>Nama Lengkap</p>
                  </v-row>
                  <v-row>
                    <v-text-field
                      disabled
                      v-model="doctorDetail.fullName"
                      outlined
                      dense
                    ></v-text-field>
                  </v-row>
                </v-col>
                <v-col offset-md="2" class="mr-2">
                  <v-row>
                    <p>Email</p>
                  </v-row>
                  <v-row>
                    <v-text-field
                      disabled
                      v-model="doctorDetail.email"
                      outlined
                      dense
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="ml-1">
                <v-col cols="4" class="mr-2">
                  <v-row>
                    <p>Biaya Konsultasi / 30menit</p>
                  </v-row>
                  <v-row>
                    <v-text-field
                      disabled
                      v-model="doctorDetail.consultationFee"
                      outlined
                      dense
                    ></v-text-field>
                  </v-row>
                </v-col>
                
                <v-col offset-md="2" class="mr-2">
                  <v-row>
                    <p>Harga Coret</p>
                  </v-row>
                  <v-row>
                    <v-text-field
                      disabled
                      v-model="doctorDetail.consultationSpecialFee"
                      outlined
                      dense
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="ml-1">
                <v-col cols="4" class="mr-2">
                  <v-row>
                    <p>No Handphone</p>
                  </v-row>
                  <v-row>
                    <v-text-field
                      disabled
                      v-model="doctorDetail.mobilePhone"
                      outlined
                      dense
                    ></v-text-field>
                  </v-row>
                </v-col>
                <v-col offset-md="2" class="mr-2">
                  <v-row>
                    <p>Hospital Employe Number</p>
                  </v-row>
                  <v-row>
                    <v-text-field
                      disabled
                      v-model="doctorDetail.hospitalEmployeeNumber"
                      outlined
                      dense
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="ml-1">
                <v-col cols="10" class="mr-2">
                  <v-row>
                    <p>No SIP</p>
                  </v-row>
                  <v-row>
                    <v-text-field
                      disabled
                      v-model="doctorDetail.sipNumber"
                      outlined
                      dense
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row justify="center">
                <v-date-picker
                  v-model="date"
                  @click:date="pickDate"
                  :min="new Date().toISOString().substr(0, 10)"
                  width="500"
                ></v-date-picker>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <!-- <v-btn elevation="2" @click="yes"></v-btn> -->
                <!-- <v-col cols="10"> </v-col>
                <v-col cols="2" class="ma-0 pa-0">
                  
                </v-col> -->
                <v-btn
                  v-if="menuList[0].create === true"
                  color="primary"
                  class="mt-4"
                  style="margin-right: 100px"
                  absolute
                  right
                  @click="addNew"
                  >New</v-btn
                >
                <v-col
                  cols="10"
                  v-for="(tanggal, index) in dateSchedule"
                  :key="index"
                >
                  <v-card>
                    <v-card-text>
                      <!-- <v-text-field v-model="dateCheddule.schDate"></v-text-field> -->
                      <v-row>
                        <v-col class="mt-5">
                          <p>{{ tanggal.schDate }}</p>
                          <!-- <p>jam</p>
                          <p>{{ tanggal.startTime }}</p> -->
                        </v-col>
                        <v-col>
                          <v-text-field
                            class="ml-3 mr-3 pt-3"
                            v-model="tanggal.startTime"
                            label="Jam Mulai"
                            dense
                            readonly
                            outlined
                          ></v-text-field>
                          <!-- <v-menu
                          ref="menu"
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="tanggal.startTime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="tanggal.startTime"
                              label="Picker in menu"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="menu2"
                            v-model="tanggal.startTime"
                            use-seconds
                            full-width
                            @click:second="$refs.menu[index].save(tanggal.startTime)"
                          ></v-time-picker>
                            </v-menu>-->
                        </v-col>
                        <v-col>
                          <v-text-field
                            class="ml-3 mr-3 pt-3"
                            v-model="tanggal.endTime"
                            readonly
                            label="Jam Selesai"
                            dense
                            outlined
                          ></v-text-field>
                          <!-- <v-menu
                           :ref="'dialog' + k"
                          v-model="modal2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="tanggal.endTime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="tanggal.endTime"
                              label="Picker in menu"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                           
                            v-model="tanggal.endTime"
                            use-seconds
                            
                          >
                          <v-spacer></v-spacer>
                            <v-btn flat color="primary" @click="$refs['dialog' + index][0].save(tanggal.endTime)">OK</v-btn>
                            </v-time-picker>
                            </v-menu>-->
                        </v-col>
                      </v-row>
                      <!-- <v-btn @click="addnew">a</v-btn> -->
                      <!-- <v-btn @click="SaveTime(tanggal)">Simpan</v-btn>
                        <v-btn @click="Cancel(tanggal)">Reset</v-btn> -->
                    </v-card-text>
                  </v-card>
                  <!-- <v-col>
                    <v-btn color="primary" @click="addNew">new</v-btn>
                  </v-col> -->
                </v-col>
                <v-col cols="10">
                  <v-card v-show="New">
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <p>{{ newData.schDate }}</p>
                        </v-col>
                        <v-col>
                          <v-select
                            class="ml-3 mr-3 pt-3"
                            v-model="newData.startTime"
                            :items="timeSelect"
                            label="Jam Mulai"
                            dense
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col>
                          <v-select
                            class="ml-3 mr-3 pt-3"
                            v-model="newData.endTime"
                            :items="timeSelect"
                            label="Jam Selesai"
                            dense
                            outlined
                          ></v-select>
                        </v-col>
                      </v-row>

                      <v-btn
                        v-if="menuList[0].create === true"
                        @click="SavingTime"
                        >Simpan</v-btn
                      >
                      <v-btn @click="Cancel">Reset</v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-btn color="primary" @click="Close">Close</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import scheduleService from "@/services/DoctorSchedule_service";
import DoctorServices from "@/services/doctor_services";
// import { VuetifyTimeSelect } from 'vuetify-time-select'
const DEFAULT_TIME = "00:00:00";
export default {
  //  components: {
  //     VuetifyTimeSelect
  //   },
  data: () => ({
    menuList: {},
    login: {},
    range: 0,
    categoryType: null,
    newData: [],
    New: false,
    timeSelect: [],
    dateSelect: "",
    time: DEFAULT_TIME,
    menu2: false,
    modal2: false,
    id: "",
    detailsch: false,
    dateSchedule: [],
    doctorDetail: [],
    done: [false, false, false],
    mouseMonth: null,
    date: new Date().toISOString().substr(0, 10),
    openDialog: false,
    doctorList: [],
    kategori: [],
    category: null,
    search: "",
    slot: [5, 10, 15, 20],
    pageNumber: 10,
    paginate: { page: 0, size: 10, id: "", name: "" },
    totalpage: [],
    pageCount: 0,
    page: 1,
  }),
  mounted() {
    this.loginData();
    this.getSpecialization();
    this.getDoctorList();
  },
  methods: {
    loginData() {
      console.log("asdasdasd");
      this.login = JSON.parse(window.localStorage.getItem("loginData"));
      console.log("login list", this.login.features);
      this.menuList = this.login.features.filter(
        (item) => item.featureName === "Doctor Maintenance"
      );
      console.log(this.menuList);
    },
    Reset() {
      this.category = "";
      this.search = "";
      this.paginate.id = "";
      this.paginate.name = "";
      this.getDoctorList(this.paginate);
    },
    Cancel() {
      console.log("cancel");
      this.newData = [];
      this.New = false;
    },
    // selectingCategory(){

    // },
    SavingTime() {
      console.log(
        "inischadule",
        this.dateSchedule[this.dateSchedule.length - 1]
      );
      console.log("newdata", this.newData);
      if (this.newData.startTime === "" || this.newData.endTime === "") {
        this.$toast.open({
          message: "Jam Mulai dan Jam Selesai Harus di Isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        if (this.dateSchedule.length === 0) {
          if (this.newData.startTime > this.newData.endTime) {
            this.$toast.open({
              message: "Jam Mulai Harus < Dari Jam selesai",
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            var re = {
              id: this.id,
              body: this.newData,
            };
            scheduleService
              .postTime(re)
              .then((res) => {
                console.log(res);
                if (res.data.error != "") {
                  this.$toast.open({
                    message: res.data.error,
                    position: "top-right",
                    type: "error",
                    duration: 6000,
                  });
                } else {
                  this.dateSchedule.push(this.newData);
                  this.newData = [];
                  this.New = false;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          var akhir = this.dateSchedule[this.dateSchedule.length - 1].endTime;
          var awal = this.dateSchedule[this.dateSchedule.length - 1].startTime;
          // console.log("ini angka akhir", akhir);
          // if (akhir > this.newData.startTime) {
          //   this.$toast.open({
          //     message: "Jam Mulai Harus > Dari Jam Schedule Sebelumnya",
          //     position: "top-right",
          //     type: "error",
          //     duration: 6000,
          //   });
          // } else {
          if (akhir < this.newData.startTime && awal > this.newData.startTime) {
            this.$toast.open({
              message: "Jam Mulai Harus > Dari Jam Schedule Sebelumnya",
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            var request = {
              id: this.id,
              body: this.newData,
            };
            scheduleService
              .postTime(request)
              .then((res) => {
                console.log(res);
                if (
                  res.data.error.includes(
                    "clash with DocDoctorHptSchDateTime(docDoctorHptSchDateTimeId"
                  )
                ) {
                  this.$toast.open({
                    message: "waktu sudah di gunakan",
                    position: "top-right",
                    type: "error",
                    duration: 6000,
                  });
                } else {
                  this.dateSchedule.push(this.newData);
                  this.newData = [];
                  this.New = false;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }
    },
    // Cancel(tanggal) {
    //   console.log(this.dateSchedule.indexOf(tanggal));
    // },
    Close() {
      window.location.reload();
    },
    SaveTime(tanggal) {
      console.log(tanggal);
      // var request = {
      //   id: this.id,
      //   body: tanggal,
      // };
      // scheduleService
      //   .postTime(request)
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => console.log(err));
    },
    timeList() {
      // var time = 0
      //   var today = new Date();
      // var todayDate = ('0' + today.getDate()).slice(-2)
      // var todayMonth = ('0' + (today.getMonth()+1)).slice(-2)
      // var dating =today.getFullYear()+'-'+todayMonth+'-'+todayDate;
      // if(this.dateSelect === dating){
      // time = today.getHours()}
      // for (let i = this.range; i <= 24; i++) {
      //   if (i < 10) {
      //     this.timeSelect.push(`0${i}:00:00`);
      //   } else {
      //     this.timeSelect.push(`${i}:00:00`);
      //   }
      // }
    },
    addNew() {
      var Empty = {
        schDate: this.dateSelect,
        startTime: "",
        endTime: "",
      };
      // this.dateSchedule.push(Empty);
      this.newData = Empty;
      this.New = true;
      console.log(this.dateSchedule);
    },
    yes() {
      console.log(this.time);
    },
    getDate() {
      console.log(this.dateSchedule);
    },
    pickDate(date) {
      this.timeSelect = [];
      this.dateSelect = date;

      // var today = new Date();
      // var todayDate = ('0' + today.getDate()).slice(-2)
      // var todayMonth = ('0' + (today.getMonth()+1)).slice(-2)
      // // console.log(todayDate);
      // var dating =today.getFullYear()+'-'+todayMonth+'-'+todayDate;

      // if(date === dating){
      //   console.log("true");
      //   this.range = today.getHours() + 1
      // }else{console.log("false")
      // this.range = 0
      // }

      // for (let i = this.range; i <= 24; i++) {
      //   if (i < 10) {
      //     this.timeSelect.push(`0${i}:00:00`);
      //   } else {
      //     this.timeSelect.push(`${i}:00:00`);
      //   }
      // }
      this.dateSchedule = [];
      let getSch = {
        DocId: this.id,
        date: date,
      };
      // console.log('trial service',getSch.date);
      // console.log(date);
      scheduleService.getSchdule(getSch).then((res) => {
        this.timeList();
        console.log(res);
        var data = res.data.payload;
        console.log(data.length);
        if (data.length === 0) {
          var Empty = {
            schDate: date,
            startTime: "",
            endTime: "",
          };
          // this.dateSchedule.push(Empty);
          this.newData = Empty;
          this.New = true;
          console.log(this.dateSchedule);
          var today = new Date();
          var todayDate = ("0" + today.getDate()).slice(-2);
          var todayMonth = ("0" + (today.getMonth() + 1)).slice(-2);
          // console.log(todayDate);
          var dating = today.getFullYear() + "-" + todayMonth + "-" + todayDate;

          if (date === dating) {
            console.log("true");
            this.range = today.getHours() + 1;
          } else {
            console.log("false");
            this.range = 0;
          }

          for (let i = this.range; i <= 24; i++) {
            if (i < 10) {
              this.timeSelect.push(`0${i}:00:00`);
              this.timeSelect.push(`0${i}:30:00`);
            } else if (i >= 10 && i < 24) {
              this.timeSelect.push(`${i}:00:00`);
              this.timeSelect.push(`${i}:30:00`);
            } else {
              this.timeSelect.push(`${i}:00:00`);
            }
          }
        } else {
          this.newData = [];
          data.map((e) => {
            this.dateSchedule.push({
              schDate: e.schDate,
              startTime: e.startTime,
              endTime: e.endTime,
            });
          });
          this.New = false;
          today = new Date();
          todayDate = ("0" + today.getDate()).slice(-2);
          todayMonth = ("0" + (today.getMonth() + 1)).slice(-2);
          // console.log(todayDate);
          dating = today.getFullYear() + "-" + todayMonth + "-" + todayDate;

          if (date === dating) {
            console.log("true");
            this.range = today.getHours() + 1;
          } else {
            console.log("false");
            this.range = 0;
          }

          for (let i = this.range; i <= 24; i++) {
            if (i < 10) {
              this.timeSelect.push(`0${i}:00:00`);
              this.timeSelect.push(`0${i}:30:00`);
            } else if (i >= 10 && i < 24) {
              this.timeSelect.push(`${i}:00:00`);
              this.timeSelect.push(`${i}:30:00`);
            } else {
              this.timeSelect.push(`${i}:00:00`);
            }
          }
        }
      });
      //    var data = {
      //      "schDate": date,
      // "startTime": "",
      // "endTime": ""
      //    }
      //    this.dateSchedule.push(data)
      this.detailsch = true;
      // this.$set(this.done, 0, true)

      // alert(`You have just double clicked the following date: ${date}`)
    },
    detail(item) {
      this.id = item.docDoctorHospitalId;
      console.log(item);
      DoctorServices.getDoctorById(item.docDoctorHospitalId).then((res) => {
        console.log(res);
        this.doctorDetail = res.data.payload;
        console.log(this.doctorDetail);
      });
      this.openDialog = true;
    },
    selectingCategory() {
      console.log(this.category);
      this.paginate.id = this.category;
      this.getDoctorList(this.paginate);
      //  console.log(this.doctorList);
      //  for (let i = 0; i < this.doctorList.length; i++) {
      //    if (this.doctorList[i].docMstMedicSpecializationId === this.category) {
      //      console.log(this.doctorList[i]);
      //    }
      //  }
    },
    choosePage(page) {
      console.log(page);
      this.paginate.page = page - 1;
      console.log(this.paginate);
      this.getDoctorList(this.paginate);
    },
    onSearch() {
      this.paginate.page = 0;
      // this.paginate.size = 100000000
      this.paginate.name = this.search;
      console.log(this.paginate);
      this.getDoctorList(this.paginate);
    },

    selectedPage() {
      console.log(this.pageNumber);
      this.paginate.size = this.pageNumber;
      this.getDoctorList(this.paginate);
    },

    getDoctorList(value) {
      value = this.paginate;
      scheduleService
        .getListDoctor(value)
        .then((res) => {
          console.log(res);
          this.pageCount = res.data.payload.totalPages;
          this.totalpage = res.data.payload;
          this.doctorList = res.data.payload.content;
        })
        .catch((err) => console.log(err));
    },
    headers() {
      return [
        {
          text: "Nama Dokter",
          value: "fullName",
          class: "primary--text",
        },
        {
          text: "No SIP",
          value: "sipNumber",
          class: "primary--text",
        },
        {
          text: "Hospital Emoloyee Number",
          value: "hospitalEmployeeNumber",
          class: "primary--text",
        },
        {
          text: "Email",
          value: "email",
          class: "primary--text",
        },
        {
          text: "No Telp",
          value: "mobilePhone",
          class: "primary--text",
        },
        {
          text: "Kategori",
          value: "specializationName",
          class: "primary--text",
        },
        {
          text: "action",
          value: "action",
          class: "primary--text",
        },
      ];
    },
    getSpecialization() {
      scheduleService
        .getSPecialization()
        .then((res) => {
          console.log(res);
          this.kategori = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    filterDoctor() {
      return this.doctorList.filter((i) => {
        return (
          !this.category || i.docMstMedicSpecializationId === this.category
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/dashboard.scss";
.background {
  background-color: #edf4fb;
}
.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
</style>
