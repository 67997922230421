<template>
  <v-app class="background">
    <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row class="ml-1">
          <v-col>
            <h5 class="mt-4">USER MAINTENANCE</h5>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ml-1">
          <v-col>
            <v-form>
              <h5>Data Search</h5>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Nama</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="search"
                    append-icon="mdi-magnify"
                    outlined
                    v-model="search"
                    @keyup.enter="onSearch"
                    
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <p>Jenis Role</p>
                </v-col>
                <v-col cols="6">
                  <v-select
                    class
                    v-model="roleTypeSelect"
                    :items="typeRoles.map((e) => e.userTypeCode)"
                    label="choose role"
                    dense
                    outlined
                    @input="selectingRoleType"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-show="pilihRs">
                <v-col cols="3" class="mt-2">
                  <p>Rumah Sakit</p>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :items="rs"
                    label="Pilih Rumah Sakit"
                    outlined
                    v-model="hospitalId"
                    item-text="name"
                    item-value="value"
                    @change="getByHospitalID"
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="6" class align="right">
                  <v-btn
                    medium
                    outlined
                    color="error"
                    dark
                    class="mr-2"
                    @click="Reset"
                    >Reset</v-btn
                  >
                  <!-- <v-btn medium outlined color="success" dark>Search</v-btn> -->
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row class="mr-5">
          <v-col cols="2">
            <v-btn
              v-if="menuList[0].create === true"
              class="ml-3 mt-1"
              small
              color="success"
              to="/AddControlAdmin"
              dark
              ><v-icon dark small> mdi-plus </v-icon> Tambah User</v-btn
            >
          </v-col>
          <v-col cols="2" class="mb-6">
            <v-select
              hide-details=""
              @change="selectedPage()"
              v-model="pageNumber"
              :items="slot"
              outlined
              dense
              class="text"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-3">
          <v-col>
            <div>
              <v-data-table
                :headers="headers()"
                :items="newadminList"
                :search="search"
                :server-items-length="pageCount"
                class="elevation-1"
                hide-default-footer
                dense
              >
                <template v-slot:[`item.active`]="{ item }">
                  <p v-if="item.active === true">aktif</p>
                  <p v-else>tidak aktif</p>
                </template>
                <template v-slot:[`item.aksi`]="{ item }">
                  <td>
                    <v-btn
                      color="primary"
                      dark
                      x-small
                      class="mr-2"
                      @click="edit(item)"
                    >
                      <v-icon dark x-small class="mr-1">mdi-pencil</v-icon>
                      <p v-if="menuList[0].update === true" class="mt-4">
                        Ubah
                      </p>
                      <p v-if="menuList[0].update === false" class="mt-4">
                        lihat
                      </p>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      v-if="menuList[0].delete === true"
                      color="error"
                      x-small
                      dark
                      class="mr-2"
                      @click="dels(item)"
                    >
                      <v-icon dark x-small class="mr-1" @click="dels(item)"
                        >mdi-delete</v-icon
                      >Hapus
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
              <v-row>
                <v-col>
                  <v-pagination
                    circle
                    v-model="page"
                    :length="totalpage.totalPages"
                    :total-visible="7"
                    @input="choosePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-dialog v-model="dialogEdit" persistent max-width="850">
          <v-card>
            <v-card-title class="icd-search">
              <p>Edit User</p>
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col lg="6" md="6" sm="12">
                    <p class="icd-text">Nama</p>
                    <v-text-field
                      v-model="param.fullName"
                      :rules="rules"
                      placeholder
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col lg="6" md="6" sm="12">
                    <p class="icd-text">Jenis Role</p>
                    <v-select
                      readonly
                      class
                      v-model="roleTypeSelectEdit"
                      :items="typeRoles.map((e) => e.userTypeCode)"
                      dense
                      outlined
                      @input="selectingRoleTypeEdit"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6" md="6" sm="12">
                    <p class="icd-text">Role</p>
                    <v-select
                      class
                      v-model="roleSelect"
                      :items="Roles.map((e) => e.roleName)"
                      label="choose role"
                      dense
                      outlined
                      @input="selectingRole"
                    ></v-select>
                  </v-col>
                  <v-col
                    lg="6"
                    md="6"
                    sm="12"
                    v-if="roleTypeSelectEdit !== 'ksi'"
                  >
                    <p class="icd-text">Nama Rumah Sakit</p>
                    <v-text-field
                      class
                      v-model="namaRs"
                      dense
                      readonly
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="pb-4 mb-3">
              <v-spacer></v-spacer>
              <v-btn
                v-if="menuList[0].update === true"
                medium
                outlined
                color="success"
                dark
                @click="editGroup"
                >Simpan Edit</v-btn
              >
              <v-btn medium outlined color="error" dark @click="closeEditDialog"
                >Batal</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dhapus" persistent max-width="1500">
          <v-card>
            <v-card-title
              >Yakin Hapus Role {{ this.namadelete }} ?</v-card-title
            >
            <v-card-actions class="pb-4 mb-3">
              <v-spacer></v-spacer>
              <v-btn medium outlined color="success" dark @click="hapus"
                >Hapus</v-btn
              >
              <v-btn medium outlined color="error" dark @click="closeDialog"
                >Batal</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import adminService from "@/services/adminList_servicce";
export default {
  components: {
    // Popup
  },
  data: () => ({
    menuList: {},
    login: {},
    newadminList: [],
    Roles: [],
    user: [],
    roleSelect: "",
    roleTypeSelectEdit: "",
    idhapus: "",
    typeRoles: [],
    roleTypeSelect: "",
    namaRs: "",
    namadelete: "",
    slot: [5, 10, 15, 20],
    pageNumber: 10,
    paginate: {hptid:"", userid:"", name: "", page: 0, size: 10 },
    paginate2: { id: "", page: 0, size: 10 },
    totalpage: [],
    pageCount: 0,
    page: 1,
    dhapus: false,
    search: "",
    hospitalId: "",
    role: ["Admin", "Ksi"],
    rs: [],
    dialogEdit: false,
    adminList: [],
    param: {
      secUserId: "",
      mobilePhone: "",
      fullName: "",
      gender: "male",
      userRoles: [
        {
          secRoleId: "string",
          default: true,
        },
      ],
    },
    pilihRs: true,
    rules: [(v) => !!v || "Name is required"],
  }),
  mounted() {
    this.loginData();
    this.listAdmin();
    this.RoleTypeList();
    this.hospitalAll();
  },
  watch: {},
  computed: {},
  methods: {
    loginData() {
      this.login = JSON.parse(window.localStorage.getItem("loginData"));
      console.log("login list", this.login.features);
      this.menuList = this.login.features.filter(
        (item) => item.featureName === "User Maintenance"
      );
      console.log(this.menuList);
    },
    onSearch() {
      this.paginate.page = 0;
      // this.paginate.size = 100000000
      this.paginate.name = this.search;
      console.log(this.paginate);
      this.listAdmin(this.paginate);
    },
    selectedPage() {
      console.log(this.pageNumber);
      this.paginate.size = this.pageNumber;
      this.listAdmin(this.paginate);
    },

    getByHospitalID() {
      console.log(this.hospitalId);
      this.paginate["hptid"] = this.hospitalId;
      this.listAdmin(this.paginate)
      // value = this.paginate;
      // adminService.getAdminByHospital(value).then((res) => {
      //   if (res.data.error !== "") {
      //     this.$toast.open({
      //       message: "nama harus terisi",
      //       position: "top-right",
      //       type: "error",
      //       duration: 6000,
      //     });
      //   } else {
      //     console.log(res);

      //     this.newadminList = [];
      //     this.pageCount = res.data.payload.totalPages;
      //     this.totalpage = res.data.payload;
      //     this.newadminList = res.data.payload.content;
      //   }
      // });
    },
    hospitalAll() {
      adminService.getHospitalAll().then((res) => {
        console.log(res);
        if (res.data.error !== "") {
          this.$toast.open({
            message: "nama harus terisi",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          let rSakit = [];
          rSakit = res.data.payload;
          rSakit.map((e) => {
            this.rs.push({
              value: e.hptHospitalId,
              name: e.hospitalName,
            });
          });
        }
      });
    },
    closeEditDialog() {
      this.dialogEdit = false;
      this.param = {
        secUserId: "",
        mobilePhone: "",
        fullName: "",
        gender: "male",
        userRoles: [
          {
            secRoleId: "string",
            default: true,
          },
        ],
      };
    },
    Reset() {
      window.location.reload();
    },
    editGroup() {
      console.log(this.param);
      if (this.param.fullName === "") {
        this.$toast.open({
          message: "nama harus terisi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        console.log(this.param);
        adminService
          .EditAdmin(this.param)
          .then((res) => {
            console.log(res), (this.dialogEdit = false);
            this.param = {
              secUserId: "",
              mobilePhone: "",
              fullName: "",
              gender: "male",
              userRoles: [
                {
                  secRoleId: "",
                  default: true,
                },
              ],
            };
            (this.roleTypeSelectEdit = ""), (this.roleSelect = "");
            this.listAdmin();
            // window.location.reload()
            // setTimeout(function(){location.reload()}, 3000);
          })
          .catch((err) => console.log(err));
      }
    },
    selectingRole() {
      console.log(this.roleSelect);
      var data = this.Roles.filter((item) => this.roleSelect === item.roleName);
      this.param.userRoles[0].secRoleId = data[0].secRoleId;
      console.log(this.param);
    },
    selectingRoleTypeEdit() {
      adminService
        .getRole(this.roleTypeSelectEdit)
        .then((res) => {
          console.log(res);
          this.Roles = res.data.payload.content;
        })
        .catch((err) => console.log(err));
    },
    selectingRoleType() {
      console.log(this.roleTypeSelect);
      if (this.roleTypeSelect === "ksi") {
        this.pilihRs = false;
      } else {
        this.pilihRs = true;
      }
      // let adlist = [];
      // for (let i = 0; i < this.adminList.length; i++) {
      //   adlist.push(this.adminList[i]);
      // }
      this.paginate.userid = this.roleTypeSelect;
      this.paginate.size = 10
      // value = this.paginate;
      console.log(this.paginate);
      this.listAdmin(this.paginate)
      // adminService.listAdmin(value).then((res) => {
      //   // console.log(res);
      //   let data = res.data.payload.content;
      //   this.pageCount = res.data.payload.totalPages;
      //   this.totalpage = res.data.payload;
      //   this.newadminList = [];
      //   data.map((e) => {
      //     // this.user = adlist.filter(function (item) {
      //     //   if (item.secUserId === e.secUserId) return item.roleName;
      //     // });
      //     this.newadminList.push({
      //       fullName: e.fullName,
      //       userTypeCode: e.userTypeCode,
      //       roleName: e.roleName,
      //       email: e.email,
      //       active: e.active,
      //       secUserId: e.secUserId,
      //       secRoleId: e.secRoleId,
      //     });
      //   });
      //   console.log("hasil", this.adminList);
      // });
    },
    RoleTypeList() {
      adminService
        .getTypeRole()
        .then((res) => {
          console.log(res);
          this.typeRoles = res.data.payload.content;
        })
        .catch((err) => console.log(err));
    },
    dels(item) {
      console.log("haha item", item);
      this.idhapus = item.secUserId;
      this.namadelete = item.fullName;
      this.dhapus = true;
    },
    hapus() {
      adminService
        .deleteAdmin(this.idhapus)
        .then((res) => {
          console.log(res);
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Data Berhasil Dihapus",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            window.location.reload();
          }
        })
        .catch((err) => console.log(err));
    },
    listAdmin(value) {
      value = this.paginate;
      this.newadminList = [];
      adminService
        .getAdmin(value)
        .then((res) => {
          console.log(res);
          this.pageCount = res.data.payload.totalPages;
          this.totalpage = res.data.payload;
          this.adminList = res.data.payload.content;
          for (let i = 0; i < this.adminList.length; i++) {
            this.newadminList.push(this.adminList[i]);
          }
        })
        .catch((err) => console.log(err));
    },
    headers() {
      return [
        // {
        //   text: "No",
        //   align: "start",
        //   sortable: true,
        //   value: "index",
        //   class: "primary--text judul",
        // },
        {
          text: "Nama",
          value: "fullName",
          class: "primary--text",
        },
        {
          text: "Jenis Role",
          // value: "icdDescription",
          value: "userTypeCode",
          class: "primary--text",
        },
        {
          text: "Role",
          value: "roleName",
          // value: "icdName",
          class: "primary--text",
        },
        {
          text: "Email",
          value: "email",
          // value: "icdName",
          class: "primary--text",
        },
        // {
        //   text: "Rumah Sakit",
        //   value: "rs",
        //   // value: "icdName",
        //   class: "primary--text",
        // },
        {
          text: "Status",
          value: "active",
          // value: "icdName",
          class: "primary--text",
        },
        {
          text: "Aksi",
          value: "aksi",
          class: "primary--text judul",
        },
        // {
        //   text: "",
        //   value: "aksi2",
        //   class: "primary--text judul",
        // },
      ];
    },
    edit(item) {
      console.log(item);
      this.dialogEdit = true;
      adminService
        .getDetailAdmin(item.secUserId)
        .then((res) => {
          console.log(res);
          this.param.fullName = res.data.payload.fullName;
          this.param.mobilePhone = res.data.payload.mobilePhone;
          this.param.secUserId = res.data.payload.secUserId;
          this.namaRs = res.data.payload.hospitalName;
          this.param.userRoles[0].secRoleId =
            res.data.payload.userRoles[0].secRoleId;
          var roleBase = this.param.userRoles[0].secRoleId;
          var rolesOfEdit = this.typeRoles.filter((item) =>
            roleBase.includes(item.secRoleId)
          );
          console.log(rolesOfEdit);
          for (let i = 0; i < rolesOfEdit.length; i++) {
            if (rolesOfEdit[i].secRoleId === roleBase) {
              for (let j = 0; j < this.typeRoles.length; j++) {
                if (
                  this.typeRoles[j].userTypeCode === rolesOfEdit[i].userTypeCode
                ) {
                  this.roleTypeSelectEdit = this.typeRoles[j].userTypeCode;
                }
              }
              adminService
                .getRole(rolesOfEdit[i].userTypeCode)
                .then((res) => {
                  console.log(res);
                  this.Roles = res.data.payload.content;
                })
                .catch((err) => console.log(err));
              this.roleSelect = rolesOfEdit[i].roleName;
            }
          }
          // this.roleSelect = this.Roles.roleName
          // this.roleTypeSelectEdit = 'admin'
          console.log(roleBase);
          console.log(this.param);
        })
        .catch((err) => console.log(err));
    },
    closeDialog() {
      window.location.reload();
      this.dialogEdit = false;
      this.dhapus = false;
    },
    choosePage(page) {
      console.log(page);
      this.paginate.page = page - 1;
      console.log(this.paginate);
      this.listAdmin(this.paginate);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/dashboard.scss";
.titleContent {
  font-weight: 500;
  line-height: 1rem !important;
}
.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
.background {
  background-color: #edf4fb;
}
.cardsearch {
  background-color: #ffffff;
  border-radius: 30px;
  width: 99%;
  margin-left: 2px;
}
.headerComponent {
  margin-left: 250px;
}
.datapasien {
  margin-left: 250px;
  margin-right: 120px;
}
.header {
  margin-left: 250px;
  /* margin-right: 250px; */
}
.poppuphead {
  text-align: center;
}
.v-card__title {
  font-size: 0.9rem !important;
  line-height: 1rem !important;
}
.instructionType {
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.search {
  margin-top: 15px;
  margin-left: 10px;
}
.detail {
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.nama {
  font-family: Nunito;
  font-size: 22px;
  text-align: left;
}
.icd-search {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b92e4;
}
.icd-text {
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
}
.text {
  font-family: Nunito;
  font-size: 11px;
  font-weight: 600;
  height: 2px !important;
  width: 80px !important;
}
</style>
