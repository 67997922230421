<template>
  <v-app class="background">
    <v-container>
      <v-card class="cardStyle">
        <v-row class="ml-1">
          <v-col>
            <v-form ref="form" v-model="valid" lazy-validation>
              <h5 class="mt-3">Tambah Corporate</h5>
              <v-divider></v-divider>
              <v-row class="ml-1 mt-3">
                <h6>Data Corporate</h6>
              </v-row>
              <v-row class="ml-1">
                <v-col cols="3" class="mt-2">
                  <p>Nama Corporate</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="" required outlined dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ml-1">
                <v-col cols="3" class="mt-2">
                  <p>Holding</p>
                </v-col>
                <v-col cols="6">
                  <v-select
                    hide-details=""
                    :items="slot"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="ml-1">
                <v-col cols="3" class="mt-2">
                  <p>No Telp</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    required
                    type="number"
                    outlined
                    class="inputPrice"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ml-1">
                <v-col cols="3" class="mt-2">
                  <p>Alamat</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="" required outlined dense></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-container>
          <v-row class="ml-1">
            <v-col> <h6>Data Contact</h6></v-col>
          </v-row>
          <v-row class="ml-3">
            <v-col>
              <v-data-table
                :headers="headers()"
                :items="contact"
                item-key="name"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:[`item.aksi`]="{ item }">
                  <td>
                    <v-btn
                      color="primary"
                      dark
                      x-small
                      class="mr-2"
                      @click="edit(item)"
                    >
                      <v-icon dark x-small class="mr-1">mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      color="error"
                      x-small
                      dark
                      class="mr-2"
                      @click="dels(item)"
                    >
                      <v-icon dark x-small class="mr-1">mdi-delete</v-icon>
                    </v-btn>
                  </td></template
                >
              </v-data-table>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="success" small v-bind="attrs" v-on="on">
                  <v-icon class="mr-1" dark> group_add </v-icon> Tambah Data
                  Contact
                </v-btn>
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-title>
                    <span class="text-h5">Tambah PIC</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            label="Nama PIC"
                            :rules="nameRules"
                            required
                            v-model="namePic"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            label="Position"
                            :rules="[(v) => !!v || 'Position is required']"
                            v-model="position"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            label="No Hp"
                            persistent-hint
                            required
                            :rules="[(v) => !!v || 'No Hp is required']"
                            type="number"
                            v-model="noHp"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            label="Email"
                            :rules="emailRules"
                            required
                            v-model="email"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Close
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="addPic">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-row>
          <v-dialog v-model="dialogEdit" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Edit PIC</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Nama PIC"
                        :rules="nameRules"
                        required
                        v-model="namePic"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Position"
                        :rules="nameRules"
                        v-model="position"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="No Hp"
                        persistent-hint
                        required
                        type="number"
                        v-model="noHp"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Email"
                        :rules="emailRules"
                        required
                        v-model="email"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeEdit">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="editPic">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-row class="ml-1">
            <v-col><h6>Fasyankes Parameter</h6></v-col>
          </v-row>
          <v-row class="ml-3" v-for="(index, key) in dataRs" :key="key">
            <v-col cols="12">
              <v-checkbox
                v-model="index.hospitalStatus"
                :label="index.value"
                @change="addStatus(index)"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" v-show="index.hospitalStatus">
              <table id="fasyankes">
                <tr>
                  <th :class="idfasyankes1">Konsultasi Tanpa Janji</th>
                  <th :class="idfasyankes2">Konsultasi Dengan Janji</th>
                  <th :class="idfasyankes3">Tebus Resep</th>
                </tr>
                <tr>
                  <td>
                    <v-radio-group
                      v-if="index.statusTanpaJanji"
                      v-model="index.dataKonsul.ktj"
                      @change="ubahItem(item)"
                      row
                    >
                      <v-radio value="paidByCompany">
                        <template v-slot:label>
                          <div>
                            <p class="mt-5" style="font-size: 10px">
                              Paid By Company
                            </p>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="paidByUser">
                        <template v-slot:label>
                          <div>
                            <p class="mt-5" style="font-size: 10px">
                              Paid By Patient
                            </p>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </td>
                  <td>
                    <v-radio-group
                      v-if="index.statusDenganJanji"
                      v-model="index.dataKonsul.kdj"
                      @change="ubahItem(item)"
                      row
                    >
                      <v-radio value="paidByCompany">
                        <template v-slot:label>
                          <div>
                            <p class="mt-5" style="font-size: 10px">
                              Paid By Company
                            </p>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="paidByUser">
                        <template v-slot:label>
                          <div>
                            <p class="mt-5" style="font-size: 10px">
                              Paid By Patient
                            </p>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </td>
                  <td>
                    <v-radio-group
                      v-if="index.statusTebusResep"
                      v-model="index.dataKonsul.tr"
                      @change="ubahItem(item)"
                      row
                    >
                      <v-radio value="paidByCompany">
                        <template v-slot:label>
                          <div>
                            <p class="mt-5" style="font-size: 10px">
                              Paid By Company
                            </p>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="paidByUser">
                        <template v-slot:label>
                          <div>
                            <p class="mt-5" style="font-size: 10px">
                              Paid By Patient
                            </p>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch dense v-model="member">
                <template v-slot:label>
                  <div>
                    <p class="mt-5" style="font-size: 14px">
                      Status Membership
                    </p>
                  </div>
                </template></v-switch
              >
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>
        <v-row>
          <v-col align="right">
            <v-btn
              class="mr-2"
              small
              color="error"
              dark
              to="/corporateMaintenance"
              >Batal</v-btn
            >
            <v-btn class="mr-10" small color="primary" @click="simpan" dark
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import serviceHospital from "@/services/hospital_services";
export default {
  components: {
    // Popup
  },
  data: () => ({
    namePic: "",
    position: "",
    sktif: true,
    member: true,
    valid: true,
    noHp: "",
    dataSementara: [],
    tebusResep: "",
    tanpaJanji: "",
    denganJanji: "",
    nameRules: [(v) => !!v || "Name is required"],
    email: "",
    dataRs: [],
    idfasyankes: "",
    dialogEdit: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    rules: [(v) => !!v || " is required"],
    dialog: false,
    contact: [],
  }),
  mounted() {
    this.gethospital();
  },
  watch: {},
  computed: {},
  methods: {
    addStatus(item) {
      console.log(item);
      if (item.hospitalStatus) {
        this.dataSementara.push(item);
      }
    },
    edit(item) {
      console.log(item);
      this.namePic = item.nama;
      this.position = item.position;
      this.noHp = item.noHp;
      this.email = item.email;
      this.dialogEdit = true;
    },
    closeEdit() {
      this.namePic = "";
      this.position = "";
      this.noHp = "";
      this.email = "";
      this.dialogEdit = false;
    },
    dels(item) {
      this.contact.splice(item, 1);
    },
    ubah(item) {
      console.log(item);
    },
    ubahItem(item) {
      console.log(item);
    },
    simpan() {
      let dataTampung = [];
      console.log(this.dataSementara);
      for (let i = 0; i < this.dataSementara.length; i++) {
        dataTampung.push(this.dataSementara[i]);
      }
      console.log(dataTampung);
    },
    gethospital() {
      serviceHospital.getHospitalAll().then((response) => {
        if (response.data.error !== "") {
          this.$toast.open({
            message: response.data.error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          console.log(response);


          this.dataRs = [];

          
          var hospital = response.data.payload.content;
          hospital.map((e) => {
            this.dataRs.push({
              hptHospitalId: e.hptHospitalId,
              hospitalName: e.hospitalName,
              value: e.hospitalName,
              hospitalStatus: false,
              active: e.active,
              statusDenganJanji: true,
              statusTanpaJanji: true,
              statusTebusResep: false,
              dataKonsul: [
                {
                  ktj: "",
                  kdj: "",
                  tr: "",
                },
              ],
            });
          });
        }
        console.log(this.dataRs);
        this.dataRs.forEach((e) => {
          if (e.statusDenganJanji) {
            this.idfasyankes2 = "fasyankesthtrue";
          } else {
            this.idfasyankes2 = "fasyankesthfalse";
          }

          if (e.statusTanpaJanji) {
            this.idfasyankes1 = "fasyankesthtrue";
          } else {
            this.idfasyankes1 = "fasyankesthfalse";
          }
          if (e.statusTebusResep) {
            this.idfasyankes3 = "fasyankesthtrue";
          } else {
            this.idfasyankes3 = "fasyankesthfalse";
          }
        });
      });
    },
    addPic() {
      if (!this.$refs.form.validate()) {
        console.log(!this.$refs.form.validate());
      } else {
        this.contact.push({
          nama: this.namePic,
          position: this.position,
          noHp: this.noHp,
          email: this.email,
        });
        this.namePic = "";
        this.position = "";
        this.noHp = "";
        this.email = "";
        this.dialog = false;
      }
    },
    close() {
      this.namePic = "";
      this.position = "";
      this.noHp = "";
      this.email = "";
      this.dialog = false;
    },

    headers() {
      return [
        {
          text: "Nama PIC",
          value: "nama",
          class: "primary--text",
        },
        {
          text: "Position",
          value: "position",
          class: "primary--text",
        },
        {
          text: "No Hp",
          value: "noHp",
          // value: "icdName",
          class: "primary--text",
        },
        {
          text: "Email",
          value: "email",
          // value: "icdName",
          class: "primary--text",
        },
        {
          text: "Action",
          value: "aksi",
          // value: "icdName",
          class: "primary--text",
        },
      ];
    },
    dialogPic() {
      this.dialogAddUser = true;
    },
  },
};
</script>
<style scoped>
.dialog {
  overflow: hidden !important;
  overflow-x: hidden !important;
}

#fasyankes {
  font-family: Nunito;
  width: 100%;
}

#fasyankes td,
#fasyankes th {
  border: 1px solid #ddd;
  padding: 8px;
}

#fasyankes tr:nth-child(even) {
  background-color: #f2f2f2;
}

#fasyankes tr:hover {
  background-color: rgb(255, 255, 255);
}

.fasyankesthtrue {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04aa6d;
  color: white;
}
.fasyankesthfalse {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #ff0b0b;
  color: white;
}.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
</style>