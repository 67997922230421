import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
// import { mdiBell } from '@mdi/js';
import "@mdi/font/css/materialdesignicons.css";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.min.css";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import vuetify from "./plugins/vuetify";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./helper/filters";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

Vue.use(VueToast);
// import VeeValidate from 'vee-validate'
import * as VeeValidate from "vee-validate";
import VueHtmlToPaper from "vue-html-to-paper";

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    "./path/to/custom.css",
  ],
};

Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);

Vue.use(VeeValidate, {
  // This is the default
  inject: true,
  // Important to name this something other than 'fields'
  fieldsBagName: "veeFields",
  // This is not required but avoids possible naming conflicts
  errorBagName: "veeErrors",
});
// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'

import { FormCheckboxPlugin } from "bootstrap-vue";
Vue.use(FormCheckboxPlugin);
import { CardPlugin } from "bootstrap-vue";
Vue.use(CardPlugin);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import { ButtonPlugin } from "bootstrap-vue";
Vue.use(ButtonPlugin);

import { FormGroupPlugin } from "bootstrap-vue";
Vue.use(FormGroupPlugin);

Vue.use(VueSidebarMenu);

import { FormRadioPlugin } from "bootstrap-vue";
Vue.use(FormRadioPlugin);

import { FormTextareaPlugin } from "bootstrap-vue";
Vue.use(FormTextareaPlugin);

import { FormPlugin } from "bootstrap-vue";
Vue.use(FormPlugin);

import { FormFilePlugin } from "bootstrap-vue";
Vue.use(FormFilePlugin);

import VueTelInput from "vue-tel-input";
Vue.use(VueTelInput);

import VuetifyTimeSelect from "vuetify-time-select";
Vue.component("vuetify-time-select", VuetifyTimeSelect);

import { FormDatepickerPlugin } from "bootstrap-vue";
Vue.use(FormDatepickerPlugin);

// import Carousel3d from 'vue-carousel-3d';

// Vue.use(Carousel3d);

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

// Install components globally
Vue.use(BootstrapVue);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
//library fontawesome
library.add(faUserSecret);
library.add(faSpinner);
library.add(faEnvelope);
library.add(faEnvelopeOpen);
library.add(faLock);
library.add(faUser);
library.add(faBell);

import { LayoutPlugin } from "bootstrap-vue";

import Print from "vue-print-nb";
// Global instruction
Vue.use(Print);

Vue.use(LayoutPlugin);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(vuetify, {
  iconfont: "mdi",
});

Vue.prototype.$ppUrl =
  " https://api.klinisia.id/storage/file/profile-picture/";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
