/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */

<template>
<form>
    <div id="registrationForm">
        <b-container>
        <!-- <h1 id="title">Login Page</h1> -->
        <b-row class="min-vh-100 " align-h="center" align-v="center" >
        <b-col sm="4">
            <b-card class="text-center">
        <b-form>
        <!-- <b-form-group @submit="onSubmit" inline> -->
            <b-form-group
            id="fullName"
            labelfor="inputName">

            <div align="left">
                <b-row>
                    <b-col  sm="1">
                     <font-awesome-icon :icon="['far','user']" />
                     </b-col>
                     <b-col sm="8">
                         <a>Full Name</a>
                     </b-col>
                </b-row>
            </div>
            <b-form-input
            id="inputName"
            v-model="form.inputName"
            type="text"
            size="lg"
            >
            </b-form-input>
            </b-form-group>


            <b-form-group
            id="emailGroup"
            label-for="inputEmail"
            >
            <div align="left">
                <b-row>
                     <b-col sm="1">
                 <font-awesome-icon :icon="['far','envelope']" />
                 </b-col>
                 <b-col sm="8">
                <a>Email adress</a>
                </b-col>
                </b-row>
            </div>
           
            <b-form-input 
            id="inputEmail" 
            v-model="form.email"
            type="email" 
            size='lg'
            required placehoder="Enter Email"
            :validation="[{type: 'required'}]"
            >
            </b-form-input>
            </b-form-group>
            
            <b-form-group 
            id="passwordGroup"
            label-for="Password"
            >       
             <div align="left">
                 <b-row>
                     <b-col sm="1">
                 <font-awesome-icon icon="lock" />
                 </b-col>
                 <b-col sm="4">
                <a>Password</a>
                </b-col>
                </b-row>
            </div>      
            
           
            <b-form-input 
            id="passwordInput"
            v-model="form.password"
            type="password"
            size='lg'
            aria-describedby="password-help-block"
            
            >
            </b-form-input>

            </b-form-group>
            <b-form-group>
           
            
            <b-row cols="2">
            <b-col>
            <a></a>
            </b-col>
            <b-col>
            <b-button 
            size="lg" 
            type="submit" 
            variant="dark"
            to="/">Register</b-button>
            </b-col>
            </b-row>
            
        </b-form-group>
       
      
        </b-form>
        
        </b-card>
        </b-col>
        </b-row>
        </b-container>

    </div>
    
    
</form>
<!-- <form>
    <div id="loginForm">
        <h1>Login Page</h1>
        <b-form>
        <b-form-input label="Email" placeholder="username@eg.com" /> -->
        <!-- <b-row class="form-group">
            <label for= -->
<!-- </b-form>
    </div>
</form> -->

</template>

<script>
// import Vue from 'vue'

// import BootstrapVue from
// import {BCard} from 'bootstrap-vue'
import {BForm} from 'bootstrap-vue'
import {BCol} from 'bootstrap-vue'
// import {BFormGroup} from 'bootstrap-vue'
import {BFormInput} from 'bootstrap-vue'
// import {BButton} from 'bootstrap-vue'

// Vue.component('b-form', BForm)
// import { FormPlugin } from 'bootstrap-vue'
// Vue.use(FormPlugin)
// import BootstrapVue  from 'bootstrap-vue'

export default {
    name:'Registration',
    components:{
        // BootstrapVue,
        // BCard,
        BCol,
        
        BForm,
        // BFormGroup,
        BFormInput,
        // BButton,
        
        },
    data() {
        return {
            form: {
                email:'',
                password:'',
                username:'',
            }
            
        }
    },
    methods: {
        // onClick() {
        //     this.login({email:this.email, password: this.password})
        // },
        onSubmit(event){
            event.preventDefault()
            alert(JSON.stringify(this.form))
        },
        
    }
}
</script>