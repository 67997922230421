<template>
  <v-app class="background">
   <v-container class="mt-n8">
      <v-card class="cardStyle">
        <v-row class="ml-2 mr-2">
          <v-col cols="4">
            <p dark class="mt-5 ml-2 icd-search" rounded small width="170">
              Cari {{ label }}
            </p>
          </v-col>
          <v-col sm="12" md="6" lg="6" class="search">
            <v-text-field
              label="search"
              append-icon="mdi-magnify"
              outlined
              class="mt-3"
              v-model="search"
              @keyup.enter="onSearch"
              rounded
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container>
      <!-- card chip -->
      <v-card class="cardStyle">
        <v-row>
          <v-col>
            <div>
              <v-row>
                <v-col cols="2">
                  <v-btn
                    v-if="itemList[0].create === true"
                    color="success"
                    class="ma-3 pa-3"
                    x-small
                    dense
                    @click="oenNewGroup"
                    ><v-icon dark x-small> mdi-plus </v-icon>Tambah
                    {{ label }}</v-btn
                  >
                </v-col>
                <v-col cols="2" class="mb-6">
                  <v-select
                    hide-details=""
                    @change="selectedPage()"
                    v-model="pageNumber"
                    :items="slot"
                    outlined
                    dense
                    class="text"
                  ></v-select>
                </v-col>
              </v-row>

              <v-data-table
                :headers="headers()"
                :items="itemgroup"
                hide-default-footer
                :server-items-length="pageCount"
                :search="search"
                class="elevation-1"
                dense
              >
                <template v-slot:item.aksi="{ item }">
                  <td>
                    <v-btn
                      color="primary"
                      dark
                      x-small
                      class="mr-2"
                      @click="edit(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-pencil </v-icon>
                      <p v-if="itemList[0].update === true" class="mt-4">
                        Ubah
                      </p>
                      <p v-if="itemList[0].update === false" class="mt-4">
                        lihat
                      </p>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      v-if="itemList[0].delete === true"
                      color="error"
                      x-small
                      dark
                      class="mr-2"
                      @click="delt(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-delete </v-icon
                      >Hapus</v-btn
                    >
                  </td>
                  <td>
                    <v-btn
                      color=""
                      x-small
                      v-show="vbtn"
                      dark
                      @click="pindah(item)"
                    >
                      <v-icon dark x-small class="mr-1"> mdi-eye </v-icon>Lihat
                      {{ subLabel }}</v-btn
                    >
                  </td>
                </template>
              </v-data-table>
              <v-row>
                <v-col cols="2">
                  <v-btn
                    v-if="label == 'Items'"
                    color="orange"
                    v-show="vbtn2"
                    class="ml-3 mt-2"
                    x-small
                    dark
                    @click="back()"
                  >
                    <v-icon dark x-small class="mr-1"> mdi-arrow-left </v-icon>
                    Kembali Ke Group</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-pagination
                    circle
                    v-model="page"
                    :length="totalpage.totalPages"
                    :total-visible="7"
                    @input="choosePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <!-- Dialog newGroup -->
      <v-dialog v-model="newGroupDIalog" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search">
            <p>Add new Group</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Group Code</p>
                  <v-text-field
                    v-model="addGroup.laboratoryGroupCode"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Group Name</p>
                  <v-text-field
                    v-model="addGroup.laboratoryGroupName"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="icd-text">Group Description</p>
                  <v-text-field
                    v-model="addGroup.laboratoryGroupDescription"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="success" dark @click="newGroup"
              >Tambah</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelNewGroup"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog Edit newGroup -->
      <v-dialog v-model="dialogEdit" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search">
            <p>
              Edit Group Name {{ this.paramsEditGroup.laboratoryGroupName }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Group Code</p>
                  <v-text-field
                    v-model="paramsEditGroup.laboratoryGroupCode"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Group Name</p>
                  <v-text-field
                    v-model="paramsEditGroup.laboratoryGroupName"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="icd-text">Group Description</p>
                  <v-text-field
                    v-model="paramsEditGroup.laboratoryGroupDescription"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="itemList[0].update === true"
              medium
              outlined
              color="success"
              dark
              @click="editGroup"
              >Simpan Edit</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelEditGroup"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Hapus -->
      <v-dialog v-model="dels" persistent max-width="830">
        <v-card>
          <v-row class="ma-4 pa-2">
            <v-col>
              <p class="icd-text">
                Yakin Hapus Group
                {{ this.paramsEditGroup.laboratoryGroupName }} ?
              </p>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="deleteConfirm"
              >Hapus</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelDelete"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog new Item -->
      <v-dialog v-model="newItemDIalog" persistent max-width="830">
        <v-card>
          <v-card-title class="icd-search">
            <p>Add New Item</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col>
                  <p class="icd-text">Group ID</p>
                  <v-text-field
                    v-model="addNewItemonGroup.labLaboratoryGroupId"
                    disabled
                    :rules="nameRules"
                    placeholder=""
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Item Code</p>
                  <v-text-field
                    v-model="addNewItemonGroup.laboratoryItemCode"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Item Name</p>
                  <v-text-field
                    v-model="addNewItemonGroup.laboratoryItemName"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Item Description</p>
                  <v-text-field
                    v-model="addNewItemonGroup.laboratoryItemDescription"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="success" dark @click="newItem"
              >Tambah</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelNewItem"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog edit Item -->
      <v-dialog v-model="editItemDIalog" persistent max-width="830">
        <v-card>
          <v-card-title class="icd-search">
            <p>Edit Item {{ this.editItem.laboratoryItemName }}</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <p class="icd-text">Group ID</p>
                  <v-text-field
                    v-model="editItem.labLaboratoryGroupId"
                    :rules="nameRules"
                    placeholder
                    required
                    disabled
                    outlined
                    dense
                  ></v-text-field> </v-col
              ></v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Item Code</p>
                  <v-text-field
                    v-model="editItem.laboratoryItemCode"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Item Name</p>
                  <v-text-field
                    v-model="editItem.laboratoryItemName"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="12" md="12" sm="12">
                  <p class="icd-text">Item Description</p>
                  <v-text-field
                    v-model="editItem.laboratoryItemDescription"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="success" dark @click="editConfirm"
              >Edit</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelEdit"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Hapus -->
      <v-dialog v-model="delsItem" persistent max-width="830">
        <v-card>
          <v-row class="ma-4 pa-2">
            <v-col>
              <p class="icd-text">
                Yakin Hapus Item {{ this.paramsEditGroup.laboratoryItemName }} ?
              </p>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="deleteConfirm2"
              >Hapus</v-btn
            >
            <v-btn medium outlined color="error" dark @click="cancelDelete"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import serviceLaboratory from "@/services/lab_services";

export default {
  data: () => ({
    slot: [5, 10, 15, 20],
    pageNumber: 10,
    paginate: { name: "", page: 0, size: 10 },
    totalpage: [],
    pageCount: 0,
    page: 1,
    itemList: {},
    login: {},
    search: "",
    backLabel: "",
    vbtn2: true,
    laboratoryHeader: [],
    listdetail: "",
    editItemDIalog: false,
    dels: false,
    laboratorySelect: null,
    diagnostikItemSelect: null,
    tool: false,
    diagnostikId: "",
    groupID: "",
    diagnostikitem: [],
    label: "Group",
    itemgroup: [],
    newGroupDIalog: false,
    newItemDIalog: false,
    addNewDiagnostikItemId: "",
    itemdelete: "",
    nameRules: [(v) => !!v || "Name is required"],
    addGroup: {
      labLaboratoryGroupId: "",
      laboratoryGroupOrder: 0,
      laboratoryGroupCode: "",
      laboratoryGroupName: "",
      laboratoryGroupDescription: "",
      createdBy: "string",
      createdOn: Date,
      upDatedBy: "string",
      updatedOn: Date,
      active: true,
      deleted: true,
    },
    addNewItemonGroup: {
      labLaboratoryItemId: "",
      labLaboratoryGroupId: "",
      laboratoryItemOrder: 0,
      laboratoryItemCode: "",
      laboratoryItemName: "",
      laboratoryItemDescription: "",
      createdBy: "",
      createdOn: Date,
      updatedBy: "",
      updatedOn: Date,
      active: true,
      deleted: true,
    },
    paramsEditGroup: {
      labLaboratoryGroupId: "",
      laboratoryGroupOrder: 0,
      laboratoryGroupCode: "",
      laboratoryGroupName: "",
      laboratoryGroupDescription: "",
      createdBy: "",
      createdOn: Date,
      updatedBy: "string",
      updatedOn: Date,
      active: true,
      deleted: true,
    },
    editItem: {
      labLaboratoryItemId: "",
      labLaboratoryGroupId: "",
      laboratoryItemOrder: 0,
      laboratoryItemCode: "",
      laboratoryItemName: "",
      laboratoryItemDescription: "",
      createdBy: "",
      createdOn: Date,
      updatedBy: "",
      updatedOn: Date,
      active: true,
      deleted: true,
    },
    delsItem: false,
    subLabel: "Items",
    vbtn: true,
    dialogEdit: false,
  }),
  mounted() {
    this.getlaboratory();
    this.loginData();
  },
  methods: {
    choosePage(page) {
      if (this.label === "Group") {
        console.log(page);
        this.paginate.page = page - 1;
        console.log(this.paginate);
        this.getlaboratory(this.paginate);
      } else if (this.label === "Items") {
        console.log(page);
        this.paginate.page = page - 1;
        console.log(this.paginate);
        this.getItems(this.paginate);
      }
    },
    onSearch() {
      if (this.label === "Group") {
        this.paginate.page = 0;
        // this.paginate.size = 100000000
        this.paginate.name = this.search;
        console.log(this.paginate);
        this.getlaboratory(this.paginate);
      } else if (this.label === "Items") {
        this.paginate.page = 0;
        // this.paginate.size = 100000000
        this.paginate.name = this.search;
        console.log(this.paginate);
        this.getItems(this.paginate);
      }
    },
    selectedPage() {
   if (this.label === "Group") {
      console.log(this.pageNumber);
      this.paginate.size = this.pageNumber;
      this.getlaboratory(this.paginate);
   }
   else if (this.label === "Items") {
      console.log(this.pageNumber);
      this.paginate.size = this.pageNumber;
      this.getItems(this.paginate);
   }
     
    },
    loginData() {
      this.login = JSON.parse(window.localStorage.getItem("loginData"));
      console.log("login list", this.login.features);
      this.itemList = this.login.features.filter(
        (item) => item.featureName === "Master Laboratory"
      );
      console.log(this.itemList);
    },
    back() {
      this.label = "Group";
      this.vbtn = true;
      this.itemgroup = [];
      this.getlaboratory();
    },
    headers() {
      return [
        // {
        //   text: "No",
        //   align: "start",
        //   sortable: true,
        //   value: "index",
        //   class: "primary--text judul",
        // },
        {
          text: `Code ${this.label} `,
          value: "groupCode",
          class: "primary--text judul",
        },
        {
          text: `Nama ${this.label} `,
          // value: "icdDescription",
          value: "groupName",
          class: "primary--text judul",
        },
        {
          text: `Deskripsi ${this.label} `,
          value: "groupDesc",
          // value: "icdName",
          class: "primary--text judul",
        },
        {
          text: "Aksi",
          value: "aksi",
          class: "primary--text judul",
        },
      ];
    },
    getItems(value) {
      serviceLaboratory.getLaboratoryitem(value).then((response) => {
        console.log(response);
        this.diagnostikitem = response.data.payload.content;
        this.pageCount = response.data.payload.totalPages;
        this.totalpage = response.data.payload;
        this.itemgroup = [];
        this.diagnostikitem.map((e) => {
          this.itemgroup.push({
            id: e.labLaboratoryItemId,
            groupID: e.labLaboratoryGroupId,
            groupCode: e.laboratoryItemCode,
            groupName: e.laboratoryItemName,
            groupDesc: e.laboratoryItemDescription,
          });
        });
      });
    },
    getlaboratory(value) {
      value = this.paginate;
      serviceLaboratory.getLaboratory(value).then((response) => {
        console.log("ini laborat", response);
        this.itemgroup = [];
        this.laboratoryHeader = response.data.payload.content;
        this.pageCount = response.data.payload.totalPages;
        this.totalpage = response.data.payload;
        this.laboratoryHeader.map((e) => {
          this.itemgroup.push({
            id: e.labLaboratoryGroupId,
            groupCode: e.laboratoryGroupCode,
            groupName: e.laboratoryGroupName,
            groupDesc: e.laboratoryGroupDescription,
          });
        });
      });
    },
    pindah(item) {
      if (this.subLabel === "Items") {
        console.log(item);
        this.label = "Items";
        this.paginate["id"] = item.id
        this.getItems(this.paginate);
        this.addNewItemonGroup.labLaboratoryGroupId = item.id;
        // this.getProvince(item.id);
        this.vbtn = false;
      }
    },
    edit(item) {
      if (this.label === "Group") {
        console.log(item);
        this.groupID = item.id;
        this.dialogEdit = true;
        this.paramsEditGroup.labLaboratoryGroupId = item.id;
        this.paramsEditGroup.laboratoryGroupCode = item.groupCode;
        this.paramsEditGroup.laboratoryGroupName = item.groupName;
        this.paramsEditGroup.laboratoryGroupDescription = item.groupDesc;
      } else if (this.label === "Items") {
        console.log(item);
        this.groupID = item.id;
        this.editItem.labLaboratoryGroupId = item.groupID;
        this.editItem.labLaboratoryItemId = item.id;
        this.editItem.laboratoryItemCode = item.groupCode;
        this.editItem.laboratoryItemName = item.groupName;
        this.editItem.laboratoryItemDescription = item.groupDesc;
        this.editItemDIalog = true;
      }
    },
    delt(item) {
      console.log("hahaha", item);
      if (this.label === "Group") {
        window.localStorage.setItem("id", item.id);
        this.groupID = item.id;
        this.paramsEditGroup.laboratoryGroupCode = item.groupCode;
        this.paramsEditGroup.laboratoryGroupName = item.groupName;
        this.dels = true;
      } else if (this.label === "Items") {
        window.localStorage.setItem("id", item.id);
        this.groupID = item.id;
        this.editItem.labLaboratoryGroupId = item.groupID;
        this.paramsEditGroup.laboratoryItemCode = item.groupCode;
        this.paramsEditGroup.laboratoryItemName = item.groupName;
        this.delsItem = true;
      }
    },
    editGroup() {
      if (this.paramsEditGroup.laboratoryGroupCode === "") {
        this.$toast.open({
          message: "isi group code terlebuh dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.paramsEditGroup.laboratoryGroupName === "") {
        this.$toast.open({
          message: "isi group name terlebuh dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        let request = {
          id: this.groupID,
          body: this.paramsEditGroup,
        };
        serviceLaboratory
          .editGroup(request)
          .then((response) => {
            console.log(response);
            if (response.data.error != "") {
              this.$toast.open({
                message: response.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
              this.dialogEdit = false;
            } else {
              this.$toast.open({
                message: "Edit Group Laboratory Berhasil Di Simpan ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              console.log(response);
              this.dialogEdit = false;
              window.location.reload();
            }
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
            console.log(error);
          });
      }
    },
    deleteConfirm() {
      console.log(this.groupID);
      serviceLaboratory
        .deleteGroup(this.groupID)
        .then((response) => {
          if (response.data.errors.length > 0) {
            this.$toast.error(
              // response.data.errors[0].field +
              //   " " +
              //   response.data.errors[0].message,

              "Hapus Semua Item Terlebih Dahulu",
              {
                type: "error",
                position: "top-right",
                duration: 4000,
              }
            );
            this.dels = false;
          } else if (response.data.error != "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
            this.dels = false;
          } else {
            this.$toast.open({
              message: "Group Laboratory Berhasil Di Hapus ",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            console.log(response);
            this.dels = false;
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        });
    },
    deleteConfirm2() {
      console.log(this.groupID);
      serviceLaboratory
        .deleteItem(this.groupID)
        .then((response) => {
          if (response.data.error != "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
            this.dels = false;
          } else {
            this.$toast.open({
              message: "Item Laboratory Berhasil Di Hapus ",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            this.getItems(this.editItem.labLaboratoryGroupId);
            console.log(response);
            this.delsItem = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        });
    },
    cancelDelete() {
      if (this.label === "Group") {
        this.dels = false;
      } else if (this.label === "Items") {
        this.delsItem = false;
      }
    },
    cancelEditGroup() {
      this.dialogEdit = false;
    },
    deleteGroup() {
      this.dels = true;
    },

    editConfirm() {
      if (this.editItem.laboratoryItemCode === "") {
        this.$toast.open({
          message: "isi item code terlebuh dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.editItem.laboratoryItemName === "") {
        this.$toast.open({
          message: "isi item name terlebuh dahulu",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        console.log(this.editItem);
        let data = {
          id: this.editItem.labLaboratoryItemId,
          item: this.editItem,
        };
        serviceLaboratory
          .editItemDetail(data)
          .then((response) => {
            console.log(response);
            if (response.data.error != "") {
              this.$toast.open({
                message: response.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
              this.dialogEdit = false;
            } else {
              this.$toast.open({
                message: "Item Laboratory Berhasil Di Edit ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              this.getItems(this.editItem.labLaboratoryGroupId);
              this.editItemDIalog = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    cancelEdit() {
      this.editItemDIalog = false;
    },

    // alert(obj) {
    //   console.log(obj);
    //   console.log(obj.labLaboratoryItemId);
    //   serviceLaboratory
    //     .openItemDetail(obj.labLaboratoryItemId)
    //     .then((response) => {
    //       console.log(response);
    //       this.editItem = response.data.payload;
    //       console.log(this.editItem);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    //   this.editItemDIalog = true;
    // },

    newItem() {
      console.log(this.addNewItemonGroup);
      if (
        this.addNewItemonGroup.laboratoryItemCode === "" ||
        this.addNewItemonGroup.laboratoryItemName === ""
      ) {
        this.$toast.open({
          message: "laboratoryItemCode dan laboratoryItemName Harus Di Isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        serviceLaboratory
          .addNewItem(this.addNewItemonGroup)
          .then((response) => {
            console.log(response);
            if (response.data.error != "") {
              this.$toast.open({
                message: response.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
              this.dialogEdit = false;
            } else {
              this.$toast.open({
                message: "Item Laboratory Baru Berhasil Di Tambahkan ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              this.getItems(this.addNewItemonGroup.labLaboratoryGroupId);
              this.newItemDIalog = false;
              window.location.reload();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    // selectingData() {
    //   console.log("Value: ", this.diagnostikItemSelect);
    //   this.addNewDiagnostikItemId = this.laboratoryHeader.filter((item) =>
    //     this.diagnostikItemSelect.includes(item.laboratoryGroupName)
    //   );
    //   console.log(this.addNewDiagnostikItemId);
    //   this.addNewItemonGroup.labLaboratoryGroupId = this.addNewDiagnostikItemId[0].labLaboratoryGroupId;
    //   console.log(this.addNewItemonGroup);
    // },

    // selectingHapus() {
    //   console.log("Value: ", this.diagnostikItemSelect);
    //   this.addNewDiagnostikItemId = this.laboratoryHeader.filter((item) =>
    //     this.diagnostikItemSelect.includes(item.laboratoryGroupName)
    //   );
    //   console.log(this.addNewDiagnostikItemId[0].labLaboratoryGroupId);
    //   this.itemdelete = this.addNewDiagnostikItemId[0].labLaboratoryGroupId;
    // },

    cancelNewItem() {
      window.location.reload();
      this.newItemDIalog = false;
      this.addNewItemonGroup = {
        labLaboratoryItemId: "",
        labLaboratoryGroupId: "",
        laboratoryItemOrder: 0,
        laboratoryItemCode: "",
        laboratoryItemName: "",
        laboratoryItemDescription: "",
        createdBy: "",
        createdOn: Date,
        updatedBy: "",
        updatedOn: Date,
        active: true,
        deleted: true,
      };
    },
    // openNewitem() {
    //   this.newItemDIalog = true;
    // },
    cancelNewGroup() {
      this.newGroupDIalog = false;
      this.addGroup = {
        labLaboratoryGroupId: "",
        laboratoryGroupOrder: 0,
        laboratoryGroupCode: "",
        laboratoryGroupName: "",
        laboratoryGroupDescription: "",
        createdBy: "string",
        createdOn: Date,
        upDatedBy: "string",
        updatedOn: Date,
        active: true,
        deleted: true,
      };
    },
    oenNewGroup() {
      if (this.label === "Group") {
        this.newGroupDIalog = true;
      } else {
        this.newItemDIalog = true;
      }
    },
    newGroup() {
      if (
        this.addGroup.laboratoryGroupName === "" ||
        this.addGroup.laboratoryGroupCode === ""
      ) {
        this.$toast.open({
          message: "laboratoryGroupName dan laboratoryGroupCode Harus Di Isi ",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else {
        serviceLaboratory
          .addNewGroup(this.addGroup)
          .then((response) => {
            console.log(response);
            if (response.data.error !== "") {
              this.$toast.open({
                message: response.data.error,
                position: "top-right",
                type: "error",
                duration: 6000,
              });
            } else {
              this.$toast.open({
                message: "Group Laboratory Baru Berhasil Di Tambahkan ",
                position: "top-right",
                type: "success",
                duration: 6000,
              });
              window.location.reload();
            }
            // // this.newGroupDIalog = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    // selecting() {
    //   console.log("Value: ", this.laboratorySelect);
    //   this.diagnostikId = this.laboratoryHeader.filter((item) =>
    //     this.laboratorySelect.includes(item.laboratoryGroupName)
    //   );
    //   console.log(this.diagnostikId);
    //   this.listdetail = this.diagnostikId[0].labLaboratoryGroupId;
    //   console.log(this.listdetail);
    //   serviceLaboratory.getLaboratoryitem(this.listdetail).then((response) => {
    //     console.log(response);
    //     this.diagnostikitem = response.data.payload.content;
    //   });
    // },

    // directToBank() {
    //   console.log("Value: ", this.laboratorySelect);
    // },
    // add(obj) {
    //   console.log(obj);
    // },
  },
};
</script>

<style>
.background {
  background-color: #edf4fb;
}
.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
.text {
  font-family: Nunito;
  font-size: 11px;
  font-weight: 600;
  height: 2px !important;
  width: 80px !important;
}
</style>