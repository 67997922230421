import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
import Login from "../components/login.vue";
import Registration from "../components/registration.vue";
import sidebar from "../components/dashboard/option.vue";
import dashboard from "../components/dashboard/dashboard.vue";
import excel from "../components/dashboard/exportExcel.vue";
import LupaPassword from "../components/LupaPassword.vue";
import reset from "../components/Reset.vue";
import listDokter from "../components/listdoctor/listDokter.vue";
import resetPassword from "../components/ResetPassword.vue";
import rekapPasien from "../components/Pasien/rekapPasien.vue";
import KonfirmasiObat from "../components/konfirmasi/konfirmasiObat";
import lihat from "../components/lihat/lihat.vue";
import edit from "../components/edit/edit.vue";
import error from "../components/Error.vue";
import pengiriman from "../components/pengiriman/Kirim.vue";
import tambahDokter from "../components/tambahdokter/tambahDokter.vue";
import antrian from "../components/antrian/antrian.vue";
import inputObat from "../components/masterData/inputObat.vue";
import inputIcd from "../components/masterData/inputIcdCode.vue";
import inputLab from "../components/masterData/inputLab.vue";
import inputGeo from "../components/masterData/inputGeo.vue";
import spesialisasiDoctor from "../components/masterData/spesialisasiDoctor.vue";
import addspesialisasiDoctor from "../components/masterData/addSpesialisasi.vue";
import subSpesialisasi from "../components/masterData/subSpesialisasi.vue";
import addsubSpesialisasi from "../components/masterData/addsubSpesialisasi.vue";
import inputDiagnostik from "../components/masterData/inputDiagnostik.vue";
import AddControlAdmin from "../components/adminControl/AddControlAdmin.vue";
import listControlAdmin from "../components/adminControl/listCotrolAdmin.vue";
import addHospital from "../components/hospitalControl/addHospital.vue";
import hospitalList from "../components/hospitalControl/hospitalList.vue";
import hospitalType from "../components/hospitalControl/hospitalType.vue";
import addhospitalType from "../components/hospitalControl/addHospitalType.vue";
import RoleControlList from "../components/RoleControl/RoleControlList.vue";
import AddRoleControl from "../components/RoleControl/AddRoleControl.vue";
import doctorSchedule from "../components/listdoctor/doctorSchedule.vue";
import doctorScheduleDetail from "../components/listdoctor/doctorScheduleDetail.vue";
import paymentchannel from "../components/payment/paymentchannel.vue";
import carousel from "../components/3dcarousel/3dcarouseltrail.vue";
import DoctorAppointment from "../components/listdoctor/DoctorAppointment.vue";
import doctorApproval from "../components/listdoctor/doctorApproval.vue";
import corporateMaintenance from "../components/corporate/Corporate_Maintenance.vue";
import addCorporateMaintenance from "../components/corporate/Add_Coporate_Maintenance.vue";
import editCorporateMaintenance from "../components/corporate/Edit_Corporate_Maintenance.vue";
import lihatCorporateMaintenance from "../components/corporate/Lihat_Corporate_Maintenance.vue";
import corporateMember from "../components/corporateMember/Corporate_Member.vue";
import addCorporateMember from "../components/corporateMember/Add_Coporate_Member.vue";
import setCorporateMember from "../components/corporateMember/Set_Coporate_Member.vue";
import editCorporateMember from "../components/corporateMember/Edit_Corporate_Member.vue";
import lihatCorporateMember from "../components/corporateMember/Lihat_Corporate_Member.vue";
import reportMember from "../components/corporateMember/Report_Upload_Member.vue";
// import dsb from '../components/dashboard trial/dsb.vue'
// import dsb from '../components/dashboard trial/dsb.vue'
// import HomePage from '../components/dashboard trial/dashboard page/HomePage.vue';
// import AboutPage from '../components/dashboard trial/dashboard page/AboutPage.vue';
// import ExamplePage from '../components/dashboard trial/dashboard page/ExamplePage.vue';

Vue.use(VueRouter);

const routes = [
  // {
  //   path:'/excel',
  //   name: 'excel',
  //   component: excel,
  //   meta: {
  //     guest: true
  //   }
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: "/registration",
    name: "Registration",
    component: Registration,
    meta: {
      guest: true,
    },
  },
  {
    path: "/ResetPassword/:token",
    name: "resetPassword",
    component: resetPassword,
    meta: {
      auth: false,
    },
  },
  {
    path: "/",
    name: "sidebar",
    redirect: "/login",
    component: sidebar,
    children: [
      {
        path: "/listDokter",
        name: "listDokter",
        component: listDokter,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/excel",
        name: "excel",
        component: excel,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/rekapPasien",
        name: "rekapPasien",
        component: rekapPasien,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/konfirmasiobat",
        name: "KonfirmasiObat",
        component: KonfirmasiObat,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/lihat",
        name: "lihat",
        component: lihat,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/tambahdokter",
        name: "tambah Dokter",
        component: tambahDokter,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Edit",
        name: "edit",
        component: edit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/pengiriman",
        name: "pengiriman",
        component: pengiriman,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/antrian",
        name: "antrian",
        component: antrian,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/inputObat",
        name: "inputObat",
        component: inputObat,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/inputIcd",
        name: "inputIcd",
        component: inputIcd,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/inputLab",
        name: "inputLab",
        component: inputLab,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/inputGeo",
        name: "inputGeo",
        component: inputGeo,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/inputDiagnostik",
        name: "inputDiagnostik",
        component: inputDiagnostik,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/spesialisasiDoctor",
        name: "spesialisasiDoctor",
        component: spesialisasiDoctor,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/subSpesialisasi",
        name: "subSpesialisasi",
        component: subSpesialisasi,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/addsubSpesialisasi",
        name: "addsubSpesialisasi",
        component: addsubSpesialisasi,
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/addspesialisasiDoctor",
        name: "addspesialisasiDoctor",
        component: addspesialisasiDoctor,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/AddControlAdmin",
        name: "AddControlAdmin",
        component: AddControlAdmin,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/listControlAdmin",
        name: "listControlAdmin",
        component: listControlAdmin,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/hospitalList",
        name: "hospitalList",
        component: hospitalList,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/addHospital",
        name: "addHospital",
        component: addHospital,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/hospitalType",
        name: "hospitalType",
        component: hospitalType,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/addhospitalType",
        name: "addhospitalType",
        component: addhospitalType,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/RoleControlList",
        name: "RoleControlList",
        component: RoleControlList,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/AddRoleControl",
        name: "AddRoleControl",
        component: AddRoleControl,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/doctorSchedule",
        name: "doctorSchedule",
        component: doctorSchedule,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/doctorScheduleDetail",
        name: "doctorScheduleDetail",
        component: doctorScheduleDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/paymentchannel",
        name: "paymentchannel",
        component: paymentchannel,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/corporateMaintenance",
        name: "corporateMaintenance",
        component: corporateMaintenance,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/addCorporateMaintenance",
        name: "addCorporateMaintenance",
        component: addCorporateMaintenance,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/editCorporateMaintenance",
        name: "editCorporateMaintenance",
        component: editCorporateMaintenance,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/lihatCorporateMaintenance",
        name: "lihatCorporateMaintenance",
        component: lihatCorporateMaintenance,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/corporateMember",
        name: "corporateMember",
        component: corporateMember,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/reportMember",
        name: "reportMember",
        component: reportMember,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/addCorporateMember",
        name: "addCorporateMember",
        component: addCorporateMember,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/setCorporateMember",
        name: "setCorporateMember",
        component: setCorporateMember,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/editCorporateMember",
        name: "editCorporateMember",
        component: editCorporateMember,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/lihatCorporateMember",
        name: "lihatCorporateMember",
        component: lihatCorporateMember,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/DoctorAppointment",
        name: "DoctorAppointment",
        component: DoctorAppointment,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/doctorApproval",
        name: "doctorApproval",
        component: doctorApproval,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/LupaPassword",
    name: "LupaPassword",
    component: LupaPassword,
    meta: {
      guest: true,
    },
  },
  {
    path: "/carousel",
    name: "carousel",
    component: carousel,
    meta: {
      guest: true,
    },
  },
  {
    path: "/Reset",
    name: "Reset",
    component: reset,
    meta: {
      guest: true,
    },
  },
  {
    path: "*",
    name: "error",
    component: error,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (window.localStorage.getItem("shortToken") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (window.localStorage.getItem("shortToken") == null) {
      next();
    } else {
      next({
        path: "/listDokter",
        params: { nextUrl: to.fullPath },
      });
    }
  } else {
    next();
  }
});

export default router;
