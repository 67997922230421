<template>
  <div id="app">
  <button @click="tableToExcel('table', 'Lorem Table')">export </button>
  <table ref="table" id="loremTable" summary="lorem ipsum sit amet" rules="groups" frame="hsides" border="2">
    <caption>lorem ipsum</caption>
    <colgroup align="center"></colgroup>
    <colgroup align="left"></colgroup>
    <colgroup span="2" align="center"></colgroup>
    <colgroup span="3" align="center"></colgroup>
    <thead valign="top">
      <tr>
        <th>id</th>
        <th>Name</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>lorem ipsum</td>
        <td>sit amet</td>
      </tr>
    </tbody>
  </table>
   <vue-excel-xlsx
        :data="data2"
        :columns="columns2"
        :filename="'xl2'"
        :sheetname="'sheetname'"
        >
        Download
    </vue-excel-xlsx>
     <vue-excel-xlsx
        :data="data"
        :columns="columns"
        :filename="'filename'"
        :sheetname="'sheetname'"
        >
        Download 2
    </vue-excel-xlsx>
</div>
</template>

<script>

export default {
  data(){
    return{
      uri :'data:application/vnd.ms-excel;base64,',
      template:'<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function(s){ return window.btoa(unescape(encodeURIComponent(s))) },
      format: function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) },
      columns : [
                    {
                        label: "Product",
                        field: "product",
                    },
                    {
                        label: "Price",
                        field: "price",
                        dataFormat: this.priceFormat
                    },
                    {
                        label: "Quantity",
                        field: "quantity",
                    },
                ],
                data : [
                    {
                        product: "Beverage",
                        price: 10,
                        quantity: 2
                    },
                    {
                        product: "Snack",
                        price: 12,
                        quantity: 6
                    },
                    {
                        product: "Beverage",
                        price: 10,
                        quantity: 5
                    },
                    {
                        product: "Snack",
                        price: 12,
                        quantity: 3
                    }
                ],
                columns2 : [
                    {
                        label: "Product",
                        field: "product",
                    },
                    {
                        label: "Price",
                        field: "price",
                        dataFormat: this.priceFormat
                    },
                    {
                        label: "Quantity",
                        field: "quantity",
                    },
                ],
                data2 : [
                    {
                        product: "Beverage",
                        price: 0,
                        quantity: 0
                    },
                    {
                        product: "Snack",
                        price: 0,
                        quantity: 0
                    },
                    {
                        product: "Beverage",
                        price: 10,
                        quantity: 5
                    },
                    {
                        product: "Snack",
                        price: 12,
                        quantity: 3
                    }
                ],
    }
  },
  // data: () =>({ 
  //     uri :'data:application/vnd.ms-excel;base64,',
  //     template:'<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
  //     base64: function(s){ return window.btoa(unescape(encodeURIComponent(s))) },
  //     format: function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) },
  //     columns : [
  //                   {
  //                       label: "Product",
  //                       field: "product",
  //                   },
  //                   {
  //                       label: "Price",
  //                       field: "price",
  //                       dataFormat: this.priceFormat
  //                   },
  //                   {
  //                       label: "Quantity",
  //                       field: "quantity",
  //                   },
  //               ],
  //               data : [
  //                   {
  //                       product: "Beverage",
  //                       price: 10,
  //                       quantity: 2
  //                   },
  //                   {
  //                       product: "Snack",
  //                       price: 12,
  //                       quantity: 6
  //                   },
  //                   {
  //                       product: "Beverage",
  //                       price: 10,
  //                       quantity: 5
  //                   },
  //                   {
  //                       product: "Snack",
  //                       price: 12,
  //                       quantity: 3
  //                   }
  //               ],
  //                columns2 : [
  //                   {
  //                       label: "Product",
  //                       field: "product",
  //                   },
  //                   {
  //                       label: "Price",
  //                       field: "price",
  //                       dataFormat: this.priceFormat
  //                   },
  //                   {
  //                       label: "Quantity",
  //                       field: "quantity",
  //                   },
  //               ],
  //               data2 : [
  //                   {
  //                       product: "Beverage",
  //                       price: 10,
  //                       quantity: 2
  //                   },
  //                   {
  //                       product: "Snack",
  //                       price: 12,
  //                       quantity: 6
  //                   },
  //                   {
  //                       product: "Beverage",
  //                       price: 10,
  //                       quantity: 5
  //                   },
  //                   {
  //                       product: "Snack",
  //                       price: 12,
  //                       quantity: 3
  //                   }
  //               ],
    
  // }
    
  // ),
  methods:{
      priceFormat(value){
                return '$ ' + value;
            },
    tableToExcel(table, name){
      
      if (!table.nodeType) table = this.$refs.table
      var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}
      window.location.href = this.uri + this.base64(this.format(this.template, ctx))
    }
  }
}
</script>

<style>
/* .radio-label {
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 30px;
} */
</style>