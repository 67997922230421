<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import axios from "axios";
  export default {
    name: "App",
    components: {
      // HelloWorld,
      // sidebar
    },

    data: () => ({
      param: {
        userId: window.localStorage.getItem("secUserId"),
        deviceType: window.localStorage.getItem("deviceType"),
        deviceCode: window.localStorage.getItem("deviceCode"),
        longToken: window.localStorage.getItem("longToken"),
        role: window.localStorage.getItem("role"),
      },
    }),
    // watch() {
    //   if(window.localStorage.getItem('longToken') !== null){
    //   this.refresh();
    //   }
    // },
    mounted() {
      if (window.localStorage.getItem("longToken") !== null) {
        this.refresh();
      }
      // console.log(window.localStorage.getItem('longToken'));
      //   var i = 1
      //  setTimeout(() => console.log("test time", i++), 1)
      // axios.post('https://api.klinisia.id/auth/login/refesh-token', this.param,
      //             {
      //                 headers: {
      //                     'Content-Type': 'application/json'
      //                 }
      //             }
      //         )
      //         .then(response=>
      //         // console.log("ini refresh")
      //         console.log("response"),
      //         console.log(response),

      //         )
      // axios.post('https://api.klinisia.id/auth/login/refesh-token', this.param,
      //             {
      //                 headers: {
      //                     'Content-Type': 'application/json'
      //                 }
      //             }
      //         )
      //         .then(response => {
      //           console.log("ini refresh");
      //           console.log(response.data.payload.shortToken.token);
      //           window.localStorage.setItem('shortToken', response.data.payload.shortToken.token)

      //         })
      //          .catch(error => {
      //            console.log("refresh error");
      //              console.log(error);
      // });
    },
    methods: {
      //  studentUpdate: function () {
      //     this.$http.get('/webapi/student').then((results) => {
      //        console.log(results.data.data);
      //        this.student = results.data.data;
      //     }, (results) => {
      //        console.log('ERROR');
      //        console.log(results);
      //     });
      //     setTimeout(this.studentUpdate, 5000);
      //  },
      refresh() {
        console.log("refresh");
        axios
          .post(
            "https://api.klinisia.id/auth/login/refesh-token",
            this.param,
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          )
          .then((response) => {
            console.log("ini refresh");
            console.log(response.data.payload.shortToken.token);
            window.localStorage.setItem(
              "shortToken",
              response.data.payload.shortToken.token,
            );
          })
          .catch((error) => {
            console.log("refresh error");
            window.localStorage.removeItem("shortToken");
            window.localStorage.removeItem("longToken");
            window.localStorage.removeItem("authenticated");
            console.log(error);
            this.$router.push("/login");
          });
        setTimeout(this.refresh, 1200000);
      },
    },
    // ready() {
    //    this.refresh();
    // }
  };
</script>
<style>
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&family=Nunito:wght@200;300;400;600&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap");

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(228, 228, 228);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
