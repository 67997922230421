<template>
  <v-app class="background">
    <v-container>
      <v-card class="cardStyle">
        <v-row class="ml-1">
          <v-col>
            <v-form ref="form" v-model="valid" lazy-validation>
              <h5 class="mt-3">Edit Corporate Member Adaro</h5>
              <v-divider></v-divider>
              <v-row class="ml-1">
                <v-col cols="3" class="mt-2">
                  <p>No Karyawan</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="" required outlined dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ml-1">
                <v-col cols="3" class="mt-2">
                  <p>NIK</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="" required outlined dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ml-1">
                <v-col cols="3" class="mt-2">
                  <p>Nama</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    required
                    type="number"
                    outlined
                    class="inputPrice"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ml-1">
                <v-col cols="3" class="mt-2">
                  <p>Tanggal Lahir</p>
                </v-col>
                <v-col cols="4">
                  <b-form-group>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="birthDate"
                          dense
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="birthDate"
                        :max="today"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </b-form-group>
                </v-col>
              </v-row>
              <v-row class="ml-1">
                <v-col cols="3" class="mt-2">
                  <p>No Hp</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    required
                    type="number"
                    outlined
                    class="inputPrice"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ml-1">
                <v-col cols="3" class="mt-2">
                  <p>Alamat</p>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="" required outlined dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ml-1">
                <v-col cols="3" class="mt-2">
                  <p>Hubungan</p>
                </v-col>
                <v-col cols="6">
                  <v-select
                    hide-details=""
                    :items="itemHubungan"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row>
          <v-col align="right">
            <v-btn
              class="mr-2"
              small
              color="error"
              dark
              to="/setCorporateMember"
              >Batal</v-btn
            >
            <v-btn class="mr-10" small color="primary" @click="simpan" dark
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
// import { BFormInput } from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    // BFormInput,
  },
  data: () => ({
    today: moment().format("YYYY-MM-DD"),
    birthDate: "",
    date: null,
    menu: false,
    upload:false,
    files: [],
    nameRules: [(v) => !!v || "Name is required"],
    dataRs: [],
    itemHubungan: [
      {
        text: "Karyawan",
        value: "karyawan",
      },
      {
        text: "Pasangan",
        value: "pasangan",
      },
      {
        text: "Anak Pertama",
        value: "anakPertama",
      },
      {
        text: "Anak Kedua",
        value: "anakKedua",
      },
      {
        text: "Anak Ketiga",
        value: "anakKetiga",
      },
    ],
    rules: [(v) => !!v || " is required"],
  }),
  mounted() {
  },
  watch: {},
  computed: {},
  methods: {
    uploadFile(){
      this.upload = true
    },
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday === 0 || weekday === 6;
    },
   
    simpan() {
      console.log(this.upload);
    },
    
  },
};
</script>
<style scoped>
.cardStyle {
    border-radius: 20px !important;
    margin-top: auto;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
.dialog {
  overflow: hidden !important;
  overflow-x: hidden !important;
}
</style>