import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://api.klinisia.id/";

class admin {
  // async getAdmin(){
  //     return await axios.get(`${API_URL}ksi/security/user-admin/search?page=0&size=1000000&sortDirection=DESC&sortField=createdOn`, {headers: headers()})
  // }
  async getAdmin(param) {
    return await axios.get(
      `${API_URL}ksi/security/user-admin/search-with-role?hptHospitalId=${param.hptid}&userType=${param.userid}&fullName=${param.name}&page=${param.page}&size=${param.size}&sortDirection=DESC&sortField=createdOn`,
      { headers: headers() },
    );
  }
  async getAdminByHospital(param) {
    return await axios.get(
      `${API_URL}ksi/security/user-admin/search-with-role?hptHospitalId=${param.id}&fullName=${param.name}&page=${param.page}&size=${param.size}&sortDirection=DESC&sortField=createdOn`,
      { headers: headers() },
    );
  }
  async getAdminbyRole(param) {
    return await axios.get(
      `${API_URL}ksi/security/user-admin/search-with-role?userType=${param.id}&fullName=${param.name}&page=${param.page}&size=${param.size}&sortDirection=DESC&sortField=createdOn`,
      { headers: headers() },
    );
  }
  async addAdmin() {
    return await axios.get(`${API_URL}ksi/security/user-admin`, {
      headers: headers(),
    });
  }
  async getTypeRole() {
    return await axios.get(
      `${API_URL}ksi/master/security/role/search?page=0&size=20&sortDirection=ASC&sortField=roleCode`,
      { headers: headers() },
    );
  }
  async getRole(id) {
    return await axios.get(
      `${API_URL}ksi/master/security/role/search?userType=${id}&page=0&size=20&sortDirection=ASC&sortField=roleCode`,
      { headers: headers() },
    );
  }
  async getHospital() {
    return await axios.get(
      `${API_URL}ksi/master/hospital/hospital/search?page=0&size=1000000&sortDirection=ASC&sortField=hospitalCode`,
      { headers: headers() },
    );
  }
  async submitAdmin(data) {
    return await axios.post(`${API_URL}ksi/security/user-admin`, data, {
      headers: headers(),
    });
  }
  async deleteAdmin(data) {
    return await axios.delete(`${API_URL}ksi/security/user-admin/${data}`, {
      headers: headers(),
    });
  }
  async getDetailAdmin(data) {
    return await axios.get(`${API_URL}ksi/security/user-admin/${data}`, {
      headers: headers(),
    });
  }
  async EditAdmin(data) {
    return await axios.put(`${API_URL}ksi/security/user-admin`, data, {
      headers: headers(),
    });
  }
  async getHospitalAll() {
    return await axios.get(API_URL + "no-auth/hospital/all", {
      headers: headers(),
    });
  }
}

export default new admin();
