import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://api.klinisia.id/";

class hospital {
  async getHospitalTypeAll() {
    return await axios.get(
      `${API_URL}ksi/master/hospital/hospital-type/search?serviceProviderTypeCode=ksi&page=0&size=100&sortDirection=ASC&sortField=hospitalTypeCode`,
      { headers: headers() },
    );
  }
  async getHospitalType(param) {
    return await axios.get(
      `${API_URL}ksi/master/hospital/hospital-type/search?hospitalTypeName=${param.name}&page=${param.page}&size=${param.size}&sortDirection=ASC&sortField=hospitalTypeCode`,
      { headers: headers() },
    );
  }
  async addHospitalType(params) {
    return await axios.post(
      `${API_URL}ksi/master/hospital/hospital-type`,
      params,
      {
        headers: headers(),
      },
    );
  }
  async delHospitalType(params) {
    return await axios.delete(
      `${API_URL}ksi/master/hospital/hospital-type/${params}`,
      {
        headers: headers(),
      },
    );
  }
  async editHospitalType(request) {
    return await axios.put(
      `${API_URL}ksi/master/hospital/hospital-type/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async getHospitalAll() {
    return await axios.get(
      `${API_URL}ksi/master/hospital/hospital/search?page=0&size=100&sortDirection=ASC&sortField=hospitalCode`,
      { headers: headers() },
    );
  }
  async getHospital(param) {
    return await axios.get(
      `${API_URL}ksi/master/hospital/hospital/search?hospitalName=${param.name}&page=${param.page}&size=${param.size}&sortDirection=ASC&sortField=hospitalCode`,
      { headers: headers() },
    );
  }
  async getHospitalId(request) {
    return await axios.get(
      `${API_URL}ksi/master/hospital/hospital/${request}`,
      { headers: headers() },
    );
  }
  async getSirsConfig(data) {
    return await axios.get(
      `${API_URL}ksi/master/hospital/hospital/with-sirs-config/${data}`,
      { headers: headers() },
    );
  }

  async addHospital(params) {
    return await axios.post(`${API_URL}ksi/master/hospital/hospital`, params, {
      headers: headers(),
    });
  }
  async delHospital(data) {
    return await axios.delete(
      `${API_URL}ksi/master/hospital/hospital/${data}`,
      {
        headers: headers(),
      },
    );
  }
  async editHospital(request) {
    return await axios.put(
      `${API_URL}ksi/master/hospital/hospital/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async addHospitalLogo(data) {
    return await axios.post(
      `${API_URL}admin/storage/file/hospital-logo/${data.id}`,
      data.input,
      {
        headers: headers(),
      },
    );
  }
}

export default new hospital();
