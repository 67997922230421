import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://api.klinisia.id/";

class diagnostik {
  async getdiagnostic(param) {
    return await axios.get(
      `${API_URL}ksi/master/radiology/group/search?diagnosticGroupName=${param.name}&page=${param.page}&size=${param.size}&sortDirection=ASC&sortField=diagnosticGroupOrder`,
      { headers: headers() },
    );
  }
  async getdiagnosticitem(data) {
    return await axios.get(
      `${API_URL}ksi/master/radiology/item/search?radDiagnosticGroupId=${data.id}&diagnosticItemName=${data.name}&page=${data.page}&size=${data.size}&sortDirection=ASC&sortField=diagnosticItemOrder`,
      { headers: headers() },
    );
  }
  async addNewGroup(data) {
    return await axios.post(`${API_URL}ksi/master/radiology/group`, data, {
      headers: headers(),
    });
  }
  async deleteGroup(data) {
    return await axios.delete(`${API_URL}ksi/master/radiology/group/${data}`, {
      headers: headers(),
    });
  }
  async addNewItem(data) {
    return await axios.post(`${API_URL}ksi/master/radiology/item`, data, {
      headers: headers(),
    });
  }
  async openItemDetail(data) {
    return await axios.get(`${API_URL}ksi/master/radiology/item/${data}`, {
      headers: headers(),
    });
  }
  async editItemDetail(data) {
    return await axios.put(
      `${API_URL}ksi/master/radiology/item/${data.id}`,
      data.item,
      { headers: headers() },
    );
  }
  async deleteItem(data) {
    return await axios.delete(`${API_URL}ksi/master/radiology/item/${data}`, {
      headers: headers(),
    });
  }
  async editGroup(request) {
    return await axios.put(
      `${API_URL}ksi/master/radiology/group/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
}

export default new diagnostik();
