<template>
  <v-app class="background">
    <v-container>
      <v-card>
        <v-card-title>
          <p class="">Tambah Type Rumah Sakit</p>
        </v-card-title>
        <v-divider class="devider"></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="4"> Kode Type Rumah Sakit </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="params.hospitalTypeCode"
                  :rules="nameRules"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> Nama Type Rumah Sakit </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="params.hospitalTypeName"
                  :rules="nameRules"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> Deskripsi Type Rumah Sakit </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="params.hospitalTypeDescription"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
                <v-col cols="4">
                  <p>Penyedia Layanan</p>
                </v-col>
                <v-col cols="6">
                  <v-select
                    dense
                    class=""
                    outlined
                    required
                    v-model="params.serviceProviderTypeCode"
                    :items="serviceProvider"
                    hide-details
                    item-value="value"
                    @input="selecttype"
                  >
                  </v-select>
                </v-col>
              </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="devider"></v-divider>
        <v-card-actions>
          <v-row justify="end" class="pb-5 mr-5">
            <v-btn
              dark
              color="error"
              bottom
              small
              rounded
              class="mr-2"
              @click="back"
            >
              Kembali
            </v-btn>
            <v-btn
              dark
              class="mr-15"
              small
              color="success"
              bottom
              rounded
              @click="simpan"
            >
              Simpan
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import hospitalServices from "@/services/hospital_services";

export default {
  components: {
    // Popup
  },
  data: () => ({
    serviceProvider: [
      {text:'infokes',value: 'infokes'},
      {text:'ksi',value: 'ksi'},
      {text:'ePoskestren',value: 'ePoskestren'},
      {text:'eKlinik',value: 'eKlinik'},

    ],
    nameRules: [(v) => !!v || "Wajib Di Isi"],
    params: {
      hptHospitalTypeId: "",
      hospitalTypeCode: "",
      hospitalTypeName: "",
      hospitalTypeDescription: "",
      serviceProviderTypeCode:"",
    },
  }),
  methods: {
      back(){
            this.$router.push("/hospitalType");
      },
    simpan() {
      console.log(this.params);
      if (this.params.hospitalTypeCode === "") {
        this.$refs.form.validate();
        this.$toast.open({
          message: "Kode Hospital Harus Di isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      } else if (this.params.hospitalTypeName === "") {
        this.$refs.form.validate();
        this.$toast.open({
          message: "Nama Hospital Harus Di isi",
          position: "top-right",
          type: "error",
          duration: 6000,
        });

      } else if (this.params.serviceProviderTypeCode === "") {
        this.$refs.form.validate();
        this.$toast.open({
          message: "Pilih Salah Satu Penyedia Layanan",
          position: "top-right",
          type: "error",
          duration: 6000,
        });

      } else {
        hospitalServices.addHospitalType(this.params).then((res) => {
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Type Hospital Berhasil Disimpan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
              this.$router.push("/hospitalType");
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/dashboard.scss";
.titleContent {
  font-weight: 500;
  line-height: 1rem !important;
}

.background {
  background-color: #edf4fb;
}
.devider {
  margin-top: -10px;
}
</style>