import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://api.klinisia.id/";

class Icd {
  async getICD(param) {
    return await axios.get(
      `${API_URL}ksi/master/consultation/icd-code/search?icdName=${param.name}&page=${param.page}&size=${param.size}&sortDirection=ASC&sortField=icdCode`,
      { headers: headers() },
    );
  }
  async addICD(request) {
    return await axios.post(
      `${API_URL}ksi/master/consultation/icd-code`,
      request,
      { headers: headers() },
    );
  }
  async detailICD(request) {
    return await axios.get(
      `${API_URL}ksi/master/consultation/icd-code/code/${request}`,
      { headers: headers() },
    );
  }
  async editICD(request) {
    return await axios.put(
      `${API_URL}ksi/master/consultation/icd-code/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async deleteICD(request) {
    return await axios.delete(
      `${API_URL}ksi/master/consultation/icd-code/${request}`,
      { headers: headers() },
    );
  }
}

export default new Icd();
