<template>
  <v-app class="background">
    <v-container>
      <v-card>
        <v-row>
          <v-col>
             <v-select
          class="ml-3 mr-3 pt-3"
          v-model="medSelection"
          :items="medList.map((e) => e.medicineName)"
          label="Pilih Obat"
          dense
          outlined
          @input="selecting"
        ></v-select>
        <!-- <v-autocomplete
          label="Filled"
          outlined
            dense
            v-model="inputNamaObat"
            required
            solo
            flat
            :items="dataSearch"
            item-text="medicineBrandName"
            item-value="phrMedicineBrandId"
            return-object
            :search-input.sync="search"
            hide-details
            clearable
            append-icon=""
            @change="changedObat()"
            class="customStyleTF"
          >
          </v-autocomplete> -->
          </v-col>
          <v-col>
             <v-autocomplete
            v-model="inputNamaObat"
            :search-input.sync="search"
            item-text="medicineBrandName"
            item-value="phrMedicineBrandId"
            :items="BrandSearch"
            outlined
            dense
            class="ml-3 mr-3 pt-3"
            label="Brand Search"
            @change="Choosen()"
          ></v-autocomplete>
         
          </v-col>
        </v-row>
       

        <v-row class="ml-2 mr-2">
          <v-col cols="4">
            <v-btn
            v-if="menuList[0].create === true"
              outlined
              round
              block
              color="success"
              dark
              rounded
              medium
              @click="openNewMed()"
            >Add New Medicine</v-btn>
          </v-col>

          <v-col cols="4">
            <v-btn
            v-if="menuList[0].create === true"
              outlined
              round
              block
              color="#2B92E4"
              dark
              rounded
              medium
              @click="openNewBrand()"
            >Add New Brand</v-btn>
          </v-col>

          <v-col class="cols-4">
            <v-btn
            v-if="menuList[0].delete === true"
              outlined
              round
              block
              color="error"
              dark
              rounded
              medium
              @click="deleteMed"
            >Delete Medicine</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container>
      <!-- card chip -->
      <v-card>
        <v-row>
          <v-col v-for="(obj, key) in BrandItem  " :key="key" cols="auto" class="ml-2">
            <v-chip
            v-if="menuList[0].update === true && menuList[0].delete === true"
              color="#4CF2A6"
              close
              @click="alert(obj)"
              @click:close="remove(obj)"
            >{{ obj.medicineBrandName }}</v-chip>
            
            <v-chip
            v-else-if="menuList[0].update === false && menuList[0].delete === true"
              color="#4CF2A6"
              close
             
              @click:close="remove(obj)"
            >{{ obj.medicineBrandName }}</v-chip>
             <v-chip
            v-else-if="menuList[0].update === true && menuList[0].delete === false"
              color="#4CF2A6"
              
               @click="alert(obj)"
              
            >{{ obj.medicineBrandName }}</v-chip>
             <v-chip
            v-else-if="menuList[0].update === false && menuList[0].delete === false"
              color="#4CF2A6"
              
            
              
            >{{ obj.medicineBrandName }}</v-chip>
          </v-col>
        </v-row>
      </v-card>

      <!-- Dialog newGroup -->
      <v-dialog v-model="newMedicineDIalog" persistent max-width="850">
        <v-card>
          <v-card-title class="icd-search">
            <p>Add new Medicine</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Group Code</p>
                  <v-text-field
                    v-model="newMed.medicineCode"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Medicine Name</p>
                  <v-text-field
                    v-model="newMed.medicineName"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="icd-text">Group Description</p>
                  <v-text-field
                    v-model="newMed.medicineDescription"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="success" dark @click="confirmNewMed">Tambah</v-btn>
            <v-btn medium outlined color="error" dark @click="cancelNewMed">Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog new Item -->
      <v-dialog v-model="newBrandDIalog" persistent max-width="830">
        <v-card>
          <v-card-title class="icd-search">
            <p>Add new Item</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col>
                  <p class="icd-text">Medicine Name</p>
                  <v-select
                    v-model="MedicineforBrand"
                    :items="medList.map((e) => e.medicineName)"
                    label="Medicine Name"
                    dense
                    outlined
                    @input="selectingData"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Brand Code</p>
                  <v-text-field
                    v-model="newBrand.medicineBrandCode"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Brand Name</p>
                  <v-text-field
                    v-model="newBrand.medicineBrandName"
                    :rules="[brandRules.required, brandRules.min]"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="TypeMed"
                    :items="baseItemsObat.map((e) => e.label)"
                    label="Tipe Obat"
                    dense
                    outlined
                    multiple
                    @input="selectingTypeMed"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    v-model="HowTo"
                    :items="baseItemHtu.map((e) => e.label)"
                    label="Cara Pakai"
                    dense
                    outlined
                    multiple
                    @input="selectingHowTo"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    v-model="Konsentrasi"
                    :items="baseItemUnit.map((e) => e.label)"
                    label="Konsentrasi"
                    dense
                    outlined
                    multiple
                    @input="selectingKonsentrasi"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Brand Description</p>
                  <v-text-field
                    v-model="newBrand.medicineBrandDescription"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="success" dark @click="ConfirmNewBrand">Tambah</v-btn>
            <v-btn medium outlined color="error" dark @click="CancelNewBrand">Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog edit Item -->
      <v-dialog v-model="editBrandDIalog" persistent max-width="830">
        <v-card>
          <v-card-title class="icd-search">
            <p>edit Item</p>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col>
                  <p class="icd-text">Medicine Name</p>
                  <v-select
                    v-model="MedicineforBrand"
                    :items="medList.map((e) => e.medicineName)"
                    label="Medicine Name"
                    dense
                    outlined
                    @input="selectingData"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12">
                  <p class="icd-text">Brand Code</p>
                  <v-text-field
                    v-model="newBrand.medicineBrandCode"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Brand Name</p>
                  <v-text-field
                    v-model="newBrand.medicineBrandName"
                    :rules="nameRules"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="TypeMed"
                    :items="baseItemsObat.map((e) => e.label)"
                    label="Tipe Obat"
                    dense
                    outlined
                    multiple
                    @input="selectingTypeMed"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    v-model="HowTo"
                    :items="baseItemHtu.map((e) => e.label)"
                    label="Cara Pakai"
                    dense
                    outlined
                    multiple
                    @input="selectingHowTo"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    v-model="Konsentrasi"
                    :items="baseItemUnit.map((e) => e.label)"
                    label="Konsentrasi"
                    dense
                    outlined
                    multiple
                    @input="selectingKonsentrasi"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="8" md="8" sm="12">
                  <p class="icd-text">Brand Description</p>
                  <v-text-field
                    v-model="newBrand.medicineBrandDescription"
                    placeholder
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4 mb-3">
            <v-spacer></v-spacer>
            <v-btn medium outlined color="success" dark @click="editConfirm">Edit</v-btn>
            <v-btn medium outlined color="error" dark @click="cancelEdit">Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Hapus -->
      <v-dialog v-model="dels" persistent max-width="830">
        <v-card>
          <v-row class="ma-4 pa-2">
            <v-col>
              <p class="icd-text">Group Name</p>
              <v-select
                v-model="MedicineforBrand"
                :items="medList.map((e) => e.medicineName)"
                label="Medicine Name"
                dense
                outlined
                @input="selectingDelete"
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn medium outlined color="primary" dark @click="deleteConfirm">Hapus</v-btn>
            <v-btn medium outlined color="error" dark @click="cancelDelete">Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

       <v-dialog v-model="delsChips" persistent max-width="830">
      <v-card>
        <v-card-text>
          <p class="detail">Yakin Hapus {{ChipsName}} ?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn medium outlined color="primary" dark @click="deleteConfirmChips"
            >Hapus</v-btn
          >
          <v-btn medium outlined color="error" dark @click="cancelDeleteChips"
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import serviceObat from "@/services/obat_service";
import PharmacyServices from "@/services/pharmacy_services";
export default {
  data: () => ({
    ChipsName:'',
    ChipsId:'',
    delsChips:false,
      menuList:{},
    login:{},
    search:null,
    inputNamaObat: "",
    editBrandDIalog: false,
    dels: false,
    DeleteId: "",
    TypeMedData: [],
    BrandSearch: [],
    HowToData: [],
    KonsentrasiData: [],
    Konsentrasi: [],
    HowTo: [],
    TypeMed: [],
    baseItemsObat: "",
    baseItemHtu: "",
    baseItemUnit: "",
    baseInstructionType: [],
    usertype: "how-to-use-type",
    MedicineforBrand: null,
    newBrandDIalog: false,
    nameRules: [(v) => !!v || "Name is required"],
    brandRules: {
     required: (value) => !!value || "Required.",
        min: (value) =>
          (value || "").length >= 5 ||
          "password minimum 5 karakter",
    },
    newMedicineDIalog: false,
    brandId: [],
    medList: [],
    listBrand: "",
    BrandItem: "",
    medSelection: null,
    newMed: {
      medicineCode: "",
      medicineName: "",
      medicineDescription: "",
    },
    newBrand: {
      phrMedicineId: "",
      medicineBrandCode: "",
      medicineBrandName: "",
      medicineTypeCodes: [],
      howToUseTypeCodes: [],
      unitTypeCodes: [],
      medicineBrandDescription: "",
      medicineBrandSearch: "",
    },
    medBrandId: "",
  }),
  mounted() {
    this.loginData();
    this.listObat();
    this.getLovs();
  },
  methods: {
    cancelDeleteChips(){
      this.delsChips = false
    },
    deleteConfirmChips(){
      console.log("delete");
serviceObat.deleteBrand(this.ChipsId).then((response) => {
        console.log(response);
        window.location.reload();
      });
    },
     loginData(){
      this.login = JSON.parse(window.localStorage.getItem("loginData"))
      console.log('login list',this.login.features);
        this.menuList = this.login.features.filter((item) =>
       item.featureName === 'Master Obat'
      );
      console.log(this.menuList);
      
    },
    Choosen(){
      console.log('ini obat',this.inputNamaObat);
      console.log(this.BrandSearch);
      var choose = this.BrandSearch.filter((item) => this.inputNamaObat.includes(item.phrMedicineBrandId))
      // console.log(choose[0].medicineName);
      // console.log(choose[0]);
      // console.log(choose[0].medicineCode);
      // console.log(this.medList);
      console.log(this.medList.filter((item)=>choose[0].phrMedicineId.includes(item.phrMedicineId)));
      this.medSelection = this.medList.filter((item)=>choose[0].phrMedicineId.includes(item.phrMedicineId))[0].medicineName
      console.log(this.medSelection);
      this.brandId = this.medList.filter((item) =>
        this.medSelection.includes(item.medicineName)
      );
      console.log(this.brandId);
      for(let i = 0; i< this.brandId.length; i++){
        if(this.brandId[i].medicineName !== ''){
           this.listBrand = this.brandId[i].phrMedicineId;
        }
      }
      // this.listBrand = this.brandId[0].phrMedicineId;
      serviceObat.getBrand(this.listBrand).then((response) => {
        console.log(response);
        this.BrandItem = response.data.payload.content;
        console.log(this.BrandItem);
      });
      console.log(this.BrandItem);
    },
    editConfirm() {
      let data = {
        id: this.medBrandId,
        item: this.newBrand,
      };
      serviceObat
        .editBrandDetail(data)
        .then((response) => {
          console.log(response);
          this.newBrand = {
            phrMedicineId: "",
            medicineBrandCode: "",
            medicineBrandName: "",
            medicineTypeCodes: [],
            howToUseTypeCodes: [],
            unitTypeCodes: [],
            medicineBrandDescription: "",
            medicineBrandSearch: "",
          };
          (this.Konsentrasi = []), (this.HowTo = []);
          this.TypeMed = [];
          // window.location.reload();
          this.BrandItem = ''
          serviceObat.getBrand(this.listBrand).then((response) => {
        console.log(response);
        this.BrandItem = ''
        this.BrandItem = response.data.payload.content;
      });
          this.editBrandDIalog = false;
        })
        .catch((error) => console.log(error));
    },
    cancelEdit() {
      this.newBrand = {
        phrMedicineId: "",
        medicineBrandCode: "",
        medicineBrandName: "",
        medicineTypeCodes: [],
        howToUseTypeCodes: [],
        unitTypeCodes: [],
        medicineBrandDescription: "",
        medicineBrandSearch: "",
      };
      (this.Konsentrasi = []), (this.HowTo = []);
      this.TypeMed = [];
      this.editBrandDIalog = false;
    },
    alert(obj) {
      console.log("Edit", obj);
      var brand = {};
      this.medBrandId = obj.phrMedicineBrandId;
      serviceObat
        .openBrandDetail(obj.phrMedicineBrandId)
        .then((response) => {
          console.log(response);
          this.editBrandDIalog = true;
          console.log(response.data.payload.phrMedicineId);
          console.log(
            this.medList.filter((item) =>
              response.data.payload.phrMedicineId.includes(item.phrMedicineId)
            )[0].medicineName
          );
          (brand = this.medList.filter((item) =>
            response.data.payload.phrMedicineId.includes(item.phrMedicineId)
          )),
            (this.MedicineforBrand = brand[0].medicineName);
          this.newBrand.phrMedicineId = response.data.payload.phrMedicineId;
          this.newBrand.medicineBrandCode =
            response.data.payload.medicineBrandCode;
          this.newBrand.medicineBrandName =
            response.data.payload.medicineBrandName;
          this.newBrand.medicineBrandDescription =
            response.data.payload.medicineBrandDescription;

          this.newBrand.unitTypeCodes = response.data.payload.unitTypeCodes.split(
            "|"
          );
          console.log(this.newBrand.unitTypeCodes);
          for(let i = 0; i < this.newBrand.unitTypeCodes.length; i ++){
          this.Konsentrasi.push(this.baseItemUnit.filter((item) => this.newBrand.unitTypeCodes[i].includes(item.value))[0].label)

          }
          this.newBrand.howToUseTypeCodes = response.data.payload.howToUseTypeCodes.split(
            "|"
          );
          for (let i = 0; i < this.newBrand.howToUseTypeCodes.length; i++) {
            this.HowTo.push(
              this.baseItemHtu.filter((item) =>
                this.newBrand.howToUseTypeCodes[i].includes(item.value)
              )[0].label
            );
          }
          this.newBrand.medicineTypeCodes = response.data.payload.medicineTypeCodes.split(
            "|"
          );
          console.log(this.newBrand.medicineTypeCodes);
          for (let i = 0; i < this.newBrand.medicineTypeCodes.length; i++) {
            this.TypeMed.push(
              this.baseItemsObat.filter((item) =>
                this.newBrand.medicineTypeCodes[i].includes(item.value)
              )[0].label
            );
            console.log(this.TypeMed);
          }
          
          console.log(this.newBrand);
        })
        .catch((error) => console.log(error));
    },
    remove(obj) {
      this.ChipsId = obj.phrMedicineBrandId
      this.ChipsName = obj.medicineBrandName
      this.delsChips = true
      console.log("Delete", obj);
      // serviceObat.deleteBrand(obj.phrMedicineBrandId).then((response) => {
      //   console.log(response);
      //   window.location.reload();
      // });
    },
    deleteConfirm() {
      console.log(this.DeleteId);
      serviceObat
        .deleteobat(this.DeleteId)
        .then((response) => {
          console.log(response);
          if (response.data.error === "Data integrity") {
          this.$toast.open({
            message: "Hapus Brand Terlebih dahulu",
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        }else{
          this.listObat();
          // this.dels = false;
           window.location.reload();
          }
        })
        .catch((error) => console.log(error));
        // window.location.reload();
    },
    selectingDelete() {
      console.log(this.MedicineforBrand);
      this.brandId = this.medList.filter((item) =>
        this.MedicineforBrand.includes(item.medicineName)
      );
      console.log(this.brandId);
      this.DeleteId = this.brandId[0].phrMedicineId;
      console.log(this.DeleteId);
    },
    cancelDelete() {
      this.dels = false;
    },
    deleteMed() {
      this.dels = true;
    },
    ConfirmNewBrand() {
      if(this.MedicineforBrand === null) {
         this.$toast.open({
              message: 'isi Medicine Name terlebih dahulu',
              position: "top-right",
              type: "error",
              duration: 6000,
            });
      }
       else if(this.newBrand.medicineBrandCode === '' || this.newBrand.medicineBrandName === ''){
        // alert('Isi Group code dan medicine Name terlebih dahulu')
        this.$toast.open({
              message: 'Isi Brand code dan Brand Name terlebih dahulu',
              position: "top-right",
              type: "error",
              duration: 6000,
            });
            console.log(this.TypeMed.length)
      }
      else if (this.newBrand.medicineBrandName.length < 5){
        this.$toast.open({
              message: 'Brand Name minimal 5 karakter',
              position: "top-right",
              type: "error",
              duration: 6000,
            });
      }
      else if(this.TypeMed.length === 0){
        this.$toast.open({
              message: 'Isi Tipe Obat terlebih dahulu',
              position: "top-right",
              type: "error",
              duration: 6000,
            });
      }
       else if(this.HowTo.length === 0){
        this.$toast.open({
              message: 'Isi Cara Pakai terlebih dahulu',
              position: "top-right",
              type: "error",
              duration: 6000,
            });
      }
       else if(this.Konsentrasi.length === 0){
        this.$toast.open({
              message: 'Isi Konsentrasi Obat terlebih dahulu',
              position: "top-right",
              type: "error",
              duration: 6000,
            });
      }
      else{
      console.log(this.newBrand);
      serviceObat
        .addNewBrand(this.newBrand)
        .then((response) => {
          console.log(response);
          if (response.data.error !== "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          }else{
            window.location.reload();
          }

          // this.newBrandDIalog = false;
          // window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });}
    },
    CancelNewBrand() {
      this.newBrandDIalog = false;
      window.location.reload();
    },
    selectingKonsentrasi() {
      console.log(this.Konsentrasi);
      this.newBrand.unitTypeCodes.splice(0, this.newBrand.unitTypeCodes.length);
      this.KonsentrasiData = this.baseItemUnit.filter((item) =>
        this.Konsentrasi.includes(item.label)
      );
      for (let i = 0; i < this.KonsentrasiData.length; i++) {
        console.log(this.KonsentrasiData[i].value);

        this.newBrand.unitTypeCodes.push(this.KonsentrasiData[i].value);
      }
      console.log(this.newBrand);
    },
    selectingHowTo() {
      console.log(this.HowTo);
      this.newBrand.howToUseTypeCodes.splice(
        0,
        this.newBrand.howToUseTypeCodes.length
      );

      this.HowToData = this.baseItemHtu.filter((item) =>
        this.HowTo.includes(item.label)
      );
      for (let i = 0; i < this.HowToData.length; i++) {
        console.log(this.HowToData[i].value);
        this.newBrand.howToUseTypeCodes.push(this.HowToData[i].value);
      }
      console.log(this.newBrand);
    },
    selectingTypeMed() {
      console.log(this.TypeMed);
      this.newBrand.medicineTypeCodes.splice(
        0,
        this.newBrand.medicineTypeCodes.length
      );
      console.log(this.newBrand.medicineTypeCodes);
      this.TypeMedData = this.baseItemsObat.filter((item) =>
        this.TypeMed.includes(item.label)
      );
      for (let i = 0; i < this.TypeMedData.length; i++) {
        console.log(this.TypeMedData[i].value);
        this.newBrand.medicineTypeCodes.push(this.TypeMedData[i].value);
      }
      console.log(this.newBrand);
    },
    getLovs() {
      PharmacyServices.getMasterLovs().then((response) => {
        console.log(response);
        this.baseItemsObat = response.data.payload["medicine-type"];
        this.itemPeriod = response.data.payload["period-type"];
        this.baseItemHtu = response.data.payload["how-to-use-type"];
        this.baseItemUnit = response.data.payload["unit-type"];
        this.baseInstructionType = response.data.payload["instruction-type"];
      });
    },
    selectingData() {
      console.log(this.MedicineforBrand);
      this.brandId = this.medList.filter((item) =>
        this.MedicineforBrand.includes(item.medicineName)
      );
      console.log(this.brandId);
      for(let i = 0; i< this.brandId.length; i++){
        if(this.brandId[i].medicineName !== ''){
          if(this.brandId[i].medicineName === this.MedicineforBrand){
           this.newBrand.phrMedicineId = this.brandId[i].phrMedicineId;
           console.log( this.newBrand.phrMedicineId)}
           
        }
      }
      // this.newBrand.phrMedicineId = this.brandId[0].phrMedicineId;
      console.log(this.newBrand);
    },
    openNewBrand() {
      this.newBrandDIalog = true;
    },
    confirmNewMed() {
      if(this.newMed.medicineCode === '' || this.newMed.medicineName === ''){
        // alert('Isi Group code dan medicine Name terlebih dahulu')
        this.$toast.open({
              message: 'Isi Group code dan medicine Name terlebih dahulu',
              position: "top-right",
              type: "error",
              duration: 6000,
            });
      }else{
      
      console.log(this.newMed);
      serviceObat
        .addObat(this.newMed)
        .then((reponse) => {
          console.log(reponse);
          if (reponse.data.error !== "") {
            this.$toast.open({
              message: reponse.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          }else{
          this.listObat();
          // this.newMedicineDIalog = false;
          window.location.reload();}
        })
        .catch((error) => console.log(error));
      }
    },
    cancelNewMed() {
      this.newMedicineDIalog = false;
      window.location.reload();
    },
    openNewMed() {
      this.newMedicineDIalog = true;
    },
    selecting() {
      console.log(this.medSelection);
      // console.log(this.medList);
      this.brandId = this.medList.filter((item) =>
        this.medSelection.includes(item.medicineName)
      );
      for(let i = 0; i< this.brandId.length; i++){
        if(this.brandId[i].medicineName !== ''){
          if(this.brandId[i].medicineName === this.medSelection){
           this.listBrand = this.brandId[i].phrMedicineId;}
        }
      }
      console.log(this.brandId);
      console.log(this.brandId.length);
      // this.listBrand = this.brandId[0].phrMedicineId;
      console.log(this.listBrand);
      serviceObat.getBrand(this.listBrand).then((response) => {
        console.log(response);
        this.BrandItem = response.data.payload.content;
      });
    },
    listObat() {
      serviceObat
        .getnameObat()
        .then((res) => {
          console.log(res);
          this.medList = res.data.payload.content;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    search(val){
      console.log(val);
      if (val != null) {
        if (val.length >= 3) {
          serviceObat.searchBrand(val).then((response) => {
            this.BrandSearch = response.data.payload.content;
            console.log(this.BrandSearch);
            // console.log(JSON.stringify(this.dataSearch));
          });
        }
      }
    }
  }
};
</script>

<style>
.background {
  background-color: #edf4fb;
}
</style>