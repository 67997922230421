/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-17 23:28:33
 * @modify date 2021-01-17 23:28:33
 * @desc [description]
 */


<template>
    <v-container fluid fill-height>
        <v-row align="center" justify="center">
            <v-col cols="6">
                <v-card height="500">
                    <v-row justify="center">
                        <img src="@/assets/ksi logo 1.png" width="50" height="50" alt="" class="mt-16">
                    </v-row>
                    <v-row justify="center" align="center" class="ma-10 pa-10">
                        <v-col cols="12">
                            <div class="plainText">Opps!!!</div>
                        </v-col>
                        <v-col cols="12">
                            <div class="plainText">Maaf Halaman yang Anda cari tidak tersedia</div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Error',
    data: () => ({

    }),
}
</script>

<style scoped>
.bgColor {
  background: #EDF4FB;
}
.plainText {
    text-align: center;
    font-size: 24px;
}
</style>