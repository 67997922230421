export default function headers() {
  let shortToken = window.localStorage.getItem("shortToken");
  if (shortToken != null) {
    return {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + shortToken,
      feType: "admin",
      feVersion: "2.15.96.20220110",
    };
  }
  return {};
}
