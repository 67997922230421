<template>
  <v-app class="background">
    <b-container>
      <b-form>
        <b-card class="content">
          <b-card-title class="title">
            <b-row>
              <b-col>
                <h1 class="text-title">Tambahkan Dokter</h1>
              </b-col>
            </b-row>
          </b-card-title>

          <b-card-text>
            <p class="text-lable">Kategori</p>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-row>
                <b-col md="3" sm="6">
                  <b-form-radio
                    v-model="user2.doctor.docMstMedicSpecializationId"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="UMUM"
                    >Dokter Umum</b-form-radio
                  >
                </b-col>
                <b-col md="3" sm="6">
                  <p>*)Wajib diisi</p>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <b-form-group class="text-label" label="Nama Lengkap *">
                    <b-form-input
                      type="text"
                      v-model="user2.doctor.fullName"
                      placeholder="Masukkan Nama Lengkap"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="user2.doctor.birthDate"
                          dense
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="user2.doctor.birthDate"
                        :max="today"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <b-form-group
                    class="text-label"
                    label="Biaya Konsultasi / 30 menit * "
                  >
                    <b-input-group
                      prepend="Rp"
                      label="Biaya Konsultasi / 30 menit * "
                    >
                      <b-form-input
                        id="biaya"
                        type="number"
                        v-model="user2.doctor.taxNumber"
                        placeholder=""
                        disabled
                        required
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="6" sm="12">
                  <b-form-group class="text-label" label="No. SIP *">
                    <b-form-input
                      v-model="user2.doctor.sipNumber"
                      type="number"
                      placeholder="cth : 9909000000"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" sm="12">
                  <b-form-group class="text-label" label="Email *">
                    <b-form-input
                      :state="passwordcheck"
                      v-model="user2.doctor.email"
                      placeholder="masukkan email dokter"
                      type="email"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback id="password-confirm-wrong">
                      wrong email format
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group class="text-label" label="No. Handphone *">
                    <b-form-input
                      v-model="user2.doctor.mobilePhone"
                      placeholder=""
                      type="number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group class="text-label" label="Foto Dokter">
                    <b-form-file
                      @change="upload($event)"
                      accept="image/*"
                      placeholder="Upload foto dokter"
                    ></b-form-file>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-card-text>

          <v-row>
            <v-col offset-md="9">
              <v-btn
                class="btn-edit"
                @click="reset"
                small
                outlined
                color="primary"
                style="margin-right: 15px"
                >Reset</v-btn
              >
              <v-btn class="btn-edit" small @click="submited" color="success"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </b-card>
      </b-form>
    </b-container>
  </v-app>
</template>

<script>
  import { required, sameAs } from "vuelidate/lib/validators";
  import { BFormInput } from "bootstrap-vue";
  import moment from "moment";

  import axios from "axios";
  export default {
    name: "dashboard",
    components: {
      BFormInput,
    },
    data: () => ({
      today: moment().format("YYYY-MM-DD"),
      foto: null,
      date: null,
      menu: false,
      menu1: false,
      menu2: false,
      modal: false,
      time: "20:30",
      // user: {
      //   selected: null,
      //   fullName: "",
      //   Foto: null,
      //   email: "",
      //   mobilePhone: "",
      //   Password: "",
      //   sipNumber: "",
      //   Confirm: "",
      //   Biaya: "",
      //   simple: "",
      //   birthDate: null,
      //   dateStart: "",
      //   dateEnd: "",
      //   options: {
      //     // https://momentjs.com/docs/
      //   },
      // },
      user2: {
        doctor: {
          docDoctorHospitalId: "string",
          docDoctorId: "string",
          hptHospitalId: "MEDISTRA",
          hospitalEmployeeNumber: "",
          docMstMedicSpecializationId: null,
          sipNumber: "",
          secUserId: "",
          idNumber: "string",
          taxNumber: "",
          passportNumber: "string",
          fullName: "",
          gender: "male",
          birthDate: null,
          mobilePhone: "",
          email: "",
          geoSubDistrictId: "string",
          postalCode: "string",
          address: "string",
          qualifications: null,
          awards: null,
          dateSchedule: null,
          daySchedule: null,
          profilePictureUrl: "string",
        },
        password: "string",
        rePassword: "string",
      },
      // user2:{},
      kategori: [
        {
          text: "Ketersediaan",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "From:",
        },
        {
          text: "To:",
        },
      ],
      jadwal: [
        {
          name: "Senin",
          check: "Senin",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
        {
          name: "Selasa",
          check: "Selasa",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
        {
          name: "Rabu",
          check: "Rabu",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
        {
          name: "Kamis",
          check: "Kamis",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
        {
          name: "Jumat",
          check: "Jumat",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
        {
          name: "Sabtu",
          check: "Sabtu",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
        {
          name: "Minggu",
          check: "Miinggu",
          timeStart: "09:00",
          timeEnd: "09:00",
          slot: "+ Time Slot",
        },
      ],
    }),
    validations: {
      password: {
        required,
      },
      repeatPassword: {
        sameAsPassword: sameAs("password"),
      },
    },

    methods: {
      upload(event) {
        console.log("VALUE " + JSON.stringify(event));
        this.foto = event.target.files[0];
      },
      submited() {
        if (this.user2.doctor.docMstMedicSpecializationId === null) {
          this.$toast.error("Pilih Jenis Dokter Lebih Dulu", {
            type: "error",
            position: "top-right",
            duration: 4000,
          });
        } else if (this.user2.doctor.fullName === "") {
          this.$toast.error("Nama Dokter Tidak Boleh Kosong", {
            type: "error",
            position: "top-right",
            duration: 4000,
          });
        } else if (this.user2.doctor.email === "") {
          this.$toast.error("Email Tidak Boleh Kosong", {
            type: "error",
            position: "top-right",
            duration: 4000,
          });
        } else if (this.user2.doctor.sipNumber === "") {
          this.$toast.error("SIP Tidak Boleh Kosong", {
            type: "error",
            position: "top-right",
            duration: 4000,
          });
        } else if (this.user2.doctor.mobilePhone === "") {
          this.$toast.error("No Handphone Tidak Boleh Kosong", {
            type: "error",
            position: "top-right",
            duration: 4000,
          });
        } else {
          axios
            .post(
              " https://api.klinisia.id/admin/doctor/register",
              this.user2,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "Bearer " + window.localStorage.getItem("shortToken"),
                },
              },
            )
            .then((response) => {
              if (response.data.errors.length === 0) {
                console.log(response);
                console.log(response);
                window.localStorage.setItem(
                  "secuserId2",
                  response.data.payload.doctor.secUserId,
                );
                console.log("ini getlocal");
                console.log(window.localStorage.getItem("secuserId2"));
                var id2 = window.localStorage.getItem("secuserId2");
                console.log(window.localStorage.getItem("secuserId2"));
                // console.log(id2);
                let formData = new FormData();
                formData.append("profilePicture", this.foto);

                axios
                  .post(
                    ` https://api.klinisia.id/storage/file/profile-picture/${window.localStorage.getItem(
                      "secuserId2",
                    )}`,
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",

                        Authorization:
                          "Bearer " + window.localStorage.getItem("shortToken"),
                      },
                    },
                  )
                  .then((response) => {
                    console.log("foto sukses");
                    console.log(response);
                    console.log("id setelah response");
                    console.log(id2);
                    console.log(
                      ` https://api.klinisia.id/storage/file/profile-picture/${id2}`,
                    );
                  })
                  .catch((err) => {
                    console.log("foto error");
                    this.$toast.error(err, {
                      type: "error",
                      position: "top-right",
                      duration: 5000,
                    });
                    console.log(this.foto);
                  });
                this.$toast.success("Dokter Baru Berhasil Di Simpan", {
                  type: "success",
                  position: "top-right",
                  duration: 5000,
                });
                this.reset();
              }

              console.warn("response", response);

              if (response.data.errors[0].message !== "") {
                console.log(response.data.errors.length);
                this.$toast.error(
                  response.data.errors[0].field +
                    " " +
                    response.data.errors[0].message,
                  {
                    type: "error",
                    position: "top-right",
                    duration: 4000,
                  },
                );
              }
            })
            .catch((error) => {
              console.log("post error");
              console.log(error);
            });
        }
      },
      reset() {
        this.user2.doctor.docMstMedicSpecializationId = null;
        this.user2.doctor.fullName = "";
        this.user2.doctor.mobilePhone = "";
        this.user2.doctor.email = "";
        this.user2.doctor.sipNumber = "";
        this.user2.doctor.birthDate = null;
      },
      dateDisabled(ymd, date) {
        const weekday = date.getDay();
        return weekday === 0 || weekday === 6;
      },
    },
    computed: {
      passwordcheck() {
        //   return this.user.Confirm.length > 0 && this.user.Confirm === this.user.Password
        return (
          (this.user2.doctor.email.includes("@") &&
            this.user2.doctor.email.includes(".")) ||
          this.user2.doctor.email === ""
        );
      },
      PhotoRule() {
        return this.user.Foto < 2000;
      },
    },
  };
</script>

<style lang="scss" scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  @import "../../scss/dashboard.scss";
</style>
