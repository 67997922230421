import axios from "axios";
import headers from "@/services/headers";

const API_URL = "https://api.klinisia.id/";

class Doctor {
  async getMedSpecialist() {
    return await axios.get(`${API_URL}general/doctor/medic-specialization`, {
      headers: headers(),
    });
  }
  async getListDoctor(request) {
    return await axios.get(
      `${API_URL}admin/doctor/list?page=${request.page}&size=${request.size}&generalDoctor=${request.filter}&sortDirection=${request.sortBy}&search=${request.search}`,
      { headers: headers() },
    );
  }
  async getDoctorById(id) {
    return await axios.get(`${API_URL}admin/doctor/view/${id}`, {
      headers: headers(),
    });
  }
  async delete(id) {
    return await axios.delete(`${API_URL}admin/doctor/delete/${id}`, {
      headers: headers(),
    });
  }
  async getSpesialisasi(param) {
    return await axios.get(
      `${API_URL}ksi/master/doctor/medic-specialization/search?specializationName=${param.name}&page=${param.page}&size=${param.size}&sortDirection=ASC&sortField=specializationCode`,
      { headers: headers() },
    );
  }
  async getSpesialisasiByID(param) {
    return await axios.get(
      `${API_URL}ksi/master/doctor/medic-specialization/${param}`,
      { headers: headers() },
    );
  }
  async getSubSpesialisasi(param) {
    return await axios.get(
      `${API_URL}ksi/master/doctor/medic-subspc/search?subSpcName=${param.name}&page=${param.page}&size=${param.size}&sortDirection=ASC&sortField=subSpcCode`,
      { headers: headers() },
    );
  }
  async postSpesialisasi(params) {
    return await axios.post(
      `${API_URL}ksi/master/doctor/medic-specialization`,
      params,
      { headers: headers() },
    );
  }
  async postSubSpesialisasi(params) {
    return await axios.post(
      `${API_URL}ksi/master/doctor/medic-subspc`,
      params,
      { headers: headers() },
    );
  }
  async editSpesialisasi(request) {
    return await axios.put(
      `${API_URL}ksi/master/doctor/medic-specialization/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async editSubSpesialisasi(request) {
    return await axios.put(
      `${API_URL}ksi/master/doctor/medic-subspc/${request.id}`,
      request.body,
      { headers: headers() },
    );
  }
  async deleteSpesialisasi(request) {
    return await axios.delete(
      `${API_URL}ksi/master/doctor/medic-specialization/${request}`,
      { headers: headers() },
    );
  }
  async deleteSubSpesialisasi(request) {
    return await axios.delete(
      `${API_URL}ksi/master/doctor/medic-subspc/${request}`,
      { headers: headers() },
    );
  }
  async postPp(params) {
    return await axios.post(
      `${API_URL}admin/storage/file/doctor-pp/${params.id}`,
      params.body,
      {
        headers: {
          "Content-Type": "multipart/form-data",

          Authorization: "Bearer " + window.localStorage.getItem("shortToken"),
        },
      },
    );
  }
}

export default new Doctor();
